///////////////////////////////////////////////////////////////////////////////////////////////
// Warning : Property of Deemsoft Global Solutions LLC, It is illegal to make copy of this code
// Author : Gangaswamy Kempahonnaiah
///////////////////////////////////////////////////////////////////////////////////////////////
export const Members = {
	userid : '', 
	cardid : '', 
	firstname : '', 
	lastname : '', 
	middlename : '', 
	dateofbirth : '', 
	age : '', 
	gender : '', 
	email : '', 
	cellphone : '', 
	homephone : '', 
	batch : '', 
	course_id : '', 
	rollno : '', 
	department : '', 
	address1 : '', 
	address2 : '', 
	city : '', 
	state : '', 
	zipcode : '', 
	country : '', 
	select : '',
	membership_fee : '', 
	registrationtype : '', 
	status : '', 
	notes : '',
	select:'', 
	id : '', 
	_id : '', 
	createdby : '', 
	updatedby : '', 
	date_created : '', 
	date_updated : '', 
}