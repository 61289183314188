import React, {Component} from 'react';
import  {Pie} from 'react-chartjs-2';
import axios from 'axios';
import { connect } from 'react-redux';
import { base_url } from '../constants/Constants';

 class Piechart extends Component {
	constructor(props){
		super(props);
		this.state = {
			
        }
    }
componentDidMount() {
	//console.log(this.props.parentObject.numlist);
}

  render() {
	var data = {
		labels: [
			'Books',
			'CD/DVD',
			'Journals'
		],
		datasets: [{
			data: this.props.parentObject.numlist,
			backgroundColor: [
			'#FF6384',
			'#36A2EB',
			'#FFCE56',
			],
			
			hoverBackgroundColor: [
			'#FF6384',
			'#36A2EB',
			'#FFCE56',
			]
		}]
	};
	
	
    return (
      <div>
         <Pie
		  ref="chart"
		  data={data}
		 
		 />
      </div>
    );
  }

}
const mapToProps = ({ loggedUserState }) => ({ user: loggedUserState })
export default connect(mapToProps)(Piechart);