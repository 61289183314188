///////////////////////////////////////////////////////////////////////////////////////////////
// Warning : Property of Deemsoft Global Solutions LLC, It is illegal to make copy of this code
// Author : Gangaswamy Kempahonnaiah
///////////////////////////////////////////////////////////////////////////////////////////////
export const Books_Catalog = {
	accnumber : '', 
	barcode : '', 
	isbnnumber : '', 
	bookauthor : '', 
	booktitle : '', 
	publishername : '', 
	publisherphone : '', 
	publishyear : '', 
	publishagent : '', 
	publishagentphone : '', 
	edition : '', 
	editionyear : '', 
	preliminarypages : '', 
	pages : '', 
	indianprice : '', 
	foreignprice : '', 
	currency : '', 
	booktype : '', 
	department : '', 
	subject : '', 
	callnumber : '', 
	ponumber : '', 
	podate : '', 
	billnumber : '', 
	billdate : '', 
	trackcode : '', 
	bookstatus : '', 
	notes : '', 
	id : '',
	_id:'', 
	createdby : '', 
	updatedby : '', 
	date_created : '', 
	date_updated : '', 
	vender:''
}