import React, {Component} from 'react';
import  { Polar } from 'react-chartjs-2';
import { connect } from 'react-redux';


 class PolarChart extends Component {
	constructor(props){
		super(props);
		this.state = {
			
        }
    }
componentDidMount() {
}

  render() {
    var data = {
      labels: ['Total Books', 'Total Issued','Total Damaged'],
      datasets: [
        {
          label: '# of Votes',
          data: this.props.parentObject.numlist,
          backgroundColor: [
            '#0033cc',
            '#ff4000',
            'rgba(255, 206, 86)',
            'rgba(75, 192, 192, 0.5)',
            'rgba(153, 102, 255, 0.5)',
            'rgba(255, 159, 64, 0.5)',
          ],
          borderWidth: 2,
        },
      ],
    };
    const text="text";
	
    return (
      <div>
         <Polar
		      data={data}
          width={100}
          height={60}
          text={text}	
		     options={{ maintainAspectRatio: true   }} 
		 />
      </div>
    );
  }

}
const mapToProps = ({ loggedUserState }) => ({ user: loggedUserState })
export default connect(mapToProps)(PolarChart);