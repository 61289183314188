import React from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { Button, Modal, ModalBody, ModalFooter } from 'react-bootstrap';
import { base_url } from '../constants/Constants';
import { Validators } from '../utils/Validators';
import { Formators } from '../utils/Formators';
import { Booktrack} from '../models/booktrack';
import '../styles/Styles.css';

class BookTrack extends React.Component {
	constructor(props){
		super(props);
		this.state = {
            validations : {},
			objList : [],
			showModal : false,
			searchmessage:'',
			searchtable:false,
			formObj :Booktrack
        }
		this.addObj = this.addObj.bind(this);
		this.searchObj=this.searchObj.bind(this);
    }
    componentDidMount(){
		let tempObj = {};
		for (let key in Booktrack) { tempObj[key] = ""; }
		this.setState({validations:tempObj});
	}
	searchObj=()=>{
		let tempObj = {};
		for (let key in Booktrack) { tempObj[key] = ""; }
		this.setState({ formObj:tempObj,showModal : true});

	}
	searchBook = (id,by) => {
		if(id != "" && id != undefined){
		 console.log(id);
		 let temp={search : id ,type: by}
		 axios.post(base_url+'books_catalog/search1', temp, this.props.user.header )
		 .then(res =>{
			if(res.data.length > 0){
			console.log(res.data)
			let tempObj = this.state.searchmessage;
				tempObj="";
				this.setState({searchmessage:tempObj});
			this.setState({objList:res.data,searchtable:true});
			}else{
				let tempObj = this.state.searchmessage;
				tempObj="Book  details you entered is not match with our records";
				this.setState({searchmessage:tempObj});

				let tempObjs = {};
				let temarr = [];
				let temp=this.state.formObj;
		    	for (let key in Booktrack ) { temp[key] = ""; }
				this.setState({objList:temarr,searchtable:false});
				for (let key in Booktrack) { tempObjs[key] = ""; }
				this.setState({formObj:temp});
				//this.setState({validations:tempObj,formObj2:tempObjs});
				//console.log("not found");
			}
		}).catch(function (error) { console.log(error); }); 

		this.renderObjlist();
	}else{
		let tempObj = this.state.searchmessage;
		tempObj="Please Enter ACC number or book title or author to search";
		let temarr = [];
		this.setState({searchmessage:tempObj});
		this.setState({objList:temarr,searchtable:false});
	}
	
}
	
	addObj = () => { 
		
		let tempObj = {};
		for (let key in Booktrack ) { tempObj[key] = ""; }
		this.setState({ formObj:tempObj});
	}
	searchType=(id,title,sname)=>{
		if(id !== '' && id !== undefined){
		   this.searchBook(id,"accnumber");
		}else if(sname !== '' && sname !== undefined){
		  this.searchBook(sname,"Name");
		}
		else if(title !== '' && title !== undefined){
			this.searchBook(title,"author");
		  }
		else{
		  let tempObj = this.state.searchmessage;
		  tempObj="Please Enter Acc number or book title or author tosearch";
		  let temarr = [];
		  this.setState({searchmessage:tempObj});
		  this.setState({objList:temarr,searchtable:false});
		}
	}
	

	getDetails = (i,id) => { 
		let tempObj = this.state.objList[i];
		this.setState({formObj:tempObj});
		//console.log(tempObj);
	this.setState({showModal:false});
	}
		
    validate = (val) => {
		let tempObj = this.state.validations;
		switch(val){

			case "accnumber": tempObj.accnumber = Validators.name(this.state.formObj.accnumber); break;
            case "isbnnumber": tempObj.isbnnumber = Validators.name(this.state.formObj.isbnnumber); break;
			case "bookauthor": tempObj.bookauthor = Validators.name(this.state.formObj.bookauthor); break;
            case "booktitle": tempObj.booktitle = Validators.name(this.state.formObj.booktitle); break;
			case "publishername": tempObj.publishername = Validators.name(this.state.formObj.publishername); break;
            case "edition": tempObj.edition = Validators.name(this.state.formObj.edition); break;
			case "editionyear": tempObj.editionyear = Validators.year(this.state.formObj.editionyear); break;
			case "preliminarypages": tempObj.preliminarypages = Validators.number(this.state.formObj.preliminarypages); break;
            case "pages": tempObj.pages = Validators.number(this.state.formObj.pages); break;
			case "indianprice": tempObj.indianprice = Validators.number(this.state.formObj.indianprice); break;
			case "foreignprice": tempObj.foreignprice = Validators.number(this.state.formObj.foreignprice); break;
            case "booktype": tempObj.booktype = Validators.select(this.state.formObj.booktype); break;
			case "department": tempObj.department = Validators.select(this.state.formObj.department); break;
			case "subject": tempObj.subject = Validators.select(this.state.formObj.subject); break;
			case "callnumber": tempObj.callnumber = Validators.phone(this.state.formObj.callnumber); break;
            case "trackcode": tempObj.trackcode = Validators.name(this.state.formObj.trackcode); break;
			case "bookstatus": tempObj.bookstatus = Validators.select(this.state.formObj.bookstatus); break;
			case "notes": tempObj.notes = Validators.name(this.state.formObj.notes); break;
			case "vender": tempObj.vender = Validators.name(this.state.formObj.vender); break;
			
        }
		this.setState({validations:tempObj});
    }
    format = (val, e) => { 
		let tempObj = this.state.formObj;
		switch(val){
			case "accnumber": tempObj.accnumber = Formators.name(e.target.value); break;
            case "isbnnumber": tempObj.isbnnumber = Formators.name(e.target.value); break;
			case "bookauthor": tempObj.bookauthor = Formators.name(e.target.value); break;
            case "booktitle": tempObj.booktitle = Formators.name(e.target.value); break;
			case "publishername": tempObj.publishername = Formators.name(e.target.value); break;
            case "edition": tempObj.edition = Formators.name(e.target.value); break;
			case "editionyear": tempObj.editionyear = Formators.name(e.target.value); break;
			case "preliminarypages": tempObj.preliminarypages = Formators.name(e.target.value); break;
            case "pages": tempObj.pages = Formators.name(e.target.value); break;
			case "indianprice": tempObj.indianprice = Formators.name(e.target.value); break;
			case "foreignprice": tempObj.foreignprice = Formators.name(e.target.value); break;
            case "vender": tempObj.vender = Formators.name(e.target.value); break;
            case "booktype": tempObj.booktype = Formators.name(e.target.value); break;
			case "department": tempObj.department = Formators.name(e.target.value); break;
            case "subject": tempObj.subject = Formators.name(e.target.value); break;
			case "callnumber": tempObj.callnumber = Formators.name(e.target.value); break;
            case "trackcode": tempObj.trackcode = Formators.name(e.target.value); break;
			case "bookstatus": tempObj.bookstatus = Formators.name(e.target.value); break;
            case "notes": tempObj.notes = Formators.name(e.target.value); break;
        }
		this.setState({formObj:tempObj});
    }
	renderObjlist = () => {
		if( this.state.objList != undefined && this.state.objList.length > 0){
			return this.state.objList.map((item, index) =>{
				return (
					<tr key={index} id={index} onClick={()=>{this.getDetails(index,item)}}>
						<td>{item.accnumber}</td>
						<td>{item.booktitle}</td>
						<td>{item.bookauthor}</td>
					</tr>
					)
			 }, this);
		}
	}
    render(){
        return(
            <div className="shadow-sm p-3 mb-5 bg-white rounded">
            <div className="d-flex row">
                  <div className="p-2">
                    <label htmlFor="idaccnumber" className="label-custom" >ACC No.<span className="star">*</span></label> 
                    <input value={this.state.formObj.accnumber} required id="idaccnumber"  onChange={(e)=>{this.format("accnumber",e)}}  onBlur={()=>{ this.validate("accnumber")}} className="form-control form-control-sm" style={this.state.validations.accnumber===""?{}:{"borderColor":"red"}} type="text" required readOnly/> 
                    <span className="errormsg">{this.state.validations.accnumber}</span>
                </div>
                <div className="p-2">
						<label htmlFor="idisbnnumber" className="label-custom" >ISBN No<span className="star">*</span></label> 
						<input value={this.state.formObj.isbnnumber} id="idisbnnumber"  onChange={(e)=>{this.format("isbnnumber",e)}}   onBlur={()=>{ this.validate("isbnnumber")}} className="form-control form-control-sm" type="text"  style={this.state.validations.isbnnumber===""?{}:{"borderColor":"red"}} required readOnly/> 
						<span className="errormsg">{this.state.validations.isbnnumber}</span>
					</div>
					

                    <div className="p-2 flex-fill">
						<label htmlFor="idbookauthor" className="label-custom" >Author<span className="star">*</span></label> 
						<input value={this.state.formObj.bookauthor} id="idbookauthor"  onChange={(e)=>{this.format("bookauthor",e)}}   onBlur={()=>{ this.validate("bookauthor")}} className="form-control form-control-sm" type="text"  required style={this.state.validations.bookauthor===""?{}:{"borderColor":"red"}} readOnly /> 
						<span className="errormsg">{this.state.validations.bookauthor}</span>
					</div>
                </div>
                <div className="d-flex row">
                <div className="p-2 flex-fill">
						<label htmlFor="idbooktitle" className="label-custom" >Title<span className="star">*</span></label> 
						<input value={this.state.formObj.booktitle} id="idbooktitle"  onChange={(e)=>{this.format("booktitle",e)}}   onBlur={()=>{ this.validate("booktitle")}} className="form-control form-control-sm" type="text"  style={this.state.validations.booktitle===""?{}:{"borderColor":"red"}} required readOnly /> 
						<span className="errormsg">{this.state.validations.booktitle}</span>
					</div>
                    <div className="p-2 flex-fill">
						<label htmlFor="idpublishername" className="label-custom" >Publisher Name<span className="star">*</span></label> 
						<input value={this.state.formObj.publishername} id="idpublishername"  onChange={(e)=>{this.format("publishername",e)}}   onBlur={()=>{ this.validate("publishername")}} className="form-control form-control-sm" type="text"   style={this.state.validations.publishername===""?{}:{"borderColor":"red"}} required readOnly /> 
						<span className="errormsg">{this.state.validations.publishername}</span>
					</div>
                </div>
                <div className="d-flex row ">
					<div className="p-2 flex-fill">
						<label htmlFor="idvender" className="label-custom" >Vendor</label> 
						<input value={this.state.formObj.vender} id="idvender" onChange={(e)=>{this.format("vender",e)}}   onBlur={()=>{ this.validate("vender")}} className="form-control form-control-sm" type="text"  required style={this.state.validations.vender===""?{}:{"borderColor":"red"}} readOnly /> 
						<span className="errormsg">{this.state.validations.vender}</span>
					</div>
					<div className="p-2 col-md-3">
						<label htmlFor="idedition" className="label-custom" >Edition</label> 
						<input value={this.state.formObj.edition} id="idedition" onChange={(e)=>{this.format("edition",e)}}   onBlur={()=>{ this.validate("edition")}} className="form-control form-control-sm" type="text"  required style={this.state.validations.edition===""?{}:{"borderColor":"red"}} readOnly /> 
						<span className="errormsg">{this.state.validations.edition}</span>
					</div>	
					<div className="p-2 ">
						<label htmlFor="ideditionyear" className="label-custom" >Edition Year</label> 
						<input value={this.state.formObj.editionyear} id="ideditionyear"  onChange={(e)=>{this.format("editionyear",e)}}   onBlur={()=>{ this.validate("editionyear")}} className="form-control form-control-sm" type="text"  required style={this.state.validations.editionyear===""?{}:{"borderColor":"red"}} readOnly/> 
						<span className="errormsg">{this.state.validations.editionyear}</span>
					</div>
					<div className="p-2 col-md-2">
						<label htmlFor="idpreliminarypages" className="label-custom" >Pre.Pages</label> 
						<input value={this.state.formObj.preliminarypages} id="idpreliminarypages"  onChange={(e)=>{this.format("preliminarypages",e)}}   onBlur={()=>{ this.validate("preliminarypages")}} className="form-control form-control-sm" type="text" required  style={this.state.validations.preliminarypages===""?{}:{"borderColor":"red"}} readOnly/> 
						<span className="errormsg">{this.state.validations.preliminarypages}</span>
					</div>
                </div>
                <div className="d-flex row ">
                <div className="p-2 col-md-2">
						<label htmlFor="idpages" className="label-custom" >Pages</label> 
						<input value={this.state.formObj.pages} id="idpages"  onChange={(e)=>{this.format("pages",e)}}   onBlur={()=>{ this.validate("pages")}} className="form-control form-control-sm" type="text"  required  style={this.state.validations.pages===""?{}:{"borderColor":"red"}} readOnly/> 
						<span className="errormsg">{this.state.validations.pages}</span>
					</div>
					<div className="p-2 col-md-2 ">
						<label htmlFor="idindianprice"  className="label-custom" >Indian Rs.</label> 
						<input value={this.state.formObj.indianprice} id="idindianprice"  onChange={(e)=>{this.format("indianprice",e)}}   onBlur={()=>{ this.validate("indianprice")}} className="form-control form-control-sm" type="text"  required style={this.state.validations.indianprice===""?{}:{"borderColor":"red"}} readOnly/> 
						<span className="errormsg">{this.state.validations.indianprice}</span>
					</div>
					<div className="p-2 col-md-2">
						<label htmlFor="idforeignprice" className="label-custom" >FGN Price</label> 
						<input value={this.state.formObj.foreignprice} id="idforeignprice"  onChange={(e)=>{this.format("foreignprice",e)}}   onBlur={()=>{ this.validate("foreignprice")}} className="form-control form-control-sm" type="text"  required style={this.state.validations.foreignprice===""?{}:{"borderColor":"red"}} readOnly/> 
						<span className="errormsg">{this.state.validations.foreignprice}</span>
					</div>
                    <div className="p-2 col-md-4">
						<label htmlFor="iddepartment" className="label-custom" >Department<span className="star">*</span></label> 
						<input  value={this.state.formObj.department} id="iddepartment"  onChange={(e)=>{this.format("department",e)}}   onBlur={()=>{ this.validate("department")}}  style={this.state.validations.department===""?{}:{"borderColor":"red"}} type="text" className="form-control form-control-sm" readOnly />
                        <span className="errormsg">{this.state.validations.department}</span>
                    </div>
                    <div className="p-2 col-md-2">
						<label htmlFor="idbooktype" className="label-custom" >Book Type</label> 
						<input value={this.state.formObj.booktype} className="form-control form-control-sm" id="idbooktype" onChange={(e)=>{this.format("booktype",e)}} onBlur={()=>{this.validate("booktype")}}  type="text" style={this.state.validations.booktype===""?{}:{"borderColor":"red"}} readOnly/> 
                        <span className="errormsg">{this.state.validations.booktype}</span>
                    </div>
                    </div>
                    <div className="d-flex row ">
                    <div className="p-2 flex-fill ">
						<label htmlFor="idsubject" className="label-custom" >Subject<span className="star">*</span></label> 
						<input value={this.state.formObj.subject} id="idsubject"  onChange={(e)=>{this.format("subject",e)}}   onBlur={()=>{ this.validate("subject")}} input type="text" style={this.state.validations.subject===""?{}:{"borderColor":"red"}}className="form-control form-control-sm" readOnly />
                        <span className="errormsg">{this.state.validations.subject}</span>
                    </div>
                    <div className="p-2 col-md-3 ">
						<label htmlFor="idcallnumber" className="label-custom" >Cell No</label> 
						<input  value={this.state.formObj.callnumber} id="idcallnumber"  onChange={(e)=>{this.format("callnumber",e)}} onKeyUp={()=>{this.getFormattedPhoneNum()}}  onBlur={()=>{ this.validate("callnumber")}} className="form-control form-control-sm" type="text"  required  style={this.state.validations.callnumber===""?{}:{"borderColor":"red"}} readOnly/> 
						<span className="errormsg">{this.state.validations.callnumber}</span>
					</div>
                    <div className="p-2 col-md-3">
						<label htmlFor="idtrackcode" className="label-custom" >Track Code</label> 
						<input value={this.state.formObj.trackcode} id="idtrackcode"  onChange={(e)=>{this.format("trackcode",e)}}  onBlur={()=>{ this.validate("trackcode")}} className="form-control form-control-sm" type="text"  required  style={this.state.validations.trackcode===""?{}:{"borderColor":"red"}} readOnly/> 
						<span className="errormsg">{this.state.validations.trackcode}</span>
					</div>
                    <div className="p-2 col-md-2">
						<label htmlFor="idbookstatus" className="label-custom" >Book Status</label> 
						<input  value={this.state.formObj.bookstatus} id="idbookstatus" onChange={(e)=>{this.format("bookstatus",e)}} type="text" onBlur={()=>{this.validate("bookstatus")}} className="form-control form-control-sm" style={this.state.validations.bookstatus===""?{}:{"borderColor":"red"}} readOnly/>
				
                        <span className="errormsg">{this.state.validations.bookstatus}</span>
					</div>
                    </div>
                    <div className="d-flex row ">
                    <div className="p-2 flex-fill">
						<label htmlFor="idnotes" className="label-custom" >Notes</label> 
						<textarea   value={this.state.formObj.notes} id="idnotes" rows="1"  onChange={(e)=>{this.format("notes",e)}} className="form-control "   onBlur={()=>{ this.validate("notes")}}   style={this.state.validations.notes===""?{}:{"borderColor":"red"}} readOnly/>  
						<span className="errormsg">{this.state.validations.notes}</span>
					</div>
                     </div>
                     <div className="d-flex row justify-content-end">
					<div className="p-2">
						<button type="button" className="btn btn-success btn-sm" onClick={()=>{this.searchObj()}}>Search</button>
					</div>
                    <div className="p-2">
						<button type="button" className="btn btn-success btn-sm"onClick={()=>{this.addObj()}} >New</button>
					</div>
                    <div className="p-2">
						<button type="button" className="btn btn-warning btn-sm">Cancel</button>
					</div>	
                    </div>
					<br/>
					<Modal size="lg" show={this.state.showModal} onHide={()=>{this.setState({showModal:false,searchtable:false})}} >
					<Modal.Header closeButton>
						<Modal.Title>Book Track</Modal.Title>
					</Modal.Header>
					<ModalBody>
						<div className="d-flex row">
						<div className="p-2 flex-fill">
								<label htmlFor="idaccnumber" className="label-custom" >ACC No/Barcode<span className="star">*</span></label> 
								<input value={this.state.formObj.accnumber} required id="idaccnumber"  onChange={(e)=>{this.format("accnumber",e)}}   className="form-control form-control-sm"  required /> 
								<span className="errormsg">{this.state.searchmessage}</span>
							</div>
							<div className="p-2 flex-fill">
								<label htmlFor="idbooktitle" className="label-custom" > Book Title<span className="star">*</span></label> 
								<input value={this.state.formObj.booktitle} id="idbooktitle"  onChange={(e)=>{this.format("booktitle",e)}}  className="form-control form-control-sm" type="text"   required /> 
								<span className="errormsg">{this.state.searchmessage}</span>
							</div>
							<div className="p-2 flex-fill">
								<label htmlFor="idbookauthor" className="label-custom" > Book Author<span className="star">*</span></label> 
								<input value={this.state.formObj.bookauthor} id="idbookauthor"  onChange={(e)=>{this.format("bookauthor",e)}}    className="form-control form-control-sm" type="text"  required  /> 
								<span className="errormsg">{this.state.searchmessage}</span>
							</div>
							<div className="p-2">
								<button type="button" className="btn btn-primary btn-sm" style={{marginTop:32}}  onClick={()=>{ this.searchType(this.state.formObj.accnumber,this.state.formObj.booktitle,this.state.formObj.bookauthor)}}>Go</button>
							</div>
						</div>	
						{this.state.searchtable?<div className="d-flex row">
							<div className="p-2 flex-fill">
								<table className="table table-sm">
									<thead>
										<tr>
											<td>Acc No</td>
											<td>Book Title</td>
											<td>Book Author</td>
										</tr>
									</thead>
									{this.renderObjlist()}
								</table>
							</div>
						</div>:null}
					</ModalBody>
				<ModalFooter>
					<Button variant="warning" onClick={()=>{this.setState({showModal:false,searchtable:false})}}>CLOSE</Button>
				</ModalFooter>
			</Modal> 
                </div>
        )
    }
}
const mapToProps = ({ loggedUserState }) => ({ user: loggedUserState })
export default connect(mapToProps)(BookTrack);