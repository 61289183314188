import React, {Component} from 'react';
import axios from 'axios';
import { base_url } from '../constants/Constants';
import { Users } from '../models/Users';
import { Validators } from '../utils/Validators';
import flashMessage from '../utils/Flash';
import FlashMessage from 'react-flash-message';
import Logo from '../assets/favicon1.png';
import '../styles/Styles.css';

export default class Signup extends Component {
  constructor(props){
    super(props);
      this.state = { 
        formObj:Users,            
        showSuccessMsg : false,      	
        message:'',
        msgType:'',
        firstpage:true,
        nextpage1:false,
        nextpage2:false,
        showRegistration:true,
        validations:{},
      }; 
      this.changeUser = this.changeUser.bind(this);
      this.validate = this.validate.bind(this);
      this.getFormattedPhoneNum = this.getFormattedPhoneNum.bind(this);
    }
    componentDidMount(){
      let validtemp={};
		  for (let key in Users) { validtemp[key] = ""; }
      this.setState({validations:validtemp});

      let tempForm={};
      for (let key in Users) { tempForm[key] = ""; }
      this.setState({ formObj:tempForm});
    
    }
    backToLogin = () => {
      this.props.history.push('/login');
    }
    changeUser = (val,e) =>{
		let tempform = this.state.formObj;
		switch(val){
			case "firstname":{var string = e.target.value; tempform.firstname = string.charAt(0).toUpperCase() + string.slice(1); } break;
			case "lastname": {var string = e.target.value; tempform.lastname = string.charAt(0).toUpperCase() + string.slice(1); } break;
			case "company": {var string = e.target.value; tempform.company = string.charAt(0).toUpperCase() + string.slice(1); } break;
			case "phone": tempform.phone = e.target.value; break; 
      case "email": tempform.email = e.target.value;  break; 
      case "password":  tempform.password = e.target.value;  break; 
      case "confirmpwd":  tempform.confirmpwd = e.target.value;  break;
			case "address1":  tempform.address1 = e.target.value;  break; 
			case "address2":  tempform.address2 = e.target.value;  break; 
			case "city":  tempform.city = e.target.value; break; 
			case "state":  {var string = e.target.value; tempform.state= string.charAt(0).toUpperCase() + string.slice(1); }break; 
			case "country": {var string = e.target.value; tempform.country= string.charAt(0).toUpperCase() + string.slice(1); }break; 
			case "zip":  tempform.zip = e.target.value;  break; 
			case "cardname":  tempform.cardname = e.target.value; break; 
			case "cardnumber":  tempform.cardnumber = e.target.value; break; 
			case "cvc":  tempform.cvc = e.target.value; break; 
			case "expmonth":  tempform.expmonth = e.target.value;  break; 
			case "expyear":  tempform.expyear= e.target.value;  break;
      case "institutionname":{var string = e.target.value; tempform.institutionname = string.charAt(0).toUpperCase() + string.slice(1); } break;
      default : { } 
		}

		this.setState({formObj:tempform});
  }
  
  validate = (val) =>{
		let tempval = this.state.validations;
		switch(val){
			case "firstname":  tempval.firstname =Validators.name(this.state.formObj.firstname);  break;
			case "lastname":  tempval.lastname = Validators.name(this.state.formObj.lastname);  break;
			case "company":  tempval.company = Validators.name(this.state.formObj.company);  break;
			case "phone":  tempval.phone = Validators.phone(this.state.formObj.phone);  break; 
      case "email": tempval.email = Validators.email(this.state.formObj.email);
      case "institutionname": tempval.institutionname = Validators.name(this.state.formObj.institutionname );
        if(tempval.email === ""){
        var emailObj = {email:this.state.formObj.email};
                axios.post(base_url+'main/emailverify', emailObj)        
                .then(res => {
                  if(res.data.msg === 'success'){   
                    tempval.email='The email address you have entered is already associated with another account'; 
                    this.setState({validations:tempval});
                  }                 
                }).catch(function (error) { 
                console.log(error);        
              }); 
            }      
          break; 
      case "password":  tempval.password = Validators.password(this.state.formObj.password);
        if(tempval.password === ""){
          var passw = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/;
          if(this.state.formObj.password.match(passw)){
            tempval.password ="";
            this.setState({validations:tempval});
          }else{
            tempval.password ='Password should be 6 to 20 characters which contain at least one numeric digit, one uppercase and one lowercase letter';
            this.setState({validations:tempval});
          }
        }
       break; 
      case "confirmpwd": tempval.confirmpwd = Validators.password(this.state.formObj.confirmpwd);
        if(tempval.confirmpwd=== ""){
          if(this.state.formObj.password === ''){
           tempval.confirmpwd="Please fill the password field first.";
           this.setState({validations:tempval});
          }else{
            if(this.state.formObj.password !== this.state.formObj.confirmpwd){
              tempval.confirmpwd="Password and confirm password is not matching";
              this.setState({validations:tempval});
            }
          }
        }  break;
			case "address1":  tempval.address1 = Validators.name(this.state.formObj.address1);  break; 
		//	case "address2": { tempval.address2 = Validators.name(this.state.formObj.address2); } break; 
			case "city":  tempval.city = Validators.name(this.state.formObj.city);  break; 
			case "state":  tempval.state = Validators.state(this.state.formObj.state);  break; 
			case "country":  tempval.country = Validators.name(this.state.formObj.country);  break; 
			case "zip":  tempval.zip = Validators.zipcode(this.state.formObj.zip);  break; 
			case "cardname":  tempval.cardname = Validators.name(this.state.formObj.cardname);  break; 
			case "cardnumber":  tempval.cardnumber = Validators.cardnumber(this.state.formObj.cardnumber);  break; 
			case "cvc":  tempval.cvc = Validators.cvc(this.state.formObj.cvc);  break; 
      case "expmonth":  tempval.expmonth = Validators.month(this.state.formObj.expmonth); 
              if(tempval.expmonth ===""){

          if(this.state.formObj.expyear === ""){
            tempval.expyear="Please fill the Expiration Month.";
            this.setState({validations:tempval});
          }else{
          var today, someday;
          var exMonth=this.state.formObj.expmonth;
          var exYear=this.state.formObj.expyear;
          today = new Date();
          someday = new Date();
          someday.setFullYear(exYear, exMonth, 1);
  
          if (someday < today) {
            tempval.expyear="The expiry date is before today's date. Please select a valid expiry date";
            this.setState({validations:tempval});
          }
        }
        } break; 
      case "expyear":  tempval.expyear= Validators.year(this.state.formObj.expyear);
        if(tempval.expyear ===""){
        if(this.state.formObj.expmonth === ""){
          tempval.expmonth="Please fill the Expiration Month.";
          this.setState({validations:tempval});
        }else{        
        exMonth=this.state.formObj.expmonth;
        exYear=this.state.formObj.expyear;
        today = new Date();
        someday = new Date();
        someday.setFullYear(exYear, exMonth, 1);

        if (someday < today) {
          tempval.expyear="The expiry date is before today's date. Please select a valid expiry date";
          this.setState({validations:tempval});
        }
      }      
      } break; 
      default : {}
		}

		this.setState({validations:tempval});
	}
  resetAll= () =>{
    this.setState({
      nextpage1:false,nextpage2:false,showRegistration:false,showSuccessMsg : false,
    });
  }
  showSuccessMsg=()=>{
    this.resetAll();
    this.setState({showSuccessMsg:true});
  }
  
  backToLogin = () => {
      this.props.history.push('/login');      
  }
 
 
  nextPage1 = () =>{ 
  let temp='';
  for (let key in Users) { this.validate(key);}  
  let tempnext=this.state.validations;
  tempnext.institutionname="";
  tempnext.address1="";
  tempnext.address2="";
  tempnext.city="";
  tempnext.state="";
  tempnext.zip="";
  tempnext.cardname="";
  tempnext.cardnumber="";
  tempnext.expmonth="";
  tempnext.expyear="";
  tempnext.cvc="";
  this.setState({validations:tempnext});
  for (let key in this.state.validations) { temp += this.state.validations[key];}
	if(temp === ""){
        this.resetAll();
       this.setState({nextpage1:true});
  }
 
}
  nextPage2=()=>{
    let temp='';
  for (let key in Users) { this.validate(key);}  
  let tempnext=this.state.validations;
  tempnext.cardname="";
  tempnext.cardnumber="";
  tempnext.expmonth="";
  tempnext.expyear="";
  tempnext.cvc="";
  this.setState({validations:tempnext});
  for (let key in this.state.validations) { temp += this.state.validations[key];}
	if(temp === ""){
    this.resetAll();
    this.setState({nextpage2:true});
  }
  
  }


getFormattedPhoneNum = () =>{
  let phnnum=this.state.formObj.phone;
  
  if(phnnum !== null && phnnum !== ''){ 
     let output = "(";
     phnnum.replace( /^\D*(\d{0,3})\D*(\d{0,3})\D*(\d{0,4})/, function( match, g1, g2, g3 )
   {
     if ( g1.length ) {
     output += g1;
     if ( g1.length === 3 ) {
       output += ")";
       if ( g2.length ) {
         output += " " + g2; 
         if ( g2.length === 3 ) {
           output += "-";
           if ( g3.length ) {
             output += g3;
           }
         }
       }
      }
     }
   }   
   );        
 let newform=this.state.formObj;
 newform.phone=output;
    
  } 
}   
 


  saveRegistration = () => {    
    let temp='';
    for (let key in Users) { this.validate(key);} 
    for (let key in this.state.validations) { temp += this.state.validations[key];}
    if(temp === ""){ 
        var formObj = {accountid:"",firstname:this.state.formObj.firstname, lastname:this.state.formObj.lastname, email:this.state.formObj.email, password:this.state.formObj.password,cardnumber:this.state.formObj.cardnumber,cvc:this.state.formObj.cvc,expmonth:this.state.formObj.expmonth,expyear:this.state.formObj.expyear, type:this.state.formObj.usertype, status:'Inactive'};
            
              axios.post(base_url+'main/signup',this.state.formObj)
              .then(res => { 
                this.setState({formObj:res.data});          
                this.setState({nextpage2:false});
                this.setState({showSuccessMsg:false}, () => {this.setState({message:'Registration Successfull and Follow your email for Activation', msgType:'Success', showSuccessMsg:true})});                 
              }).catch(function (error) { 
                console.log(error);        
              });             
       }   
     
}
  render() {
    return (
      <div className="container-fluid">
       <div className="brdcolor" >
        <br/><br/><br/>                     
          {this.state.showRegistration ?
          <div>               
              <div className="d-flex justify-content-center">
               {this.state.showSuccessMsg ?
                  <div>
                      <FlashMessage duration={5000} persistOnHover={true}>
                      {flashMessage(this.state.message, this.state.msgType)}
                      </FlashMessage>	
                      <br/><br/>
                  </div>
                  :null
                  }
              </div>  
              <div className="d-flex justify-content-center">
                <span   onClick={() =>this.backToLogin()}><img  src={Logo} alt="Logo" /></span>
                </div><br/><br/>                        
              <div className="d-flex justify-content-center">
                  <div className="form-group col-md-4">                       
                      <input type="text" id="firstname" name="firstname" value={this.state.formObj.firstname} placeholder="First Name" required onChange={(e)=>{this.changeUser("firstname",e)}}  onBlur={()=>{ this.validate("firstname")}} className="form-control " style={this.state.validations.firstname===""?{}:{"borderColor":"red"}} required   />
					          	<span className="errormsg">{this.state.validations.firstname}</span>
                  </div>
               </div>
               <div className="d-flex justify-content-center">
                <div className="form-group col-md-4">                       
                    <input type="text"  id="lastname" name="lastname" value={this.state.formObj.lastname}  placeholder="Last Name" required onChange={(e)=>{this.changeUser("lastname",e)}}  onBlur={()=>{ this.validate("lastname")}} className="form-control " style={this.state.validations.lastname===""?{}:{"borderColor":"red"}} required   />
					        	<span className="errormsg">{this.state.validations.lastname}</span>
                </div>
               </div>
                  <div className="d-flex justify-content-center">
                     <div className="form-group col-md-4">                      
                       <input type="text"  id="email" name="email" value={this.state.formObj.email} placeholder="Work Email"  required onChange={(e)=>{this.changeUser("email",e)}} onBlur={()=>{ this.validate("email")}} className="form-control" style={this.state.validations.email===""?{}:{"borderColor":"red"}} required   />
				            	 <span className="errormsg">{this.state.validations.email}</span>
                     </div>
                  </div>
                  <div className="d-flex justify-content-center">
                      <div className="form-group col-md-4">                       
                          <input type="text"  id="phone" name="phone" value={this.state.formObj.phone}  placeholder="Phone" onKeyUp={()=>{this.getFormattedPhoneNum()}} onChange={(e)=>{this.changeUser("phone",e)}} onBlur={()=>{ this.validate("phone")}} className="form-control" style={this.state.validations.phone===""?{}:{"borderColor":"red"}} required   />
					               	<span className="errormsg">{this.state.validations.phone}</span>
                      </div>
                  </div>
                  <div className="d-flex justify-content-center">
                      <div className="form-group col-md-2">                      
                        <input type="password"  id="password" name="password" value={this.state.formObj.password} placeholder="Create Password"  onChange={(e)=>{this.changeUser("password",e)}}  onBlur={()=>{ this.validate("password")}} className="form-control" style={this.state.validations.password===""?{}:{"borderColor":"red"}} required   />
				            		<span className="errormsg">{this.state.validations.password}</span>
                      </div>
                      <div className="form-group col-md-2">                      
                        <input type="password"  id="confirmpwd" name="confirmpwd" value={this.state.formObj.confirmpwd} placeholder="Confirm Password" onChange={(e)=>{this.changeUser("confirmpwd",e)}} onBlur={()=>{ this.validate("confirmpwd")}} className="form-control" style={this.state.validations.confirmpwd===""?{}:{"borderColor":"red"}} required   />
					            	<span className="errormsg">{this.state.validations.confirmpwd}</span>
                      </div>
                 </div>
                 <br/> 
                 <div className="d-flex justify-content-center"> 
                  <div className="form-group col-md-4">                          
                    <button type="button"  className="btn btn-primary btn-block" onClick={() =>this.nextPage1()}> Next</button>                         
                  </div>
                </div>           
          </div>
           :        
          null
         }       
       <div>{this.state.nextpage1?<div>
             <div className="d-flex justify-content-center">
                <span   onClick={() =>this.backToLogin()}><img  src={Logo} alt="Logo" /></span>
                </div><br/><br/>
              <div className="d-flex justify-content-center">
                <div className="form-group col-md-3">                       
                  <input type="text"  id="address1" name="address1" value={this.state.formObj.institutionname} placeholder="Institution Name" onChange={(e)=>{this.changeUser("institutionname",e)}} onBlur={()=>{ this.validate("institutionname")}} className="form-control " style={this.state.validations.institutionname===""?{}:{"borderColor":"red"}} required   />
					            	<span className="errormsg">{this.state.validations.institutionname}</span>    
                </div>
              </div>
              <div className="d-flex justify-content-center">
                <div className="form-group col-md-3">                       
                  <input type="text"  id="address1" name="address1" value={this.state.formObj.address1} placeholder="Address1" onChange={(e)=>{this.changeUser("address1",e)}} onBlur={()=>{ this.validate("address1")}} className="form-control " style={this.state.validations.address1===""?{}:{"borderColor":"red"}} required   />
					            	<span className="errormsg">{this.state.validations.address1}</span>    
                </div>
              </div>
              <div className="d-flex justify-content-center">
                <div className="form-group col-md-3">                       
                  <input type="text"  id="address2" name="address2" value={this.state.formObj.address2} placeholder="Address2" onChange={(e)=>{this.changeUser("address2",e)}}  className="form-control"  required   />    
                </div>
              </div>
              <div className="d-flex justify-content-center">
                <div className="form-group col-md-1">                       
                  <input type="text"  id="city" name="city" value={this.state.formObj.city} placeholder="City" onChange={(e)=>{this.changeUser("city",e)}} onBlur={()=>{ this.validate("city")}} className="form-control" style={this.state.validations.city===""?{}:{"borderColor":"red"}} required   />
					            	<span className="errormsg">{this.state.validations.city}</span>    
                </div>              
                <div className="form-group col-md-1">                       
                  <input type="text"  id="state" name="state" value={this.state.formObj.state} placeholder="State" onChange={(e)=>{this.changeUser("state",e)}} onBlur={()=>{ this.validate("state")}} className="form-control" style={this.state.validations.state===""?{}:{"borderColor":"red"}} required   />
					            	<span className="errormsg">{this.state.validations.state}</span>    
                </div>
                <div className="form-group col-md-1">                       
                  <input type="text"  id="zip" name="zip" value={this.state.formObj.zip}  min="1" max="6" placeholder="Zipcode" onChange={(e)=>{this.changeUser("zip",e)}} onBlur={()=>{ this.validate("zip")}} className="form-control" style={this.state.validations.zip===""?{}:{"borderColor":"red"}} required   />
					            	<span className="errormsg">{this.state.validations.zip}</span>    
                </div>
              </div><br/>
              <div className="d-flex justify-content-center"> 
                  <div className="form-group col-md-3">                         
                    <button type="button"  className="btn btn-primary btn-block " onClick={() =>this.nextPage2()} >Next</button>                         
                  </div>
                </div> 
        </div>
        :null} </div> 
       <div> {this.state.nextpage2?<div>
        <div className="d-flex justify-content-center">
          <span onClick={() =>this.backToLogin()}><img  src={Logo} alt="Logo" /></span>
        </div><br/><br/>
       < div className="d-flex justify-content-center">
              <div className="form-group col-md-3">                       
                <input type="text"  id="cardname" name="cardname" value={this.state.formObj.cardname} placeholder="Name on The Card" required onChange={(e)=>{this.changeUser("cardname",e)}} onBlur={()=>{ this.validate("cardname")}} className="form-control" style={this.state.validations.cardname===""?{}:{"borderColor":"red"}} required   />
					            	<span className="errormsg">{this.state.validations.cardname}</span>   
              </div>
       </div>
       <div className="d-flex justify-content-center">
              <div className="form-group col-md-3">                       
                <input type="number"  id="cardnumber" name="cardnumber"  min="1" max="16" value={this.state.formObj.cardnumber} placeholder="Card Number"  onChange={(e)=>{this.changeUser("cardnumber",e)}} onBlur={()=>{ this.validate("cardnumber")}} className="form-control" style={this.state.validations.cardnumber===""?{}:{"borderColor":"red"}} required   />
					            	<span className="errormsg">{this.state.validations.cardnumber}</span>    
              </div>
        </div>
        <div className="d-flex justify-content-center">
              <div className="form-group col-md-1">                       
                <input type="number"  id="expmonth" name="expmonth" value={this.state.formObj.expmonth}  min="1" max="2" placeholder="Expiration Month" onChange={(e)=>{this.changeUser("expmonth",e)}} onBlur={()=>{ this.validate("expmonth")}} className="form-control" style={this.state.validations.expmonth===""?{}:{"borderColor":"red"}} required   />
					            	<span className="errormsg">{this.state.validations.expmonth}</span>     
              </div>
              <div className="form-group col-md-1">                       
                <input type="number"  id="expyear" name="expyear" value={this.state.formObj.expyear}  min="1" max="4"placeholder="Expiration Year" onChange={(e)=>{this.changeUser("expyear",e)}}  onBlur={()=>{ this.validate("expyear")}} className="form-control" style={this.state.validations.expyear===""?{}:{"borderColor":"red"}} required   />
					            	<span className="errormsg">{this.state.validations.expyear}</span> 
              </div>
              <div className="form-group col-md-1">                       
                <input type="number"  id="cvc" name="cvc" value={this.state.formObj.cvc}  min="1" max="3" placeholder="CVC" required onChange={(e)=>{this.changeUser("cvc",e)}} onBlur={()=>{ this.validate("cvc")}} className="form-control" style={this.state.validations.cvc===""?{}:{"borderColor":"red"}} required   />
					            	<span className="errormsg">{this.state.validations.cvc}</span> 
              </div>
        </div><br/> 
           <div className="d-flex justify-content-center"> 
                  <div className="form-group col-md-3">                          
                  <button type="button"  className="btn btn-primary btn-block " onClick={()=>this.saveRegistration()}>Get Started</button>                         
                  </div>
                </div> 
        </div>
        :
        <div className="d-flex justify-content-center"> 
           <br/><br/><br/>    
            <div>
               {this.state.showSuccessMsg ?
                <div>
                    <div className="d-flex justify-content-center">
                      <div className="alert alert-success btn-block" role="alert"><strong>{this.state.msgType}</strong> {this.state.message}</div>	
                    </div>
                      <div className="d-flex justify-content-center">         
                        <div className="col-md-6 text-center">                               
                        <button type="button" className="btn btn-warning btn-block" onClick={() =>this.backToLogin()} >Back To Login</button>                                               
                      </div>                          
                    </div><br/><br/> 
                </div>
                :null
                }
            </div>             
         </div>
        }</div>
      </div>  
     </div>
    
    );
  }

}
