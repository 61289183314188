export const Sms = {
    message:'',
    cardid:'',
    membertype:'',
    barcode:'',
    firstname:'',
    template:'',
    phone:'',
    department:'',
    id : '', 
	createdby : '', 
	updatedby : '', 
	date_created : '', 
	date_updated : '', 

}