

///////////////////////////////////////////////////////////////////////////
// Warning : Property of Deemsoft, It is illegal to make copy of this code
// Author : Gangaswamy Kempahonnaiah
///////////////////////////////////////////////////////////////////////////
import { base_url } from '../constants/Constants';
import axios from 'axios';


export const Getpdf = {
    GetActivebookdata: function(val1,val2,id){
		console.log(id);
		var arr=[]; 
		var b=[];
		for(var i=0;i<id.length;i++){
			
				if(id[i].status == "Active"){
					if(id[i].date_created >= val1 && id[i].date_created <= val2){
						arr.push(id[i]);
				}
			}
		
		}
		console.log(arr);
		return arr;
		
	},
	Getallbookdata:function(val1,val2,id){
		console.log(id);
		var arr=[]; 
	
		for(var i=0;i<id.length;i++){
			if(id[i].date_created >= val1 && id[i].date_created <= val2){
				arr.push(id[i]);
			}
			
		
		}
	
		return arr;
	},
	FastMovingTitles: function(val1,val2,id){
		console.log(id);
		var date=new Date(val2);
		date.setHours(24, 0, 0, 0);
		var y = date.getFullYear();
		var d = date.getDate();
		var m = date.getMonth()+1;
		var enddt = y+ '-' +(m <= 9 ? '0' + m : m) + '-' +(d <= 9 ? '0' + d : d);

		var date1 =new Date(val1);
		var date2=new Date(date1);
		date2.setDate(date2.getDate()-1);
		var y1 = date2.getFullYear();
		var d1 = date2.getDate();
		var m1 = date2.getMonth()+1;
		var startdt = y1+ '-' +(m1 <= 9 ? '0' + m1 : m1) + '-' +(d1 <= 9 ? '0' + d1 : d1);
	   
		console.log(startdt);
		console.log(enddt);
		
		var count=0;
                var arr=[];
                for(var i=0;i<id.length;i++){
                    for(var j=0;j<id.length;j++){
                        if(id[i].booktitle ==id[j].booktitle){
                            count++;
                        }
                    }
                    var temp1=id[i];
                    temp1.counter=count;
                    arr.push(temp1);
                    count=0;
                }
                const tempobj = [...arr.reduce((map, obj) => map.set(obj.booktitle, obj), new Map()).values()];
                var a=[];
                for(var i=0;i<tempobj.length;i++){
                    if(tempobj[i].counter>=2){
                        a.push(tempobj[i])
                    }
                }
				var arr=[];
				for(var i=0;i<a.length;i++){
					console.log(a[i].date_created >= val2 );
					if(a[i].date_created >= startdt && a[i].date_created <= enddt){
						arr.push(a[i]);
					}
				}
				arr.reverse();
               return arr;
	},
	FastMovingTitle: function(val1,val2,id){
		console.log(id);
		var count=0;
                var arr=[];
                for(var i=0;i<id.length;i++){
                    for(var j=0;j<id.length;j++){
                        if(id[i].booktitle ==id[j].booktitle){
                            count++;
                        }
                    }
                    var temp1=id[i];
                    temp1.counter=count;
                    arr.push(temp1);
                    count=0;
                }
                const tempobj = [...arr.reduce((map, obj) => map.set(obj.booktitle, obj), new Map()).values()];
                var a=[];
                for(var i=0;i<tempobj.length;i++){
                    if(tempobj[i].counter>=2){
                        a.push(tempobj[i])
                    }
                }
				a.reverse();
               return a;
	},
	SlowMovingTitles:function(val1,val2,id){
		var date=new Date(val2);
		date.setHours(24, 0, 0, 0);
		var y = date.getFullYear();
		var d = date.getDate();
		var m = date.getMonth()+1;
		var enddt = y+ '-' +(m <= 9 ? '0' + m : m) + '-' +(d <= 9 ? '0' + d : d);

		var date1 =new Date(val1);
		var date2=new Date(date1);
		date2.setDate(date2.getDate()-1);
		var y1 = date2.getFullYear();
		var d1 = date2.getDate();
		var m1 = date2.getMonth()+1;
		var startdt = y1+ '-' +(m1 <= 9 ? '0' + m1 : m1) + '-' +(d1 <= 9 ? '0' + d1 : d1);
	
		var count=0;
		var arr=[];
		for(var i=0;i<id.length;i++){
			for(var j=0;j<id.length;j++){
				if(id[i].booktitle ==id[j].booktitle){
					count++;
				}
			}
			var temp1=id[i];
			temp1.counter=count;
			arr.push(temp1);
			count=0;
		}
		const tempobj = [...arr.reduce((map, obj) => map.set(obj.booktitle, obj), new Map()).values()];
		var a=[];
		for(var i=0;i<tempobj.length;i++){
			if(tempobj[i].counter<=1){
				a.push(tempobj[i]);
			}
		}
		var arr=[];
				for(var i=0;i<a.length;i++){
					if(a[i].date_created >= startdt && a[i].date_created <= enddt){
						arr.push(a[i]);
					}
				}
				arr.reverse();
               return arr;
	},
	SlowMovingTitle:function(val1,val2,id){
		var count=0;
		var arr=[];
		for(var i=0;i<id.length;i++){
			for(var j=0;j<id.length;j++){
				if(id[i].booktitle ==id[j].booktitle){
					count++;
				}
			}
			var temp1=id[i];
			temp1.counter=count;
			arr.push(temp1);
			count=0;
		}
		const tempobj = [...arr.reduce((map, obj) => map.set(obj.booktitle, obj), new Map()).values()];
		var a=[];
		for(var i=0;i<tempobj.length;i++){
			if(tempobj[i].counter<=1){
				a.push(tempobj[i]);
			}
		}
		a.reverse();
               return a;
	},

	CheckoutTitles:function(val1,val2,id){
		var temp=[];
		var now = new Date();
		var month = (now.getMonth() + 1);               
		var day = now.getDate();
		if (month < 10) 
			 month = "0" + month;
		if (day < 10) 
			day = "0" + day;
		var today =  day + '-' + month+'-'+now.getFullYear();
		var data=today.toString();
		for(var i=0;i<id.length;i++){
			if(id[i].issuedate.toString()==data){
				temp.push(id[i]);
			}
		}
		var arr=[];
				for(var i=0;i<temp.length;i++){
					if(temp[i].date_created >= val1 && temp[i].date_created <= val2){
						arr.push(temp[i]);
					}
				}
               return arr;
		
	},
	CheckoutTitle:function(val1,val2,id){
		var temp=[];
		var now = new Date();
		var month = (now.getMonth() + 1);               
		var day = now.getDate();
		if (month < 10) 
			 month = "0" + month;
		if (day < 10) 
			day = "0" + day;
		var today =  day + '-' + month+'-'+now.getFullYear();
		var data=today.toString();
		for(var i=0;i<id.length;i++){
			if(id[i].issuedate.toString()==data){
				temp.push(id[i]);
			}
		}
	
				
               return temp;
		
	},
	CheckoutMembers:function(val1,val2,id){
		var temp=[];
		var now = new Date();
        var month = (now.getMonth() + 1);               
        var day = now.getDate();
        if (month < 10) 
            month = "0" + month;
        if (day < 10) 
            day = "0" + day;
		var today =  day + '-' + month+'-'+now.getFullYear();
        var data=today.toString();
            for(var i=0;i<id.length;i++){
                if(id[i].issuedate.toString()==data){
                    temp.push(id[i]);
                }
            } 
			var arr=[];
				for(var i=0;i<temp.length;i++){
					if(temp[i].date_created >= val1 && temp[i].date_created <= val2){
						arr.push(temp[i]);
					}
				}
               return arr;
		
            
	},
	CheckoutMember:function(val1,val2,id){
		var temp=[];
		var now = new Date();
        var month = (now.getMonth() + 1);               
        var day = now.getDate();
        if (month < 10) 
            month = "0" + month;
        if (day < 10) 
            day = "0" + day;
		var today =  day + '-' + month+'-'+now.getFullYear();
        var data=today.toString();
            for(var i=0;i<id.length;i++){
                if(id[i].issuedate.toString()==data){
                    temp.push(id[i]);
                }
            } 
		
               return temp;
		
            
	}
	
	


}