////////////////////////////////////////////////////////////////////////////////////////////////////
// Warning : Property of Deemsoft Global Solutions LLC, USA. It is illegal to make copy of this code
// Author : Gangaswamy Kempahonnaiah
////////////////////////////////////////////////////////////////////////////////////////////////////
import React from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { FaPen, FaTimes } from 'react-icons/fa';
import { Button, Modal, ModalBody, ModalFooter } from 'react-bootstrap';
import { base_url } from '../constants/Constants';
import { Validators } from '../utils/Validators';
import { Formators } from '../utils/Formators';
import { Users_Group } from '../models/Users_Group';
import '../styles/Styles.css';

class Users_GroupForm extends React.Component {
	constructor(props){
		super(props);
		this.state = {
			objList : [],
			showModal : false,
			validations : {},
			formObj : Users_Group,
		}
		this.getObjList = this.getObjList.bind(this);
		this.saveObj = this.saveObj.bind(this);
		this.addObj = this.addObj.bind(this);
		this.editObj = this.editObj.bind(this);
		this.validate = this.validate.bind(this);
		this.format = this.format.bind(this);
		this.deleteObj = this.deleteObj.bind(this);
	}

	componentDidMount(){
		let tempObj = {};
		for (let key in Users_Group) { tempObj[key] = ""; }
		this.setState({validations:tempObj});
		this.getObjList();
	}

	getObjList = () => {
		axios.get(base_url+'users_group/list', this.props.user.header ).then(res => {
			if ( res.data.length > 0 ){
				this.setState({ objList:res.data});
			}
		}).catch(function (error) { console.log(error); });
	}
	saveObj = () => {
		axios.post(base_url+'users_group/save', this.state.formObj, this.props.user.header ).then(res => {
			if ( res.data.length > 0 ){
				this.setState({ formObj:res.data});
				this.getObjList();
			}
		}).catch(function (error) { console.log(error); });
	}

	addObj = () => { 
		let tempObj = {};
		for (let key in Users_Group ) { tempObj[key] = ""; }
		this.setState({ formObj:tempObj, showModal:true});
	}

	editObj = (i) => { 
		let tempObj = {};
		for (let key in this.state.objList[i] ) { tempObj[key] = this.state.objList[i][key]; }
		this.setState({ formObj:tempObj, showModal:true});
	}

	deleteObj = (i) => { 
		let tempObj = this.state.objList[i];
		tempObj.status = 'Inactive';
		axios.post(base_url+'users_group/save', tempObj , this.props.user.header ).then(res =>{
			if ( res.data.length > 0 ){
				this.setState({ formObj:res.data});
				this.getObjList();
			}
		}).catch(function (error) { console.log(error); });
	}

	validate = (val) => {
		let tempObj = this.state.validations;
		switch(val){
			case "groupname": tempObj.groupname = Validators.name(this.state.formObj.groupname); break;
			case "status": tempObj.status = Validators.name(this.state.formObj.status); break;
		}
		this.setState({validations:tempObj});
	 } 

	format = (val, e) => { 
		let tempObj = this.state.formObj;
		switch(val){
			case "groupname": tempObj.groupname = Formators.name(e.target.value); break;
			case "status": tempObj.status = Formators.name(e.target.value); break;
		}
		this.setState({formObj:tempObj});
	 } 

	renderObjlist = () => {
		if( this.state.objList != undefined && this.state.objList.length > 0){
			return this.state.objList.map((item, index) =>{
				return (
					<tr key={index} id={index}>
						<td>{item.groupname}</td>
						<td>{item.status}</td>
						<td><span><FaPen size="15px" color="blue" onClick={()=>{this.editObj(index)}}/>&nbsp;&nbsp;
						<FaTimes size="15px" color="red" onClick={() => {this.deleteObj(index)}} />&nbsp;&nbsp;</span></td>
					</tr>
					)
			 }, this);
		}
	}

	render() {
		return (
		<div class="container mt-3">
			<label className="label-custom">USERS_GROUP LIST</label>
			<table className="table table-bordered table-sm">
			<thead className="thead">
				<tr>
					<td>Groupname</td>
					<td>Status</td>
				</tr>
			</thead>
			<tbody>{ this.renderObjlist() }</tbody> 
			</table>
			<div className="d-flex justify-content-end mb-3">
				<div className="p-2"><button type="button" className="btn btn-primary btn-block btn-sm" onClick={()=>{this.addObj()}}>+Add</button></div>
			</div>
			<Modal size="lg" show={this.state.showModal} onHide={()=>{this.setState({showModal:false})}} >
			<Modal.Header closeButton>
			<Modal.Title>USERS_GROUP</Modal.Title>
			</Modal.Header>
			<ModalBody>
				<div className="d-flex flex-wrap bg-light">
					<div className="p-2 border">
						<label htmlFor="idgroupname" className="label-custom" >Groupname</label> 
						<input value={this.state.formObj.groupname} id="idgroupname"  onChange={(e)=>{this.format("groupname",e)}}  placeholder="Enter Groupname" onBlur={()=>{ this.validate("groupname")}} className="form-control form-control-sm" style={this.state.validations.email===""?{}:{"borderColor":"red"}} type="text" className='form-control form-control-sm' required /> 
						<span className="errormsg">{this.state.validations.staffname}</span>
					</div>
					<div className="p-2 border">
						<label htmlFor="idstatus" className="label-custom" >Status</label> 
						<input value={this.state.formObj.status} id="idstatus"  onChange={(e)=>{this.format("status",e)}}  placeholder="Enter Status" onBlur={()=>{ this.validate("status")}} className="form-control form-control-sm" style={this.state.validations.email===""?{}:{"borderColor":"red"}} type="text" className='form-control form-control-sm' required /> 
						<span className="errormsg">{this.state.validations.staffname}</span>
					</div>
				</div>
			</ModalBody>
			<ModalFooter>
				<Button variant="warning" onClick={()=>{this.setState({showModal:false})}}>CLOSE</Button>
				<Button variant="primary" onClick={()=>{this.saveObj()}}>SAVE</Button>
			</ModalFooter>
			</Modal>
			</div>
		)
		}
	}

const mapToProps = ({ loggedUserState }) => ({ user: loggedUserState })
export default connect(mapToProps)(Users_GroupForm);
