///////////////////////////////////////////////////////////////////////////////////////////////
// Warning : Property of Deemsoft Global Solutions LLC, It is illegal to make copy of this code
// Author : Gangaswamy Kempahonnaiah
///////////////////////////////////////////////////////////////////////////////////////////////
import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { getLoggedUser } from '../actions/LoginAction';

class AuthorizedRoute extends React.Component {
  componentWillMount() {
    getLoggedUser();
  }

  render() {
    const { component: Component, pending, logged, ...rest } = this.props;
  
      return (
          <Route {...rest} render={props => {
          if (pending) return <div>Loading...</div>;
          if(logged ){ 
            return  <Component {...props} /> 
          }else{
            return <Redirect to="/login" />; }
          }} />
      )
    }
  }

const mapToProps = ({ loggedUserState }) => ({
  pending: loggedUserState.pending,
  logged: loggedUserState.logged,
  email: loggedUserState.email,
  userid: loggedUserState.userid
})

export default connect(mapToProps)(AuthorizedRoute);