///////////////////////////////////////////////////////////////////////////////////////////////
// Warning : Property of Deemsoft Global Solutions LLC, It is illegal to make copy of this code
// Author : Gangaswamy Kempahonnaiah
///////////////////////////////////////////////////////////////////////////////////////////////
export const Checkout = {
	membershipid : '', 
	cardid : '', 
	firstname : '', 
	lastname : '', 
	age : '', 
	trackcode :'',
	accnumber : '', 
	barcode : '', 
	isbnnumber : '', 
	booktitle : '', 
	issuedate : '', 
	duedate : '', 
	receiveddate : '', 
	overduedays : '', 
	indianprice:'',
	registrationtype:'',
	address:'',
	fine : '', 
	notes : '', 
	id : '', 
	_id:'',
	status : '',
	cellphone:'',
	rollno:'',
	issuetype:'',
	createdby : '', 
	updatedby : '', 
	date_created : '', 
	date_updated : '', 
}