////////////////////////////////////////////////////////////////////////////////////////////////////
// Warning : Property of Deemsoft Global Solutions LLC, USA. It is illegal to make copy of this code
// Author : Gangaswamy Kempahonnaiah
////////////////////////////////////////////////////////////////////////////////////////////////////
import React from 'react';
import { connect } from 'react-redux';
import { base_url } from '../constants/Constants';
import '../styles/Styles.css';
import { NavLink } from 'react-router-dom';


class Success extends React.Component {
	constructor(props){
		super(props);
		this.state = {
			
		}
	}

	componentDidMount(){				
		
	}
	backToLogin = () => {
        this.props.history.push('/login');
        }
	render() {
		
	 return (
		<div className="container">
            <br/><br/><br/><br/><br/><br/><br/>
           <div className="card bg-light">
           <br/><br/><br/>
               <div class="row justify-content-center">
               <h1 className="text-success">Thank You!!Your Email Id Activated Successfully.</h1>
               </div><br/>
               <div className="d-flex justify-content-center">
                    <h2><NavLink to="/login">Login Here</NavLink><br/></h2>
                </div>
             <br/><br/><br/>   
           </div>
        </div>
		)
	}	
}

const mapToProps = ({ loggedUserState }) => ({ user: loggedUserState })
export default connect(mapToProps)(Success);
