/////////////////////////////////////////////////
// Warning : Propeerty of Deemsoft, It is illegal to make copy of this code
// Author : Gangaswamy Kempahonnaiah
//////////////////////////////////////////////////////

import React from 'react';
import axios from 'axios';
import { base_url } from '../constants/Constants';
import flashMessage from '../utils/Flash';
import Logo from '../assets/favicon1.png';
import FlashMessage from 'react-flash-message';


export default class Password extends React.Component {
    constructor(props){
        super(props);
        this.state = { 
            formObj : {},
            email :'',
            showSuccessMsg : false,      	
            message:'',
            msgType:'',          
        }        
    }
    emailChange = (e) => {
        this.setState({email:e.target.value});
    }
    
    backToLogin = () => {
        this.props.history.push('/login');
    }


    sendResetEmail = () => {
      this.setState({showSuccessMsg:false});       
      if(this.state.email === ''){          
          this.setState({showSuccessMsg:false}, () => {this.setState({message:'Please enter the email to submit your request.', msgType:'Failure', showSuccessMsg:true})});
      }else{
          
          var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);

          if (!pattern.test(this.state.email)) { 
              this.setState({showSuccessMsg:false}, () => {this.setState({message:'Please enter valid email address.', msgType:'Failure', showSuccessMsg:true})}); 

          }else{
              var formObj = {email:this.state.email};
              axios.post(base_url+'main/emailverify', formObj)        
                .then(res => {
                  //console.log(res.data.msg);
                    if(res.data.msg === 'success'){
                      axios.post(base_url+'main/sendpasscode', formObj)        
                          .then(res => {
                              this.setState({msgType:'Success', message:'Email is sent to reset the password please check your email', showSuccessMsg:true, email:''});                                          
                          }).catch(function (error) {console.log(error);});
                          setTimeout(this.backToLogin, 3000);

                    }else{
                      //console.log("error");  
                      this.setState({msgType:'Failure', message:'The email address you have entered is not matching with our record, please check your email', showSuccessMsg:true});
                    }                     
                }).catch(function (error) { 
                  console.log(error);        
                });
          }
          
      }        
  }
    
    render() {
        return (           
            <div>
                <br/><br/><br/><br/><br/>       
                <div className="container ">          
                <div className="brdcolor" >             
                       
                          <div className="d-flex justify-content-center">
                                {this.state.showSuccessMsg ?
                                <div>
                                    <FlashMessage duration={5000} persistOnHover={true}>
                                    {flashMessage(this.state.message, this.state.msgType)}
                                    </FlashMessage>
                                </div>
                                :null
                                }
                            </div>
                           
                            <br/><br/>
                            <div className="d-flex justify-content-center">

                            <span onClick={() =>this.backToLogin()}><span><img  src={Logo} alt="Logo" /></span></span>
                              </div><br/><br/>

                            <div className="d-flex justify-content-center">
                                                              
                                  <span className="wlcmcolor">Forgot Password</span>                        
                                                           
                            </div> <br/>
                            <div className="d-flex justify-content-center">
                              <div className="form-group col-sm-4">                       
                                  <input type="text" className="form-control" id="email" name="email" value={this.state.email} placeholder="Enter Your Email Id"  required onChange={this.emailChange}/>
                                  <div className="valid-feedback">Valid.</div>
                                  <div className="invalid-feedback">Please fill out this field.</div>
                              </div> 
                           </div>   
                                                     
                              <div className="d-flex justify-content-center">
                                  <div className="form-group col-sm-4"> 
                                    <button type="submit" className="btn btn-primary  btn-block" onClick={() =>this.sendResetEmail()}>Submit</button>
                                  </div>
                                </div>            
                    </div>                      
               </div>
            </div>       
        )
    }
}
