///////////////////////////////////////////////////////////////////////////////////////////////
// Warning : Property of Deemsoft Global Solutions LLC, It is illegal to make copy of this code
// Author : Gangaswamy Kempahonnaiah
///////////////////////////////////////////////////////////////////////////////////////////////
export const Cd_Catalog = {
	cdentrydate : '', 
	cdnumber : '', 
	barcode : '', 
	cdtitle : '', 
	cdyear : '', 
	journaltype : '', 
	department : '', 
	billno : '', 
	billdate : '', 
	notes : '', 
	authorname:'',
	price:'',
	cdstatus:'',
	id : '',
	_id:'', 
	createdby : '', 
	updatedby : '', 
	date_created : '', 
	date_updated : '', 
}