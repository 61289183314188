///////////////////////////////////////////////////////////////////////////////////////////////
// Warning : Property of Deemsoft Global Solutions LLC, It is illegal to make copy of this code
// Author : Gangaswamy Kempahonnaiah
///////////////////////////////////////////////////////////////////////////////////////////////
import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';
import Dashboard from './Dashboard';
import Test from './Test';

import Users from '../components/UsersForm';
import Users_Group from '../components/Users_GroupForm';
import Permissions from '../components/PermissionsForm';
import Vendors from '../components/VendorsForm';
import Audits from '../components/AuditsForm';
import Books_Catalog from '../components/Books_CatalogForm';
import Cd_Catalog from '../components/Cd_CatalogForm';
import Journals_Catalog from '../components/Journals_CatalogForm';
import Journals_Editions from '../components/Journals_EditionsForm';
import Checkin from '../components/CheckinForm';
import Checkout from '../components/CheckoutForm';
import Members from '../components/MembersForm';
import MenuForm from '../components/MenuForm';
import PrintBarcodes from '../components/PrintBarcodes';
import ReportForm from '../components/ReportForm';
import BookTrack from '../components/BooktrackForm';
import AdminForm from '../components/AdminForm';
import SearchForm from '../components/SearchForm';

const AppLayout = ({ match }) => (
	
	<div>
	<Header />
	<div id="page-container">
   <div id="content-wrap">
	<main>
	<Switch>
		<Route path={`${match.path}`} exact component={Dashboard} />
		<Route path={`${match.path}/users`} component={Users} />
		<Route path={`${match.path}/users_group`} component={Users_Group} />
		<Route path={`${match.path}/permissions`} component={Permissions} />
		<Route path={`${match.path}/vendors`} component={Vendors} />
		<Route path={`${match.path}/audits`} component={Audits} />
		<Route path={`${match.path}/books_catalog`} component={Books_Catalog} />
		<Route path={`${match.path}/cd_catalog`} component={Cd_Catalog} />
		<Route path={`${match.path}/journals_catalog`} component={Journals_Catalog} />
		<Route path={`${match.path}/journals_editions`} component={Journals_Editions} />
		<Route path={`${match.path}/checkin`} component={Checkin} />
		<Route path={`${match.path}/checkout`} component={Checkout} />
		<Route path={`${match.path}/members`} component={Members} />
		<Route path={`${match.path}/menus`} component={MenuForm} />
		<Route path={`${match.path}/test`} component={Test} />
		<Route path={`${match.path}/printbarcodes`} component={PrintBarcodes} />
		<Route path={`${match.path}/report`} component={ReportForm} />
		<Route path={`${match.path}/booktrack`} component={BookTrack} />
		<Route path={`${match.path}/admin`} component={AdminForm} />
		<Redirect to={`${match.url}`} />
	</Switch>
	</main><br/><br/><br/>
	</div>
   <footer id="footer"><Footer /></footer>
 </div>
	
	</div>
	);

export default AppLayout;