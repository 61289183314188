/////////////////////////////////////////////////
// Warning : Property of Deemsoft Global Solutions LLC, It is illegal to make copy of this code
// Author : Gangaswamy Kempahonnaiah
//////////////////////////////////////////////////////

import React, {Component} from 'react';
import {connect} from 'react-redux';
import DatePicker from "react-datepicker";
import {DateOnly } from '../utils/DateTime';
import { Books_Catalog } from '../models/Books_Catalog';
import axios from 'axios';
import { NavLink } from 'react-router-dom';
import { base_url } from '../constants/Constants';
import { logout } from '../actions/LoginAction';
import Logo from '../assets/logo.png';
import { Validators } from '../utils/Validators';
import { Formators } from '../utils/Formators';
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

class Test extends Component {
    constructor(props){
    super(props);
    this.state = {
      settings:[],
      dateformat:'',
      dateformat:'',
      formObj:Books_Catalog,
      endDate:new Date(),
      start_date:new Date(),
      validations : {},

    }
} 
 
componentDidMount(){
  try {
var dt="18-10-2020";
var checkdt=dt.replace(/\D/g,"");
if(checkdt.length == 8){
var inputdate = dt.split('-');
var inputformat= inputdate[1]+'-'+inputdate[0]+'-'+inputdate[2];
var date= new Date(inputformat);
if( date == "Invalid Date"){
   console.log("invalid");
}else{
  console.log(date);  
}
}else{
  //Please provide the correct Date format with dd-mm-yyyy format
}
} catch (error) {
    console.log(error);
}
var dateFormat = 'DD-MM-YYYY'
var startdate = "12-12-2020";
// startdate = startdate.replace(/\D/g,"");
if(startdate.length == 10){
var startsdate = startdate.split('-');
console.log(startsdate);
if(startsdate[0] >= 1 && startsdate[0] <= 31) {
  console.log("date fine");
if(startsdate[1] >= 1 && startsdate[1] <= 12){
  console.log("month fine");
if (startsdate.length == 3) {
  var d = moment(startsdate[0]+"-"+startsdate[1]+"-"+startsdate[2], dateFormat);
  console.log(d.isValid());
  var dt=moment(d,dateFormat).format();
  console.log(dt);
} else {
  //incorrectFormat
}
}else{
 
  //incorrect month Format
}
}else{
  //incorrect date Format
}
}





  let tempObj = {};
  for (let key in Books_Catalog) { tempObj[key] = ""; }
  this.setState({validations:tempObj}); 
  let tempObjs = {};
  for (let key in Books_Catalog) { tempObjs[key] = ""; }
  this.setState({formObj:tempObjs}); 

  axios.get(base_url+'settings/list', this.props.user.header ).then(res => {
    if ( res.data.length > 0 ){
         for(var i=0;i<res.data.length;i++){
        this.setState({ settings:res.data[i]});	
        }	
    }
    if(this.state.settings.dateformats == ''){
      this.setState({dateformat:"dd/MM/yyyy"});
    }else if(this.state.settings.dateformats !==''){
      this.setState({dateformat:this.state.settings.dateformats});
    }
  }).catch(function (error) { console.log(error); });


  
  
}
changeEvent = (e) =>{
 console.log(e);
 this.setState({ start_date : e });
 var tempObj=this.state.formObj;
		tempObj.billdate=e ;
		this.setState({formObj:tempObj});
    console.log(this.state.formObj);
}
validate = (val) => {
  let tempObj = this.state.validations;
  switch(val){
    case "billdate": tempObj.billdate = Validators.date(this.state.formObj.billdate);break;			
  }	
  console.log(this.state.validations);
  this.setState({validations:tempObj});
 } 
render() {
  return ( 
    <div className="container">
      <br/><br/><br/>
      <div className="d-flex justify-content-center">
      <div className="col-md-4">
							<label htmlFor="idbilldate" className="label-custom" >Bill Date</label> <br/>
							<DateOnly 
               name="billdate"
               selected={this.state.start_date}
              	value={this.state.formObj.billdate}  
                onChange={(e)=>{this.changeEvent(e)}}
                dateformat={this.state.dateformat}
               // class_name="form-control form-control-sm"
                class_name={ this.state.validations.billdate==""?"form-control form-control-sm":"form-control form-control-sm red-border" }
                onBlur={this.validate}
              /></div>
            <span className="errormsg">{this.state.validations.billdate}</span>
      </div>
    </div>
  );
  }
}

const mapToProps = ({ loggedUserState }) => ({ user: loggedUserState })
export default connect(mapToProps)(Test);
