
////////////////////////////////////////////////////////////////////////////////////////////////////
// Warning : Property of Deemsoft Global Solutions LLC, USA. It is illegal to make copy of this code
// Author : Gangaswamy Kempahonnaiah
////////////////////////////////////////////////////////////////////////////////////////////////////
import React from 'react';
import { connect } from 'react-redux';
import '../styles/Styles.css';
import CheckinForm from './CheckinForm';
import CheckoutForm from './CheckoutForm';
import Cd_catalogForm from './Cd_CatalogForm';
import Books_CatalogForm from './Books_CatalogForm';
import Journals_CatalogForm from './Journals_CatalogForm';
import MembersForm from './MembersForm';
import Journals_EditionsForm from './Journals_EditionsForm';
import PrintBarcodes from'./PrintBarcodes';
import ReportForm from './ReportForm';
import AuditsForm from './AuditsForm';
import SmsForm from './SmsForm';
import Bill_CatalogForm from './BillingForm';

class MenuForm extends React.Component {
	constructor(props){
		super(props);
		this.state = {
		step:1,
		}
	
	}

	componentDidMount(){
	
	}

    set_Step=(stp)=>{ 
        this.setState({step:stp});

    }
    render_menu(stp,label){
        if(stp == this.state.step){
        return(
            <div className="btnstyle" onClick={()=>{this.set_Step(stp)}}>
            <span className="textActive">{label}</span>
            </div> 
        );
        }else{
        return(
            <div className="btnstyle" onClick={()=>{this.set_Step(stp)}}>
              <span className="text2">{label}</span>
            </div>  
        );
        }
    }
    render_menus(){
        return(
            
            <div className="nav flex-column"  aria-orientation="vertical">
             {this.render_menu(1,'Registration')}
             {this.render_menu(2,'Issue Books')}
             {this.render_menu(3,'Receive Books')}
             {this.render_menu(4,'Journal Entry')}
             {this.render_menu(5,'Stock Verification')}
             {this.render_menu(6,'Books Catalog')}
             {this.render_menu(7,'Journal Catalog')}
             {this.render_menu(8,'CD/DVD Catalog')}
             {this.render_menu(9,'Print Barcodes')}
             {this.render_menu(10,'Billing')}
             {this.render_menu(11,'Reports')}
             {this.render_menu(12,'SMS/Notification')}
             </div>
        )
    }
    render_body(){
        switch(this.state.step) {
             case 1: return (<MembersForm parentObject={this}  header={this.props.user.header} />);
             case 2: return (<CheckoutForm parentObject={this}  header={this.props.user.header} />);
             case 3: return (<CheckinForm parentObject={this}  header={this.props.user.header} />);
             case 4: return (<Journals_EditionsForm parentObject={this}  header={this.props.user.header} />);
             case 5: return (<AuditsForm parentObject={this}  header={this.props.user.header} />);
             case 6: return (<Books_CatalogForm parentObject={this}  header={this.props.user.header} />);
             case 7: return (<Journals_CatalogForm parentObject={this}  header={this.props.user.header} />);
             case 8: return (<Cd_catalogForm parentObject={this}  header={this.props.user.header} />);
             case 9: return (< PrintBarcodes parentObject={this}  header={this.props.user.header} />);
             case 10: return (< Bill_CatalogForm parentObject={this}  header={this.props.user.header} />);
             case 11: return (< ReportForm parentObject={this}  header={this.props.user.header} />);
             case 12: return (<SmsForm parentObject={this}  header={this.props.user.header} />);
             default: return (<CheckinForm parentObject={this}  header={this.props.user.header} />);
        }
    }


	render() {
		return (
            <div className="container-fluid">
             <div className="d-flex row">
                 <div className="p-2  col-md-2">{this.render_menus()}</div>
                 <div className="p-2 col-md-10">{this.render_body()}</div>
            </div> 
			
			</div>
		)
		}
	}

const mapToProps = ({ loggedUserState }) => ({ user: loggedUserState })
export default connect(mapToProps)(MenuForm);
