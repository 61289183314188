/////////////////////////////////////////////////////////////////////////////
//Property of Deemsoft Global Solutions LLC
//Proprietory code and it is illegal to make copy or export to any form
//Constants.js
/////////////////////////////////////////////////////////////////////////////

//export const base_url = 'https://webapp.elibrarysoft.com/';
//export const base_url = 'http://localhost:5010/'; // skamc
//export const base_url = 'https://amc.elibrarysoft.com/';
export const base_url = 'https://skamclibrary.deemsoft.com/';
//export const base_url = 'http://localhost:5001/';

export const date_format = "MM/dd/yyyy";
export const time_format = "h:mm aa";
export const datetime_format = "MM/dd/yyyy hh:mm aa";
export const status_options = [
                { label: 'Active', value: 'Active' },
                { label: 'Inactive', value: 'Inactive' }
             ];
export const type_options = [
                { label: 'Active', value: 'Active' },
                { label: 'Inactive', value: 'Inactive' }
             ];
