///////////////////////////////////////////////////////////////////////////
// Warning : Property of Deemsoft, It is illegal to make copy of this code
// Author : Gangaswamy Kempahonnaiah
///////////////////////////////////////////////////////////////////////////

export const Formators = {
    name: function(val){
       let ret='';
       if(val !== undefined){
            ret = val;
			
       }else{
           ret = "It must have at least one Alphanumeric";
       }
       return ret;
    },
	price: function(val){
		let ret='';
		if(val !== undefined){
			 ret = val + ".00";
		}else{
			ret = "It must have at least one Alphanumeric";
		}
		return ret;
	 },

	 fine: function(val){
		 if(val !== "" && val !== undefined){
          var fine= val.toFixed(2);
		  return fine;
		 }

	 },
	

getFormattedPhoneNum:function(val){
	var pattern = new RegExp(/^[0-9\b]+$/);
	if(val !== null && val !== ''){
	let output = "(";
	val.replace( /^\D*(\d{0,3})\D*(\d{0,3})\D*(\d{0,4})/, function( match, g1, g2, g3 )
	{
	if ( g1.length ) {
	output += g1;
	if ( g1.length == 3 ) {
	output += ")";
	if ( g2.length ) {
	output += " " + g2;
	if ( g2.length == 3 ) {
	output += "-";
	if ( g3.length ) {
	output += g3;
	}
	}
	}
	}
	}
	}
	);
	return output;
	}
	},
getpages:function(val){
	if(val !== null && val !== ''){
		var output="";
		val.replace( /^\D*(\d*)/,function(match,g1){
		output +=g1;
		}
		)
	}
	return output;
},
firstname:function(val){
var output=	val.charAt(0).toUpperCase() + val.slice(1); 
return output;
},
cardnumber:function(val){
	if(val !== "" && val !== undefined)
	var mystr = val;
	var pad = '0000'
	return (pad + mystr).slice(-pad.length);

},
accnumber:function(val){
	if(val !== "" && val !== undefined)
	var mystr = val;
	var pad = '00000'
	return (pad + mystr).slice(-pad.length);

}

}