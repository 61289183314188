////////////////////////////////////////////////////////////////////////////////////////////////////
// Warning : Property of Deemsoft Global Solutions LLC, USA. It is illegal to make copy of this code
// Author : Gangaswamy Kempahonnaiah
////////////////////////////////////////////////////////////////////////////////////////////////////
import React from 'react';
import { connect } from 'react-redux';
import { base_url } from '../constants/Constants';
import '../styles/Styles.css';


class Approval extends React.Component {
	constructor(props){
		super(props);
		this.state = {
			
		}
	}

	componentDidMount(){				
		
	}
	
	render() {
		
	 return (
		<div className="container">
            <br/><br/><br/><br/><br/><br/><br/>
           <div className="card">
           <br/><br/><br/>
               <div class="row justify-content-center">
               <h1 className="text-primary">Thank you!! Template Approved Successfully</h1>
               </div>
             <br/><br/><br/>   
           </div>
        </div>
		)
	}	
}

const mapToProps = ({ loggedUserState }) => ({ user: loggedUserState })
export default connect(mapToProps)(Approval);
