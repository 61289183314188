///////////////////////////////////////////////////////////////////////////////////////////////
// Warning : Property of Deemsoft Global Solutions LLC, It is illegal to make copy of this code
// Author : Gangaswamy Kempahonnaiah
///////////////////////////////////////////////////////////////////////////////////////////////
export const Users_Group = {
	groupname : '', 
	status : '', 
	id : '', 
	createdby : '', 
	updatedby : '', 
	date_created : '', 
	date_updated : '', 
}