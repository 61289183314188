import pdfMake from 'pdfmake/build/pdfmake';
import vfsFonts from 'pdfmake/build/vfs_fonts';
import { base_url } from '../constants/Constants';
import axios from 'axios';

async function getDocumentDefnition(eventid, option1,option2,option3){
  var objlist=[];
  await axios.get(base_url+'bills/list',  eventid ).then(res => {
    if ( res.data.length > 0 ){
      objlist=res.data;  
      console.log(objlist);
    }
  }).catch(function (error) { console.log(error); });
  var stafflist=[[
    { text: 'ID', style: 'tableHeader'}, 				  
    { text: 'First Name', style: 'tableHeader' },   
    { text: 'Last Name', style: 'tableHeader' }, 
    {text:  'Net Total',style:'tableHeader'},    
    {text:  'Paid',style:'tableHeader'},    
    {text:  'Balance',style:'tableHeader'},    
          
  ]]; 
  for(var i in objlist) {   
    stafflist.push([
      { text: ''+objlist[i].id+'' , style:'header2'},						
      { text: ''+objlist[i].firstname+'' , style:'header2'},
      { text: ''+objlist[i].lastname+'' , style:'header2'},
      { text: ''+objlist[i].nettotal+'' , style:'header2',alignment:"right"},
      { text: ''+objlist[i].paid+'' , style:'header2',alignment:"right"},
      { text: ''+objlist[i].balance+'' , style:'header2',alignment:"right"},
    ]);
  }
  var docDefinition = {
    pageOrientation: 'page',
    content: [ 
      { text: 'Bill Report', style:'header1' },
      {text:'\n'},
      { table:{
        headerRows: 1,
        widths: [30,120,70,80,80,80],
        body: stafflist
      },
      layout: {
        hLineWidth: function (i, node) {
          return  1;
        },
        vLineWidth: function (i, node) {
          return  1;
        },
        hLineColor: function (i, node) {
          return  '#C0C0C0';
        },
        vLineColor: function (i, node) {
          return  '#C0C0C0';
        },
      },
    },],
    styles:{
      header:{fontSize:16, bold:true, margin:[0, 10, 0, 10], alignment:'center'},
      header1:{fontSize:16, bold:true, margin:[0, 10, 0, 10],alignment:'center',},
      header2:{fontSize:12},
      header4:{fontSize:10},
      header3:{fontSize:10, alignment:'right'},
      tableHeader:{fillColor:'#C0C0C0',bold:true,fontSize:14},
      label:{fontSize:11},
    }
  }
  const {vfs} = vfsFonts.pdfMake;
  pdfMake.vfs = vfs;
  if(option1 =='open'){
    pdfMake.createPdf(docDefinition).open(); 
  }
  else if(option2=='download'){
    pdfMake.createPdf(docDefinition).download(); 
  }
  else if(option3=='print'){
    pdfMake.createPdf(docDefinition).print(); 
  }
}
export const PdfDoc = {    
  openPdf: function(id){  
    getDocumentDefnition(id,'open','download','print');
      
  }
}