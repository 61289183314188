////////////////////////////////////////////////////////////////////////////////////////////////////
// Warning : Property of Deemsoft Global Solutions LLC, USA. It is illegal to make copy of this code
// Author : Gangaswamy Kempahonnaiah
////////////////////////////////////////////////////////////////////////////////////////////////////
import React from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { base_url } from '../constants/Constants';
import { Validators } from '../utils/Validators';
import { Formators } from '../utils/Formators';
import {DateOnly} from '../utils/DateTime';
import { Journals_Editions } from '../models/Journals_Editions';
import '../styles/Styles.css';

class Journals_EditionsForm extends React.Component {
constructor(props){
	super(props);
	this.state = {
		objList : [],
		objList1 : [],
		showModal : false,
		validations : {},
		searchform:true,
		mainform:false,
		searchtable:false,
		formObj : Journals_Editions,
		endDate:new Date(),
		dateformat:'',
		settings:[],
		year: []
	}
}

componentDidMount(){
	let tempObj = {};
	for (let key in Journals_Editions) { tempObj[key] = ""; }
	this.setState({validations:tempObj});
	let tempObjs = {};
	for (let key in Journals_Editions ) { tempObjs[key] = ""; }
	this.setState({ formObj:tempObjs});
    this.getObjList();
	const year = new Date().getFullYear()
	let year_opt = []
	for(let i=2008; i<=year; i++){
		year_opt.unshift(i)
	}
	this.state.year = year_opt
}

getObjList = () => {
	axios.get(base_url+'settings/list', this.props.user.header ).then(res => {
		if ( res.data.length > 0 ){
			for(var i=0;i<res.data.length;i++){
				this.setState({ settings:res.data[i]});	
			}	
		} 
		if(this.state.settings.dateformats == ''){
			this.setState({dateformat:"dd/MM/yyyy"});
		}else if(this.state.settings.dateformats !==''){
			this.setState({dateformat:this.state.settings.dateformats});
		}  
	}).catch(function (error) { console.log(error); });
	axios.get(base_url+'journals_editions/list', this.props.user.header ).then(res => {
		if ( res.data.length > 0 ){
			this.setState({ objList:res.data});
		}
	}).catch(function (error) { console.log(error); });

}
saveObj = () => {
	let temp='';
	for (let key in Journals_Editions) { this.validate(key);}
	for (let key in this.state.validations) {temp += this.state.validations[key];}
	if(temp == ''){
	let tempObj = this.state.formObj;
		tempObj.status = 'Active';
	axios.post(base_url+'journals_editions/save', tempObj, this.props.user.header ).then(res => {
			this.setState({ formObj:res.data.data});
			this.getObjList();
	}).catch(function (error) { console.log(error); });
}
}
gotoSearchpage = () =>{
	this.clearform();
	this.setState({searchtable:false,searchform:true,mainform:false});
}
addObj = () => { 
	let tempObj = {};
	for (let key in Journals_Editions ) { tempObj[key] = ""; }
	this.setState({ formObj:tempObj, showModal:true});
}

editObj = (i) => { 
	let tempObj = {};
	for (let key in this.state.objList[i] ) { tempObj[key] = this.state.objList[i][key]; }
	this.setState({ formObj:tempObj, showModal:true});
}

deleteObj = (i) => { 
	let tempObj = this.state.objList[i];
	tempObj.status = 'Inactive';
	axios.post(base_url+'journals_editions/save', tempObj , this.props.user.header ).then(res =>{
		if ( res.data.length > 0 ){
			this.setState({ formObj:res.data});
			this.getObjList();
		}
	}).catch(function (error) { console.log(error); });
}


clearform = () => {
		
	let tempObj = {};
	for (let key in Journals_Editions ) { tempObj[key] = ""; }
	this.setState({ formObj:tempObj});
	let tempObjs = {};
	for (let key in Journals_Editions ) { tempObj[key] = ""; }
	this.setState({ validations:tempObjs});

}
searchType=(id,barcode,name)=>{
	if(id !== '' && id !== undefined){
		this.searchJournal(id,"pissn");
	}else if(barcode !== '' && barcode !== undefined){
		this.searchJournal(barcode, "Barcode");
	}
	else if(name !== '' && name !== undefined){
		var output = name.trim();
		var input=output.substring(0, 1).toUpperCase() + output.substring(1);
		this.searchJournal(input, "author");
		}
	
	else{
		let tempObj = this.state.searchmessage;
		tempObj="Please Enter pissn or  Journal title to search";
		let temarr = [];
		this.setState({searchmessage:tempObj});
		this.setState({objList:temarr,searchtable:false});
	}
}
searchJournal =(id,by) => {
	if(id != "" && id != undefined){
		let temp={search : id ,type: by}
		axios.post(base_url+'journals_catalog/search1', temp, this.props.user.header )
		.then(res =>{
		if(res.data.length > 0){
		let tempObj = this.state.searchmessage;
			tempObj="";
			this.setState({searchmessage:tempObj});
		this.setState({objList1:res.data,searchtable:true});
		}else{
			let tempObj = this.state.searchmessage;
			tempObj="Journal details you entered is not match with our records";
			this.setState({searchmessage:tempObj});
			let temarr = [];
			this.setState({objList1:temarr,searchtable:false});
		}
	}).catch(function (error) { console.log(error); }); 

	this.renderObjlist1();
}else{
	let tempObj = this.state.searchmessage;
	tempObj="Please Enter pissn or Journal title  to search";
	let temarr = [];
	this.setState({searchmessage:tempObj});
	this.setState({objList1:temarr,searchtable:false});
}

}

validate = (val) => {
	let tempObj = this.state.validations;
	switch(val){
		case "barcode": tempObj.barcode = Validators.name(this.state.formObj.barcode); break;
		case "pissn": tempObj.pissn = Validators.name(this.state.formObj.pissn); break;
		case "journalmonth": tempObj.journalmonth = Validators.select(this.state.formObj.journalmonth); break;
		case "journalyear": tempObj.journalyear = Validators.select(this.state.formObj.journalyear); break;
		case "volumenum": tempObj.volumenum = Validators.name(this.state.formObj.volumenum); break;
		case "issuenum": tempObj.issuenum = Validators.name(this.state.formObj.issuenum); break;
		case "receiveddate": tempObj.receiveddate = Validators.date1(this.state.formObj.receiveddate); break;
	}
	this.setState({validations:tempObj});
	} 

format = (val, e) => { 
	let tempObj = this.state.formObj;
	switch(val){
		case "barcode": tempObj.barcode = Formators.name(e.target.value); break;
		case "pissn": tempObj.pissn = Formators.name(e.target.value); break;
		case "journaltitle": tempObj.journaltitle = Formators.name(e.target.value); break;
		case "journalpublisher": tempObj.journalpublisher = Formators.name(e.target.value); break;
		case "journalmonth": tempObj.journalmonth = Formators.name(e.target.value); break;
		case "journalyear": tempObj.journalyear = Formators.name(e.target.value); break;
		case "volumenum": tempObj.volumenum = Formators.name(e.target.value); break;
		case "issuenum": tempObj.issuenum = Formators.name(e.target.value); break;
		case "receiveddate": tempObj.receiveddate = Formators.name(e.target.value); break;
		case "notes": tempObj.notes = Formators.name(e.target.value); break;
	}
	this.setState({formObj:tempObj});
	} 

	getDetails = (i,id) => { 
	let tempObjs = {};
	for (let key in Journals_Editions  ) { tempObjs[key] = ""; }
	this.setState({validations:tempObjs});;
	let tempObj = this.state.formObj;
	tempObj.barcode=this.state.objList1[i].barcode;
	tempObj.pissn=this.state.objList1[i].pissn;
	tempObj.journaltitle=this.state.objList1[i].journaltitle;
	tempObj.journalpublisher=this.state.objList1[i].journalpublisher;
	this.setState({formObj:tempObj});
	this.setState({mainform:true,searchform:false,searchtable:false});
}

renderObjlist1 = () => {
	if( this.state.objList1 != undefined && this.state.objList1.length > 0){
		return this.state.objList1.map((item, index) =>{
			return (
				<tr key={index} id={index}  onClick={()=>{this.getDetails(index,item.pissn)}}>
					<td>{item.pissn}</td>
					<td>{item.journaltitle}</td>
					<td>{item.journalpublisher}</td>
				</tr>
				)
			}, this);
	}
}
changeEvent = (e) =>{
	//console.log(e);
	this.setState({ endDate : e });
	var tempObj=this.state.formObj;
		tempObj.receiveddate=e ;
	this.setState({formObj:tempObj});
	this.validate("receiveddate");
	  // console.log(this.state.formObj);
   }
renderObjlist = () => {
	if( this.state.objList != undefined && this.state.objList.length > 0){
		return this.state.objList.map((item, index) =>{
			var now = new Date(item.receiveddate);
			var month = (now.getMonth() + 1);               
			var day = now.getDate();
				if (month < 10) 
					month = "0" + month;
				if (day < 10) 
					day = "0" + day;
					var today =  day + '-' + month+ '-' +now.getFullYear();
			return (
				<tr key={index} id={index}>
					<td>{item.pissn}</td>
					<td>{item.journaltitle}</td>
					<td>{item.journalmonth}</td>
					<td>{item.volumenum}</td>
					<td>{today}
					</td>
					</tr>
				)
			}, this);
	}
}


render() {
	return (
	<div className="shadow-sm p-3 mb-5 bg-white rounded">
		<div><div className="d-flex row justify-content-center"><h3>Journal Entry</h3></div></div><br/>
		{this.state.searchform?
		<div className="p-2 row">
		<div className="p-2  flex-fill row">
		<div className="p-2 flex-fill">
					<label htmlFor="idpissn" className="label-custom" >P-ISSN</label> 
				<input value={this.state.formObj.pissn} id="idpissn"  onChange={(e)=>{this.format("pissn",e)}}  onKeyPress={(e) => e.key === 'Enter' ? this.searchType(this.state.formObj.pissn,this.state.formObj.barcode): null} className="form-control form-control-sm"  type="text" className='form-control form-control-sm' required /> 
				<span className="errormsg">{this.state.searchmessage}</span>
		</div>	
		<div className="p-2  flex-fill">
				<label htmlFor="idbarcode" className="label-custom" >Barcode</label> 
				<input value={this.state.formObj.barcode} id="idbarcode"  onChange={(e)=>{this.format("barcode",e)}}   onKeyPress={(e) => e.key === 'Enter' ? this.searchType(this.state.formObj.pissn,this.state.formObj.barcode): null}  className="form-control form-control-sm"  type="text" className='form-control form-control-sm' required /> 
				
		</div>
		<div className="p-2  flex-fill">
			<button type="button" className="btn btn-primary btn-sm" style={{marginTop:32}}  onClick={()=>{ this.searchType(this.state.formObj.pissn,this.state.formObj.barcode)}}>Search</button>
		</div>
		</div>
		<div className="p-2  flex-fill"></div>
		</div>
			:null}
			{this.state.searchtable?
					<div className="d-flex row">
						<table className="table table-hover table-sm">
							<thead className="thead">
								<tr>
									<td>PISSN No</td>
									<td>Journal Title</td>
									<td>Journal Publisher</td>
								</tr>
							</thead>
							<tbody>{this.renderObjlist1()}</tbody>
						</table>
					</div>
		:null}
			
			{this.state.mainform?<div>
			<div className="d-flex row">
			<table className="table table-bordered tablepointer table-sm">
							<thead className="thead">
								<tr>
									<td>Journal Id</td>
									<td>Journal Title</td>
									<td>Journal Publisher</td>
								</tr>
							</thead>
							<tbody>
								<tr>
								<td>{this.state.formObj.pissn}</td>
								<td>{this.state.formObj.journaltitle}</td>
								<td>{this.state.formObj.journaltitle}</td>
								</tr>
							</tbody>
						</table>
			</div>
			<div className="d-flex row">
			<div className="p-2 flex-fill">
					<label htmlFor="idjournalmonth" className="label-custom" >Month</label> 
					<select value={this.state.formObj.journalmonth} id="idjournalmonth"  onChange={(e)=>{this.format("journalmonth",e)}}  placeholder="Enter Journalmonth" onBlur={()=>{ this.validate("journalmonth")}} className="form-control form-control-sm" style={this.state.validations.journalmonth===""?{}:{"borderColor":"red"}} type="text" className='form-control form-control-sm' required > 
					<option value="">Month</option>
					<option>Jan</option>
					<option>Feb</option>
					<option>Mar</option>
					<option>Apr</option>
					<option>May</option>
					<option>Jun</option>
					<option>Jul</option>
					<option>Aug</option>
					<option>Sep</option>
					<option>Oct</option>
					<option>Nov</option>
					<option>Dec</option>
					</select>
					<span className="errormsg">{this.state.validations.journalmonth}</span>
				</div>
				<div className="p-2 flex-fill">
					<label htmlFor="idjournalyear" className="label-custom" >Year</label> 
					<select value={this.state.formObj.journalyear} id="idjournalyear"  onChange={(e)=>{this.format("journalyear",e)}}  placeholder="Enter Journalyear" onBlur={()=>{ this.validate("journalyear")}} className="form-control form-control-sm" style={this.state.validations.journalyear===""?{}:{"borderColor":"red"}} type="text" className='form-control form-control-sm' required >
					<option value=""></option>
					{[...Array((new Date().getFullYear() + 1) - 2000).keys()].reverse().map(i => {
						return <option>{2000 + i}</option>
					})}
					</select> 
					<span className="errormsg">{this.state.validations.journalyear}</span>
				</div>
				<div className="p-2 flex-fill">
					<label htmlFor="idvolumenum" className="label-custom" >Volume No.</label> 
					<input value={this.state.formObj.volumenum} id="idvolumenum"  onChange={(e)=>{this.format("volumenum",e)}} onBlur={()=>{ this.validate("volumenum")}} className="form-control form-control-sm" style={this.state.validations.volumenum===""?{}:{"borderColor":"red"}} type="text" className='form-control form-control-sm' required /> 
					<span className="errormsg">{this.state.validations.volumenum}</span>
				</div>
				<div className="p-2 flex-fill">
					<label htmlFor="idissuenum" className="label-custom" >Issue No.</label> 
					<input value={this.state.formObj.issuenum} id="idissuenum"  onChange={(e)=>{this.format("issuenum",e)}}   onBlur={()=>{ this.validate("issuenum")}} className="form-control form-control-sm" style={this.state.validations.issuenum===""?{}:{"borderColor":"red"}} type="text" className='form-control form-control-sm' required /> 
					<span className="errormsg">{this.state.validations.issuenum}</span>
				</div>
				<div className="p-2">
					<label htmlFor="idreceiveddate" className="label-custom" >Received Date</label> <br/>
				 <DateOnly 
					name="receiveddate"
					selected={this.state.endDate}
					value={this.state.formObj.receiveddate}  
					onChange={(e)=>{this.changeEvent(e)}}
					dateformat={this.state.dateformat}
					class_name={ this.state.validations.receiveddate ==""?"form-control form-control-sm":"form-control form-control-sm red-border" }
					onBlur={this.validate}
					/><br/>
				 	<span className="errormsg">{this.state.validations.receiveddate}</span>
				</div>
			</div>
			<div className="d-flex row">
				<div className="p-2 flex-fill">
					<label htmlFor="idnotes" className="label-custom" >Notes</label> 
					<textarea value={this.state.formObj.notes} id="idnotes" rows="2" cols="20" onChange={(e)=>{this.format("notes",e)}}  onBlur={()=>{ this.validate("notes")}} className="form-control form-control-sm" style={this.state.validations.notes===""?{}:{"borderColor":"red"}} type="text" className='form-control form-control-sm' required /> 
					<span className="errormsg">{this.state.validations.notes}</span>
				</div> 
			</div>
			<br/><br/>
		<div className="d-flex row justify-content-end">
		<div className="p-2">
		<button type="button" className="btn btn-success btn-sm" onClick={()=>{this.gotoSearchpage()}}>New</button>
		</div>
		<div className="p-2">
		<button type="button" className="btn btn-success btn-sm" onClick={()=>{this.saveObj()}}>Save</button>
		</div>
		<div className="p-2">
		<button type="button" className="btn btn-warning btn-sm" onClick={()=>{this.gotoSearchpage()}}>Cancel</button>
		</div>	
		</div>
		<table className="table table-bordered tablepointer table-sm">
		<thead className="thead">
			<tr>
				<td>P-ISSN</td>					
				<td>Book Title</td>
				<td>Month</td>
				<td>Volume No.</td>
				<td>Received Date</td>					
			</tr>
		</thead>
		<tbody>{ this.renderObjlist() }</tbody> 
		</table>
		</div>
		:null}
		</div>
	)
	}
}

const mapToProps = ({ loggedUserState }) => ({ user: loggedUserState })
export default connect(mapToProps)(Journals_EditionsForm);
