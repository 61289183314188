////////////////////////////////////////////////////////////////////////////////////////////////////
// Warning : Property of Deemsoft Global Solutions LLC, USA. It is illegal to make copy of this code
// Author : Gangaswamy Kempahonnaiah
////////////////////////////////////////////////////////////////////////////////////////////////////
import React from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { base_url } from '../constants/Constants';
import {ReactTabulator} from 'react-tabulator';
import 'react-tabulator/lib/styles.css';
import "react-tabulator/lib/css/bootstrap/tabulator_bootstrap.min.css";
import '../styles/Styles.css';
import '../styles/Tabulator.css';
import { FaFileExcel, FaFilePdf,FaBars } from 'react-icons/fa';
import pdfMake from 'pdfmake/build/pdfmake';
import vfsFonts from 'pdfmake/build/vfs_fonts';
import { CSVLink, CSVDownload } from "react-csv";
import {Setting} from '../models/Setting';
import moment from 'moment'

const editableColumns = [
    {
      title: "ID",
      field: "id",
      headerFilterPlaceholder:" ",
      headerFilter: "input"
    },
    
    {
      title: "Author Name",
      field: "authorname",
      headerFilterPlaceholder:" ",
      headerFilter: "input"
    },
    {
      title: "CD Title",
      field: "cdtitle",
      headerFilterPlaceholder:" ",
      headerFilter: "input"
    },
    {
      title: "CD Year",
      field: "cdyear",
      headerFilterPlaceholder:" ",
      headerFilter: "input"
    },
    {
      title: "Department",
      field: "department",
      headerFilterPlaceholder:" ",
      headerFilter: "input"
    },
    {
        title: "Status",
        field: "status",
        headerFilterPlaceholder:" ",
        headerFilter: "input"
    },
    {
        title: "Created Date",
        field: "date_created",
        headerFilterPlaceholder:" ",
        headerFilter: "input",
    },
    
  ];
class CdTabulator extends React.Component {
    constructor(props){
        super(props);
        this.state={
            objList:this.props.parentObject,
            originalTabColums:[],
            settings:Setting,
            filteredTabColums:editableColumns,
            defaultcolumns:[],
            tabdata:this.props.parentObject,
            finaldata:[],
            imageheader:'data:image/png;base64,',
        }
    }
    componentDidMount(){
        if(this.state.objList !="" && this.state.objList !=undefined){
            this.loadAllTabFields();
        axios.get(base_url+'settings/list', this.props.user.header ).then(res => {
            if ( res.data.length > 0 ){
                for(var i=0;i<res.data.length;i++){
                    this.setState({ settings:res.data[i]});	
                }	
            }
            var temp=this.state.tabdata;
            var dateformat='';
            if(this.state.settings.dateformats !== "" && this.state.settings.dateformats !== undefined){
                dateformat=this.state.settings.dateformats.toUpperCase();
              }else{
                  dateformat='DD/MM/YYYY';
              }
            for(let i in this.state.tabdata){
               let inDate = this.state.tabdata[i].date_created,
               upDate = this.state.tabdata[i].date_updated,
               billDate = this.state.tabdata[i].billdate,
               cdentryDate = this.state.tabdata[i].cdentrydate,
               inFormat = 'YYYY-MM-DD[T]HH:mm:ss.SSS[Z]Z',
               outFormat = this.state.settings.dateformats,
               outDate = moment(inDate, inFormat).format(dateformat);
               let outupDate = moment(upDate, inFormat).format(dateformat);
               let billoutDate = moment(billDate, inFormat).format(dateformat);
               let cdoutDate = moment(cdentryDate, inFormat).format(dateformat);
                 temp[i].date_created=outDate;
                 temp[i].date_updated=outupDate;
                 temp[i].billdate=billoutDate;
                 temp[i].cdentrydate=cdoutDate;
                 this.state.finaldata.push(temp[i]);
            }
            this.state.finaldata.sort(function(a, b) {
                return a.cdnumber - b.cdnumber 
            });
            this.loadAllTabFields();
        }).catch(function (error) { console.log(error); });
        }
        var imageData=this.state.imageheader;
		axios.post(base_url+'file/b64download',{filename:'header.png'},this.props.user.header)
        .then(res=>{
        imageData += res.data.data;
        this.setState({imageheader:imageData});
        }).catch(function (error) { console.log(error); }); 
    }
    addToTab = (e, item, index) => {
        var list1 = this.state.originalTabColums;
        if(e.target.checked){           
            list1[index].status = true;     
        }else{  
            list1[index].status = false;        
        }
        this.setState({originalTabColums:list1});
            var tempArray = [];
            for (var key in list1) {
                if(list1[key].status){
                    if(list1[key].title=='ID' || list1[key].title=='AUTHORNAME' || list1[key].title=='CDTITLE' || list1[key].title=='CDYEAR' || list1[key].title=='DEPARTMENT'){
                        tempArray.push({ id:list1[key].id,headerFilterPlaceholder:" ",
                        headerFilter: "input", title: list1[key].title, field: list1[key].field, status:list1[key].status,});
                    }else{
                        tempArray.push({ id:list1[key].id,headerFilterPlaceholder:" ",
                        headerFilter: "input", title: list1[key].title, field: list1[key].field, status:list1[key].status});
                    }
                }                   
            }
        this.setState({filteredTabColums:tempArray});     
    }
    loadAllTabFields(){
        var newTabColums = [];
        var tempArray1 = [];
        var selectedFields =["cdnumber","authorname","cdtitle","cdyear","department","cdstatus","date_created"];
        var keys = Object.keys(this.state.objList[0]);
            keys=[ "cdnumber","authorname","cdtitle","cdyear","department","cdstatus","date_created", "barcode", "billdate", "billno", "cdentrydate","date_updated","notes", "price", "status"];
        var i = 0;
        keys.forEach(function(key){ 
            i = Number(i)+1;            
            if(selectedFields.includes(key)){
                if(key=='id' || key=='authorname' || key=='cdtitle' || key=='cdyear' || key=='department'){
                    newTabColums.push({ id:i,headerFilterPlaceholder:" ",
                    headerFilter: "input", title: key.toUpperCase(), field: key, status:true},);
                    tempArray1.push({ id:i,headerFilterPlaceholder:" ",
                    headerFilter: "input", title: key.toUpperCase(), field: key, status:true,},);
                }else{
                    newTabColums.push({ id:i, headerFilterPlaceholder:" ",
                    headerFilter: "input",title: key.toUpperCase(), field: key, status:true},);
                    tempArray1.push({ id:i, headerFilterPlaceholder:" ",
                    headerFilter: "input",title: key.toUpperCase(), field: key, status:true},);
                }
            }
            else{
                if(key=='id' || key=='authorname' || key=='cdtitle' || key=='cdyear' || key=='department'){
                    newTabColums.push({ id:i,headerFilterPlaceholder:" ",
                    headerFilter: "input", title: key.toUpperCase(), field: key, status:false,},);
                }else{
                    newTabColums.push({ id:i,headerFilterPlaceholder:" ",
                    headerFilter: "input", title: key.toUpperCase(), field: key, status:false},);
                }
            }           
        });
        newTabColums.sort((a,b) => (a.id > b.id) ? 1 : ((b.id > a.id) ? -1 : 0));
        tempArray1.sort((a,b) => (a.id > b.id) ? 1 : ((b.id > a.id) ? -1 : 0));
        this.setState( {originalTabColums:newTabColums, filteredTabColums:tempArray1 });
    }
    downloadPDF=(option1,option2,option3)=>{
        var stafflist=[[
            { text: 'ID', style: 'tableHeader' }, 				   			
            { text: 'CD/DVD No', style: 'tableHeader' },   
            {text:'Publish Year',style:'tableHeader'},
            { text: 'CD Title', style: 'tableHeader' }, 
            {text:'Author Name',style:'tableHeader'},
        ]]; 
        var temp=this.state.objList;
        for(var i in temp) {                         
            stafflist.push(
            [
                { text: ''+temp[i].id+'' , style:'header2'},						
                { text: ''+temp[i].cdnumber+'' , style:'header2'},
                { text: ''+temp[i].cdyear+'' , style:'header2'},
                { text: ''+temp[i].cdtitle+'' , style:'header2'},
                { text: ''+temp[i].authorname+'' , style:'header2'},
                   
            ]);
        } 
        var docDefinition = {
            pageOrientation: 'page',
            content: 
            [ 
                {
                    image: this.state.imageheader,
                    height:70,
                    width:500,
                    alignment:'center',
                },
                { text: 'CD/DVD List', style:'header1', bold:true},
                {text:'\n'},
                { table:{
                    headerRows: 1,
                    widths: [30,90,90,130,130],
                    body: stafflist
                },
                layout: {
                    hLineWidth: function (i, node) {
                      return  1;
                    },
                    vLineWidth: function (i, node) {
                      return  1;
                    },
                    hLineColor: function (i, node) {
                      return  '#C0C0C0';
                    },
                    vLineColor: function (i, node) {
                      return  '#C0C0C0';
                    },
                },
            },]	,
            styles:{
                header:{fontSize:16, bold:true, margin:[0, 10, 0, 10], alignment:'center',color:'#1160a6'},
                header1:{fontSize:16, bold:true, margin:[0, 10, 0, 10],alignment:'center'},
                header2:{fontSize:12},
                header4:{fontSize:10},
                header3:{fontSize:10, alignment:'right'},
                tableHeader:{fillColor:'#C0C0C0',bold:true,fontSize:14},
                label:{fontSize:11},
            }
        }
        const {vfs} = vfsFonts.pdfMake;
        pdfMake.vfs = vfs;
        if(option1=='open'){
            pdfMake.createPdf(docDefinition).open(); 
        }
        else if(option2=='download'){
            pdfMake.createPdf(docDefinition).download(); 
        }
        else if(option3=='print'){
            pdfMake.createPdf(docDefinition).print(); 
        }
    }
          
    render(){
        const temp=this.state.objList.length;
        const options = { height:500, showFilter:true, footerElement:'<span>Total Items:'+temp+'</span>'};
        const columnlist= this.state.originalTabColums.length > 0 &&  this.state.originalTabColums.map((item, index) => {
            return (
                <tr key={index}><td><a className="dropdown-item"><input type="checkbox" className="custom-check-box" id={index} name={index} checked={item.status} onChange={(e)=>this.addToTab(e, item, index)}></input>&nbsp;&nbsp;&nbsp;&nbsp;{item.title}</a></td></tr>
            )
        }, this);
        return(
            <div>    
                <div className="sample67">                       
                    <div className="">       
                        <ReactTabulator
                            ref={ref => (this.ref = ref)}
                            data={this.state.finaldata}
                            columns={this.state.filteredTabColums}
                            options={options}
                            rowClick={this.rowClick}
                            data-custom-attr="test-custom-attribute"
                            className="custom-css-class"
                        /> 
                        <div className=" input-group-append tabulator-row-handle-bar append1">
                            <span className="" type="text" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><FaBars /></span>
                            <div className="dropdown-menu scrollable-dropdown my-custom-scrollbar">                                 
                                <table className="table table-condensed">
                                    <tbody>
                                         <tr><td><a className="dropdown-item" href="#" onClick={()=>this.downloadPDF('open','download','print')}><FaFilePdf className="fafilecolor"/>&nbsp;&nbsp;&nbsp;Download PDF</a></td></tr>
                                        <tr><td> <i className="dropdown-item" href='#'><CSVLink  className="csvfile"data={this.state.objList.map(item =>({Id:item.id,CDnumber:item.cdnumber,CD_Entrydate:item.cdentrydate,Author_Name:item.authorname,CD_Title:item.cdtitle,Publish_Year:item.cdyear,Department:item.department,BillNo:item.billno}))} enclosingCharacter={`'`}>
                                        <FaFileExcel className="fafilecolor" />&nbsp;&nbsp;&nbsp;Download CSV
                                        </CSVLink></i></td></tr>
                                        {columnlist}
                                    </tbody>
                                </table>                                            
                            </div>
                        </div>
                    </div> 
                </div>
            </div>    
         )
     }
}
const mapToProps = ({ loggedUserState }) => ({ user: loggedUserState })
export default connect(mapToProps)(CdTabulator);  
        
