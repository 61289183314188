////////////////////////////////////////////////////////////////////////////////////////////////////
// Warning : Property of Deemsoft Global Solutions LLC, USA. It is illegal to make copy of this code
// Author : Gangaswamy Kempahonnaiah
////////////////////////////////////////////////////////////////////////////////////////////////////
import React from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { base_url } from '../constants/Constants';
import { Transaction } from '../models/Transaction';
import { FaFilePdf} from 'react-icons/fa';
import pdfMake from 'pdfmake/build/pdfmake';
import vfsFonts from 'pdfmake/build/vfs_fonts';
import '../styles/Styles.css';

class TransactionsForm extends React.Component {
	constructor(props){
		super(props);
		this.state = {
			objList : [],
			mainform : false,
			showModal : false,
			validations : {},
			imageheader:'data:image/png;base64,',
			formObj : Transaction,
		}
		this.getObjList = this.getObjList.bind(this);
	}

	componentDidMount(){
		let tempObj = {};
		for (let key in Transaction) { tempObj[key] = ""; }
		this.setState({validations:tempObj});
		var imageData=this.state.imageheader;
		axios.post(base_url+'file/b64download',{filename:'header.png'},this.props.user.header)
     	.then(res=>{
		imageData += res.data.data;
		this.setState({imageheader:imageData});
		}).catch(function (error) { console.log(error); }); 
		this.getObjList();


	}
	downloadPDF=(option1,option2,option3,i)=>{
		console.log(this.state.objList[i]);
		var transObj=this.state.objList[i];
		var docDefinition = {
			content: [
				{
					image: this.state.imageheader,
					width: 600,
					alignment:'center'
				},
				{
				  columns: [
					[
					  {
						text: 'Receipt',
						color: '#333333',
						width: '*',
						fontSize: 16,
						bold: true,
						alignment: 'right',
						margin: [0, 0, 0, 15],
					  },
					  {
						stack: [
						  {
							columns: [
							  {
								text: 'Receipt No.',
								color: '#aaaaab',
								bold: true,
								width: '*',
								fontSize: 12,
								alignment: 'right',
							  },
							  {
								text: ''+transObj.id+'',
								bold: true,
								color: '#333333',
								fontSize: 12,
								alignment: 'right',
								width: 100,
							  },
							],
						  },
						  {
							columns: [
							  {
								text: 'Date Issued',
								color: '#aaaaab',
								bold: true,
								width: '*',
								fontSize: 12,
								alignment: 'right',
							  },
							  {
								text: ''+transObj.billdate+'',
								bold: true,
								color: '#333333',
								fontSize: 12,
								alignment: 'right',
								width: 100,
							  },
							],
						  },
						  {
							columns: [
							  {
								text: 'Status',
								color: '#aaaaab',
								bold: true,
								fontSize: 12,
								alignment: 'right',
								width: '*',
							  },
							  {
								text: ''+transObj.status+'',
								bold: true,
								fontSize: 14,
								alignment: 'right',
								color: 'green',
								width: 100,
							  },
							],
						  },
						],
					  },
					],
				  ],
				},
				{
				  columns: [
					{
					  text: 'From',
					  color: '#aaaaab',
					  bold: true,
					  fontSize: 14,
					  alignment: 'left',
					  margin: [0, 20, 0, 5],
					},
					{
					  text: 'To',
					  color: '#aaaaab',
					  bold: true,
					  fontSize: 14,
					  alignment: 'left',
					  margin: [0, 20, 0, 5],
					},
				  ],
				},
				{
				  columns: [
					{
					  text: 'Your Name \n Your Company Inc.',
					  bold: true,
					  color: '#333333',
					  alignment: 'left',
					},
					{
					  text: 'Client Name \n Client Company',
					  bold: true,
					  color: '#333333',
					  alignment: 'left',
					},
				  ],
				},
				{
				  columns: [
					{
					  text: 'Address',
					  color: '#aaaaab',
					  bold: true,
					  margin: [0, 7, 0, 3],
					},
					{
					  text: 'Address',
					  color: '#aaaaab',
					  bold: true,
					  margin: [0, 7, 0, 3],
					},
				  ],
				},
				{
				  columns: [
					{
					  text: '9999 Street name 1A \n New-York City NY 00000 \n   USA',
					  style: 'invoiceBillingAddress',
					},
					{
					  text: '1111 Other street 25 \n New-York City NY 00000 \n   USA',
					  style: 'invoiceBillingAddress',
					},
				  ],
				},
				'\n\n',
				{
				  width: '100%',
				  alignment: 'center',
				  text: 'InvoiceNo# '+transObj.id+'',
				  bold: true,
				  margin: [0, 10, 0, 10],
				  fontSize: 15,
				},
				{
				  layout: {
					defaultBorder: false,
					hLineWidth: function(i, node) {
					  return 1;
					},
					vLineWidth: function(i, node) {
					  return 1;
					},
					hLineColor: function(i, node) {
					  if (i === 1 || i === 0) {
						return '#bfdde8';
					  }
					  return '#eaeaea';
					},
					vLineColor: function(i, node) {
					  return '#eaeaea';
					},
					hLineStyle: function(i, node) {
					  // if (i === 0 || i === node.table.body.length) {
					  return null;
					  //}
					},
					// vLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
					paddingLeft: function(i, node) {
					  return 10;
					},
					paddingRight: function(i, node) {
					  return 10;
					},
					paddingTop: function(i, node) {
					  return 2;
					},
					paddingBottom: function(i, node) {
					  return 2;
					},
					fillColor: function(rowIndex, node, columnIndex) {
					  return '#fff';
					},
				  },
				  table: {
					headerRows: 1,
					widths: ['*', 80],
					body: [
					  [
						{
						  text: 'DESCRIPTION',
						  fillColor: '#eaf2f5',
						  border: [false, true, false, true],
						  margin: [0, 5, 0, 5],
						  textTransform: 'uppercase',
						},
						{
						  text: 'TOTAL',
						  border: [false, true, false, true],
						  alignment: 'right',
						  fillColor: '#eaf2f5',
						  margin: [0, 5, 0, 5],
						  textTransform: 'uppercase',
						},
					  ],
					  [
						{
						  text: ''+transObj.subscriptiontype+' Subscription',
						  border: [false, false, false, true],
						  margin: [0, 5, 0, 5],
						  alignment: 'left',
						},
						{
						  border: [false, false, false, true],
						  text: '$'+transObj.txnamt+'',
						  fillColor: '#f5f5f5',
						  alignment: 'right',
						  margin: [0, 5, 0, 5],
						},
					  ],
					],
				  },
				},
				'\n',
				'\n\n',
				{
				  layout: {
					defaultBorder: false,
					hLineWidth: function(i, node) {
					  return 1;
					},
					vLineWidth: function(i, node) {
					  return 1;
					},
					hLineColor: function(i, node) {
					  return '#eaeaea';
					},
					vLineColor: function(i, node) {
					  return '#eaeaea';
					},
					hLineStyle: function(i, node) {
					  // if (i === 0 || i === node.table.body.length) {
					  return null;
					  //}
					},
					// vLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
					paddingLeft: function(i, node) {
					  return 10;
					},
					paddingRight: function(i, node) {
					  return 10;
					},
					paddingTop: function(i, node) {
					  return 3;
					},
					paddingBottom: function(i, node) {
					  return 3;
					},
					fillColor: function(rowIndex, node, columnIndex) {
					  return '#fff';
					},
				  },
				  table: {
					headerRows: 1,
					widths: ['*', 'auto'],
					body: [
					  [
						{
						  text: 'Payment Subtotal',
						  border: [false, true, false, true],
						  alignment: 'right',
						  margin: [0, 5, 0, 5],
						},
						{
						  border: [false, true, false, true],
						  text: '$'+transObj.txnamt+'',
						  alignment: 'right',
						  fillColor: '#f5f5f5',
						  margin: [0, 5, 0, 5],
						},
					  ],
					  [
						{
						  text: 'Payment Processing Fee',
						  border: [false, false, false, true],
						  alignment: 'right',
						  margin: [0, 5, 0, 5],
						},
						{
						  text: '$'+transObj.txnamt+'',
						  border: [false, false, false, true],
						  fillColor: '#f5f5f5',
						  alignment: 'right',
						  margin: [0, 5, 0, 5],
						},
					  ],
					  [
						{
						  text: 'Total Amount',
						  bold: true,
						  fontSize: 20,
						  alignment: 'right',
						  border: [false, false, false, true],
						  margin: [0, 5, 0, 5],
						},
						{
						  text: 'USD '+'$'+transObj.txnamt+'',
						  bold: true,
						  fontSize: 20,
						  alignment: 'right',
						  border: [false, false, false, true],
						  fillColor: '#f5f5f5',
						  margin: [0, 5, 0, 5],
						},
					  ],
					],
				  },
				},
				'\n\n',
			  ],
			  styles: {
				notesTitle: {
				  fontSize: 10,
				  bold: true,
				  margin: [0, 50, 0, 3],
				},
				notesText: {
				  fontSize: 10,
				},
			  },
			  defaultStyle: {
				columnGap: 20,
				//font: 'Quicksand',
			  },
		
			}
			const {vfs} = vfsFonts.pdfMake;
			pdfMake.vfs = vfs;
			if(option1=='open'){
			pdfMake.createPdf(docDefinition).open(); 
			}
			else if(option2=='download'){
			pdfMake.createPdf(docDefinition).download(); 
			}
		else if(option3=='print'){
		pdfMake.createPdf(docDefinition).print(); 
		
		}
		}
		
	getObjList = () => {
		axios.get(`https://elibrarysoft.com/transactions/list`)
		.then(res => {
			var x=res.data;
			for(var i in x){
				if(x[i].accountid == this.props.user.accountid){
				this.state.objList.push(x[i]);
				}
			}
			this.setState({mainform:true});
		}).catch(function (error) { console.log(error); });
	}

	payAmount = (i) =>{
		let tempObj = this.state.objList[i];
		axios.post('https://elibrarysoft.com/transactions/payment', tempObj ).then(res =>{
			window.open(res.data);
		}).catch(function (error) { console.log(error); });
	}


	renderObjlist = () => {
		if( this.state.objList != undefined && this.state.objList.length > 0){
			return this.state.objList.map((item, index) =>{
				var show=true;
				if(item.status == "paid"){
                  show =false;
				}
				return (
					<tr key={index} id={index}>
						<td>{item.id}</td>
						<td>{item.billdate}</td>						
						<td>{item.subscriptiontype}</td>
						<td>{item.email}</td>
						<td>{item.bank_name}</td>
						<td>{item.amt}</td>						
						<td>{item.status}{show?<span><button className="btn btn-primary btn-sm" onClick={()=>{this.payAmount(index)}}>Pay</button></span>:<FaFilePdf size="20px" color="red" onClick={()=>this.downloadPDF('open','download','print',index)}/>}</td>
						</tr>
					)
			 }, this);
		}
	}

	render() {
		return (
		<div>{this.state.mainform?
		<div className="container mt-3">
            <div className="shadow-sm p-3 mb-5 bg-white rounded">
			<table className="table table-bordered table-sm">
					<thead className="thead">
						<tr>
							<td>Order #</td>
							<td>Date</td>
							<td>Description</td>
							<td>Email</td>
							<td>Bank Name</td>
							<td>Amount</td>
							<td>Status</td>
						</tr>
					</thead>
					<tbody>
						{this.renderObjlist()}
                    </tbody> 
				</table>
                </div>
			</div>
			:null}
			</div>
		);
		}
	}

const mapToProps = ({ loggedUserState }) => ({ user: loggedUserState })
export default connect(mapToProps)(TransactionsForm);

