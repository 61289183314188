import React from 'react';
import axios from 'axios';
import { base_url } from '../constants/Constants';
import { connect } from 'react-redux';
import {ReactTabulator} from 'react-tabulator';
import 'react-tabulator/lib/styles.css';
import "react-tabulator/lib/css/bootstrap/tabulator_bootstrap.min.css";
import '../styles/Styles.css';
import {FaFileExcel, FaFilePdf,FaBars } from 'react-icons/fa';
import pdfMake from 'pdfmake/build/pdfmake';
import vfsFonts from 'pdfmake/build/vfs_fonts';
import { CSVLink} from "react-csv";
import {Setting} from '../models/Setting';
import moment from 'moment'

const editableColumns = [
    {
        title: "ID",
        field: "id",
        headerFilterPlaceholder:" ",
        headerFilter: 'input',
    },
    {
        title: "Acc No",
        field: "accnumber",
        headerFilterPlaceholder:" ",
        headerFilter: "input"
    },
    {
        title: "ISBN Number",
        field: "isbnnumber",
        headerFilterPlaceholder:" ",
        headerFilter: "input"
    },
    {
        title: "Book Author",
        field: "bookauthor",
        headerFilterPlaceholder:" ",
        headerFilter: "input"
    },
    {
        title: "Book Title",
        field: "booktitle",
        headerFilterPlaceholder:" ",
        headerFilter: "input"
    },
    {
        title: "Book Status",
        field: "bookstatus",
        headerFilterPlaceholder:" ",
        headerFilter: "input"
    },
    {
        title: "Created Date",
        field: "date_created",
        headerFilterPlaceholder:" ",
        headerFilter: "input",
    },
 ];
class BookTabulator extends React.Component {
    constructor(props){
        super(props);
        this.state={
            objList:this.props.parentObject,
            originalTabColums:[],
            filteredTabColums:editableColumns,
            defaultcolumns:[],
            settings : Setting,
            tabdata:this.props.parentObject,
            finaldata:[],
            imageheader:'data:image/png;base64,',
        }
     }
    componentDidMount(){
        if(this.state.objList != "" && this.state.objList !=undefined){
        let dateformat='';
        axios.get(base_url+'settings/list', this.props.user.header ).then(res => {
            if ( res.data.length > 0 ){
                for(var i=0;i<res.data.length;i++){
                    this.setState({ settings:res.data[i]});	
                }	
            }
            var temp=this.state.tabdata;
            var temparr=[];
            if(this.state.settings.dateformats !== "" && this.state.settings.dateformats !== undefined){
                dateformat=this.state.settings.dateformats.toUpperCase();
              }else{
                  dateformat='DD/MM/YYYY';
              }
            for(let i in this.state.tabdata){
               let inDate = this.state.tabdata[i].date_created,
               upDate = this.state.tabdata[i].date_updated,
               poDate = this.state.tabdata[i].podate,
               billDate = this.state.tabdata[i].billdate,
               inFormat = 'YYYY-MM-DD[T]HH:mm:ss.SSS[Z]Z',
               outFormat = this.state.settings.dateformats,
               outDate = moment(inDate, inFormat).format(dateformat);
               let outupDate = moment(upDate, inFormat).format(dateformat);
               let billoutDate = moment(billDate, inFormat).format(dateformat);
               let outpoDate = moment(poDate, inFormat).format(dateformat);
                 temp[i].date_created=outDate;
                 temp[i].date_updated=outupDate;
                 temp[i].podate=outpoDate;
                 temp[i].billdate=billoutDate;
                 temparr.push(temp[i]);
            }
           temparr.sort(function(a, b) {
                return a.accnumber - b.accnumber 
            });
            this.setState({finaldata:temparr});
            this.loadAllTabFields();
    }).catch(function (error) { console.log(error); });
    }
        var imageData=this.state.imageheader;
		axios.post(base_url+'file/b64download',{filename:'header.png'},this.props.user.header)
        .then(res=>{
        imageData += res.data.data;
        this.setState({imageheader:imageData});
        }).catch(function (error) { console.log(error); }); 
    }

    loadAllTabFields(){

        var newTabColums = [];
        var tempArray1 = [];
        var selectedFields =["accnumber","booktitle","bookauthor","isbnnumber","bookstatus","date_created"];
        var keys = Object.keys(this.state.objList[0]);
        keys=["accnumber","booktitle","bookauthor","isbnnumber","bookstatus","date_created", "barcode", "billdate", "billnumber", "booktype", "callnumber", "currency", "date_updated", "department", "edition", "editionyear", "foreignprice", "id", "indianprice", "notes", "pages", "podate", "ponumber", "preliminarypages", "publishername", "publisherphone", "publishyear", "status", "subject", "trackcode"];
        var i = 0;
        keys.forEach(function(key){ 
            i = Number(i)+1;            
            if(selectedFields.includes(key)){
                if(key=="id" || key=='accnumber' || key=='booktitle' || key=='bookauthor' || key=='isbnnumber'){
                    newTabColums.push({ id:i, title: key.toUpperCase(),  headerFilterPlaceholder:" ",
                    headerFilter: "input",field: key, status:true},);
                    tempArray1.push({ id:i, headerFilterPlaceholder:" ",
                    headerFilter: "input", title: key.toUpperCase(), field: key, status:true,},);
                }else{
                    newTabColums.push({ id:i, headerFilterPlaceholder:" ",
                    headerFilter: "input", title: key.toUpperCase(), field: key, status:true},);
                    tempArray1.push({ id:i,  headerFilterPlaceholder:" ",
                    headerFilter: "input",title: key.toUpperCase(), field: key, status:true},);
                }
            }
            else{
                if(key=="id" || key=='accnumber' || key=='booktitle' || key=='bookauthor' || key=='isbnnumber'){
                    newTabColums.push({ id:i,  headerFilterPlaceholder:" ",
                    headerFilter: "input",title: key.toUpperCase(), field: key, status:false,},);
                }else{
                    newTabColums.push({ id:i,  headerFilterPlaceholder:" ",
                    headerFilter: "input",title: key.toUpperCase(), field: key, status:false},);
                }
            }           
        });
        newTabColums.sort((a,b) => (a.id > b.id) ? 1 : ((b.id > a.id) ? -1 : 0));
        tempArray1.sort((a,b) => (a.id > b.id) ? 1 : ((b.id > a.id) ? -1 : 0));
        this.setState( {originalTabColums:newTabColums, filteredTabColums:tempArray1 });
       // console.log(this.state.originalTabColums);
    }

    addToTab = (e, item, index) => {
        var list1 = this.state.originalTabColums;
        if(e.target.checked){           
            list1[index].status = true;     
        }else{  
            list1[index].status = false;        
        }
        this.setState({originalTabColums:list1});
        var tempArray = [];
        for (var key in list1) {
            if(list1[key].status){
                if(list1[key].title=='ID' || list1[key].title=='ACCNUMBER' || list1[key].title=='BOOKTITLE' || list1[key].title=='BOOKAUTHOR' || list1[key].title=='ISBNNUMBER'){
                    tempArray.push({ id:list1[key].id,  headerFilterPlaceholder:" ",
                    headerFilter: "input",title: list1[key].title, field: list1[key].field, status:list1[key].status,});
                }else{
                    tempArray.push({ id:list1[key].id, headerFilterPlaceholder:" ",
                    headerFilter: "input", title: list1[key].title, field: list1[key].field, status:list1[key].status});
                }
                    
            }                   
        }
        this.setState({filteredTabColums:tempArray});     
    }

    downloadPDF=(option1,option2,option3)=>{
        var stafflist=[
        [
            { text: 'ID', style: 'tableHeader'}, 				  
            { text: 'ACC NO', style: 'tableHeader' },   
            { text: 'ISBN NO', style: 'tableHeader' }, 
            {text:'Book Title',style:'tableHeader'},
            {text:'Book Author',style:'tableHeader'},    
         ]
         ]; 
        var temp=this.state.objList;
        for(var i in temp) {                        
            stafflist.push([
                { text: ''+temp[i].id+'' , style:'header2'},						
                { text: ''+temp[i].accnumber+'' , style:'header2'},
                { text: ''+temp[i].isbnnumber+'' , style:'header2'},
                { text: ''+temp[i].booktitle+'' , style:'header2'},
                { text: ''+temp[i].bookauthor+'' , style:'header2'},
            ]);
        }
        var docDefinition = {
            pageOrientation: 'page',
            content: 
            [ 
                {
                    image: this.state.imageheader,
                    height:70,
                    width:500,
                    alignment:'center',
                },
                { text: 'Book List', style:'header1' },
                {text:'\n'},
                { table:{
                    headerRows: 1,
                    widths: [30,80,80,140,140],
                    body: stafflist
                },
                layout: {
                    hLineWidth: function (i, node) {
                        return  1;
                    },
                    vLineWidth: function (i, node) {
                        return  1;
                    },
                    hLineColor: function (i, node) {
                        return  '#C0C0C0';
                    },
                    vLineColor: function (i, node) {
                        return  '#C0C0C0';
                    },
                },
                },
            ],
            styles:{
                header:{fontSize:16, bold:true, margin:[0, 10, 0, 10], alignment:'center'},
                header1:{fontSize:16, bold:true, margin:[0, 10, 0, 10],alignment:'center',},
                header2:{fontSize:12},
                header4:{fontSize:10},
                header3:{fontSize:10, alignment:'right'},
                tableHeader:{fillColor:'#C0C0C0',bold:true,fontSize:14},
                label:{fontSize:11},
            }
        }
        const {vfs} = vfsFonts.pdfMake;
        pdfMake.vfs = vfs;
        if(option1 =='open'){
            pdfMake.createPdf(docDefinition).open(); 
        }
        else if(option2=='download'){
            pdfMake.createPdf(docDefinition).download(); 
        }
        else if(option3=='print'){
            pdfMake.createPdf(docDefinition).print(); 
        }
    }
    render(){
        const temp=this.state.objList.length; 
        const options = {  height: 500, showFilter:true,footerElement:'<span>Total Items:'+temp+'</span>' };
        const columnlist= this.state.originalTabColums.length > 0 &&  this.state.originalTabColums.map((item, index) => {
            return (
                <tr key={index}><td><a className="dropdown-item"><input type="checkbox" className="custom-check-box" id={index} name={index} checked={item.status} onChange={(e)=>this.addToTab(e, item, index)}></input>&nbsp;&nbsp;&nbsp;&nbsp;{item.title}</a></td></tr>
            )
        }, this);
        return(
            <div>
                <div className="sample67">                   
                    <div className="">     
                        <ReactTabulator
	                        ref={ref => (this.ref = ref)}
			                data={this.state.finaldata}
		      	            columns={this.state.filteredTabColums}
		      	            options={options}
		      	            rowClick={this.rowClick}
		      	            data-custom-attr="test-custom-attribute"
		                    className="custom-css-class"
			            /> 
                        <div className=" input-group-append tabulator-row-handle-bar append1">
                            <span className="" type="text" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><FaBars /></span>
                            <div className="dropdown-menu scrollable-dropdown my-custom-scrollbar">                                 
                                <table className="table table-condensed">
                                    <tbody>
                                        <tr><td><a className="dropdown-item" href="#" onClick={()=>this.downloadPDF("open","download","print")}><FaFilePdf className="fafilecolor"/>&nbsp;&nbsp;&nbsp;Download PDF</a></td></tr>
                                        <tr><td> <i className="dropdown-item"><CSVLink  className="csvfile"data={this.state.objList.map(item =>({Id:item.id,ACCnumber:item.accnumber,ISBN_Number:item.isbnnumber,Book_Author:item.bookauthor,Book_Title:item.booktitle,Edition:item.edition,Subject:item.subject,Vendor:item.vender,Indian_Price:item.indianprice,Pages:item.pages,Billdate:item.billdate,Book_Status:item.status}))} enclosingCharacter={`'`}>
                                        <FaFileExcel className="fafilecolor" />&nbsp;&nbsp;&nbsp; Download CSV
                                        </CSVLink></i></td></tr>
                                        {columnlist}
                                    </tbody>
                                </table>                                            
                            </div>
                        </div>
                    </div> 
                </div>
            </div> 
        )
    }
  }
 const mapToProps = ({ loggedUserState }) => ({ user: loggedUserState })
export default connect(mapToProps)(BookTabulator);