import React, {Component} from 'react';
import  {Doughnut} from 'react-chartjs-2';
import axios from 'axios';
import { connect } from 'react-redux';
import { base_url } from '../constants/Constants';



class Doughnutchart extends Component {
	constructor(props){
		super(props);
		this.state = {
			
        }
    }
componentDidMount() {
	//console.log(this.props.parentObject);
	
	  }
	  getSettings = () => {
        axios.get(base_url+'settings/list', this.props.user.header ).then(res => {
			if ( res.data.length > 0 ){
                for(var i=0;i<res.data.length;i++){
					this.setState({ settings:res.data[i]});	
                }	
			}
		}).catch(function (error) { console.log(error); });
    }
  render() {
	  
	var data = {
		labels: this.props.parentObject.labels,
		datasets: [{
			data: this.props.parentObject.numlist,
			backgroundColor: [
			'#ff66ff',
			'#59cc33',
			'#FFCE56',
			'#8A2BE2',
			'#D8BFD8',
			'#ffff99',
			'#33ffff',
			'#49cc33',
			'#ffd11a',
			'#0080ff',
			],
			hoverBackgroundColor: [
			'#ff66ff',
			'#59cc33',
			'#FFCE56',
			'#8A2BE2',
			'#D8BFD8',
			'#ffff99',
			'#33ffff',
			'#49cc33',
			'#ffd11a',
			'#0080ff',

			]
		}],
		
	};
	const text="text";
    return (
      <div>
         < Doughnut
		  ref="chart"
		  data={data}
		  text={text}	
		  options={{ maintainAspectRatio: true   }} 
		 />
      </div>
    );
  }

}
const mapToProps = ({ loggedUserState }) => ({ user: loggedUserState })
export default connect(mapToProps)(Doughnutchart);