import React from 'react';
import axios from 'axios'; 
import { connect } from 'react-redux';
import { base_url } from '../constants/Constants';
import {  Modal, ModalBody} from 'react-bootstrap';
import '../styles/Styles.css';
import SettingsForm from'./SettingsForm';
import UserDetailsForm from './UserDetailsForm';
import SmsSettingsForm from './SmsSettingsForm';
import TransactionsForm from './TransactionsForm';
import UploadCatalogSetting from './UploadCatalogSetting';

class AdminForm extends React.Component {
	constructor(props){
		super(props);
		this.state = {
			username:'nav-link userActive',
			transactionform:false,
			settingform:false, 
			navbar:true,
			transname:'nav-link transaction',
			userform:true, 
			selectedFile: '',
			settingname:'nav-link settings',
			templatename:'nav-link templates',
			uploadlogoname:'nav-link uploadlogo',
			headerlogo:'',
			upload:false,
			smsform:false,  
			catalogupload:'nav-link uploadcatalog',
			btnhide:true, 
			btnbanner:true, 
			show:false,
			successmoal:false,
        }
	 }
    componentDidMount(){
        
    }
	Sms=()=>{
		this.setState({uploadcatalogform:false,upload:false,smsform:true,settingform:false,transactionform:false,userform:false,templatename:'nav-link transActive ',catalogupload:'nav-link uploadcatalog',uploadlogoname:'nav-link uploadlogo',settingname:'nav-link settings',username:'nav-link userdetails',transname:'nav-link transaction'})
	}

	Settings=()=>{
		this.setState({uploadcatalogform:false,upload:false,settingform:true,transactionform:false,smsform:false,userform:false,settingname:'nav-link settingActive',catalogupload:'nav-link uploadcatalog',uploadlogoname:'nav-link uploadlogo',username:'nav-link userdetails',transname:'nav-link transaction',templatename:'nav-link templates'})
	}
	UserDetails=()=>{
		this.setState({uploadcatalogform:false,upload:false,settingform:false,smsform:false,transactionform:false,userform:true,username:'nav-link userActive',catalogupload:'nav-link uploadcatalog',transname:'nav-link transaction',uploadlogoname:'nav-link uploadlogo',settingname:'nav-link settings',templatename:'nav-link templates'})
	}
	Uploadlogo=()=>{
		this.setState({uploadcatalogform:false,upload:true,smsform:false,settingform:false,transactionform:false,userform:false,uploadlogoname:'nav-link logoActive',catalogupload:'nav-link uploadcatalog',username:'nav-link userdetails',transname:'nav-link transaction',settingname:'nav-link settings',templatename:'nav-link templates'});
	}
	TransDetails=()=>{
		this.setState({uploadcatalogform:false,transactionform:true,smsform:false,settingform:false,upload:false,userform:false,username:'nav-link userdetails',catalogupload:'nav-link uploadcatalog',transname:'nav-link transActive',settingname:'nav-link settings',uploadlogoname:'nav-link uploadlogo',templatename:'nav-link templates'});
	}
	CatlogUploadDetails=()=>{
		this.setState({uploadcatalogform:true,transactionform:false,smsform:false,settingform:false,upload:false,userform:false,username:'nav-link userdetails',transname:'nav-link transaction',settingname:'nav-link settings',uploadlogoname:'nav-link uploadlogo',templatename:'nav-link templates',catalogupload:'nav-link catalogActive'});
	}
	
	onChangeHandler=event=>{
		var image=event.target.files[0];
		if (!image.name.match(/\.(jpg|jpeg|png|JPG)$/)) {
			this.setState({show:true,btnhide:true});
		  }else{
			this.setState({selectedFile: event.target.files[0],btnhide:false});
		  }
	}

	onChangeHader=event=>{
		var image=event.target.files[0];
		if (!image.name.match(/\.(jpg|jpeg|png|JPG)$/)) {
			this.setState({show:true,btnbanner:true});
		}else{
	    	this.setState({headerlogo: event.target.files[0],btnbanner:false});
		}
	 }
	
	onClickHandler = () => {
		const data = new FormData() 
		data.append('file', this.state.selectedFile);
		let fileObj = this.state.selectedFile;
		data.append('myfile', fileObj);
		console.log(this.state.selectedFile);
		axios.post(base_url+'file/uploadlogo',data, this.props.user.header).then(res =>{ 
			this.setState({successmoal:true});
		}).catch(function (error) { console.log(error); }); 
	}

	onClickHeader = () => {
		const data = new FormData() 
		data.append('file', this.state.headerlogo);
		let fileObj = this.state.headerlogo;
		data.append('myfile', fileObj);
		axios.post(base_url+'file/uploadbanner',data, this.props.user.header).then(res =>{ 
			this.setState({successmoal:true});
			this.setState({btnhide:true,btnbanner:true});
		}).catch(function (error) { console.log(error); }); 
	}


    render() {
		return (
            <div className="container my-4">
				{this.state.navbar?
				<ul className="nav">
    				<li className="nav-item ">
						<a className={this.state.username} onClick={() => {this.UserDetails()}}>USER DETAILS</a>
    				</li>
					<li className="nav-item  ">
      					<a className={this.state.catalogupload} onClick={() => {this.CatlogUploadDetails()}}>UPLOAD BOOK/CDDVD/JOURNAL</a>
    				</li>
					<li className="nav-item  ">
      					<a className={this.state.settingname} onClick={() => {this.Settings()}}>SETTINGS</a>
    				</li>
    				<li className="nav-item  ">
     		 			<a className={this.state.uploadlogoname} onClick={() => {this.Uploadlogo()}} >UPLOAD LOGO & BANNER</a>
    				</li>
					<li className="nav-item  ">
      					<a className={this.state.templatename} onClick={() => {this.Sms()}}>TEMPLATES</a>
    				</li>
					<li className="nav-item ">
      					<a className={this.state.transname} onClick={() => {this.TransDetails()}}>eLIBRARYSOFT RENEWAL</a>
    				</li>
					
  				</ul>:null}
				{this.state.userform?
				<UserDetailsForm />
				:null}
				
				{this.state.upload?
				<div className="my-5">
					<div className="d-flex row">
						<div className="p-3">
							<p className="label-custom">Upload  Header Logo:   &nbsp; &nbsp;
	            			</p>
							<p><b>(max hight:70px and width:70px)</b></p>
						</div>
						<div className="p-3"><input type="file" className="form-control-file" name="file"  accept=".jpg,.png" onChange={this.onChangeHandler}/></div>
						<div className="p-3"><button type="button" className="btn btn-primary btn-sm" disabled={this.state.btnhide} onClick={this.onClickHandler}>Upload</button></div>
					</div>
					<div className="d-flex row">
						<div className="p-3">
							<p className="label-custom">Upload Header Banner:</p>
							<p><b>(max hight:70px and width:700px)</b></p>
						</div>
						<div className="p-3">
							<input type="file" className="form-control-file" name="file" accept=".jpg,.png"  onChange={this.onChangeHader}/>
						</div>
						<div className="p-3">
							<button type="button" className="btn btn-primary btn-sm" disabled={this.state.btnbanner} onClick={this.onClickHeader}>Upload</button>	
						</div>
					</div>
					
				</div>:null}
				{this.state.settingform?
				<SettingsForm />:null}
				{this.state.smsform?
				<SmsSettingsForm />
				:null}
				{this.state.transactionform?
				<TransactionsForm />
				:null}
				{this.state.uploadcatalogform?
				<UploadCatalogSetting />
				:null}
				<Modal size="sm" centered show={this.state.show} onHide={()=>{this.setState({show:false})}}>
					<ModalBody>
					<div className="d-flex justify-content-right">
					<div className="p-2">Upload Only .jpeg/.jpg/.png Files
					</div>
					</div>
					<div className="d-flex justify-content-end">
					<div className="p-2"><button className="btn btn-warning" onClick={()=>{this.setState({show:false})}}>CLOSE</button></div>	
					</div>
					</ModalBody>
			</Modal>
			<Modal size="sm" centered show={this.state.successmoal} onHide={()=>{this.setState({successmoal:false})}}>
					<ModalBody>
					<div className="d-flex justify-content-right">
					<div className="p-2">Image Uploaded Successfully!!<br/>Please Refresh the Page to Load the Image.
					</div>
					</div>
					<div className="d-flex justify-content-end">
					<div className="p-2"><button className="btn btn-warning" onClick={()=>{this.setState({successmoal:false})}}>OK</button></div>	
					</div>
					</ModalBody>
			</Modal>	
			</div>
		
		)
	}
    
}
const mapToProps = ({ loggedUserState }) => ({ user: loggedUserState })
export default connect(mapToProps)(AdminForm);