import React from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { base_url } from '../constants/Constants';
import {Link} from 'react-router-dom';
import { FaBook,FaCompactDisc,FaNewspaper } from 'react-icons/fa';
import favicon1 from '../assets/favicon1.png';
import '../styles/Styles.css';



class  SearchForm extends React.Component {
constructor(props){
super(props);
this.state = {
books_details:[],
cd_details:[],
journal_details:[],
booktable:false,
cdtable:false,
journaltable:false,
error:false,
errormessage:"",
searchObj:{selectby:'',selecttype:'',searchval:''}  
}
}
componentDidMount(){

}
backToLogin = () => {
this.props.history.push('/login');
}

changeSearchObj = (val,e) =>{
var tempObj=this.state.searchObj;
switch(val){
case "selectby": tempObj.selectby = e.target.value; break;
case "selecttype": tempObj.selecttype = e.target.value; break;
case "searchval": tempObj.searchval = e.target.value; break;
}
this.setState({searchObj:tempObj});
}

searchBook = (id,by) =>{
let temp={search : id ,type: by}
axios.post(base_url+'search/book', temp, this.props.user.header )
.then(res =>{
if(res.data.length !== 0){
this.setState({books_details:res.data});
this.setState({booktable:true,cdtable:false,journaltable:false,error:false});
this.renderbook();
}else{
    this.setState({books_details:[]});
    this.setState({error:true,errormessage:"Result Not Found."});
    this.setState({booktable:false,cdtable:false,journaltable:false}); 
}
}).catch(function (error) { console.log(error); });  
}

searchCDDVD = (id,by) =>{
let temp={search : id ,type: by}
axios.post(base_url+'search/cddvd', temp, this.props.user.header )
.then(res =>{
if(res.data.length !== 0){
this.setState({cd_details:res.data});
this.setState({booktable:false,cdtable:true,journaltable:false,error:false});
this.renderCDDVD();
}else{
    this.setState({cd_details:[]});
    this.setState({booktable:false,cdtable:false,journaltable:false});
    this.setState({error:true,errormessage:"Result Not Found."});
}
}).catch(function (error) { console.log(error); });  
}

searchJournal = (id,by) =>{
let temp={search : id ,type: by}
axios.post(base_url+'search/journals', temp, this.props.user.header )
.then(res =>{
if(res.data.length !== 0){
this.setState({journal_details:res.data});
this.setState({booktable:false,cdtable:false,journaltable:true,error:false});
this.renderJournals();
}else{
    this.setState({journal_details:[]});
    this.setState({error:true,errormessage:"Result Not Found."});
    this.setState({booktable:false,cdtable:false,journaltable:false});
}
}).catch(function (error) { console.log(error); });  
}

renderbook(){
if( this.state.books_details != undefined && this.state.books_details.length > 0  ){
return this.state.books_details.map((item, index) =>{
var linkcolor="";
if(item.bookstatus === "issued"){
linkcolor="redlink";
}else{
linkcolor="greenlink";
}
return(
<div className="p-2" id={index} key={index}>
<div className="card  text-black bg-light mb-3" style={{"width": "350px"}}>
<div className="card-header"><strong>{item.booktitle}</strong></div>
<div className="card-body">
<div className="d-flex">
<div className="p-2 col-md-5"><FaBook size="80px"/></div>
<div className="p-2 col-md-7">
<h6 className="card-title">BOOK-{item.editionyear}</h6>
<p className="card-text">{item.accnumber},{item.edition}</p>
<span>{item.bookauthor}</span><br/>
<Link className={linkcolor} to="/login/">{item.bookstatus}</Link>
</div>

</div>
</div>
</div>
</div>
)
}, this);
}
}

renderCDDVD(){
if( this.state.cd_details != undefined && this.state.cd_details.length > 0  ){
return this.state.cd_details.map((item, index) =>{
return(
<div className="p-2">
<div className="card  text-black bg-light mb-3" style={{"width": "350px"}}>
<div className="card-header"><strong>{item.cdtitle}</strong></div>
<div className="card-body">
<div className="d-flex">
<div className="p-2 col-md-5"> <FaCompactDisc size="80px"/></div>
<div className="p-2 col-md-7">
<h6 className="card-title">CD/DVD-{item.cdyear}</h6>
<p className="card-text">{item.cdnumber}</p>
<span>{item.authorname}</span><br/>
</div>
</div>
</div>
</div>
</div>
)
}, this);
}
}


renderJournals(){
if( this.state.journal_details != undefined && this.state.journal_details.length > 0  ){
return this.state.journal_details.map((item, index) =>{
return(
<div className="p-2">
<div className="card  text-black bg-light mb-3" style={{"width": "350px"}}>
<div className="card-header"><strong>{item.journaltitle}</strong></div>
<div className="card-body">
<div className="d-flex">
<div className="p-2 col-md-5"> <FaNewspaper size="80px"/> </div>
<div className="p-2 col-md-7">
<h6 className="card-title">JOURNAL-{item.journaltype}</h6>
<p className="card-text">{item.pissn}</p>
<span>{item.journalpublisher}</span><br/>
</div>
</div>
</div>
</div>
</div>
)
}, this);
}
}


searchObj(){

if(this.state.searchObj.searchval !== "" && this.state.searchObj.searchval !== undefined){
if(this.state.searchObj.selecttype === "Books"){
var output = this.state.searchObj.searchval.trim();
var input=output.substring(0, 1).toUpperCase() + output.substring(1);
this.searchBook(input,this.state.searchObj.selectby);
}else if(this.state.searchObj.selecttype === "CD/DVD"){
var output = this.state.searchObj.searchval.trim();
var input=output.substring(0, 1).toUpperCase() + output.substring(1);
this.searchCDDVD(input,this.state.searchObj.selectby);
}else if(this.state.searchObj.selecttype === "Journals"){
var output = this.state.searchObj.searchval.trim();
var input=output.substring(0, 1).toUpperCase() + output.substring(1);
this.searchJournal(input,this.state.searchObj.selectby);
}else{
this.setState({error:true,errormessage:"Please select the labels."});
}
}else{
    this.setState({error:true,errormessage:"Please select the labels."});  
} 
}

render(){ 
return (
<div className="container-fluid">
<div className="d-flex">
<div className="p-2">
<h4>LIBRARYSOFT</h4>
</div>
<div className="p-2 ml-auto">
<button className="btn btn-primary" onClick={this.backToLogin}>Login</button> 
</div>
</div>
<hr/>
<nav className="navbar navbar-expand-sm  bg-light justify-content-between">
<Link className="navbar-brand" to="/login/"><span className="brandname"><img className="image1" src={favicon1} alt="favicon1" /></span> </Link>
<div className="form-inline">
<label>Select</label>
<div className="p-2">
<select className="form-control form-control-sm" id="idselecttype" value={this.state.searchObj.selecttype}   onChange={(e)=>{this.changeSearchObj("selecttype",e)}} name="selecttype">
<option value=""></option>
<option>Books</option>
<option>CD/DVD</option>
<option>Journals</option>
</select>
</div>
<label>By</label>
<div className="p-2">
<select className="form-control form-control-sm" id="idselectby" value={this.state.searchObj.selectby}  onChange={(e)=>{this.changeSearchObj("selectby",e)}} name="selectby">
<option value=""></option>
<option>ACC No.</option>
<option>Author</option>
<option>Name</option>
</select>
</div>   
<input className="form-control form-control-sm mr-sm-2" value={this.state.searchObj.searchval} type="search" placeholder="Search"  onChange={(e)=>{this.changeSearchObj("searchval",e)}} aria-label="Search"/>
<button className="btn btn-sm btn-outline-success my-2 my-sm-0" type="submit" onClick={()=>{this.searchObj()}}>Search</button>
</div>
</nav><br/>

{this.state.booktable? <div className="d-flex flex-wrap"> {this.renderbook()} </div>:null}
{this.state.cdtable?<div className="d-flex flex-wrap"> {this.renderCDDVD()} </div>:null}
{this.state.journaltable?  <div className="d-flex flex-wrap"> {this.renderJournals()} </div>:null}
{this.state.error? 
 <div class="d-flex justify-content-center">
     <div className="p-2 flex-fill">
     <div className="alert alert-warning" role="alert">{this.state.errormessage}</div>
    </div>
 </div>:null}
</div>

);
}
}

const mapToProps = ({ loggedUserState }) => ({ user: loggedUserState })
export default connect(mapToProps)(SearchForm);