////////////////////////////////////////////////////////////////////////////////////////////////////
// Warning : Property of Deemsoft Global Solutions LLC, USA. It is illegal to make copy of this code
// Author : Gangaswamy Kempahonnaiah
////////////////////////////////////////////////////////////////////////////////////////////////////
import React from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { Button, Modal, ModalBody, ModalFooter } from 'react-bootstrap';
import { base_url } from '../constants/Constants';
import { Validators } from '../utils/Validators';
import { Formators } from '../utils/Formators';
import {Setting} from '../models/Setting';
import { Cd_Catalog } from '../models/Cd_Catalog';
import {DateOnly} from '../utils/DateTime';
import '../styles/Styles.css';
import flashMessage from '../utils/Flash';
import FlashMessage from 'react-flash-message';



class Cd_CatalogForm extends React.Component {
	constructor(props){
		super(props);
		this.state = {
			objList : [],
			settings : Setting,
			showSuccessMsg:false,
			showModal : false,
			searchmessage:'',
			alertmsg:'',
			show:false,
			showMessage:false,
			index:'',
			dateformat:'',
			searchtable:false,
			searchform:true,
			validations : {},
			deletebtn:'btn btn-danger btn-sm showbarcodebtn',
			classname:'btn btn-success btn-sm showbarcodebtn',
			cdform:false,
			disvalue:true,
			endDate:new Date(),
			formObj : Cd_Catalog,
			cdnumid:'',
			year: []
		}
		this.getObjList = this.getObjList.bind(this);
		
	}

	componentDidMount(){
		let tempObj = {};
		for (let key in Cd_Catalog ) { tempObj[key] = ""; }
		this.setState({validations:tempObj});
		let tempObjs = {};
		for (let key in Cd_Catalog ) { tempObjs[key] = ""; }
		this.setState({formObj:tempObjs});
		this.getObjList();
		this.getSettings();
		const year = new Date().getFullYear()
		let year_opt = []
		for(let i=2008; i<=year; i++){
			year_opt.unshift(i)
		}
		this.state.year = year_opt
	}
	searchObj=()=>{
		let tempObj = {};
		for (let key in Cd_Catalog ) { tempObj[key] = ""; }
		this.setState({ formObj:tempObj,showModal : true});

	}
		
	printbarcode=()=>{
		var id2="books";
		var temp = this.state.formObj.barcode  +" " +this.state.formObj.barcode;
		var text= temp.split(" ");
		
		window.open('/barcode?id1='+text+'&id2='+id2+'', "_blank", "width=500,height=500")	
		
	}
	getSettings = () => {
        axios.get(base_url+'settings/list', this.props.user.header ).then(res => {
			if ( res.data.length > 0 ){
                for(var i=0;i<res.data.length;i++){
					this.setState({ settings:res.data[i]});	
                }	
			} 
			if(this.state.settings.dateformats == ''){
				this.setState({dateformat:"dd/MM/yyyy"});
		    }else if(this.state.settings.dateformats !== ''){
				this.setState({dateformat:this.state.settings.dateformats});
			}  
		}).catch(function (error) { console.log(error); });
    }

	getObjList = () => {
		axios.get(base_url+'cd_catalog/list', this.props.user.header ).then(res => {
			if ( res.data.length > 0 ){
				this.setState({ objList:res.data});
			}
		}).catch(function (error) { console.log(error); });
	}
	saveObj = () => {
		if(this.state.formObj._id === ""){
			let tempo = '';
			for (let key in  Cd_Catalog) { this.validate(key);}
			for (let key in this.state.validations) {tempo += this.state.validations[key];}
			if(tempo === ""){
				var text=this.state.formObj;
				text.status="Active";
				text.barcode=this.state.formObj.cdnumber;
				this.setState({formObj:text});
			 	axios.post(base_url+'cd_catalog/save', this.state.formObj, this.props.user.header ).then(res => {
					this.setState({ objList:res.data,formObj:res.data.data,cdnumid:res.data.data.cdnumber});
					this.setState({classname:'btn btn-success btn-sm showbarcodebtn',disvalue:false,alertmsg:'Your data is saved successfully'});
					this.setState({showSuccessMsg: true}, ()=>{
						setTimeout(() => {
						this.setState({showSuccessMsg: false })
						}, 3000);
					});
					this.getObjList();
					 
			 	}).catch(function (error) { console.log(error); });
		  	 }
		}else{
			let temp='';
			for (let key in  Cd_Catalog) { this.validate(key);}
			let tempkey = this.state.validations;
			tempkey.cdnumber="";
			this.setState({validations:tempkey});
			for (let key in this.state.validations) {temp += this.state.validations[key];}
			if(temp == ''){
				axios.post(base_url+'cd_catalog/save', this.state.formObj, this.props.user.header ).then(res => {
					this.setState({formObj:res.data.data,cdnumid:res.data.data.cdnumber,classname:'btn btn-success btn-sm showbarcodebtn',disvalue:false,alertmsg:'Your data is updated successfully'});
					this.getObjList();
					this.setState({showSuccessMsg: true}, ()=>{
						setTimeout(() => {
						this.setState({showSuccessMsg: false })
						}, 3000);
					});
				}).catch(function (error) { console.log(error); });
			}
		}

	}

	searchCD=(id,by) => {
		if(id != "" && id != undefined){
		 	let temp={search : id ,type: by}
		 	axios.post(base_url+'cd_catalog/search2', temp, this.props.user.header )
		 		.then(res =>{
				if(res.data.length > 0){
					let tempObj = this.state.searchmessage;
					tempObj="";
					this.setState({searchmessage:tempObj});
					this.setState({objList:res.data,searchtable:true,showSuccessMsg:false});
			
				}
				else{
					let tempObj = this.state.searchmessage;
					tempObj="CD  details you entered is not match with our records";
					this.setState({searchmessage:tempObj,showSuccessMsg:false});
					let temarr = [];
					this.setState({objList:temarr,searchtable:false});
				
				
				}
			}).catch(function (error) { console.log(error); }); 

			this.renderObjlist();
		}else{
			let tempObj = this.state.searchmessage;
			tempObj="Please Enter CD number or CD title  to search";
			let temarr = [];
			this.setState({searchmessage:tempObj});
			this.setState({objList:temarr,searchtable:false});
		
		}
	}
	addObj = () => { 
		let tempObj = {};
		for (let key in  Cd_Catalog ) { tempObj[key] = ""; }
		this.setState({ formObj:tempObj});
		let tempObjs = {};
		for (let key in Cd_Catalog ) { tempObjs[key] = ""; }
		this.setState({validations:tempObjs});
		this.setState({disvalue:true,classname:'hidebarcodebtn',deletebtn:'hidebarcodebtn',showSuccessMsg:false,cdnumid:''});
		
		
	}
	goToSearch = () =>{
		let tempObjs = {};
		for (let key in Cd_Catalog ) { tempObjs[key] = ""; }
		this.setState({validations:tempObjs});
		let tempObj = {};
		for (let key in Cd_Catalog ) { tempObj[key] = ""; }
		this.setState({ formObj:tempObj,searchmessage:""});
		this.setState({cdform:false,searchform:true,searchtable:false});
	}

	editObj = (i) => { 
		let tempObj = {};
		for (let key in this.state.objList[i] ) { tempObj[key] = this.state.objList[i][key]; }
		this.setState({ formObj:tempObj, showModal:true});
	}
	setNewform = () =>{
		this.addObj();
		this.setState({cdform:true,searchform:false,searchtable:false,classname:'hidebarcodebtn'});
	}

	
	deleteObj = ()=> { 
		
		let tempObj = this.state.formObj;
		if(tempObj.cdstatus=="Available"){
		this.setState({show:true});
		this.setState({index:tempObj});
	}
	else{
		this.setState({showMessage:true});
	}
	
		
	}
	conformDelete=()=>{
		var tempObj=this.state.index;
			tempObj.status = 'Inactive';
			axios.post(base_url+'cd_catalog/save', tempObj , this.props.user.header ).then(res =>{
				this.setState({show:false});
				this.setState({alertmsg:'Your data is deleted successfully ',classname:'hidebarcodebtn',deletebtn:'hidebarcodebtn'});
				this.setState({showSuccessMsg: true}, ()=>{
					setTimeout(() => {
					this.setState({showSuccessMsg: false })
					}, 3000);
				});
				let tempObj = {};
				for (let key in  Cd_Catalog ) { tempObj[key] = ""; }
				this.setState({ formObj:tempObj,disvalue:true});
			
		}).catch(function (error) { console.log(error); });

		
		

	}
	getDetails = (i,id) => {
		let tempObjs = {};
		for (let key in Cd_Catalog  ) { tempObjs[key] = ""; }
		this.setState({validations:tempObjs});
		let tempObj = this.state.objList[i];
		console.log(tempObj)
		this.setState({formObj:tempObj,cdnumid:tempObj.cdnumber});
		this.setState({isdisabled:true});
		this.setState({showModal:false});
		this.setState({cdform:true,searchform:false,searchtable:false,disvalue:false,classname:'btn btn-success btn-sm showbarcodebtn',deletebtn:'btn btn-danger btn-sm showbarcodebtn',showSuccessMsg:false});
		
	}
	searchType=(id,title)=>{
		if(id !== '' && id !== undefined){
		   this.searchCD(id,"cdnumber");
		}else if(title !== '' && title !== undefined){
			let output = title.trim();
			let input=output.substring(0, 1).toUpperCase() + output.substring(1);
		    this.searchCD(input, "Name");
		}
		
		else{
		  let tempObj = this.state.searchmessage;
		  tempObj="Please Enter cd number or cd title to search";
		  let temarr = [];
		  this.setState({searchmessage:tempObj});
		  this.setState({objList:temarr,searchtable:false});
		}
	}
	Veryfycdnumber=(cdnumber)=>{
		if(cdnumber !== ""){
			if(cdnumber !== this.state.cdnumid){
			var cardObj = {cdnumber:this.state.formObj.cdnumber};
			var tempObj=this.state.validations;
			axios.post(base_url+'cd_catalog/cdnumberverify', cardObj,this.props.user.header)        
				.then(res => {
				if(res.data.msg === 'success'){   
					tempObj.cdnumber='The cd number you have entered is already associated with another cd'; 
					this.setState({validations:tempObj});
				}               
				}).catch(function (error) {        
			}); 
				
		}
	}
	}
	
	validate = (val) => {
		let tempObj = this.state.validations;
		switch(val){
			case "cdentrydate": tempObj.cdentrydate = Validators.date1(this.state.formObj.cdentrydate); break;
			case "cdnumber": tempObj.cdnumber = Validators.name(this.state.formObj.cdnumber);this.Veryfycdnumber(this.state.formObj.cdnumber);break;
			case "authorname": tempObj.authorname = Validators.name(this.state.formObj.authorname); break;
			case "cdtitle": tempObj.cdtitle = Validators.name(this.state.formObj.cdtitle); break;
			case "cdyear": tempObj.cdyear = Validators.select(this.state.formObj.cdyear); break;
			case "department": tempObj.department = Validators.select(this.state.formObj.department); break;
			case "price": tempObj.price = Validators.number(this.state.formObj.price); break;
			case "cdstatus": tempObj.cdstatus = Validators.select(this.state.formObj.cdstatus); break;
		}
		this.setState({validations:tempObj});
	 } 

	format = (val, e) => { 
		let tempObj = this.state.formObj;
		switch(val){
			case "cdentrydate": tempObj.cdentrydate = Formators.name(e.target.value); break;
			case "cdnumber": tempObj.cdnumber = Formators.name(e.target.value); break;
			case "barcode": tempObj.barcode = Formators.name(e.target.value); break;
			case "authorname": tempObj.authorname = Formators.name(e.target.value); break;
			case "cdtitle": tempObj.cdtitle = Formators.name(e.target.value); break;
			case "cdyear": tempObj.cdyear = Formators.name(e.target.value); break;
			case "journaltype": tempObj.journaltype = Formators.name(e.target.value); break;
			case "department": tempObj.department = Formators.name(e.target.value); break;
			case "billno": tempObj.billno = Formators.name(e.target.value); break;
			case "billdate": tempObj.billdate = Formators.name(e.target.value); break;
			case "cdstatus": tempObj.cdstatus= Formators.name(e.target.value); break;
			case "price": tempObj.price= Formators.name(e.target.value); break;
			case "notes": tempObj.notes = Formators.name(e.target.value); break;
		}
		this.setState({formObj:tempObj,showSuccessMsg:false});
	 } 

	renderObjlist = () => {
		if( this.state.objList != undefined && this.state.objList.length > 0){
			return this.state.objList.map((item, index) =>{
				return (
					<tr key={index} id={index} onClick={()=>{this.getDetails(index,item.cdnumber)}}>
						<td>{item.cdnumber}</td>
						<td>{item.cdtitle}</td>
						<td>{item.cdstatus}</td>
						</tr>
					)
			 }, this);
		}
	}
	changeEvent = (e) =>{
		console.log(e);
		this.setState({ endDate : e });
		var tempObj=this.state.formObj;
			tempObj.billdate=e ;
		this.setState({formObj:tempObj});
		  // console.log(this.state.formObj);
	   }
	   changeEvents = (e) =>{
		console.log(e);
		this.setState({ endDate : e });
		var tempObj=this.state.formObj;
			tempObj.cdentrydate=e ;
		this.setState({formObj:tempObj});
		this.validate("cdentrydate")
		  // console.log(this.state.formObj);
	   }

	render() {
		return (
			<div className="shadow-sm p-3 mb-5 bg-white rounded">
				<div><div className="d-flex row justify-content-center"><h3>CD Catalog</h3></div></div><br/>
				 {this.state.showSuccessMsg ?
                  <div className="d-flex row">
					  <div className="p-2 flex-fill">
                      <FlashMessage duration={3000} persistOnHover={true}>
                      {flashMessage(this.state.alertmsg, "Success")}
                      </FlashMessage>
					  </div>	
                  </div>
                  :null
                  }
				{this.state.searchform?
				<div className="d-flex row">
				  	<div className="p-2 flex-fill">
						<label htmlFor="idcdnumber" className="label-custom" >CD/DVD/Barcode No</label> 
						<input  type="text"  value={this.state.formObj.cdnumber} id="idcdnumber"  onChange={(e)=>{this.format("cdnumber",e)}}  onKeyPress={(e) => e.key === 'Enter' ? this.searchType(this.state.formObj.cdnumber,this.state.formObj.cdtitle): null}   className="form-control  form-control-sm" />
						<span className="errormsg">{this.state.searchmessage}</span>
					</div>
					<div className="p-2 flex-fill">
						<label htmlFor="idcdtitle" className="label-custom" >Title</label> 
						<input type="text" value={this.state.formObj.cdtitle} id="idcdtitle" onKeyPress={(e) => e.key === 'Enter' ? this.searchType(this.state.formObj.cdnumber,this.state.formObj.cdtitle): null}onChange={(e)=>{this.format("cdtitle",e)}}   className="form-control form-control-sm" />

					</div>
					<div className="p-2">
						<button type="button" className="btn btn-primary btn-sm" style={{marginTop:32}}  onClick={()=>{ this.searchType(this.state.formObj.cdnumber,this.state.formObj.cdtitle)}}>Search</button>
					</div>
					<div className="p-2">
						<button type="button" className="btn btn-success btn-sm" style={{marginTop:32}}  onClick={()=>{this.setNewform()}}>New</button>
					</div>
				</div>:null}
				{this.state.searchtable?
				<div className="d-flex row">
					<table className="table table-hover table-sm">
						<thead className="thead">
							<tr>
								<td>CD No</td>
								<td>CD Title</td>
							    <td>CD Status</td>
							</tr>
						</thead>
						<tbody>{this.renderObjlist()}</tbody>
					</table>
				</div>:null}
				{this.state.cdform?
				<div>
					<div className="d-flex row">
				  		<div className="p-2 flex-fill">
						  <div className="d-flex row">
						  <div className="col-md-8">
							<label htmlFor="idcdnumber" className="label-custom" >CD/DVD/Barcode No<span className="star">*</span></label> 
							<input tabIndex="1" type="text" value={this.state.formObj.cdnumber} id="idcdnumber"  onChange={(e)=>{this.format("cdnumber",e)}}   onBlur={()=>{ this.validate("cdnumber")}}  style={this.state.validations.cdnumber===""?{}:{"borderColor":"red"}} className="form-control  form-control-sm" />
							<span className="errormsg">{this.state.validations.cdnumber}</span>
						</div>
						<div className="col-md-4">
							<label htmlFor="idcdentrydate" className="label-custom" >Date<span className="star">*</span></label><br/> 
							<DateOnly 
								name="cdentrydate"
								selected={this.state.endDate}
								value={this.state.formObj.cdentrydate}  
								onChange={(e)=>{this.changeEvents(e)}}
								dateformat={this.state.dateformat}
								class_name={ this.state.validations.cdentrydate==""?"form-control form-control-sm":"form-control form-control-sm red-border" }
								onBlur={this.validate}
								tabindex={2}
							/>  	
							<span className="errormsg">{this.state.validations.cdentrydate}</span>
						</div>
						  </div>
						  <div className="d-flex row">
						  <div className="col-md-6 flex-fill">
							<label htmlFor="idcdyear" className="label-custom" >Year<span className="star">*</span></label> 
							<select  tabIndex="5"  value={this.state.formObj.cdyear} id="idcdyear"  onChange={(e)=>{this.format("cdyear",e)}}  onBlur={()=>{ this.validate("cdyear")}} className="form-control form-control-sm" style={this.state.validations.cdyear===""?{}:{"borderColor":"red"}}  className='form-control form-control-sm' required >
								<option value=""></option>
								{[...Array((new Date().getFullYear() + 1) - 2000).keys()].reverse().map(i => {
									return <option>{2000 + i}</option>
								})}
							</select> 
						</div>	
						<div className="col-md-6">
							<label htmlFor="idprice" className="label-custom" >Price<span className="star">*</span></label> 
							<input type="text"  tabIndex="6"  value={this.state.formObj.price} id="idprice"  onChange={(e)=>{this.format("price",e)}}   onBlur={()=>{ this.validate("price")}}  style={this.state.validations.price===""?{}:{"borderColor":"red"}}className="form-control form-control-sm " />
							<span className="errormsg">{this.state.validations.price}</span>
						
						</div>	
						  </div>
						  <div className="d-flex row">
						  <div className="col-md-12 flex-fill">
							<label htmlFor="iddepartment" className="label-custom" >Department<span className="star">*</span></label> 
							<select  tabIndex="9"  value={this.state.formObj.department} id="iddepartment"  onChange={(e)=>{this.format("department",e)}}   onBlur={()=>{ this.validate("department")}}  style={this.state.validations.department===""?{}:{"borderColor":"red"}}className="form-control form-control-sm">
								<option value=""></option>
								{this.state.settings.departmentTypes.map((item,index) => <option value={item.department} id={index} key={index}>{item.department}</option>)}
							
							</select>
							<span className="errormsg">{this.state.validations.department}</span>
						</div>
						  </div>

						  </div>
					    <div className="p-2 flex-fill">
						<div className="d-flex row">
						<div className="col-md-6 flex-fill">
							<label htmlFor="idauthorname" className="label-custom" >Author Name<span className="star">*</span></label> 
							<input  tabIndex="3"  type="text" value={this.state.formObj.authorname} id="idauthorname"  onChange={(e)=>{this.format("authorname",e)}}   onBlur={()=>{ this.validate("authorname")}}  style={this.state.validations.authorname===""?{}:{"borderColor":"red"}} className="form-control form-control-sm" />
							<span className="errormsg">{this.state.validations.authorname}</span>
						</div>
						<div className="col-md-6 flex-fill">
							<label htmlFor="idcdtitle" className="label-custom" >Title<span className="star">*</span></label> 
							<input  tabIndex="4"  type="text" value={this.state.formObj.cdtitle} id="idcdtitle" onChange={(e)=>{this.format("cdtitle",e)}}  onBlur={()=>{ this.validate("cdtitle")}}  style={this.state.validations.cdtitle===""?{}:{"borderColor":"red"}}className="form-control form-control-sm" />
							<span className="errormsg">{this.state.validations.cdtitle}</span>
						</div>
						</div>
						<div className="d-flex row">
						<div className="col-md-8 flex-fill">
							<label htmlFor="idbillno" className="label-custom" >Bill No</label> 
							<input  tabIndex="7" type="text" value={this.state.formObj.billno} id="idbillno"  onChange={(e)=>{this.format("billno",e)}}  onBlur={()=>{ this.validate("billno")}} className="form-control form-control-sm" style={this.state.validations.billno===""?{}:{"borderColor":"red"}} className="form-control form-control-sm" />
							<span className="errormsg">{this.state.validations.billno}</span>
						</div>
						<div className="col-md-4 flex-fill">
							<label htmlFor="idbilldate" className="label-custom" >Bill Date</label> <br/>
							<DateOnly 
								name="billdate"
								selected={this.state.endDate}
								value={this.state.formObj.billdate}  
								onChange={(e)=>{this.changeEvent(e)}}
								dateformat={this.state.dateformat}
								class_name={ this.state.validations.billdate==""?"form-control form-control-sm":"form-control form-control-sm red-border" }
								onBlur={this.validate}
								tabindex={8}
							/>  
							<span className="errormsg">{this.state.validations.billdate}</span>
						</div>
						</div>
						<div className="d-flex row">
						<div className="col-md-12 flex-fill">
							<label htmlFor="idcdstatus" className="label-custom" >Status<span className="star">*</span></label> 
							<select  tabIndex="10"  value={this.state.formObj.cdstatus} id="idcdstatus"  onChange={(e)=>{this.format("cdstatus",e)}}   onBlur={()=>{ this.validate("cdstatus")}}  style={this.state.validations.cdstatus===""?{}:{"borderColor":"red"}}className="form-control form-control-sm">
								<option value=""></option>
								<option value="Available">Available</option>
								<option value="Not Available">Not Available</option>
								<option value="Damaged">Damaged</option>
							</select>
							<span className="errormsg">{this.state.validations.cdstatus}</span>
						
						</div>
						</div>
						</div>

					</div>	  
					<div className="d-flex row">
						<div className="col-md-12 flex-fill">
						<label htmlFor="idnotes" className="label-custom" >Notes</label> 
						<textarea  tabIndex="11" value={this.state.formObj.notes} id="idnotes"  onChange={(e)=>{this.format("notes",e)}}  onBlur={()=>{ this.validate("notes")}}  style={this.state.validations.notes===""?{}:{"borderColor":"red"}} className="form-control" rows="1"/>
						<span className="errormsg">{this.state.validations.notes}</span>
					</div>
				</div>
				<div className="d-flex row justify-content-end">
					
					<div className="p-2">
						<button type="button" className={this.state.deletebtn} onClick={()=>{this.deleteObj()}} >Delete</button>
					</div>
					<div className="p-2">
						<button type="button" className={this.state.classname} onClick={()=>{this.printbarcode()}} >Print Barcode</button>
					</div>
					<div className="p-2">
						<button type="button" className="btn btn-success btn-sm" onClick={()=>{this.addObj()}}>New</button>
					</div>
					
					<div className="p-2">
						<button type="button" className="btn btn-success btn-sm"onClick={()=>{this.saveObj()}} >Save</button>
					</div>
					<div className="p-2">
						<button type="button" className="btn btn-warning btn-sm" onClick={()=>{this.goToSearch()}}>Cancel</button>
					</div>	
				</div> 
			</div>:null}
			<br/>
			<Modal size="lg" show={this.state.showModal} onHide={()=>{this.setState({showModal:false,searchtable:false})}} >
					<Modal.Header closeButton>
						<Modal.Title>CD Catalog</Modal.Title>
					</Modal.Header>
					<ModalBody>
						<div className="d-flex row">
							<div className="p-2">
								<label htmlFor="idcdnumber" className="label-custom" >CD/DVD/Barcode No<span className="star">*</span></label> 
								<input type="text"  value={this.state.formObj.cdnumber} id="idcdnumber"  onChange={(e)=>{this.format("cdnumber",e)}}    className="form-control  form-control-sm" />
								<span className="errormsg">{this.state.searchmessage}</span>
							</div>
							<div className="p-2 flex-fill">
								<label htmlFor="idcdtitle" className="label-custom" >Title<span className="star">*</span></label> 
								<input type="text" value={this.state.formObj.cdtitle} id="idcdtitle"  onChange={(e)=>{this.format("cdtitle",e)}} className="form-control form-control-sm" />
								<span className="errormsg">{this.state.searchmessage}</span>
							</div>
							<div className="p-2">
								<button type="button" className="btn btn-primary btn-sm" style={{marginTop:32}}  onClick={()=>{ this.searchType(this.state.formObj.cdnumber,this.state.formObj.cdtitle)}}>Go</button>
							</div>
						</div>	
						{this.state.searchtable?<div className="d-flex row">
							<div className="p-2 flex-fill">
								<table className="table table-sm">
									<thead>
										<tr>
											<td>CD/DVD/Barcode No</td>
											<td> Title</td>
										
										</tr>
									</thead>
									<tbody>{this.renderObjlist()}</tbody>
								</table>
							</div>
						</div>:null}
					</ModalBody>
				<ModalFooter>
					<Button variant="warning" onClick={()=>{this.setState({showModal:false,searchtable:false})}}>CLOSE</Button>
				</ModalFooter>
			</Modal> 
			<Modal size="md" centered show={this.state.show} onHide={()=>{this.setState({show:false})}}>
            <ModalBody>
			<div className="d-flex justify-content-right">
             <div className="p-2">Are you sure want to delete the record!!.</div>
			</div>
			<div className="d-flex justify-content-end">
			<div className="p-2"><button className="btn btn-warning" onClick={()=>{this.conformDelete()}}>YES</button></div>
			<div className="p-2"><button className="btn btn-primary" onClick={()=>{this.setState({show:false})}}>NO</button></div>
			   </div>
			</ModalBody>
        </Modal>
		<Modal  size="md" centered show={this.state.showMessage} onHide={()=>{this.setState({showMessage:false})}}>
		<ModalBody>
			<div className="d-flex justify-content-center">
             <div className="p-2">This CD/DVD is not available !! you can not able to delete it</div>
			</div>
			<div className="d-flex justify-content-end">
			<div className="p-2"><button className="btn btn-warning" onClick={()=>{this.setState({showMessage:false})}}>OK</button></div>
			
			   </div>
			</ModalBody>
        </Modal>
		</div>
		)
	}
}
			
			
	

const mapToProps = ({ loggedUserState }) => ({ user: loggedUserState })
export default connect(mapToProps)(Cd_CatalogForm);
