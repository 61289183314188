////////////////////////////////////////////////////////////////////////////////////////////////////
// Warning : Property of Deemsoft Global Solutions LLC, USA. It is illegal to make copy of this code
// Author : Gangaswamy Kempahonnaiah
////////////////////////////////////////////////////////////////////////////////////////////////////
import React from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { Button, Modal, ModalBody, ModalFooter } from 'react-bootstrap';
import { base_url } from '../constants/Constants';
import {DateOnly} from '../utils/DateTime';
import { Validators } from '../utils/Validators';
import { Formators } from '../utils/Formators';
import {Setting} from '../models/Setting';
import { Members } from '../models/Members';
import flashMessage from '../utils/Flash';
import FlashMessage from 'react-flash-message';
import '../styles/Styles.css';


class MembersForm extends React.Component {
	constructor(props){
		super(props);
		this.state = {
			objList : [],
			totallist : [],
			settings : Setting,
			validations : {},
			searchtable : false,
			searchform:true,
			searchmessage :'',
			barcodebtn:false,
			memberform:false,
			alertmsg:"",
			showSuccessMsg:false,
			formObj : Members,
			diablebtn : false,
			deletebtn: false,
			show:false,
			dateformat:'',
			endDate:new Date(),
			confirmbtn:true,
			errormessage:"Are You Sure ?? Want to Delete the Library Member!!.",
			cardid:'',
			email:'',
			befreshow:false,
			memberid:'',
			adminusertype:false,

		}
		
	}

	componentDidMount(){
		if(this.props.user.usertype === "admin"){
			this.setState({adminusertype:true});
		  }
		let tempObj = {};
		for (let key in Members) { tempObj[key] = ""; }
		this.setState({validations:tempObj});
		let tempObjs = {};
		for (let key in Members) { tempObjs[key] = ""; }
		this.setState({formObj:tempObjs});
		this.getSettings();
		this.getBillList();
	}

	getSettings = () => {
        axios.get(base_url+'settings/list', this.props.user.header ).then(res => {
			if ( res.data.length > 0 ){
                for(var i=0;i<res.data.length;i++){
					this.setState({ settings:res.data[i]});	
                }	
			}
			if(this.state.settings.dateformats == ''){
				this.setState({dateformat:"dd/MM/yyyy"});
		    }else if(this.state.settings.dateformats !==''){
				this.setState({dateformat:this.state.settings.dateformats});
			}
		}).catch(function (error) { console.log(error); });
    }


	getObjList = () => {
		axios.get(base_url+'members/list', this.props.user.header ).then(res => {
			if ( res.data.length > 0 ){
				this.setState({ objList:res.data});
				this.renderObjlist();
			}
		}).catch(function (error) { console.log(error); });
	}

	printbarcode=()=>{
		var id2="books";
		var temp = this.state.formObj.barcode  +" " +this.state.formObj.barcode;
		var text= temp.split(" ");
		window.open('/barcode?id1='+text+'&id2='+id2+'', "_blank", "top=500,left=500,width=500,height=500");	
		
	}
 checkBeforeChange=(e)=>{
	 var temps=e.target.value;
if(this.state.memberid !== "" &&  this.state.memberid !== undefined){
	this.setState({befreshow:false});
	axios.get(base_url+'checkout/listbycardid/'+this.state.formObj.id, this.props.user.header ).then(res => {
		if(res.data.length === 0){
			var temp={type:"Membershipid",search:this.state.formObj.id};
			axios.post(base_url+'bills/search', temp, this.props.user.header )
			.then(res =>{
				if(res.data.length === 0){
                  this.setState({memberid:""});
				  var temp=this.state.formObj;
                    temp.registrationtype=temps;
				    this.setState({formObj:temp});
				
				}else{
					this.setState({errormessage:'Please clear all the bill in the billing Section for this card id and then change the registration type.',befreshow:true,confirmbtn:false});	
				}
			}).catch(function (error) { console.log(error); });		
		}else{
			this.setState({errormessage:'Please Return all issued book for this card id and then change the registration type.',befreshow:true,confirmbtn:false});
		}
	}).catch(function (error) { console.log(error); });
	}

 }
 checkActiveChange=(e)=>{
	var temps=e.target.value;
if(this.state.memberid !== "" &&  this.state.memberid !== undefined){
   this.setState({befreshow:false});
   axios.get(base_url+'checkout/listbycardid/'+this.state.formObj.id, this.props.user.header ).then(res => {
	   if(res.data.length === 0){
		   var temp={type:"Membershipid",search:this.state.formObj.id};
		   axios.post(base_url+'bills/search', temp, this.props.user.header )
		   .then(res =>{
			   if(res.data.length === 0){
				 this.setState({memberid:""});
				 var temp=this.state.formObj;
				   temp.status=temps;
				   this.setState({formObj:temp});
			   
			   }else{
				   this.setState({errormessage:'Please clear all the bill in the billing Section for this card id and then change the Status.',befreshow:true,confirmbtn:false});	
			   }
		   }).catch(function (error) { console.log(error); });		
	   }else{
		   this.setState({errormessage:'Please Return all issued book for this card id and then change the Status.',befreshow:true,confirmbtn:false});
	   }
   }).catch(function (error) { console.log(error); });
   }

}

	deleteObj = () => { 
		this.setState({show:false});
		axios.get(base_url+'checkout/listbycardid/'+this.state.formObj.id, this.props.user.header ).then(res => {
			console.log(res.data);
			if(res.data.length === 0){
				var temp={type:"Membershipid",search:this.state.formObj.id};
				axios.post(base_url+'bills/search', temp, this.props.user.header )
				.then(res =>{
					console.log(res.data);
					if(res.data.length === 0){
						let tempObj = this.state.formObj;
						tempObj.status = 'Inactive';
						axios.post(base_url+'members/save', tempObj , this.props.user.header ).then(res =>{
							this.setState({errormessage:'Member Deleted Successfully',show:true,confirmbtn:false});	
						}).catch(function (error) { console.log(error); });
						
					}else{
						this.setState({errormessage:'Please clear all the bill',show:true,confirmbtn:false});	
					}
				}).catch(function (error) { console.log(error); });		
			}else{
				this.setState({errormessage:'Please Return all issued book',show:true,confirmbtn:false});
			}
		}).catch(function (error) { console.log(error); });
	}

	saveObj = () => {
		if(this.state.formObj.id === ""){
		let tempo = '';
		for (let key in Members) { this.validate(key);}
		for (let key in this.state.validations) {tempo += this.state.validations[key];}
		if(tempo === ""){
		var tempObj=this.state.formObj;
		tempObj.status="Active";	
		 axios.post(base_url+'members/save', this.state.formObj, this.props.user.header ).then(res => {
			this.setState({ formObj:res.data.data});
			this.setState({cardid:this.state.formObj.cardid,email:this.state.formObj.email});
			this.setState({ showSuccessMsg:true,alertmsg:"You have Successfully Registered",barcodebtn:true,diablebtn:true});
		 }).catch(function (error) { console.log(error); });
       }
	   }else{
		let temp='';
		for (let key in Members) { this.validate(key);}
		for (let key in this.state.validations) {temp += this.state.validations[key];}
		if(temp == ''){
		axios.post(base_url+'members/save', this.state.formObj, this.props.user.header ).then(res => {
			this.setState({ formObj:res.data.data});
			this.setState({cardid:this.state.formObj.cardid,email:this.state.formObj.email});
			this.setState({ showSuccessMsg:true,alertmsg:"Updated Successfully",barcodebtn:true});
			this.getObjList();
	    }).catch(function (error) { console.log(error); });
       }
	}
	this.setState({showSuccessMsg:false});
    }

	getDetails = (i,id) => { 
		let tempObjs = {};
		for (let key in Members) { tempObjs[key] = ""; }
		this.setState({validations:tempObjs});
		let tempObj = this.state.objList[i];
		this.setState({cardid:this.state.objList[i].cardid,email:this.state.objList[i].email,memberid: this.state.objList[i].id});
		this.setState({formObj:tempObj});
		this.setState({memberform:true,searchform:false,searchtable:false,barcodebtn:true,diablebtn:true,deletebtn:true,confirmbtn:true});
	}

	getBillList=()=>{
		axios.get(base_url+'bills/list',  this.props.user.header ).then(res => {
				if ( res.data.length > 0 ){
				this.setState({totallist:res.data});
				}
			}).catch(function (error) { console.log(error); });
	  }

	goToSearch = () =>{
		let tempObj = {};
		for (let key in Members ) { tempObj[key] = ""; }
		this.setState({ formObj:tempObj});
		this.setState({memberform:false,searchform:true,searchtable:false});
	}

	clearform = () => {
		let tempObj = {};
		for (let key in Members ) { tempObj[key] = ""; }
		this.setState({ formObj:tempObj});

		let tempObjs = {};
		for (let key in Members) { tempObjs[key] = ""; }
		this.setState({validations:tempObjs});
		this.setState({barcodebtn:false,showSuccessMsg:false,diablebtn:false,searchmessage:"",memberid:""});
		this.setState({show:false,errormessage:'Are You Sure ?? Want to Delete the Library Member!!.',confirmbtn:true});
	}

	goToRegistrationForm = () =>{
		this.clearform();
		this.setState({memberform:true,searchform:false,searchtable:false,barcodebtn:false,deletebtn:false});
	}

	closeObjdata = () =>{
		this.clearform();
		this.goToSearch();
	}

	searchMember = (id,by) => {
		if(id != "" && id != undefined){
		  let temp={search : id ,type: by}
		 axios.post(base_url+'members/search', temp, this.props.user.header )
		 .then(res =>{
			if(res.data.length > 0){
			let tempObj = this.state.searchmessage;
				tempObj="";
				this.setState({searchmessage:tempObj});
			this.setState({objList:res.data,searchtable:true,showSuccessMsg:false});
			}else{
				let tempmsg = this.state.searchmessage;
				tempmsg="Member Details You Entered is Not Match With Our Records";
				this.setState({searchmessage:tempmsg,showSuccessMsg:false});
				this.setState({objList:[],searchtable:false});
			}
		}).catch(function (error) { console.log(error); }); 

		this.renderObjlist();
	}else{
		let tempObj = this.state.searchmessage;
		tempObj="Please Enter Cardid or Firstname to Search";
		let temarr = [];
		this.setState({searchmessage:tempObj});
		this.setState({objList:temarr,searchtable:false});
	}
	}
	searchRegisterMembers = (id,sname) =>{
		if(id !== '' && id !== undefined){
			this.searchMember(id,"cardid");
		}else if(sname !== '' && sname !== undefined){
			var output = sname.trim();
			var input=output.substring(0, 1).toUpperCase() + output.substring(1);
			this.searchMember(input,"Name");
		}else{
			let tempObj = this.state.searchmessage;
			tempObj="Please Enter Cardid or Firstname to search";
			let temarr = [];
			this.setState({searchmessage:tempObj});
			this.setState({objList:temarr,searchtable:false});
		}
	}

	
    verifyCardID =(id)=>{

		var tempObj=this.state.validations;
		var cardObj = {cardid:id};
		axios.post(base_url+'members/cardverify', cardObj,this.props.user.header)        
		.then(res => {
		if(res.data.data.cardid == this.state.cardid){
				tempObj.cardid=''; 
				this.setState({validations:tempObj});
		} else{
			if(res.data.msg === 'success'){   
				tempObj.cardid='The Cardid is Already Associated With Another Account'; 
				this.setState({validations:tempObj});
			}   
		}           
		}).catch(function (error) { 
		console.log(error);        
		}); 
	  
	}

	verifyEmailID = (email) =>{

		var emailObj = {email:this.state.formObj.email};
		var tempObj=this.state.validations;
		axios.post(base_url+'members/emailverify',  emailObj,this.props.user.header)        
		.then(res => {
			if(res.data.data.email === this.state.email){
				tempObj.email=''; 
				this.setState({validations:tempObj});
		} else{
			if(res.data.msg === 'success'){   
				tempObj.email='The emailid you have entered is already associated with another account'; 
				this.setState({validations:tempObj});
			}   
		}	            
		}).catch(function (error) { 
		console.log(error);        
		});     
	}

	validate = (val) => {
		let tempObj = this.state.validations;
		switch(val){
			case "cardid": tempObj.cardid = Validators.name(this.state.formObj.cardid);	this.verifyCardID(this.state.formObj.cardid); break;
			case "firstname": tempObj.firstname = Validators.name(this.state.formObj.firstname); break;
			case "lastname": tempObj.lastname = Validators.name1(this.state.formObj.lastname); break;
			case "dateofbirth": tempObj.dateofbirth = Validators.dob(this.state.formObj.dateofbirth); break;
			case "gender": tempObj.gender = Validators.select(this.state.formObj.gender); break;
			case "email": tempObj.email = Validators.email(this.state.formObj.email); this.verifyEmailID(this.state.formObj.email); break;
			case "cellphone": tempObj.cellphone = Validators.phone(this.state.formObj.cellphone); break;
			case "batch": tempObj.batch = Validators.select(this.state.formObj.batch); break;
			case "address1": tempObj.address1 = Validators.name(this.state.formObj.address1); break;
			//case "status": tempObj.status = Validators.select(this.state.formObj.status); break;
			case "department": tempObj.department = Validators.select(this.state.formObj.department); break;
			case "registrationtype":
				if(this.state.memberid == ""){
					tempObj.registrationtype = Validators.select(this.state.formObj.registrationtype); 
				}
				break;
				
		}
		this.setState({validations:tempObj});
	 } 

	format = (val, e) => { 
		let tempObj = this.state.formObj;
		switch(val){
			case "userid": tempObj.userid = Formators.name(e.target.value); break;
			case "cardid": tempObj.cardid = Formators.name(e.target.value); break;
			case "firstname": tempObj.firstname = Formators.firstname(e.target.value); break;
			case "lastname": tempObj.lastname = Formators.firstname(e.target.value); break;
			case "middlename": tempObj.middlename = Formators.name(e.target.value); break;
			case "dateofbirth": tempObj.dateofbirth = Formators.name(e.target.value); break;
			case "age": tempObj.age = Formators.name(e.target.value); break;
			case "gender": tempObj.gender = Formators.name(e.target.value); break;
			case "email": tempObj.email = Formators.name(e.target.value); break;
			case "cellphone": tempObj.cellphone = Formators.name(e.target.value); break;
			case "homephone": tempObj.homephone = Formators.name(e.target.value); break;
			case "batch": tempObj.batch = Formators.name(e.target.value); break;
			case "course_id": tempObj.course_id = Formators.name(e.target.value); break;
			case "rollno": tempObj.rollno = Formators.name(e.target.value); break;
			case "department": tempObj.department = Formators.name(e.target.value); break;
			case "address1": tempObj.address1 = Formators.name(e.target.value); break;
			case "address2": tempObj.address2 = Formators.name(e.target.value); break;
			case "city": tempObj.city = Formators.name(e.target.value); break;
			case "state": tempObj.state = Formators.name(e.target.value); break;
			case "zipcode": tempObj.zipcode = Formators.name(e.target.value); break;
			case "country": tempObj.country = Formators.name(e.target.value); break;
			case "membership_fee": tempObj.membership_fee = Formators.name(e.target.value); break;
			case "registrationtype": this.checkBeforeChange(e);
				if(this.state.memberid == ""){
				 tempObj.registrationtype = e.target.value; 
				}
				break;
			case "status": this.checkActiveChange(e);
			if(this.state.memberid == ""){
				 tempObj.status = e.target.value;
			 } break;
			case "notes": tempObj.notes = Formators.name(e.target.value); break;
			default : tempObj[val] =Formators.name(this.state.formObj[val]);
		}
		this.setState({formObj:tempObj});
	 } 

	renderObjlist = () => {
		if( this.state.objList != undefined && this.state.objList.length > 0  ){
			return this.state.objList.map((item, index) =>{
				return (
					<tr key={index} id={index}  onClick={()=>{this.getDetails(index,item.cardid)}} >
						<td>{item.cardid}</td>
						<td>{item.firstname}</td>						
					</tr>
					)
			 }, this);
		}
	}

	changeEvent = (e) =>{
		var tempObj = this.state.formObj;
			tempObj.dateofbirth = e ;
		this.setState({formObj:tempObj});
		console.log(tempObj.dateofbirth);
		this.validate("dateofbirth");
    }

	renderDepartmentTypes = () => {
		if( this.state.settings.departmentTypes != undefined && this.state.settings.departmentTypes.length > 0){
			return this.state.settings.departmentTypes.map((item, index) =>{
				return (
					<option value={item.department} key={index} id={index}>{item.department}</option>	
					)
			}, this);
		}
	}
    

    renderRegistrationTypes = () => {
		if( this.state.settings.registrationTypes != undefined && this.state.settings.registrationTypes.length > 0){
			return this.state.settings.registrationTypes.map((item, index) =>{
				return (
					<option vale={item.registrationtype} key={index} id={index}>{item.registrationtype}</option>
					)
			}, this);
		}
	}

	renderBatchTypes = () => {
		if( this.state.settings.batchTypes != undefined && this.state.settings.batchTypes.length > 0){
			return this.state.settings.batchTypes.map((item, index) =>{
				return (
						<option value={item.batch} key={index} id={index}>{item.batch}</option>
						
					)
			}, this);
		}
	}


	render() {
		return (
		<div className="shadow-sm p-3 mb-5 bg-white rounded">
			<div><div className="d-flex row justify-content-center"><h3>Registration</h3></div></div><br/>
				 {this.state.showSuccessMsg ?
                  <div className="d-flex row">
					  <div className="p-2 flex-fill">
                      <FlashMessage duration={3000} persistOnHover={true}>
                      {flashMessage(this.state.alertmsg, "Success")}
                      </FlashMessage>
					  </div>	
                  </div>
                  :null
                  }
			{this.state.searchform?
			<div className="d-flex row">
				<div className="p-2 flex-fill">
					<label htmlFor="idcardid" className="label-custom" >Card Id/Barcode</label> 
					<input value={this.state.formObj.cardid} id="idcardid" onKeyPress={(e) => e.key === 'Enter' ? this.searchRegisterMembers(this.state.formObj.cardid,this.state.formObj.firstname): null} onChange={(e)=>{this.format("cardid",e)}}   className="form-control form-control-sm" type="text" required/> 
					<span className="errormsg">{this.state.searchmessage}</span>
				</div>
				<div className="p-2 flex-fill">
					<label htmlFor="idfirstname" className="label-custom" >First Name</label> 
					<input value={this.state.formObj.firstname} id="idfirstname" onKeyPress={(e) => e.key === 'Enter' ? this.searchRegisterMembers(this.state.formObj.cardid,this.state.formObj.firstname): null} onChange={(e)=>{this.format("firstname",e)}}   className="form-control form-control-sm" type="text" required/> 
				</div>
				<div className="p-2">
				<button type="button" className="btn btn-primary btn-sm" style={{marginTop:32}}  onClick={()=>{ this.searchRegisterMembers(this.state.formObj.cardid,this.state.formObj.firstname)}}>Search</button>
				</div>
				<div className="p-2">
				<button type="button" className="btn btn-success btn-sm" style={{marginTop:32}}  onClick={()=>{this.goToRegistrationForm()}}>New</button>
				</div>
				<div className="p-2 flex-fill"></div>
			</div>	
			:null}
			{this.state.searchtable?
			<div className="d-flex row">
				<table className="table table-hover table-sm">
				<thead className="thead">
					<tr>
						<td>Cardid</td>
						<td>First Name</td>
					</tr>
				</thead>
				<tbody>
				{this.renderObjlist()}
				</tbody>
		    	</table>
			</div>
			:null}
			{this.state.memberform?
			<div>
            <div className="d-flex row">
				<div className="p-2 flex-fill" style={{padding: "5.5px"}}>
				<div className="d-flex row">
				<div className="col-md-6">
					<label htmlFor="idregistrationtype" className="label-custom">Registration Type<span className="star">*</span></label>
					<select tabIndex="1" id="idregistrationtype" name="registrationtype" value={this.state.formObj.registrationtype}   onChange={(e)=>{this.format("registrationtype",e)}} onBlur={()=>{ this.validate("registrationtype")}} className="form-control form-control-sm" style={this.state.validations.registrationtype===""?{}:{"borderColor":"red"}} > 
					<option value=""></option>
					{this.renderRegistrationTypes()}
					</select>
					<span className="errormsg">{this.state.validations.registrationtype}</span>
				</div>
				<div className="col-md-6">
					<label htmlFor="idcardid" className="label-custom" >Card Id/Barcode<span className="star">*</span></label> 
					<input tabIndex="2" value={this.state.formObj.cardid} id="idcardid"  onChange={(e)=>{this.format("cardid",e)}}  onBlur={()=>{this.validate("cardid")}} style={this.state.validations.cardid===""?{}:{"borderColor":"red"}} type="text" className='form-control form-control-sm'  required /> 
					<span className="errormsg">{this.state.validations.cardid}</span>
				</div>
				</div>
				<div className="d-flex row">
				<div className="col-md-12 flex-fill">
						<label htmlFor="idbatch" className="label-custom" >Batch<span className="star">*</span></label> 
						<select tabIndex="5" value={this.state.formObj.batch} id="idbatch"  onChange={(e)=>{this.format("batch",e)}}   onBlur={()=>{ this.validate("batch")}} style={this.state.validations.batch===""?{}:{"borderColor":"red"}} type="text" className='form-control form-control-sm'> 
							<option value=""></option>	
							{this.renderBatchTypes()}
						</select>
						<span className="errormsg">{this.state.validations.batch}</span>
					</div>
					</div>
					<div className="d-flex row">	
					<div className="col-md-12 flex-fill">
						<label htmlFor="iddepartment" className="label-custom" >Department<span className="star">*</span></label> 
						<select tabIndex="8" value={this.state.formObj.department} id="iddepartment"  onChange={(e)=>{this.format("department",e)}}   onBlur={()=>{ this.validate("department")}}  style={this.state.validations.department===""?{}:{"borderColor":"red"}} type="text" className='form-control form-control-sm' required > 
						<option value=""></option>
						{this.renderDepartmentTypes()}		
							</select>
						<span className="errormsg">{this.state.validations.department}</span>
				   </div>
				</div>
				<div className="d-flex row">
				    <div className="col-md-6 flex-fill">
						<label htmlFor="idcourse_id" className="label-custom" >Course Id</label> 
						<input tabIndex="11" value={this.state.formObj.course_id} id="idcourse_id"  onChange={(e)=>{this.format("course_id",e)}}   onBlur={()=>{ this.validate("course_id")}} className="form-control form-control-sm" style={this.state.validations.course_id ===""?{}:{"borderColor":"red"}} type="text"  required /> 
						<span className="errormsg">{this.state.validations.course_id}</span>
					</div>
					<div className="col-md-6 flex-fill">
						<label htmlFor="idrollno" className="label-custom" >Rollno</label> 
						<input tabIndex="12" value={this.state.formObj.rollno} id="idrollno"  onChange={(e)=>{this.format("rollno",e)}}  onBlur={()=>{ this.validate("rollno")}} className="form-control form-control-sm" style={this.state.validations.rollno===""?{}:{"borderColor":"red"}} type="text" required /> 
						<span className="errormsg">{this.state.validations.rollno}</span>
					</div>
				</div>	
				</div>

				<div className="p-2 flex-fill" style={{padding: "5.5px"}}>
				<div className="d-flex row">
				<div className="col-md-6 flex-fill">
					<label  htmlFor="idfirstname" className="label-custom" >First Name<span className="star">*</span></label> 
					<input tabIndex="3" value={this.state.formObj.firstname} id="idfirstname"  onChange={(e)=>{this.format("firstname",e)}}  onBlur={()=>{ this.validate("firstname")}}  style={this.state.validations.firstname===""?{}:{"borderColor":"red"}} type="text" className='form-control form-control-sm' required /> 
					<span className="errormsg">{this.state.validations.firstname}</span>
				</div>
				<div className="col-md-6 flex-fill">
						<label htmlFor="idlastname" className="label-custom" >Last Name<span className="star">*</span></label> 
						<input tabIndex="4" value={this.state.formObj.lastname} id="idlastname"  onChange={(e)=>{this.format("lastname",e)}}  onBlur={()=>{ this.validate("lastname")}}  style={this.state.validations.lastname===""?{}:{"borderColor":"red"}} type="text" className='form-control form-control-sm' required /> 
						<span className="errormsg">{this.state.validations.lastname}</span>
				</div>
				</div>
				<div className="d-flex row">
			      <div className="col-md-4 flex-fill">
						<label htmlFor="iddateofbirth" className="label-custom" >Date of Birth<span className="star">*</span></label> 
						<br/><DateOnly 
                           name="dateofbirth"
                           value={this.state.formObj.dateofbirth}  
							onChange={(e)=>{this.changeEvent(e)}}
							dateformat={this.state.dateformat}
							class_name={ this.state.validations.dateofbirth ==""?"form-control form-control-sm":"form-control form-control-sm red-border" }
							onBlur={this.validate}
							tabindex={6}
						/><br/>
						<span className="errormsg">{this.state.validations.dateofbirth}</span>
					</div>
					<div className="col-md-8 flex-fill ">
						<label htmlFor="idemail" className="label-custom" >Email<span className="star">*</span></label> 
						<input tabIndex="7" value={this.state.formObj.email} id="idemail"  onChange={(e)=>{this.format("email",e)}}  onBlur={()=>{ this.validate("email")}} style={this.state.validations.email===""?{}:{"borderColor":"red"}} type="text" className='form-control form-control-sm' required /> 
						<span className="errormsg">{this.state.validations.email}</span>
					</div>
				</div>
				<div className="d-flex row">
					<div className="col-md-6 flex-fill">
						<label htmlFor="idcellphone" className="label-custom" >Cellphone<span className="star">*</span></label> 
						<input tabIndex="9" value={this.state.formObj.cellphone} id="idcellphone"  onChange={(e)=>{this.format("cellphone",e)}}  onBlur={()=>{ this.validate("cellphone")}}  style={this.state.validations.cellphone===""?{}:{"borderColor":"red"}} type="number" className='form-control form-control-sm' required /> 
						<span className="errormsg">{this.state.validations.cellphone}</span>
					</div>
					<div className="col-md-6 flex-fill">
						<label htmlFor="idgender" className="label-custom" >Gender<span className="star">*</span></label> 
						<select tabIndex="10" value={this.state.formObj.gender} id="idgender"  onChange={(e)=>{this.format("gender",e)}}  onBlur={()=>{ this.validate("gender")}}  style={this.state.validations.gender===""?{}:{"borderColor":"red"}} type="text" className='form-control form-control-sm'> 
						<option value=""></option>
							<option>Male</option>
							<option>Female</option>
							</select>
						<span className="errormsg">{this.state.validations.gender}</span>
					</div>
				</div>
				{this.state.adminusertype?<div className="d-flex row">
				<div className="col-md-6 flex-fill">
						<label htmlFor="idaddress1" className="label-custom" >Address<span className="star">*</span></label> 
						<input tabIndex="13" value={this.state.formObj.address1} id="idaddress1"  onChange={(e)=>{this.format("address1",e)}}   onBlur={()=>{ this.validate("address1")}} className="form-control form-control-sm" style={this.state.validations.address1 ===""?{}:{"borderColor":"red"}} type="text"  required /> 
						<span className="errormsg">{this.state.validations.address1}</span>
					</div>
				 	<div className="col-md-6 flex-fill">
					<label htmlFor="idstatus" className="label-custom" >Status<span className="star">*</span></label> 
						<select tabIndex="14" value={this.state.formObj.status} id="idstatus"  onChange={(e)=>{this.format("status",e)}}  onBlur={()=>{ this.validate("status")}}  style={this.state.validations.status===""?{}:{"borderColor":"red"}} type="text" className='form-control form-control-sm'> 
						<option value=""></option>
							<option>Active</option>
							<option>Inactive</option>
							</select>
					<span className="errormsg">{this.state.validations.status}</span>
					</div> 
					</div>:<div className="d-flex row">
				<div className="col-md-12 flex-fill">
						<label htmlFor="idaddress1" className="label-custom" >Address<span className="star">*</span></label> 
						<input tabIndex="13" value={this.state.formObj.address1} id="idaddress1"  onChange={(e)=>{this.format("address1",e)}}   onBlur={()=>{ this.validate("address1")}} className="form-control form-control-sm" style={this.state.validations.address1 ===""?{}:{"borderColor":"red"}} type="text"  required /> 
						<span className="errormsg">{this.state.validations.address1}</span>
					</div></div>}
				
				</div>
			</div>
			<div className="d-flex row">
			        <div className="col-md-12 flex-fill">
						<label htmlFor="idnotes" className="label-custom" >Notes</label> 
						<textarea tabIndex="14" value={this.state.formObj.notes} id="idnotes" rows="2" cols="20"  onChange={(e)=>{this.format("notes",e)}}  onBlur={()=>{ this.validate("notes")}}  style={this.state.validations.notes===""?{}:{"borderColor":"red"}} type="text" className='form-control form-control-sm' required /> 
						<span className="errormsg">{this.state.validations.notes}</span>
					</div>

			</div>

			<br/><br/>
				<div className="d-flex row justify-content-end">
					{this.state.deletebtn?
			    	<div className="p-2">
					<button type="button" className="btn btn-danger btn-md"   onClick={()=>{this.setState({show:true})}} >Delete</button>
			    	</div>:null}
					<div className="p-2">
					<button type="button" className="btn btn-success btn-md" onClick={()=>{this.clearform()}}>New</button>
					</div>
					{this.state.barcodebtn?
					<div className="p-2">
					<button type="button"  className="btn btn-success btn-md" onClick={()=>{this.printbarcode()}}>Print Barcode</button>
					</div>
					:null}
					<div className="p-2">
					<button type="button" className="btn btn-success btn-md" onClick={()=>{this.saveObj()}}>Save</button>
					</div>
					<div className="p-2">
					<button type="button" className="btn btn-warning btn-md" onClick={()=>{this.goToSearch()}}>Cancel</button>
					</div>	
				</div>
			<br/>
			</div>
			:null}
			<Modal size="md"   centered show={this.state.show} onHide={()=>{this.setState({show:false})}}>
            <ModalBody>
			<div className="d-flex justify-content-right">
             <div className="p-2">{this.state.errormessage}</div>
			</div>
			{this.state.confirmbtn?
			<div className="d-flex justify-content-end">
			<div className="p-2"><button className="btn btn-warning  btn-sm" onClick={()=>{this.deleteObj()}}>YES</button></div>
			<div className="p-2"><button className="btn btn-primary  btn-sm" onClick={()=>{this.setState({show:false})}}>NO</button></div>
			   </div>:
			  <div className="d-flex justify-content-end">
				  <div className="p-2"><button className="btn btn-warning btn-sm"  onClick={()=>{this.closeObjdata()}}>CLOSE</button></div>
				</div>}
			</ModalBody>
            </Modal>

			<Modal size="md"   centered show={this.state.befreshow} onHide={()=>{this.setState({befreshow:false})}}>
            <ModalBody>
			<div className="d-flex justify-content-right">
             <div className="p-2">{this.state.errormessage}</div>
			</div>
		
			  <div className="d-flex justify-content-end">
				  <div className="p-2"><button className="btn btn-success btn-sm" onClick={()=>{this.setState({befreshow:false})}}>
					  Ok</button> &nbsp; &nbsp;
					 <button className="btn btn-warning btn-sm"  onClick={()=>{this.setState({befreshow:false})}}>
					  Cancel</button>
				  </div>
				</div>
			</ModalBody>
            </Modal>
			</div>
		)
		}
	}

const mapToProps = ({ loggedUserState }) => ({ user: loggedUserState })
export default connect(mapToProps)(MembersForm);
