///////////////////////////////////////////////////////////////////////////////////////////////
// Warning : Property of Deemsoft Global Solutions LLC, It is illegal to make copy of this code
// Author : Gangaswamy Kempahonnaiah
///////////////////////////////////////////////////////////////////////////////////////////////
export const Users = {
	firstname : '', 
	lastname : '', 
	title : '', 
	designation : '', 
	phone : '', 
	email : '', 
	password : '', 
	confirmpwd : '',
	language : '', 
	currency : '', 
	timezone : '', 
	accountid : '',
	address1 : '',
	address2 :'',
	city : '',
	state : '',
	zip : '',
	institutionname : '', 
	emailverificationcode : '', 
	resetPasswordToken : '', 
	resetPasswordExpires : '', 
	token : '', 
	note : '', 
	type : '', 
	status : '', 
	id : '', 
	date_created : '', 
	date_updated : '', 
}