////////////////////////////////////////////////////////////////////////////////////////////////////
// Warning : Property of Deemsoft Global Solutions LLC, USA. It is illegal to make copy of this code
// Author : Gangaswamy Kempahonnaiah
////////////////////////////////////////////////////////////////////////////////////////////////////
import React from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { Button, Modal, ModalBody, ModalFooter } from 'react-bootstrap';
import { base_url } from '../constants/Constants';
import { Validators } from '../utils/Validators';
import {DateOnly} from '../utils/DateTime';
import { Formators } from '../utils/Formators';
import DatePicker from "react-datepicker";
import { Books_Catalog } from '../models/Books_Catalog';
import {Setting} from '../models/Setting';
import "react-datepicker/dist/react-datepicker.css";
import '../styles/Styles.css';
import flashMessage from '../utils/Flash';
import FlashMessage from 'react-flash-message';

	

class Books_CatalogForm extends React.Component {
	constructor(props){
		super(props);
		this.state = {
			objList : [],
			settings : Setting,
			showModal : false,
			saveuser : false,
			searchmessage:'',
			show:false,
			alertmsg:'',
			searchform:true,
			dateformat:'',
			disvalue:true,
			showSuccessMsg:false,
			deletebtn:"btn btn-danger btn-sm showbarcodebtn",
			index:'',
			classname:'btn btn-success btn-sm showbarcodebtn',
			bookform:false,
			showMessage:false,
			tempdata:[],
			endDate:new Date(),
			validations : {},
			formObj : Books_Catalog,
			accid:''
		}
		this.getObjList = this.getObjList.bind(this);
		
		
	}

	componentDidMount(){
		let tempObj = {};
		for (let key in Books_Catalog) { tempObj[key] = ""; }
		this.setState({validations:tempObj});
		let tempObjs = {};
		for (let key in Books_Catalog) { tempObjs[key] = ""; }
		this.setState({formObj:tempObjs});
		this.getObjList();
		this.getSettings();

		
	}
	printbarcode=()=>{
		var id2="books";	
		var temp = this.state.formObj.barcode  +" " +this.state.formObj.barcode;
		var text= temp.split(" ");
		window.open('/barcode?id1='+text+'&id2='+id2+'', "_blank");
		
	} 
	getSettings = () => {
        axios.get(base_url+'settings/list', this.props.user.header ).then(res => {
			if ( res.data.length > 0 ){
                for(var i=0;i<res.data.length;i++){
					this.setState({ settings:res.data[i]});	
                }	
			}
			if(this.state.settings.dateformats == ''){
				this.setState({dateformat:"dd/MM/yyyy"});
		    }else if(this.state.settings.dateformats !==''){
				this.setState({dateformat:this.state.settings.dateformats});
			}
		
			
        }).catch(function (error) { console.log(error); });
    }

	getObjList = () => {
		axios.get(base_url+'books_catalog/list',  this.props.user.header ).then(res => {
			if ( res.data.length > 0 ){
				this.setState({ objList:res.data});
				
			}
		}).catch(function (error) { console.log(error); });
	}
	saveObj = () => {
		if(this.state.formObj._id === ""){
			let tempo = '';
			for (let key in Books_Catalog) { this.validate(key);}
			let tempkey = this.state.validations;
			tempkey.callnumber="";
			this.setState({validations:tempkey});
			for (let key in this.state.validations) {tempo += this.state.validations[key];}
			if(tempo === ""){
		 		var text=this.state.formObj;
				 text.indianprice=this.state.formObj.indianprice + ".00";
		 		text.status="Active"
		 		text.barcode=this.state.formObj.accnumber;
		 		this.setState({formObj:text});
			 	axios.post(base_url+'books_catalog/save', this.state.formObj, this.props.user.header ).then(res => {
					this.setState({ objList:res.data,formObj:res.data.data,accid:res.data.data.accnumber});
					 this.setState({classname:'btn btn-success btn-sm showbarcodebtn',disvalue:false,alertmsg:'Your data is saved successfully'});
					 this.setState({showSuccessMsg: true}, ()=>{
						setTimeout(() => {
						this.setState({showSuccessMsg: false })
						}, 3000);
					});
					 this.getObjList();
				}).catch(function (error) { console.log(error); });
		   	}
			   
		}
		else{
			let temp='';
			for (let key in Books_Catalog) { this.validate(key);}
			let tempkey = this.state.validations;
			tempkey.accnumber="";
			tempkey.callnumber="";
			this.setState({validations:tempkey});
			for (let key in this.state.validations) {temp += this.state.validations[key];}
			var tempobj=this.state.formObj;
			this.setState({formObj:tempobj});
			
			if(temp == ''){
				axios.post(base_url+'books_catalog/save', this.state.formObj, this.props.user.header ).then(res => {
					this.setState({formObj:res.data.data,accid:res.data.data.accnumber});
					this.setState({classname:'btn btn-success btn-sm showbarcodebtn',disvalue:false,alertmsg:'Your data is updated successfully'});
					this.setState({showSuccessMsg: true}, ()=>{
						setTimeout(() => {
						this.setState({showSuccessMsg: false })
						}, 3000);
					});
					this.getObjList();
				}).catch(function (error) { console.log(error); });
		   	}
		}
	}
	addObj = () => { 
		let tempObj = {};
		for (let key in  Books_Catalog ) { 
		if( key == "podate" || key == "billdate" ){
			tempObj[key] = "2023-05-11";
		}else{
		tempObj[key] = "";
		}

		}
		this.setState({ formObj:tempObj});
		let tempObjs = {};
		for (let key in Books_Catalog) { tempObjs[key] = ""; }
		this.setState({validations:tempObjs});
		
		this.setState({disvalue:true,classname:'hidebarcodebtn',showSuccessMsg:false,deletebtn:'hidebarcodebtn',accid:''});	
	}
	editObj = (i) => { 
		let tempObj = {};
		for (let key in this.state.objList[i] ) { tempObj[key] = this.state.objList[i][key]; }
		this.setState({ formObj:tempObj, showModal:true});
	}
	deleteObj = ()=> { 
		
		let tempObj = this.state.formObj;
		if(tempObj.bookstatus=="Available"){
		this.setState({show:true});
		this.setState({index:tempObj});
	}
	else{
		this.setState({showMessage:true});
	}
	
		
	}
	conformDelete=()=>{
		var tempObj=this.state.index;
			tempObj.status = 'Inactive';
			axios.post(base_url+'books_catalog/save', tempObj , this.props.user.header ).then(res =>{
				this.setState({show:false});
				this.setState({alertmsg:'Your data is deleted successfully ',classname:'hidebarcodebtn',deletebtn:'hidebarcodebtn'});
				this.setState({showSuccessMsg: true}, ()=>{
					setTimeout(() => {
					this.setState({showSuccessMsg: false })
					}, 3000);
				});
				let tempObj = {};
				for (let key in  Books_Catalog ) { tempObj[key] = ""; }
				this.setState({ formObj:tempObj,disvalue:true});
		}).catch(function (error) { console.log(error); });

	}
	getDetails = (i,id) => { 
		let tempObjs = {};
		for (let key in Books_Catalog ) { tempObjs[key] = ""; }
		this.setState({validations:tempObjs});
		let tempObj = this.state.objList[i];
		this.setState({formObj:tempObj,accid:tempObj.accnumber});
		this.setState({showModal:false});
		this.setState({bookform:true,searchform:false,searchtable:false,disvalue:false,classname:'btn btn-success btn-sm showbarcodebtn',deletebtn:'btn btn-danger btn-sm showbarcodebtn'});
		this.setState({tempdata:this.state.objList});
	}		
	searchBook = (id,by) => {
		if(id != "" && id != undefined){
			let temp={search : id ,type: by}
			axios.post(base_url+'books_catalog/search1', temp, this.props.user.header )
		 	.then(res =>{
				if(res.data.length > 0){
					let tempObj = this.state.searchmessage;
					tempObj="";
					this.setState({searchmessage:tempObj});
					this.setState({objList:res.data,searchtable:true,showSuccessMsg:false});
				}else{
					let tempObj = this.state.searchmessage;
					tempObj="Book  details you entered is not match with our records";
					this.setState({searchmessage:tempObj,showSuccessMsg:false});
					let temarr = [];
					this.setState({objList:temarr,searchtable:false});
				}
			}).catch(function (error) { console.log(error); }); 
			this.renderObjlist();
		}else{
			let tempObj = this.state.searchmessage;
			tempObj="Please Enter ACC number or book title or author to search";
			let temarr = [];
			this.setState({searchmessage:tempObj});
			this.setState({objList:temarr,searchtable:false});
		
		}
	
	}
	goToSearch = () =>{
		let tempObjs = {};
		for (let key in Books_Catalog ) { tempObjs[key] = ""; }
		this.setState({validations:tempObjs});
		let tempObj = {};
		for (let key in Books_Catalog ) { tempObj[key] = ""; }
			this.setState({ formObj:tempObj,searchmessage:""});
			this.setState({bookform:false,searchform:true,searchtable:false});
	}
	searchType=(id,title,sname)=>{
		if(id !== '' && id !== undefined){
	   		this.searchBook(id,"accnumber");
		}else if(sname !== '' && sname !== undefined){
			var output = sname.trim();
			var input=output.substring(0, 1).toUpperCase() + output.substring(1);
			console.log(input);
	  		this.searchBook(input,"Name");
		}
		else if(title !== '' && title !== undefined){
			var outputs = title.trim();
			var inputs=outputs.substring(0, 1).toUpperCase() + outputs.substring(1);
			console.log(inputs);
			this.searchBook(inputs,"author");
	  	}
		else{
	  		let tempObj = this.state.searchmessage;
	  		tempObj="Please Enter Acc number or book title or author to search";
	  		let temarr = [];
	  		this.setState({searchmessage:tempObj});
	  		this.setState({objList:temarr,searchtable:false});
		}
	}
	setNewform = () =>{
		this.addObj();
		this.setState({bookform:true,searchform:false,searchtable:false,classname:'hidebarcodebtn'});
	}

	Verifyaccnumber=(accnumber)=>{
		if(accnumber !== ""){
			if(accnumber !== this.state.accid){
			var cardObj = {accnumber:this.state.formObj.accnumber};
			var tempObj=this.state.validations;
			axios.post(base_url+'books_catalog/accnumberverify', cardObj,this.props.user.header)        
				.then(res => {
				 if(res.data.msg === 'success'){   
					tempObj.accnumber='The ACC number you have entered is already associated with another book'; 
						this.setState({validations:tempObj});
				}               
				}).catch(function (error) { 
			
			}); 
		}	
	}	   
	}

	validate = (val) => {
		let tempObj = this.state.validations;
		switch(val){
			case "accnumber": tempObj.accnumber = Validators.name(this.state.formObj.accnumber);this.Verifyaccnumber(this.state.formObj.accnumber);break;			
			case "isbnnumber": tempObj.isbnnumber = Validators.name(this.state.formObj.isbnnumber); break;
			case "bookauthor": tempObj.bookauthor = Validators.name(this.state.formObj.bookauthor); break;
			case "booktitle": tempObj.booktitle = Validators.name(this.state.formObj.booktitle); break;
			case "publishername": tempObj.publishername = Validators.name(this.state.formObj.publishername); break;			
			case "indianprice": tempObj.indianprice = Validators.name(this.state.formObj.indianprice); break;
			case "booktype": tempObj.booktype = Validators.select(this.state.formObj.booktype); break;
			case "department": tempObj.department = Validators.select(this.state.formObj.department); break;
			case "subject": tempObj.subject = Validators.select(this.state.formObj.subject); break;
			case "bookstatus": tempObj.bookstatus = Validators.select(this.state.formObj.bookstatus); break;
			case "callnumber": tempObj.callnumber = Validators.phone(this.state.formObj.callnumber); break;
			case "trackcode": tempObj.trackcode =Validators.name(this.state.formObj.trackcode); break;		
		}
		
		this.setState({validations:tempObj});
	 } 
	 
	format = (val, e) => { 
		let tempObj = this.state.formObj;
		switch(val){
			case "accnumber": tempObj.accnumber = Formators.name(e.target.value); break;
			case "barcode": tempObj.barcode = Formators.name(e.target.value); break;
			case "isbnnumber": tempObj.isbnnumber = Formators.name(e.target.value); break;
			case "bookauthor": tempObj.bookauthor = Formators.firstname(e.target.value); break;
			case "booktitle": tempObj.booktitle = Formators.firstname(e.target.value); break;
			case "publishername": tempObj.publishername = Formators.firstname(e.target.value); break;
			case "publisherphone": tempObj.publisherphone = Formators.name(e.target.value); break;
			case "publishyear": tempObj.publishyear = Formators.name(e.target.value); break;
			case "publishagent": tempObj.publishagent = Formators.name(e.target.value); break;
			case "publishagentphone": tempObj.publishagentphone = Formators.name(e.target.value); break;
			case "edition": tempObj.edition = Formators.name(e.target.value); break;
			case "editionyear": tempObj.editionyear = Formators.name(e.target.value); break;
			case "preliminarypages": tempObj.preliminarypages = Formators.name(e.target.value); break;
			case "pages": tempObj.pages = Formators.getpages(e.target.value); break;
			case "indianprice": tempObj.indianprice = Formators.name(e.target.value); break;
			case "foreignprice": tempObj.foreignprice = Formators.name(e.target.value); break;
			case "currency": tempObj.currency = Formators.name(e.target.value); break;
			case "booktype": tempObj.booktype = Formators.name(e.target.value); break;
			case "department": tempObj.department = Formators.name(e.target.value); break;
			case "subject": tempObj.subject = Formators.name(e.target.value); break;
			case "callnumber": tempObj.callnumber = Formators.name(e.target.value); break;
			case "ponumber": tempObj.ponumber = Formators.name(e.target.value); break;
			case "podate": tempObj.podate = Formators.name(e.target.value); break;
			case "billnumber": tempObj.billnumber = Formators.name(e.target.value);break;
			case "billdate": tempObj.billdate = Formators.name(e.target.value);break;
			case "trackcode": tempObj.trackcode = Formators.name(e.target.value); break;
			case "bookstatus": tempObj.bookstatus = Formators.name(e.target.value); break;
			case "notes": tempObj.notes = Formators.name(e.target.value); break;
			case "vender": tempObj.vender = Formators.name(e.target.value); break;
		}
		this.setState({formObj:tempObj,showSuccessMsg:false});
		
	 }

	renderObjlist = () => {
		if( this.state.objList != undefined && this.state.objList.length > 0){
			return this.state.objList.map((item, index) =>{
				return (
					<tr key={index} id={index} onClick={()=>{this.getDetails(index,item)}}>
						<td>{item.accnumber}</td>
						<td>{item.booktitle}</td>
						<td>{item.bookauthor}</td>
						<td>{item.bookstatus}</td>
					</tr>
					)
			 }, this);
		}
	}

renderSubjectTypes = () => {
		if( this.state.settings.subjectTypes != undefined && this.state.settings.subjectTypes.length > 0){
			return this.state.settings.subjectTypes.map((item, index) =>{
				return (
					
					<option value={item.subject} key={index} id={index}>{item.subject}</option>
					)
			}, this);
		}
}

renderBookTypes = () => {
	if( this.state.settings.bookTypes != undefined  &&  this.state.settings.bookTypes.length > 0){
		return this.state.settings.bookTypes.map((item, index) =>{
			return (
			
				 <option value={item.booktype}  key={index} id={index} >{item.booktype}</option>
			)
		}, this);
	}
}
renderDepartmentTypes = () => {
	if( this.state.settings.departmentTypes != undefined && this.state.settings.departmentTypes.length > 0){
		return this.state.settings.departmentTypes.map((item, index) =>{
			return (
				<option value={item.department}  key={index} id={index} >{item.department}</option>	
				)
		}, this);
	}
}


	handleEndDate=(date)=>{
		this.setState({ endDate : date });
		var tempObj=this.state.formObj;
		tempObj.billdate=this.state.endDate;
		this.setState({formObj:tempObj});
	}
	changeEvent = (e) =>{
		this.setState({ endDate : e });
		var tempObj=this.state.formObj;
			tempObj.billdate=e ;
		this.setState({formObj:tempObj});
	 }
	 changeEvents = (e) =>{
		this.setState({ endDate : e });
		var tempObj=this.state.formObj;
			tempObj.podate=e ;
		this.setState({formObj:tempObj});
	 }
	render() {
		return (
			 <div className="shadow-sm p-3 mb-5 bg-white rounded">
				 <div><div className="d-flex row justify-content-center"><h3>Books Catalog</h3></div></div><br/>
				 {this.state.showSuccessMsg ?
                  <div className="d-flex row">
					  <div className="p-2 flex-fill">
                      <FlashMessage duration={3000} persistOnHover={true}>
                      {flashMessage(this.state.alertmsg, "Success")}
                      </FlashMessage>
					  </div>	
                  </div>
                  :null
                  }
				{this.state.searchform?
				<div className="d-flex row">
				  	<div className="p-2 flex-fill">
						<label htmlFor="idaccnumber" className="label-custom" >ACC No/Barcode</label> 
						<input   value={this.state.formObj.accnumber} required id="idaccnumber"  onChange={(e)=>{this.format("accnumber",e)}} onKeyPress={(e) => e.key === 'Enter' ? this.searchType(this.state.formObj.accnumber,this.state.formObj.booktitle,this.state.formObj.bookauthor): null} className="form-control form-control-sm"  type="text" required /> 
						<span className="errormsg">{this.state.searchmessage}</span>
					</div>	
					<div className="p-2 flex-fill">
						<label htmlFor="idbooktitle" className="label-custom" > Book Title</label> 
						<input value={this.state.formObj.booktitle} id="idbooktitle"  onChange={(e)=>{this.format("booktitle",e)}}  onKeyPress={(e) => e.key === 'Enter' ? this.searchType(this.state.formObj.accnumber,this.state.formObj.booktitle,this.state.formObj.bookauthor): null}  className="form-control form-control-sm" type="text"   required /> 
					</div>
					<div className="p-2 flex-fill">
						<label htmlFor="idbookauthor" className="label-custom" > Book Author</label> 
						<input value={this.state.formObj.bookauthor} id="idbookauthor" onChange={(e)=>{this.format("booktitle",e)}}  onKeyPress={(e) => e.key === 'Enter' ? this.searchType(this.state.formObj.accnumber,this.state.formObj.booktitle,this.state.formObj.bookauthor): null}  onChange={(e)=>{this.format("bookauthor",e)}}   className="form-control form-control-sm" type="text"  required  /> 	
					</div>
					<div className="p-2">
						<button type="button" className="btn btn-primary btn-sm" style={{marginTop:32}}  onClick={()=>{ this.searchType(this.state.formObj.accnumber,this.state.formObj.booktitle,this.state.formObj.bookauthor)}}>Search</button>
					</div>
					<div className="p-2">
						<button type="button" className="btn btn-success btn-sm" style={{marginTop:32}}  onClick={()=>{this.setNewform()}}>New</button>
					</div>
				</div>:null}
				{this.state.searchtable?
				<div className="d-flex row">
					<table className="table table-hover table-sm">
						<thead className="thead">
							<tr>
								<td>Acc No</td>
								<td>Book Title</td>
								<td>Book Author</td>
								<td>Book Status</td>
							</tr>
						</thead>
						<tbody>{this.renderObjlist()}</tbody>
					</table>
					
				</div>:null}
				{this.state.bookform?
				<div>
					<div className="d-flex row">
					<div className="p-2  flex-fill">
						<div className="d-flex row">	
							<div className="col-md-6 flex-fill">
								<label htmlFor="idaccnumber" className="label-custom" >ACC No/Barcode<span className="star">*</span></label> 
								<input  tabIndex="1" value={this.state.formObj.accnumber} required id="idaccnumber"  onChange={(e)=>{this.format("accnumber",e)}}  onBlur={()=>{ this.validate("accnumber")}} className="form-control form-control-sm" style={this.state.validations.accnumber===""?{}:{"borderColor":"red"}} type="text" required /> 
								<span className="errormsg">{this.state.validations.accnumber}</span>
							</div>
							<div className="col-md-6 flex-fill">
								<label htmlFor="idisbnnumber"  className="label-custom" >ISBN No<span className="star">*</span></label> 
								<input tabIndex="2" value={this.state.formObj.isbnnumber} id="idisbnnumber"  onChange={(e)=>{this.format("isbnnumber",e)}}   onBlur={()=>{ this.validate("isbnnumber")}} className="form-control form-control-sm" type="text"  style={this.state.validations.isbnnumber===""?{}:{"borderColor":"red"}} required /> 
								<span className="errormsg">{this.state.validations.isbnnumber}</span>
							</div>
						</div>	
						<div className="d-flex row">
						<div className="col-md-6 flex-fill">
							<label htmlFor="idindianprice" className="label-custom" >Indian Rs.<span className="star">*</span></label> 
							<input tabIndex="5" value={this.state.formObj.indianprice} id="idindianprice" onChange={(e)=>{this.format("indianprice",e)}}   onBlur={()=>{ this.validate("indianprice")}} className="form-control form-control-sm" type="text"  required style={this.state.validations.indianprice===""?{}:{"borderColor":"red"}} /> 
							<span className="errormsg">{this.state.validations.indianprice}</span>
					    </div>
						<div className="col-md-6 flex-fill">
							<label htmlFor="idforeignprice" className="label-custom" >FGN Price</label> 
							<input tabIndex="6" value={this.state.formObj.foreignprice} id="idforeignprice"  onChange={(e)=>{this.format("foreignprice",e)}}    className="form-control form-control-sm" type="text"   /> 
							<span className="errormsg">{this.state.validations.foreignprice}</span>
					    </div>
						</div>
						<div className="d-flex row">
							<div className="col-md-6 flex-fill">
								<label htmlFor="idcurrency" className="label-custom" >Currency</label> 
								<input tabIndex="9" value={this.state.formObj.currency} id="idcurrency" onChange={(e)=>{this.format("currency",e)}}   onBlur={()=>{ this.validate("currency")}} className="form-control form-control-sm" type="text"  /> 
								<span className="errormsg">{this.state.validations.currency}</span>
							</div>
							<div className="col-md-6 flex-fill">
								<label htmlFor="idtrackcode" className="label-custom" >Track Code<span className="star">*</span></label> 
								<input tabIndex="10" value={this.state.formObj.trackcode} id="idtrackcode"  onChange={(e)=>{this.format("trackcode",e)}}   onBlur={()=>{ this.validate("trackcode")}} className="form-control form-control-sm" type="text"  required  style={this.state.validations.trackcode===""?{}:{"borderColor":"red"}} /> 
								<span className="errormsg">{this.state.validations.trackcode}</span>
							</div>
						</div>
						<div className="d-flex row">
						<div className="col-md-4 flex-fill">
							<label htmlFor="idbillnumber" className="label-custom" >Bill No</label> 
							<input tabIndex="13" value={this.state.formObj.billnumber} id="idbillnumber"  onChange={(e)=>{this.format("billnumber",e)}}   onBlur={()=>{ this.validate("billnumber")}} className="form-control form-control-sm" type="text"  required  style={this.state.validations.billnumber===""?{}:{"borderColor":"red"}}/> 
							<span className="errormsg">{this.state.validations.billnumber}</span>
				    	</div>
					    <div className="col-md-3 flex-fill">
							<label htmlFor="idbilldate" className="label-custom" >Bill Date</label> <br/>
							<DateOnly 
								name="billdate"
								value={new Date(`${this.state.formObj.billdate.trim().split('-')[2]}-${this.state.formObj.billdate.trim().split('-')[1]}-${this.state.formObj.billdate.trim().split('-')[0]} GMT`)}
								onChange={(e)=>{this.changeEvent(e)}}
								dateformat={this.state.dateformat}
								class_name={ this.state.validations.billdate==""?"form-control form-control-sm":"form-control form-control-sm red-border" }
								onBlur={this.validate}
								tabindex={14}
							/>  
						<span className="errormsg">{this.state.validations.billdate}</span>
					    </div>
						<div className="col-md-5 flex-fill">
						<label htmlFor="idcallnumber" className="label-custom" >Cell Phone</label> 
						<input  tabIndex="15" value={this.state.formObj.callnumber} id="idcallnumber"   onChange={(e)=>{this.format("callnumber",e)}}  onBlur={()=>{ this.validate("callnumber")}} className="form-control form-control-sm" type="text"  required  style={this.state.validations.callnumber===""?{}:{"borderColor":"red"}} /> 
						<span className="errormsg">{this.state.validations.callnumber}</span>
					</div>
						</div>	
					<div className="d-flex row">
					<div className="col-md-6  flex-fill ">
						<label htmlFor="idbooktype" className="label-custom" >Book Type<span className="star">*</span></label> 
						<select tabIndex="18" value={this.state.formObj.booktype} className="form-control form-control-sm" id="idbooktype" onChange={(e)=>{this.format("booktype",e)}} onBlur={()=>{this.validate("booktype")}} style={this.state.validations.booktype===""?{}:{"borderColor":"red"}} > 
							<option value=""></option>
							{this.renderBookTypes()}	
						</select>
						<span className="errormsg">{this.state.validations.booktype}</span>
					</div>
					<div className="col-md-6  flex-fill">
						<label htmlFor="idbookstatus" className="label-custom" >Book Status<span className="star">*</span></label> 
						<select tabIndex="19" value={this.state.formObj.bookstatus} id="idbookstatus" onChange={(e)=>{this.format("bookstatus",e)}} onBlur={()=>{this.validate("bookstatus")}} className="form-control form-control-sm" style={this.state.validations.bookstatus===""?{}:{"borderColor":"red"}}>
						<option value=""></option>
						<option value="Available">Available</option>
						<option value="Not Available">Not Available</option>
						<option value="Damaged">Damaged</option>
						<option value="issued">Issued</option>
						</select>
						<span className="errormsg">{this.state.validations.bookstatus}</span>
					</div>
					</div>	
					<div className="d-flex row">
					<div className="col-md-12 flex-fill">
						<label htmlFor="idsubject" className="label-custom" >Subject<span className="star">*</span></label> 
						<select tabIndex="22" value={this.state.formObj.subject} id="idsubject"  onChange={(e)=>{this.format("subject",e)}}   onBlur={()=>{ this.validate("subject")}}  style={this.state.validations.subject===""?{}:{"borderColor":"red"}}className="form-control form-control-sm">
							<option value=""></option>
							{this.renderSubjectTypes()}
					
									</select>
						<span className="errormsg">{this.state.validations.subject}</span>
					</div>
				   </div>
					</div>


					<div className="p-2 flex-fill">
						<div className="d-flex row">
							<div className="col-md-6 flex-fill">
								<label htmlFor="idbooktitle" className="label-custom" > Book Title<span className="star">*</span></label> 
								<input tabIndex="3" value={this.state.formObj.booktitle} id="idbooktitle"  onChange={(e)=>{this.format("booktitle",e)}}   onBlur={()=>{ this.validate("booktitle")}} className="form-control form-control-sm" type="text"  style={this.state.validations.booktitle===""?{}:{"borderColor":"red"}} required /> 
								<span className="errormsg">{this.state.validations.booktitle}</span>
							</div>
							<div className="col-md-6 flex-fill">
								<label htmlFor="idbookauthor" className="label-custom" > Book Author<span className="star">*</span></label> 
								<input tabIndex="4" value={this.state.formObj.bookauthor} id="idbookauthor"  onChange={(e)=>{this.format("bookauthor",e)}}   onBlur={()=>{ this.validate("bookauthor")}} className="form-control form-control-sm" type="text"  required style={this.state.validations.bookauthor===""?{}:{"borderColor":"red"}} /> 
								<span className="errormsg">{this.state.validations.bookauthor}</span>
					       </div>
						</div>
						<div className="d-flex row">
						<div className="col-md-6 flex-fill">
							<label htmlFor="idpublishername" className="label-custom" >Publisher Name<span className="star">*</span></label> 
							<input tabIndex="7" value={this.state.formObj.publishername} id="idpublishername"   onChange={(e)=>{this.format("publishername",e)}}   onBlur={()=>{ this.validate("publishername")}} className="form-control form-control-sm" type="text"   style={this.state.validations.publishername===""?{}:{"borderColor":"red"}} required /> 
							<span className="errormsg">{this.state.validations.publishername}</span>
				    	</div>
						<div className="col-md-6 flex-fill">
							<label htmlFor="idvender" className="label-custom" >Vendor</label> 
							<input tabIndex="8" value={this.state.formObj.vender} id="idvender"  name="vender" onChange={(e)=>{this.format("vender",e)}}  className="form-control form-control-sm" type="text"   /> 
							<span className="errormsg">{this.state.validations.vender}</span>
							</div>
						</div>
						<div className="d-flex row">
							<div className="col-md-6 flex-fill">
								<label htmlFor="idedition" className="label-custom" >Edition</label> 
								<input tabIndex="11" value={this.state.formObj.edition} id="idedition" onChange={(e)=>{this.format("edition",e)}}    className="form-control form-control-sm" type="text"   /> 
								<span className="errormsg">{this.state.validations.edition}</span>
							</div>	
							<div className="col-md-6 flex-fill">
								<label htmlFor="ideditionyear" className="label-custom" >Edition Year</label> 
								<input tabIndex="12" value={this.state.formObj.editionyear} id="ideditionyear"  onChange={(e)=>{this.format("editionyear",e)}}   onBlur={()=>{ this.validate("editionyear")}} className="form-control form-control-sm" type="text"  required style={this.state.validations.editionyear===""?{}:{"borderColor":"red"}} /> 
								<span className="errormsg">{this.state.validations.editionyear}</span>
							</div>
						</div>
						<div className="d-flex row">
						<div className="col-md-6 flex-fill ">
							<label htmlFor="idpreliminarypages" className="label-custom" >Pre.Pages</label> 
							<input tabIndex="16" value={this.state.formObj.preliminarypages} id="idpreliminarypages"  onChange={(e)=>{this.format("preliminarypages",e)}}   onBlur={()=>{ this.validate("preliminarypages")}} className="form-control form-control-sm" type="text" required  style={this.state.validations.preliminarypages===""?{}:{"borderColor":"red"}} /> 
							<span className="errormsg">{this.state.validations.preliminarypages}</span>
						</div>	
						<div className="col-md-6 flex-fill">
							<label htmlFor="idpages" className="label-custom" >Pages</label> 
							<input tabIndex="17" value={this.state.formObj.pages} id="idpages"  onChange={(e)=>{this.format("pages",e)}}   onBlur={()=>{ this.validate("pages")}} className="form-control form-control-sm" type="text"  required  style={this.state.validations.pages===""?{}:{"borderColor":"red"}}/> 
							<span className="errormsg">{this.state.validations.pages}</span>
					   </div>
					   </div>
					   <div className="d-flex row">
					   <div className="col-md-8 flex-fill">
						<label htmlFor="idponumber" className="label-custom" >PO No</label> 
						<input tabIndex="20" value={this.state.formObj.ponumber} id="idponumber"  onChange={(e)=>{this.format("ponumber",e)}}   onBlur={()=>{ this.validate("ponumber")}} className="form-control form-control-sm" type="text"  style={this.state.validations.ponumber===""?{}:{"borderColor":"red"}} required /> 
						<span className="errormsg">{this.state.validations.ponumber}</span>
					   </div>
					   <div className="col-md-4 flex-fill">
						<label htmlFor="idpodate" className="label-custom" >PO Date</label> 
					    <br/><DateOnly 
							name="podate"
							selected={this.state.endDate}
							value={new Date(`${this.state.formObj.podate.trim().split('-')[2]}-${this.state.formObj.podate.trim().split('-')[1]}-${this.state.formObj.podate.trim().split('-')[0]} GMT`)}
							onChange={(e)=>{this.changeEvents(e)}}
							dateformat={this.state.dateformat}
							class_name={ this.state.validations.podate==""?"form-control form-control-sm":"form-control form-control-sm red-border" }
							onBlur={this.validate}
							tabindex={21}
						/><br/>  
					 	<span className="errormsg">{this.state.validations.podate}</span>
				    	</div>
					   </div>
					   <div className="d-flex row">
						<div className="col-md-12 flex-fill">
							<label htmlFor="iddepartment" className="label-custom" >Department<span className="star">*</span></label> 
							<select tabIndex="23" value={this.state.formObj.department} id="iddepartment"  onChange={(e)=>{this.format("department",e)}}   onBlur={()=>{ this.validate("department")}}  style={this.state.validations.department===""?{}:{"borderColor":"red"}}className="form-control form-control-sm">
							<option value=""></option>
					    	{this.renderDepartmentTypes()}
							</select>
							<span className="errormsg">{this.state.validations.department}</span>
						</div>
						</div>

					</div>
					</div>

				<div className="d-flex row">
					<div className="p-2 flex-fill">
						<label htmlFor="idnotes" className="label-custom" >Notes</label> 
						<textarea tabIndex="24"  value={this.state.formObj.notes} id="idnotes" rows="1"   onChange={(e)=>{this.format("notes",e)}} className="form-control "     />  
						<span className="errormsg">{this.state.validations.notes}</span>
					</div>
				</div>
				<div className="d-flex row justify-content-end">
				<div className="p-2">
					<button type="button" className={this.state.deletebtn} disabled={this.state.disvalue}  onClick={()=>{this.deleteObj()}} >Delete</button>
				</div>
					<div className="p-2">
					<button type="button" className={this.state.classname} onClick={()=>{this.printbarcode()}} >Print Barcode</button>
				</div>	
					<div className="p-2">
						<button type="button" className="btn btn-success btn-sm" onClick={()=>{this.addObj()}}>New</button>
					</div>
					<div className="p-2">
						<button type="button" className="btn btn-success btn-sm" onClick={()=>{this.saveObj()}}>Save</button>
					</div>
					
					
					<div className="p-2">
						<button type="button" className="btn btn-warning btn-sm"  onClick={()=>{this.goToSearch()}} >Cancel</button>
					</div>	
				</div>
				</div>:null}
				<br/>
				<Modal size="lg" show={this.state.showModal} onHide={()=>{this.setState({showModal:false,searchtable:false})}} >
					<Modal.Header closeButton>
						<Modal.Title>Book Catalog</Modal.Title>
					</Modal.Header>
					<ModalBody>
						<div className="d-flex row">
							<div className="p-2 flex-fill">
								<label htmlFor="idaccnumber" className="label-custom" >ACC No/Barcode<span className="star">*</span></label> 
								<input value={this.state.formObj.accnumber} required id="idaccnumber"  onChange={(e)=>{this.format("accnumber",e)}}   className="form-control form-control-sm"  type="text" required /> 
								<span className="errormsg">{this.state.searchmessage}</span>
							</div>
							
							<div className="p-2 flex-fill">
								<label htmlFor="idbooktitle" className="label-custom" > Book Title<span className="star">*</span></label> 
								<input value={this.state.formObj.booktitle} id="idbooktitle"  onChange={(e)=>{this.format("booktitle",e)}}    className="form-control form-control-sm" type="text"   required /> 
								<span className="errormsg">{this.state.searchmessage}</span>
							</div>
							<div className="p-2 flex-fill">
								<label htmlFor="idbookauthor" className="label-custom" > Book Author<span className="star">*</span></label> 
								<input value={this.state.formObj.bookauthor} id="idbookauthor"  onChange={(e)=>{this.format("bookauthor",e)}}    className="form-control form-control-sm" type="text"   /> 
								<span className="errormsg">{this.state.searchmessage}</span>
							</div>
							<div className="p-2">
								<button type="button" className="btn btn-primary btn-sm" style={{marginTop:32}}  onClick={()=>{ this.searchType(this.state.formObj.accnumber,this.state.formObj.booktitle,this.state.formObj.bookauthor)}}>Go</button>
							</div>
						</div>	
						{this.state.searchtable?<div className="d-flex row">
							<div className="p-2 flex-fill">
								<table className="table table-sm">
									<thead>
										<tr>
											<td>Acc No</td>
											<td>Book Title</td>
											<td>Book Author</td>
										</tr>
									</thead>
									<tbody>{this.renderObjlist()}</tbody>
								</table>
							</div>
						</div>:null}
					</ModalBody>
				<ModalFooter>
					<Button variant="warning" onClick={()=>{this.setState({showModal:false,searchtable:false})}}>CLOSE</Button>
				</ModalFooter>
			</Modal> 
			<Modal size="md" centered show={this.state.show} onHide={()=>{this.setState({show:false})}}>
            <ModalBody>
			<div className="d-flex justify-content-right">
             <div className="p-2">Are you sure want to delete the record!!.</div>
			</div>
			<div className="d-flex justify-content-end">
			<div className="p-2"><button className="btn btn-warning" onClick={()=>{this.conformDelete()}}>YES</button></div>
			<div className="p-2"><button className="btn btn-primary" onClick={()=>{this.setState({show:false})}}>NO</button></div>
			   </div>
			</ModalBody>
        </Modal>
		<Modal  size="md"  centered show={this.state.showMessage} onHide={()=>{this.setState({showMessage:false})}}>
		<ModalBody>
			<div className="d-flex justify-content-right">
             <div className="p-2">This book is not available !! you can not able to delete it</div>
			</div>
			<div className="d-flex justify-content-end">
			<div className="p-2"><button className="btn btn-warning" onClick={()=>{this.setState({showMessage:false})}}>Ok</button></div>
			
			   </div>
			</ModalBody>
        </Modal>
			
		</div>
			
		)
	}
}
		
		

const mapToProps = ({ loggedUserState }) => ({ user: loggedUserState })
export default connect(mapToProps)(Books_CatalogForm);
