export const Bills_Catalog = {
    firstname:'',
    lastname:'',
    cardid:'',
    billno:'',
    billid:'', 
    cashbookno:'',
    address:'',
    membershipid : '', 
    email:'',
    change:'',
    cellphone:'',
    billTypes:[],
    total:'',
    nettotal:'',
    discount:'',
    booktitle:'',
    paid:'',
    balance:'',
    date:'',
    _id:'',
    billstatus:'',
    paymenttype:'',
    selectcard:'',
    selectcash:'',
    id : '', 
	createdby : '', 
	updatedby : '', 
	date_created : '', 
	date_updated : '', 

}