////////////////////////////////////////////////////////////////////////////////////////////////////
// Warning : Property of Deemsoft Global Solutions LLC, USA. It is illegal to make copy of this code
// Author : Gangaswamy Kempahonnaiah
////////////////////////////////////////////////////////////////////////////////////////////////////
import React from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { FaPen, FaTimes } from 'react-icons/fa';
import { Button, Modal, ModalBody, ModalFooter } from 'react-bootstrap';
import { base_url } from '../constants/Constants';
import {Setting} from '../models/Setting';
import { Formators } from '../utils/Formators';
import { Validators } from '../utils/Validators';
import flashMessage from '../utils/Flash';
import FlashMessage from 'react-flash-message';
import '../styles/Styles.css';


class SmsSettingsForm extends React.Component {
constructor(props){
	super(props);
	this.state = {
		settings : Setting,
		index : -1,
		showModels : {smstemplate:false,emailtemplate:false}, 
		formObj:{template:'',templatecontent:'',templateno:'',emailtemplt:'',smsapproval:'',emailapproval:''},
		showSuccessMsg:false,
		message:'',
		show:false,
		index:-1,
		template:'',
		validations : {},
		showsucess:false,
		smstempobj:{template:'',templatecontent:''},
		emailtempobj:{templateno:'',emailtemplt:''}
		
	}
}
componentDidMount(){
var tempobj={};
this.setState({formObj:tempobj});
let tempObj = {};
this.setState({validations:tempObj});
this.getSettings();
}

getSettings = () => {
axios.get(base_url+'settings/list', this.props.user.header ).then(res => {
	if ( res.data.length >= 0 ){
		for(var i=0;i<res.data.length;i++){
			this.setState({ settings:res.data[i]});	
		}	
	}
	
}).catch(function (error) { console.log(error); });
}


saveSettings = () => {
    console.log(this.state.settings);
	axios.post(base_url+'settings/save',this.state.settings, this.props.user.header ).then(res => {
		if ( res.data.length > 0 ){
				this.setState({ settings:res.data[0],showsucess:true});	    
		} 
	}).catch(function (error) { console.log(error); });  

}

editObj = (index, model) => {
	console.log(model, index);
	this.showModel(model);
	var tempForm = {};
	switch(model){			
		case "smstemplate": tempForm = {template:this.state.settings.smstemplate[index].template,templatecontent:this.state.settings.smstemplate[index].templatecontent}; break;
		case "emailtemplate": tempForm = {templateno:this.state.settings.emailtemplate[index].templateno,emailtemplt:this.state.settings.emailtemplate[index].emailtemplt}; break;
		
	}
	console.log(tempForm);
	this.setState({formObj:tempForm, index:index,showSuccessMsg:false});

}
confirmDelete=(i,m)=>{
	this.setState({show:true,template:m,index:i});	
}
saveAutoSMSSending = (e) =>{
	console.log(e.target.checked);
	var temp=this.state.settings
	temp.autosmsservice=e.target.checked;
	this.setState({settings:temp});
	this.saveSettings();
}
saveAutoEmailSending = (e) =>{
	console.log(e.target.checked);
	var temp=this.state.settings
	temp.autoemailservice=e.target.checked;
	this.setState({settings:temp});
	this.saveSettings();
}

deleteObj = (index, model) => {
	var tempSettings = this.state.settings;
	switch(model){			
		case "smstemplate": tempSettings.smstemplate.splice(index, 1); break;
		case "emailtemplate": tempSettings.emailtemplate.splice(index, 1); break;
		
	}
	this.setState({settings:tempSettings,show:false});
	this.saveSettings();
}
saveObj = (model) => {
	var tempSettings = this.state.settings;
	console.log(this.state.index);
		switch(model){			
		case "smstemplate": if( this.state.index >= 0 ){
			tempSettings.smstemplate[this.state.index] = this.state.formObj;
		}else{
			console.log(this.state.settings);
			let tempo = '';
		for (let key in this.state.smstempobj) { this.validate(key);}
		for (let key in this.state.validations) {tempo += this.state.validations[key];}
		if(tempo === ""){
			if(this.state.formObj.template !== "" && this.state.formObj.template !== undefined && this.state.formObj.templatecontent !== "" && this.state.formObj.templatecontent !== undefined){
			tempSettings.smstemplate.push({template:this.state.formObj.template, templatecontent:this.state.formObj.templatecontent,smsapproval:"pending"});
			var templates={email:"info@deemsoft.com",content:this.state.formObj.templatecontent,id:this.state.formObj.template};
              axios.post(base_url+'sms/approvesmstemplates',templates, this.props.user.header ).then(res => {
					console.log(res);
				}).catch(function (error) { console.log(error); });
				this.closeModel();
			this.setState({ showSuccessMsg:true,showsucess:true});  
			this.saveSettings();
			}
		}
		}; break;
		case "emailtemplate": if( this.state.index >= 0 ){
			tempSettings.emailtemplate[this.state.index] = this.state.formObj;
		}else{
			let tempo = '';
			for (let key in this.state.emailtempobj) { this.validate(key);}
			for (let key in this.state.validations) {tempo += this.state.validations[key];}
			if(tempo === ""){
			if(this.state.formObj.templateno !== "" && this.state.formObj.templateno !== undefined && this.state.formObj.emailtemplt !== "" && this.state.formObj.emailtemplt !== undefined){
			tempSettings.emailtemplate.push({templateno:this.state.formObj.templateno, emailtemplt:this.state.formObj.emailtemplt, emailapproval:"pending"});
			var templates={email:"info@deemsoft.com",content:this.state.formObj.emailtemplt,id:this.state.formObj.templateno};
              axios.post(base_url+'sms/approveemailtemplates',templates, this.props.user.header ).then(res => {
					console.log(res);
				}).catch(function (error) { console.log(error); });
				this.closeModel();
			this.setState({ showSuccessMsg:true,showsucess:true}); 
			this.saveSettings();
			}
		}
		}; break;
	}
	this.setState({settings:tempSettings, index:-1});
	var tempobj={};
	this.setState({formObj:tempobj});
}
renderSMSTemplate = () => {
	if( this.state.settings.smstemplate != undefined && this.state.settings.smstemplate.length > 0){
		return this.state.settings.smstemplate.map((item, index) =>{
			return (
				<tr key={index} id={index}>
					<td>{item.template}</td>
					<td>{item.templatecontent}</td>
					<td>{item.smsapproval}
					<span>{/* <FaPen size="15px" color="blue" onClick={()=>{this.editObj(index, 'smstemplate')}}/> */}&nbsp;&nbsp;
					<FaTimes size="15px" color="red" onClick={() =>{this.confirmDelete(index, 'smstemplate')}} />&nbsp;&nbsp;</span></td>
				</tr>
				)
		}, this);
	}
}
renderEmailTemplate = () => {
	if( this.state.settings.emailtemplate != undefined && this.state.settings.emailtemplate.length > 0){
		return this.state.settings.emailtemplate.map((item, index) =>{
			return (
				<tr key={index} id={index}>
					<td>{item.templateno}</td>
					<td>{item.emailtemplt}</td>
					<td>{item.emailapproval}
					<span>{/* <FaPen size="15px" color="blue" onClick={()=>{this.editObj(index, 'emailtemplate')}}/> */}&nbsp;&nbsp;
					<FaTimes size="15px" color="red"  onClick={() =>{this.confirmDelete(index, 'emailtemplate')}}/>&nbsp;&nbsp;</span></td>
				</tr>
				)
		}, this);
	}
}
showModel = (model) =>{
	var tempModels = this.state.showModels;
	tempModels.smstemplate = false;
	tempModels.emailtemplate = false;
	this.setState({formObj:{}});
	let tempObj = {};
    this.setState({validations:tempObj});
	switch(model){			
		case "smstemplate": tempModels.smstemplate = true; break;
		case "emailtemplate": tempModels.emailtemplate = true; break;
	}
	this.setState({showModels:tempModels,showSuccessMsg:false});
}
format = (val, e) => { 
	let tempObj = this.state.formObj;
	switch(val){
		case "template": tempObj.template = Formators.name(e.target.value); break;
		case "templatecontent": tempObj.templatecontent = Formators.name(e.target.value); break;
		case "templateno": tempObj.templateno = Formators.name(e.target.value); break;
		case "emailtemplt": tempObj.emailtemplt = Formators.name(e.target.value); break;
	}
	this.setState({formObj:tempObj});
	console.log(this.state.formObj);
	
	
}
validate =(val) => {
	let tempObj = this.state.validations;
	switch(val){
		case "template": tempObj.template = Validators.name(this.state.formObj.template); this.verifyTempName(this.state.formObj.template);break;
		case "templatecontent": tempObj.templatecontent = Validators.name(this.state.formObj.templatecontent); break;
		case "templateno": tempObj.templateno = Validators.name(this.state.formObj.templateno); this.verifyTempemail(this.state.formObj.templateno); break;
		case "emailtemplt": tempObj.emailtemplt = Validators.name(this.state.formObj.emailtemplt); break;

	}
	this.setState({validations:tempObj});
}
verifyTempName = (tempname) =>{
	if(tempname !== ""){
		var smsObj = {template:this.state.formObj.template};
		var tempObj=this.state.validations;
		axios.post(base_url+'settings/smsTemplateverify', smsObj,this.props.user.header)        
		.then(res => {
			console.log(res);
			if(res.data.msg === 'success'){   
				tempObj.template='The template name you have entered is already There'; 
				this.setState({validations:tempObj});
			}               
		}).catch(function (error) { 
		console.log(error);        
		}); 
	}      
}
verifyTempemail = (tempname) =>{
	if(tempname !== ""){
		var emailObj = {template:this.state.formObj.templateno};
		var tempObj=this.state.validations;
		axios.post(base_url+'settings/emailTemplateverify', emailObj,this.props.user.header)        
		.then(res => {
			console.log(res);
			if(res.data.msg === 'success'){   
				tempObj.templateno='The template name you have entered is already There'; 
				this.setState({validations:tempObj});
			}               
		}).catch(function (error) { 
		console.log(error);        
		}); 
	}      
}
closeModel = () =>{
	var tempModels = this.state.showModels;
	tempModels.smstemplate = false;
	tempModels.emailtemplate = false;
	this.setState({showModels:tempModels,index:-1});
}
 getAppoval = () =>{
	var templates={email:"deemsoft5@gmail.com",content:"hello"};
	axios.post(base_url+'sms/approvetemplates',templates, this.props.user.header ).then(res => {
		console.log(res);
	}).catch(function (error) { console.log(error); });
	}
		
render() {

	return (
		<div>
				{this.state.showSuccessMsg ?
				<div className="p-2">
					<FlashMessage duration={3000} persistOnHover={true}>
					{flashMessage('Template Saved Successfully', 'Success')}
					</FlashMessage>	
				</div>
				:null
				}
			<div className="d-flex flex-column">
				<div align="center"><br/>
				<div className="form-check">
					<input type="checkbox" onChange={(e)=>{this.saveAutoSMSSending(e)}} checked={this.state.settings.autosmsservice} className="form-check-input" id="exampleCheck1"/>
					<label className="form-check-label" htmlFor="exampleCheck1">Enabel Auto Sending SMS for Overdues Books</label>
				</div>
				</div>
					<div className="p-3 flex-fill">
						<p className="settingheader">SMS Template Details</p>
						<table className="table table-bordered table-sm">
							<thead className="thead">
								<tr>
									<td>Template Name</td>
									<td>Template Content</td>
									<td>Approval</td>
								</tr>
							</thead>
							<tbody>
								{this.renderSMSTemplate()}
							</tbody> 
						</table>
						<div className="float-right">
							<button className="btn btn-primary btn-sm" onClick={()=>{this.showModel('smstemplate')}} >+ Add</button>
						</div>
					</div>
					<div align="center"><br/>
					<div className="form-check">
						<input type="checkbox" onChange={(e)=>{this.saveAutoEmailSending(e)}} checked={this.state.settings.autoemailservice} className="form-check-input" id="exampleCheck2"/>
						<label className="form-check-label" htmlFor="exampleCheck2">Enabel Auto Sending Emails for Overdues Books</label>
					</div>
					</div>
					<div className="p-3 flex-fill">
						<p className="settingheader">Email Template Details</p>
						<table className="table table-bordered table-sm">
							<thead className="thead">
								<tr>
									<td>Template Name</td>
									<td>Template Content</td>
									<td>Approval</td>
								</tr>
							</thead>
							<tbody>
							{this.renderEmailTemplate()}
							</tbody> 
						</table>
						<div className="float-right">
							<button className="btn btn-primary btn-sm" onClick={()=>{this.showModel('emailtemplate')}}>+ Add</button>
						</div>
					</div>
				</div>
				<Modal size="md"show={this.state.showModels.smstemplate} onHide={()=>{this.closeModel()}}>
			<Modal.Header closeButton>
				<Modal.Title>SMS TEMPLATE</Modal.Title>
			</Modal.Header>
		<ModalBody>
			<div className="d-flex row">
				<div className="p-2 flex-fill">
					<label htmlFor="idtemplate" className="label-custom" >Template Name</label> 		
					<input value={this.state.formObj.template} id="idtemplate"  onChange={(e)=>{this.format("template",e)}}  onBlur={()=>{this.validate("template")}} className="form-control form-control-sm"  type="text" className='form-control form-control-sm' required /> 
					<span className="errormsg">{this.state.validations.template}</span>
				</div>
				</div>
				<div className="d-flex row">
				<div className="p-2 flex-fill">
					<label htmlFor="idtemplatecontent" className="label-custom" >Template Content</label> 		
					<textarea value={this.state.formObj.templatecontent} id="idtemplatecontent"  onChange={(e)=>{this.format("templatecontent",e)}} onBlur={()=>{this.validate("templatecontent")}}  className="form-control form-control-sm"  type="text" className='form-control form-control-sm' required /> 
					<span className="errormsg">{this.state.validations.templatecontent}</span>
				</div>
			</div>
		</ModalBody>
		<ModalFooter>
			<Button variant="warning" onClick={()=>{this.closeModel()}}>CLOSE</Button>
			<Button variant="primary" onClick={()=>{this.saveObj('smstemplate')}}>SAVE & GET APPROVAL</Button>
		</ModalFooter>
	</Modal>
	<Modal size="md"show={this.state.showModels.emailtemplate} onHide={()=>{this.closeModel()}}>
			<Modal.Header closeButton>
				<Modal.Title>EMAIL TEMPLATE</Modal.Title>
			</Modal.Header>
		<ModalBody>
			<div className="d-flex row">
				<div className="p-2 flex-fill">
					<label htmlFor="idtemplateno" className="label-custom" >Template Name</label> 		
					<input value={this.state.formObj.templateno} id="idtemplateno"  onChange={(e)=>{this.format("templateno",e)}}  onBlur={()=>{this.validate("templateno")}}   className="form-control form-control-sm"  type="text" className='form-control form-control-sm' required />  
					<span className="errormsg">{this.state.validations.templateno}</span>
				</div>
				</div>
				<div className="d-flex row">
				<div className="p-2 flex-fill">
					<label htmlFor="idemailtemplt" className="label-custom" >Template Content</label> 		
					<textarea value={this.state.formObj.emailtemplt} id="idemailtemplt"  onChange={(e)=>{this.format("emailtemplt",e)}}   onBlur={()=>{this.validate("emailtemplt")}} className="form-control form-control-sm"  type="text" className='form-control form-control-sm' required /> 
					<span className="errormsg">{this.state.validations.emailtemplt}</span>
				</div>
			</div>
		</ModalBody>
		<ModalFooter>
			<Button variant="warning" onClick={()=>{this.closeModel()}}>CLOSE</Button>
			<Button variant="primary" onClick={()=>{this.saveObj('emailtemplate')}}>SAVE & GET APPROVAL</Button>
		</ModalFooter>
	</Modal>
	<Modal size="md" centered show={this.state.show} onHide={()=>{this.setState({show:false})}}>
		<ModalBody>
		<div className="d-flex justify-content-right">
			<div className="p-2">Are You Sure ?? Want to Delete the Template !!.</div>
		</div>
		<div className="d-flex justify-content-end">
		<div className="p-2"><button className="btn btn-warning" onClick={()=>{this.deleteObj(this.state.index, this.state.template)}}>YES</button></div>
		<div className="p-2"><button className="btn btn-primary" onClick={()=>{this.setState({show:false})}}>NO</button></div>
			</div>
		</ModalBody>
	</Modal>
	<Modal size="md" centered show={this.state.showsucess} onHide={()=>{this.setState({showsucess:false})}}>
		<ModalBody>
		<div className="d-flex justify-content-right">
			<div className="p-2">Thank You!! Your Template is sent for verification.</div>
		</div>
		<div className="d-flex justify-content-end">
		<div className="p-2"><button className="btn btn-primary" onClick={()=>{this.setState({showsucess:false})}}>Close</button></div>
			</div>
		</ModalBody>
	</Modal>

	
		</div>
		)
	}
}

const mapToProps = ({ loggedUserState }) => ({ user: loggedUserState })
export default connect(mapToProps)(SmsSettingsForm);

		
