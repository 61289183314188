////////////////////////////////////////////////////////////////////////////////////////////////////
// Warning : Property of Deemsoft Global Solutions LLC, USA. It is illegal to make copy of this code
// Author : Gangaswamy Kempahonnaiah
////////////////////////////////////////////////////////////////////////////////////////////////////
import React from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { Button, Modal, ModalBody, ModalFooter } from 'react-bootstrap';
import { base_url } from '../constants/Constants';
import { Formators } from '../utils/Formators';
import {ReactTabulator} from 'react-tabulator';
import {Bills_Catalog } from '../models/Bill_Catalog'
import 'react-tabulator/lib/styles.css';
import { PdfDoc} from '../utils/BillReportPdf';
import pdfMake from 'pdfmake/build/pdfmake';
import { Validators } from '../utils/Validators';
import vfsFonts from 'pdfmake/build/vfs_fonts';
import "react-tabulator/lib/css/bootstrap/tabulator_bootstrap.min.css";
import { FaPen, FaFileExcel, FaFilePdf,FaBars,FaFilter, FaTimes, FaSmileBeam } from 'react-icons/fa';
import '../styles/Styles.css';
import flashMessage from '../utils/Flash';
import FlashMessage from 'react-flash-message';
import { CSVLink, CSVDownload } from "react-csv";
import {Setting} from '../models/Setting';
import moment from 'moment'

const editableColumns = [
   
    {
        title: "Bill No",
        field: "billid",
        visible:false,
        headerFilterPlaceholder:" ",
    },
    {
        title: "Bill Date",
        field: "date",
        headerFilterPlaceholder:" ",
    },
    {
        title: "Bill Type",
        field: "billType",
        headerFilterPlaceholder:" ",
    },
    {
        title: "Name",
        field: "firstname",
        headerFilterPlaceholder:" ",
    },
    {
        title: "Amount",
        field: "total",
        align:"right",
        headerFilterPlaceholder:" ",
    },
    {
        title: "Discount",
        align:"right",
        field: "discount",
        headerFilterPlaceholder:" ",
    },
    {
        title: "Total",
        field: "nettotal",
        align:"right",
        headerFilterPlaceholder:" ",
    },
    {
        title: "Balance",
        field: "balance",
        align:"right",
        headerFilterPlaceholder:" ",
    },
    {
        title: "Bill Status",
        field: "billstatus",
        headerFilterPlaceholder:" ",
    
     },
     
];
class Bill_CatalogForm extends React.Component {
    constructor(props){
        super(props);
            this.state = {
            showbillform:false,
            formObj:Bills_Catalog,
            cashtype:false,
            cardtype:false,
            searchtable:false,
            totallist:[],
            objList:[],
            settings:Setting,
            searchvalue:'',
            paidbtn:false,
            showModel:false,
            discountbtn:false,
            alertmsg:'',
            messageType:'',
            showSuccessMsg:false,
            savebutton:"btn btn-success btn-sm savedisplay",
            originalTabColums:[],
            filteredTabColums:editableColumns,
            defaultcolumns:[],
            tabdata:[],
            namebtn:false,
            billbtn:false,
            inputbox:false,
            addbtn:'btn btn-sm btn-primary hidebtn',
            showdatamodel:true,
            billObj:{billtype:'',booktitle:'',fine:'',subtotal:'',totaldays:''},
            billlist:[],
            finallist:[],
            imageheader:'data:image/png;base64,',
            validations:{},
            printbtn:true,
            disablesave:true,
            dateformat:""
                
        }
     
    }
 
    componentDidMount(){
        axios.get(base_url+'settings/list', this.props.user.header ).then(res => {
            if ( res.data.length > 0 ){
                for(var i=0;i<res.data.length;i++){
                    this.setState({ settings:res.data[i]});	
                }	
            }
        }).catch(function (error) { console.log(error); });
        axios.get(base_url+'bills/getbymaxid', this.props.user.header ).then(res => {
            if ( res.data.length > 0 ){
                console.log(res.data[0].cashbookno);
            }
        }).catch(function (error) { console.log(error); });
        let tempObj = {};
	 	for (let key in this.state.billObj) { tempObj[key] = ""; }
		this.setState({validations:tempObj});
        var temp={};
        this.setState({billObj:temp});
        var temp1={};
        this.setState({formObj:temp1});
        this.getBillList();
        var imageData=this.state.imageheader;
		axios.post(base_url+'file/b64download',{filename:'header.png'},this.props.user.header)
	.then(res=>{
	imageData += res.data.data;
	this.setState({imageheader:imageData});
	}).catch(function (error) { console.log(error); }); 
  
    }

    getMaxCashbookNum =()=>{
        var num="0";
        axios.get(base_url+'bills/getbymaxid', this.props.user.header ).then(res => {
            if ( res.data.length > 0 ){
                console.log(res.data);
                num=res.data[0].cashbookno;
            }else{
                num = 0;
            }
            num=Number(num)+1;
            return  {status: true,id: num};
        }).catch(function (error) { console.log(error); });
        
        
    }

    addToTab = (e, item, index) => {
        var list1 = this.state.originalTabColums;
        if(e.target.checked){           
            list1[index].status = true;     
        }else{  
            list1[index].status = false;        
        }
        this.setState({originalTabColums:list1});
        var tempArray = [];
        for (var key in list1) { 
            if(list1[key].status){
                if(list1[key].title=='TOTAL' || list1[key].title=='DISCOUNT' || list1[key].title=='NETTOTAL' || list1[key].title=='PAID' || list1[key].title=='BALANCE'){
                     tempArray.push({ id:list1[key].id, headerFilterPlaceholder:" ",
                    headerFilter: "input",title: list1[key].title, field: list1[key].field, status:list1[key].status,align:"right", bottomCalc:"sum", bottomCalcParams:{precision:2},});
                }else{
                    tempArray.push({ id:list1[key].id, headerFilterPlaceholder:" ",
                    headerFilter: "input",title: list1[key].title, field: list1[key].field, status:list1[key].status});
                }
              
            }                   
        }
        console.log(tempArray);
      this.setState({filteredTabColums:tempArray});     
    }

    loadAllTabFields(){
        var newTabColums = [];
        var tempArray1 = [];
        var selectedFields =["billid","date", "firstname",,"total","discount","nettotal","balance","billstatus"];
        var keys = Object.keys(this.state.totallist[0]);
            keys=["billid","date", "firstname",,"total","discount","nettotal","balance", "billstatus", "address", "lastname", "cardid", "cellphone", "booktitle", "email", "membershipid", "id", "date_created","billTypes", "date_updated", "paid", "change", "status", "billno", "paymenttype"];
        var i = 0;
        keys.forEach(function(key){ 
            i = Number(i)+1;            
            if(selectedFields.includes(key)){
                if(key=='total' || key=='discount' || key=='nettotal' || key=='paid' || key=='balance'){
                    newTabColums.push({ id:i,headerFilterPlaceholder:" ",
                    headerFilter: "input", title: key.toUpperCase(), field: key, status:true, align:"right", bottomCalc:"sum", bottomCalcParams:{precision:2}},);
                    tempArray1.push({ id:i,headerFilterPlaceholder:" ",
                    headerFilter: "input", title: key.toUpperCase(), field: key, status:true, align:"right", bottomCalc:"sum", bottomCalcParams:{precision:2}},);
                }else{
                    newTabColums.push({ id:i, headerFilterPlaceholder:" ",
                    headerFilter: "input",title: key.toUpperCase(), field: key, status:true},);
                    tempArray1.push({ id:i, headerFilterPlaceholder:" ",
                    headerFilter: "input",title: key.toUpperCase(), field: key, status:true},);
                }
            }
            else{
                if(key=='total' || key=='discount' || key=='nettotal' || key=='paid' || key=='balance'){
                    newTabColums.push({ id:i, headerFilterPlaceholder:" ",
                    headerFilter: "input",title: key.toUpperCase(), field: key, status:false, align:"right", bottomCalc:"sum", bottomCalcParams:{precision:2}},);
                }else{
                    newTabColums.push({ id:i, headerFilterPlaceholder:" ",
                    headerFilter: "input",title: key.toUpperCase(), field: key, status:false},);
                }
            }           
        });
        newTabColums.sort((a,b) => (a.id > b.id) ? 1 : ((b.id > a.id) ? -1 : 0));
        tempArray1.sort((a,b) => (a.id > b.id) ? 1 : ((b.id > a.id) ? -1 : 0));
        console.log(tempArray1);
        this.setState( {originalTabColums:newTabColums, filteredTabColums:tempArray1 });
    }

    getprint = (email) =>{
        var dateformat="";
        if(this.state.settings.dateformats !== "" && this.state.settings.dateformats !== undefined){
            dateformat=this.state.settings.dateformats.toUpperCase();
            console.log(dateformat);
          }else{
              dateformat='DD/MM/YYYY';
          }
          let outdate= moment(this.state.formObj.date, 'MM-DD-YYYY').format(dateformat);
        var data={
            name:this.state.formObj.firstname,
            address:this.state.formObj.address,
            Phone:this.state.formObj.cellphone,
            Date:outdate,
            subtotal:this.state.formObj.total,
            total:this.state.formObj.nettotal,
            balance:this.state.formObj.balance,
            discount:this.state.formObj.discount,
            paid:this.state.formObj.paid,
            BillNO:this.state.formObj.billno,
            billid:this.state.formObj.billid,
        }
        var itemslist = [[
            { text: 'SN#', style: 'tableHeader' },
            { text: 'Bill Type', style: 'tableHeader' }, 				
            { text: 'Price', style: 'tableHeader' },       	
            { text: 'Total Days', style: 'tableHeader' },
            { text: 'Subtotal', style: 'tableHeader' },				
        ]];
        for(var i in this.state.billlist) {                    
            var slno = Number(i) + 1;               
            itemslist.push(
                [
                {text:''+slno+'', style:'header2' },
                { text: ''+this.state.billlist[i].billtype+'' , style:'header2'},						
                { text: ''+Number(this.state.billlist[i].fine).toFixed(2)+'' , alignment:'right',style:'header2'},
                { text: ''+this.state.billlist[i].totaldays+'', alignment:'right' , style:'header2'}, 
                { text: ''+Number(this.state.billlist[i].subtotal).toFixed(2)+'', alignment:'right' , style:'header2'}
            ]);
        }
        itemslist.push([
            { colSpan: 3, text: '', border: [false, false, false, false] }, 
            { text: 'table' },
            { text: 'table' },    	        
            { text: 'Subtotal', style:'header2',alignment:'right',bold:true}, 
            { text:  ''+data.subtotal+'', alignment:'right', style:'header2' ,bold:true}
        ]);
        itemslist.push([
            { colSpan: 3, text: '', border: [false, false, false, false]  }, 
            { text: 'table' }, 
            { text: 'table' }, 	
            { text: 'Discount', style:'header2',alignment:'right',bold:true }, 
            { text: ''+data.discount+'', alignment:'right', style:'header2',bold:true }
        ]);
        itemslist.push([
            { colSpan: 3, text: '', border: [false, false, false, false]  }, 
            { text: 'table' }, 
            { text: 'table' }, 
            
            { text: 'Net Total', style:'header2',alignment:'right',bold:true }, 
            { text: ''+data.total+'', alignment:'right', style:'header2' ,bold:true}
        ]);
        itemslist.push([
            { colSpan: 3, text: '', border: [false, false, false, false] }, 
            { text: 'table' }, 
            { text: 'table' }, 
            { text: 'Paid',style:'header2',alignment:'right', bold:true },  
            { text: ''+data.paid+'', alignment:'right', bold:true }
        ]);
        itemslist.push([
            { colSpan: 3, text: '', border: [false, false, false, false] }, 
            { text: 'table' }, 
            { text: 'table' }, 
            { text: 'Balance' ,style:'header2',alignment:'right',bold:true}, 
            { text: ''+data.balance+'', alignment:'right', bold:true }
        ]);	
        var docDefinition = {
            pageOrientation: 'page',
            content:[ 
                {
                    image: this.state.imageheader,
                    width: 500,
                    height:70,
                    alignment:'center'
                },         
            {text:'RECEPIT',alignment:'center', fontSize:16},
                { text:'\n\n'},
                {table: {
                    widths: [ 150,50,160,150],
                    body: [
                        [{ text: 'Name: '+data.name+'',  style:'header2',border:[false, false, false, false]},{text: '',border:[false, false, false, false]},{text: '', border:[false, false, false, false]},{text: 'Bill Id:'+" "+ +data.billid+'', style:'header2',border:[false, false, false, false]}],
                        [{ text: 'Phone: '+data.Phone+'',  style:'header2',border:[false, false, false, false]},{text: '',border:[false, false, false, false]},{text: '', border:[false, false, false, false]},{text: 'Bill Date:  ' +data.Date+'', style:'header2',border:[false, false, false, false]}],
                        [{ text: 'Address:'+data.address+'',  style:'header2',border:[false, false, false, false]},{text: '',border:[false, false, false, false]},{text: '', border:[false, false, false, false]},{text: '',border:[false, false, false, false]}],
                    ]
                }
            },
            { text:'\n\n'},
            { text: '', style:'header' },
            { table:{
              headerRows: 1,
              widths: [ 30, 180, 75,80, 100,70],
              body: itemslist
            }
        },
        { text:'\n\n'},
        { text: '', style:'header' },
        { table:{
          headerRows: 1,
          widths: [500],
          body:  [ 
			[{ text: 'Librarian Signature', alignment:'right', border:[false, false, false, false]} ],
			
		  ]
        },
                
                layout: {
                    hLineWidth: function (i, node) {
                    return  1;
                    },
                    vLineWidth: function (i, node) {
                    return  1;
                    },
                    hLineColor: function (i, node) {
                    return  '#C0C0C0';
                    },
                    vLineColor: function (i, node) {
                    return  '#C0C0C0';
                    },
                }
            },],
            styles:{
                header:{fontSize:16, bold:true, margin:[0, 10, 0, 10], alignment:'center'},
                header1:{fontSize:10, bold:true, margin:[0, 10, 0, 10]},
                header2:{fontSize:12},
                header3:{fontSize:12, margin:[0, 10, 0, 10], alignment:'right'},
                tableHeader:{fillColor:'#C0C0C0', fontSize:13,bold:true,color:'white'}
            }
        };
        const {vfs} = vfsFonts.pdfMake;
        pdfMake.vfs = vfs;
        if(email=="print"){
        pdfMake.createPdf(docDefinition).print(); 
        }
        else{
        var filename = "billreport.pdf"
        const pdfDocGenerator = pdfMake.createPdf(docDefinition).getBase64(function(encodedString) {
        axios.post(base_url+'sms/sendattachemail',{email:email, file:filename, pdf:encodedString}).then(res => {
            });
        
        });
    }

}

    sendMail=()=>{
        this.setState({inputbox:true});
        this.setState({showSuccessMsg:false});
    }
    Sendpdf=()=>{
        this.getprint(this.state.formObj.email);
        this.setState({inputbox:false});
        this.setState({showSuccessMsg:true,alertmsg:'Bill report is sent to your email.', messageType:"Success"});
          
    }
    changeSearching=(e)=>{
        var output=	e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1); 
        this.setState({searchvalue:output});
     }

    SearchDatalist=()=>{
        this.SearchData(this.state.searchvalue,"Name");
    }
   
    SearchData=(id,by)=>{
        if(id != "" && id != undefined){
            let temp={search : id ,type: by}
            axios.post(base_url+'bills/search', temp, this.props.user.header )
            .then(res =>{
            if(res.data.length>0){
                this.setState({totallist:res.data});
                this.setState({finallist:res.data});
            
            }
            
            }).catch(function (error) { console.log(error); }); 
        
        }
        else{
            this.getBillList();
        }
    }

    getBillList = () =>{
        axios.get(base_url+'bills/list',  this.props.user.header ).then(res => {
            if ( res.data.length > 0 ){
            var temp=res.data;
            this.setState({totallist:res.data});
            var temparr=[];
            var dateformat='';
            if(this.state.settings.dateformats !== "" && this.state.settings.dateformats !== undefined){
              dateformat=this.state.settings.dateformats.toUpperCase();
              this.setState({dateformat:dateformat});
            }else{
                dateformat='DD/MM/YYYY';
                this.setState({dateformat:dateformat});
            }
             for(var i=0;i<= temp.length-1;i++ ){
                let inDate = temp[i].date_created,
                upDate = temp[i].date_updated,
                entrydate = temp[i].date,
                inFormat = 'YYYY-MM-DD[T]HH:mm:ss.SSS[Z]Z',
                outDate = moment(inDate, inFormat).format(dateformat);
                let outupDate = moment(upDate, inFormat).format(dateformat);
                let cdoutDate = moment(entrydate, 'MM-DD-YYYY').format(dateformat);
                  temp[i].date_created=outDate;
                  temp[i].date_updated=outupDate;
                  temp[i].date=cdoutDate;
                  temparr.push(temp[i]);
             }  
             this.setState({finallist:temparr});
             this.loadAllTabFields();  
            }
        }).catch(function (error) { console.log(error); });

    }

    rowClick=(e,row)=>{
        var data = row.getData();
        if(data.paymenttype == "cash"){
            this.setState({cashtype:true,cardtype:false});
        }
        else if(data.paymenttype=="card"){
            this.setState({cashtype:false,cardtype:true});
        }
        this.setState({formObj:data});
        if(this.state.formObj.billstatus=="paid"){
            this.setState({paidbtn:true,printbtn:false,savebutton:"savedisplay",btndisabled:true,discountbtn:true});

        }

        else if(this.state.formObj.billstatus=="pending" && this.state.formObj.discount > 0.00){
            this.setState({paidbtn:false,discountbtn:true,billbtn:true,printbtn:true,savebutton:"btn btn-success btn-sm",btndisabled:true,disablesave:true});
        }
        else if(this.state.formObj.billstatus=="pending" && this.state.formObj.discount == "0.00"){
            this.setState({paidbtn:false,discountbtn:false,billbtn:false,printbtn:true,savebutton:"btn btn-success btn-sm",btndisabled:true,disablesave:true});
        }
        var temp=this.state.formObj;
        var now = new Date();
        var month = (now.getMonth() + 1);               
        var day = now.getDate();
        if (month < 10) 
            month = "0" + month;
        if (day < 10) 
            day = "0" + day;
        var today =  month + '-' + day + '-' +now.getFullYear();
        temp.date=today;
        console.log(temp);
        this.setState({formObj:temp});
        this.setState({billlist:data.billTypes});
        this.setState({showbillform:true,namebtn:true,showdatamodel:false,addbtn:'btn btn-sm btn-primary hidebtn',disvalue:true});

    }

    addNew=()=>{
        var temps=this.state.formObj;
        temps.discount="0.00";
        this.setState({formObj:temps});
        this.setState({showbillform:true,showdatamodel:false,namebtn:false,billbtn:false,discountbtn:false,savebutton:"btn btn-success btn-sm",disablesave:true,printbtn:true});
     }
   
    backToSearch=()=>{
        this.setState({showdatamodel:true,showbillform:false});
        this.getBillList();
        this.setState({disvalue:false,showSuccessMsg:false,btndisabled:false,savebutton:"btn btn-success btn-sm savedisplay"});
        let tempObj = {};
        for (let key in  Bills_Catalog ) { tempObj[key] = ""; }
        this.setState({ formObj:tempObj});
        let temp=[];
        this.setState({billlist:temp});
        this.setState({cardtype:false,cashtype:false})
    }

    selectAll=(id,by)=> {
        var temp=this.state.formObj;
        if(by=="cash"){
            this.setState({cashtype:true,cardtype:false});
            temp.paymenttype=by;
        }
        else if(by=="card"){
            this.setState({cardtype:true,cashtype:false});
            temp.paymenttype=by;
        }
        this.setState({formObj:temp});
    }

    GetNettotal=()=>{

        var tempobj=this.state.formObj;

         if(Number(tempobj.paid) == 0 || Number(tempobj.paid) == 0.00 || Number(tempobj.paid) == ""){
            var total=Number(tempobj.total) - Number(tempobj.discount);
         }else{
            var total=Number(tempobj.total)- Number(tempobj.discount);
            //total=Number(tempobj.paid)-total;
         }
        tempobj.nettotal=total.toFixed(2);
         var balanceamount=Number(tempobj.nettotal) - Number(tempobj.paid);
         if(Number(tempobj.nettotal) <= Number(tempobj.paid)){
             tempobj.balance =Number(0).toFixed(2);
             tempobj.change=balanceamount.toFixed(2);
         }else{
             tempobj.balance = balanceamount.toFixed(2);
             tempobj.change=Number(0).toFixed(2);;
         }
       // var total=Number(tempobj.total) - Number(tempobj.discount);
      
      
        //tempobj.change= '-' + total.toFixed(2);
        if(tempobj.balance == "0.00"){
            this.setState({disablesave:false,savebutton:"btn btn-success btn-sm showbutton"});  
        }else{
            this.setState({disablesave:true});  
        }
       // this.setState({disablesave:false,savebutton:"btn btn-success btn-sm showbutton"});
        this.setState({formObj:tempobj});

    }

    SearchObj=(id,by)=>{
        if(id != "" && id != undefined){
            let temp={search : id ,type: by}
           axios.post(base_url+'members/search', temp, this.props.user.header ).then(res =>{
               if(res.data.length > 0){
              this.setState({objList:res.data,searchtable:true});
               }
               else{
                   this.setState({searchtable:false,showSuccessMsg:true,alertmsg:'You search the name does not match with our records', messageType:"Failure"});
               }
          }).catch(function (error) { console.log(error); }); 
        }
       
        else{
            this.setState({searchtable:false,showSuccessMsg:true,alertmsg:'You search the name does not match with our records', messageType:"Failure"});
        }
    }

    saveObj=()=>{
        var num="0";
        axios.get(base_url+'bills/getbymaxid', this.props.user.header ).then(res => {
            if ( res.data.length > 0 ){
                console.log(res.data);
                num=res.data[0].cashbookno;
            }else{
                num = 0;
            }
            num=Number(num)+1;
      
        var temp=this.state.formObj;
        temp.status="Active";
        temp.nettotal=Number(temp.nettotal).toFixed(2);
        temp.total=Number(temp.total).toFixed(2);
        temp.disount=Number(temp.disount).toFixed(2);
        var dt=new Date().toISOString();
        if(this.state.formObj._id !== ""){
             dt=moment(this.state.formObj.date_created, this.state.dateformat).format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]Z');
        }
        temp.date_created=dt;
        if(temp.balance !== "0.00"){
            temp.billstatus="pending";
        }
        else{
        temp.billstatus="paid";
        temp.cashbookno = num;
        console.log(temp.cashbookno);
        }
        if(temp.billTypes == undefined || temp.billTypes == ''){
            temp.billTypes=this.state.billlist;
        }
        console.log(temp);
         axios.post(base_url+'bills/save', temp, this.props.user.header ).then(res => {
            console.log(res.data);
            this.setState({formObj:res.data.data,btndisabled:true,paidbtn:true,discountbtn:true});
        }).catch(function (error) { console.log(error); });
        }).catch(function (error) { console.log(error); });
         this.setState({showSuccessMsg:true,alertmsg:'Data is saved successfully',messageType:'Success',savebutton:' savedisplay',printbtn:false});
      
    }

    addObj=()=>{
        let tempObj = {};
        for (let key in  Bills_Catalog ) { tempObj[key] = ""; }
        this.setState({ formObj:tempObj});
        let temp=[];
        this.setState({billlist:temp});
        this.setState({cardtype:false,cashtype:false,printbtn:true})
  
    }
   
    GetTotalAmount=()=>{
        var temp=[];
        var sum=0;
        for(var i=0;i<this.state.billlist.length;i++){
           sum = Number(sum) +Number(this.state.billlist[i].subtotal);
        }
        var subtotal=sum.toFixed(2);
        var tempobj=this.state.formObj;
        tempobj.total=subtotal;
        tempobj.nettotal=subtotal;
        this.setState({formObj:tempobj});

    }
    GetBalance=()=>{
        var tempobj=this.state.formObj;
             tempobj.nettotal=Number(tempobj.total)- Number(tempobj.discount);
             tempobj.nettotal=tempobj.nettotal.toFixed(2);
        var balanceamount=Number(tempobj.nettotal) - Number(tempobj.paid);
        if(Number(tempobj.nettotal) <= Number(tempobj.paid)){
            tempobj.balance =Number(0).toFixed(2);
            tempobj.change=balanceamount.toFixed(2);
        }else{
            tempobj.balance = balanceamount.toFixed(2);
            tempobj.change=Number(0).toFixed(2);;
        }

        if(tempobj.balance == "0.00"){
            this.setState({disablesave:false,savebutton:"btn btn-success btn-sm showbutton"});  
        }else{
            this.setState({disablesave:true});  
        }
        this.setState({formObj:tempobj});


    }
    downloadPDF=()=>{
        PdfDoc. openPdf(this.props.user.header );
    }

    validate = (val) => {
		let tempObj = this.state.validations;
		switch(val){
			case "billtype": tempObj.billtype = Validators.name(this.state.billObj.billtype);break;			
			case "fine": tempObj.fine = Validators.number(this.state.billObj.fine); break;
			case "totaldays": tempObj.totaldays = Validators.number(this.state.billObj.totaldays); break;	
		}
		this.setState({validations:tempObj});
	 } 
	 

    format = (val, e) => { 
        let tempObj = this.state.formObj;
        let temp=this.state.billObj;

        switch(val){
            case "firstname":tempObj.firstname=Formators.firstname(e.target.value);break;
            case "email":tempObj.email=Formators.name(e.target.value);break;
            case "lastname":tempObj.lastname=Formators.name(e.target.value);break;
            case "change":tempObj.change=Formators.name(e.target.value);break;
            case "date":tempObj.date=Formators.name(e.target.value);break;
            case "cardid":tempObj.cardid=Formators.name(e.target.value);break;
            case "cellphone":tempObj.cellphone=Formators.name(e.target.value);break;
            case "address":tempObj.address=Formators.name(e.target.value);break;
            case "card":tempObj.card=Formators.name(e.target.value);break;
            case "cash":tempObj.cash=Formators.name(e.target.value);break;
            case "billno":tempObj.billno=Formators.name(e.target.value);break;
            case "billtype":temp.billtype=Formators.firstname(e.target.value);break;
            case "totaldays":temp.totaldays=Formators.name(e.target.value);break;
            case "booktitle":temp.booktitle=Formators.name(e.target.value);break;
            case "fine": temp.fine=Formators.name(e.target.value);break;
            case "total":tempObj.total=Formators.name(e.target.value);break;
            case "discount":tempObj.discount=Formators.name(e.target.value);break;
            case "nettotal":tempObj.nettotal=Formators.name(e.target.value);break;
            case "balance":tempObj.balance=Formators.name(e.target.value);break;
            case "paid":tempObj.paid=Formators.name(e.target.value);break;
         }
        
        this.setState({formObj:tempObj});
        this.setState({billObj:temp});
    }

    addItem = () =>{
        this.setState({showModel:true,paidbtn:false});
        var temp={billtype:"",totaldays:"",fine:""};
        this.setState({billObj:temp});
    }

    getDetails = (i) =>{
        var temp=this.state.objList[i];
        var tempobj=this.state.formObj;
        tempobj.firstname=temp.firstname;
        tempobj.lastname=temp.lastname;
        tempobj.address=temp.address1;
        tempobj.cellphone=temp.cellphone;
        tempobj.cardid=temp.cardid;
        var now = new Date();
        var month = (now.getMonth() + 1);               
        var day = now.getDate();
        if (month < 10) 
            month = "0" + month;
        if (day < 10) 
            day = "0" + day;
        var today =  month+ '-' + day + '-' +now.getFullYear();
        tempobj.date=today;
        this.setState({formObj:tempobj,searchtable:false,addbtn:'btn btn-sm btn-primary displaybtn'});

    }

    closeModel=()=>{
        let tempObj = {billtype:"",totaldays:"",fine:""};
        this.setState({validations:tempObj});
        this.setState({showModel:false});
    }
    
    renderSeachList=()=>{
        if( this.state.objList != undefined && this.state.objList.length > 0){
            return this.state.objList.map((item, index) =>{
                return (
                    <tr key={index} id={index} onClick={()=>{this.getDetails(index)}}>
                        <td>{item.firstname}</td>
                        <td>{item.lastname}</td>
                        <td>{item.cardid}</td>
                    </tr>

                )
            }, this);
        }
        
    }
    savedata=()=>{
      if(this.state.billObj.billtype != undefined && this.state.billObj.billtype !='' && this.state.billObj.fine != undefined && this.state.billObj.fine !=''){
        var sum=0;
        var tempobj=this.state.formObj;
        var temp=this.state.billObj;
        console.log(temp);
        if(temp.totaldays == undefined || temp.totaldays == '' || temp.totaldays ==0 || temp.totaldays <0){
            temp.subtotal=Number(temp.fine).toFixed(2);
        }
        else{
            temp.subtotal=Number(temp.fine) * Number(temp.totaldays);
        }
        this.setState({billObj:temp});
        this.state.billlist.push(this.state.billObj);
        for(var i=0;i<this.state.billlist.length;i++){
            sum=Number(sum) + Number(this.state.billlist[i].subtotal);
        }
        tempobj.total=Number(sum).toFixed(2);
        tempobj.nettotal=Number(sum).toFixed(2);
        tempobj.balance=Number(sum).toFixed(2);
        tempobj.change= Number(0).toFixed(2);
        tempobj.paid= Number(0).toFixed(2);
        this.setState({formObj:tempobj});
        let tempObj = {};
	 	for (let key in this.state.billObj) { tempObj[key] = ""; }
		this.setState({billObj:tempObj});
          }
        this.setState({showModel:true});
        
    }

    rendertablelist = () =>{
        if( this.state.billlist != undefined && this.state.billlist.length > 0){
            return this.state.billlist.map((item, index) =>{
                return (
                    <tr key={index} id={index}>
                        <td>{index + 1}</td>
                        <td>{item.billtype}</td>
                        <td className="subtotal">{Number(item.fine).toFixed(2)}</td>
                        <td>{item.totaldays}</td>
                        <td className="subtotal">{Number(item.subtotal).toFixed(2)}
                        </td>
                    </tr>

                )
            }, this);
        }
    }


    render(){
        const options = {  height: 600,  movableRows: true,footerElement:'<span></span>',pagination:"local", paginationSize:100,  paginationButtonCount:9,};
        const columnlist= this.state.originalTabColums.length > 0 &&  this.state.originalTabColums.map((item, index) => {
            if(item.title === "BILLTYPES"){

            }else{
            return (
            <tr key={index}><td><i className="dropdown-item"><input type="checkbox" className="custom-check-box" id={index} name={index} checked={item.status} onChange={(e)=>this.addToTab(e, item, index)}></input>&nbsp;&nbsp;&nbsp;&nbsp;{item.title}</i></td></tr>
            )
            }
        }, this);
    
        return(
            <div className="shadow-sm p-3 mb-5 bg-white rounded">
                <div><div className="d-flex row justify-content-center"><h3>Billing</h3></div></div><br/>
                 {this.state.showSuccessMsg ?
                  <div className="d-flex row">
					  <div className="p-2 flex-fill">
                      <FlashMessage duration={3000} persistOnHover={true}>
                      {flashMessage(this.state.alertmsg, this.state.messageType)}
                      </FlashMessage>
					  </div>	
                  </div>
                  :null
                  }
            {this.state.showdatamodel?
                <div>
                    <div className="d-flex row" >
                        <div className="p-2 col-md-5 ">
                            <input type="text" className="form-control  form-control-sm" onKeyUp={()=>{this.SearchDatalist()}}  onChange={(e)=>{this.changeSearching(e)}} placeholder="Search by Firstname" />
                
                        </div>
                        <div className="p-2 col-md-4">
                        </div>
                        <div className="p-2">
                            <button type="button" className="btn btn-sm btn-primary"  onClick={()=>{this.addNew()}} >Add New</button>
                        </div>
                </div>
                <div className="my-5 sample67">                   
                    <div className="">      
                        <ReactTabulator
                            ref={ref => (this.ref = ref)}
                            data={this.state.finallist}
                            columns={this.state.filteredTabColums}
                            options={options}
                            rowClick={this.rowClick}
                            data-custom-attr="test-custom-attribute"
                            className="custom-css-class"	
                        /> 
                        <div className=" input-group-append tabulator-row-handle-bar append1">
                            <span className="" type="text" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><FaBars /></span>
                                <div className="dropdown-menu scrollable-dropdown my-custom-scrollbar">                                 
                                    <table className="table table-condensed">
                                        <tbody>
                                            <tr><td><a className="dropdown-item" href="#" onClick={()=>this.downloadPDF()}><FaFilePdf className="fafilecolor"/>Download PDF</a></td></tr>
                                            <tr><td> <i className="dropdown-item"><CSVLink  className="csvfile"data={this.state.totallist.map(item =>({Id:item.id,First_Name:item.firstname,Last_Name:item.lastname,Total:item.total,Disount:item.discount,NetTotal:item.nettotal,Paid:item.paid,Balance:item.balance}))} enclosingCharacter={`'`}>
                                            <FaFileExcel className="fafilecolor" /> Download CSV
                                            </CSVLink></i></td></tr>
                                            {columnlist}
                                        </tbody>
                                    </table>                                            
                                </div>
                            </div>
                        </div>
                    </div> 
                </div>:null}
                {this.state.showbillform?
                <div>
                    <div className="d-flex row justify-content-between "> 
                        <div className="input-group   col-md-4 ">
                            <div className="input-group-prepend ">
                                <button type="button" className="namebtn">Name</button>
                            </div>
                            <input type="text" disabled={this.state.namebtn} onKeyPress={(e) => e.key === 'Enter' ? this.SearchObj(this.state.formObj.firstname,"Name"): null} value={this.state.formObj.firstname} onChange={(e)=>{this.format("firstname",e)}} className="form-control form-control-sm " placeholder="Search here" />
                            <span className="errormsg">{this.state.searchmessage}</span>
                        </div>
                        <div className="input-group  col-md-4 ">
                            <div className="input-group-prepend ">
                                <button type="button" className="namebtn">Date</button>
                            </div>
                            <input type="text" value={this.state.formObj.date} onChange={(e)=>{this.format("date",e)}} disabled={true} className="form-control form-control-sm "/>
                        </div>
                    </div>
                    {this.state.searchtable?
                    <div className="d-flex row p-3">
                        <table className="table table-bordered table-sm">
                            <thead className="thead">
                                <tr>
                                    <td> First Name</td>
                                    <td>Last Name</td>
                                    <td>Card Id</td>
                                </tr>
                            </thead>
                            <tbody>
                            {this.renderSeachList()}
                            </tbody> 
                        </table>
                    </div>:null}

                    <div className="d-flex row justify-content-between">
                        <div className="input-group  col-md-4">
                            <div className="input-group-prepend ">
                                <button type="button" className="namebtn">Phone</button>
                            </div>
                            <input type="text" value={this.state.formObj.cellphone} onChange={(e)=>{this.format("cellphone",e)}} disabled={true} className="form-control form-control-sm "/>
                        </div>
                        {/* <div className="input-group  col-md-2 ">
                            <div className="input-group-prepend ">
                                <button type="button" className="namebtn">Bill No</button>
                            </div>
                            <input type="text" disabled={this.state.billbtn} className="form-control form-control-sm "value={this.state.formObj.billno} onChange={(e)=>{this.format("billno",e)}}  />
                        </div> */}
                        <div className="input-group  col-md-4 ">
                            <div className="input-group-prepend ">
                                <button type="button" className="namebtn">Bill Id</button>
                            </div>
                            <input type="text" disabled={true}className="form-control form-control-sm "value={this.state.formObj.billid} onChange={(e)=>{this.format("billno",e)}}  />
                        </div>
                    </div>
                    <div className="d-flex row justify-content-between">
                        <div className="input-group  col-md-4 ">
                            <div className="input-group-prepend ">
                                <button type="button" className="namebtn">Address</button>
                            </div>
                            <input type="text"disabled={true} value={this.state.formObj.address} onChange={(e)=>{this.format("address",e)}} className="form-control form-control-sm "  />
                        </div>
                        <div className="input-group  col-md-4 ">
                            <div className="input-group-prepend ">
                                <button type="button" className="namebtn">Card Id</button>
                            </div>
                            <input type="text" value={this.state.formObj.cardid} onChange={(e)=>{this.format("cardid",e)}} disabled={true} className="form-control form-control-sm " />
                        </div>
                    </div>

                    <div className="d-flex-row mt-3">
                        <div className=" flex-fill">
                            <table className="table table-bordered table-sm">
                                <thead className="thead">
                                    <tr>
                                        <td>SI No</td>    
                                        <td>Bill Type</td>
                                        <td>Price</td>
                                        <td>Days</td>
                                        <td>Subtotal</td>
                                    </tr>
                                </thead>
                                <tbody>
                                {this.rendertablelist()}
                                </tbody> 
                            </table>
                        </div>
                    </div>

                    <div className="d-flex row ">
                        <div className="p-3">
                            <button type="button" className={this.state.addbtn} onClick={()=>{this.addItem()}}>Add</button>
                        </div>
                    </div>

                    <div className="d-flex row  justify-content-between">
                        <div className="ml-3"></div>
                        <div className="input-group  col-md-4">
                            <div className="input-group-prepend ">
                                <button type="button" className="namebtn">Total</button>
                            </div>
                            <input type="text" disabled={true} value={this.state.formObj.total} onChange={(e)=>{this.format("total",e)}}  className="form-control form-control-sm totalAmount "  />
                        </div>
                    </div>
                    <div className="d-flex row justify-content-end">
                        <div className="textsample">
                            <input type="radio" name="payment" className="radiobutton" checked={this.state.cashtype} value={this.state.formObj.paymenttype}  onChange={()=>{this.selectAll(this.state.cashtype,"cash")}} />&nbsp;&nbsp;<label className="textsize">Cash&nbsp;&nbsp;&nbsp;&nbsp;</label>
                            <input type="radio" name="payment"className="radiobutton" checked={this.state.cardtype} value={this.state.formObj.paymenttype}  onChange={()=>{this.selectAll(this.state.cardtype,"card")}}/>&nbsp;&nbsp;<label >Card</label>
                        </div>
                    <div className="input-group  col-md-4">
                        <div className="input-group-prepend ">
                            <button type="button" className="namebtn">Discount</button>
                        </div>
                        <input type="number" disabled={this.state.discountbtn} onKeyUp={()=>{this.GetNettotal()}} value={this.state.formObj.discount} onChange={(e)=>{this.format("discount",e)}} className="form-control form-control-sm totalAmount" />
                    </div>
                </div>
                <div className="d-flex row justify-content-end">
                    <div className="input-group  col-md-4">
                        <div className="input-group-prepend ">
                            <button type="button" className="namebtn">Paid</button>
                        </div>
                        <input type="number" disabled={this.state.paidbtn} onKeyUp={()=>{this.GetBalance()}}  value={this.state.formObj.paid} onChange={(e)=>{this.format("paid",e)}} className="form-control form-control-sm totalAmount " />
                    </div>
                    <div className="input-group  col-md-4">
                        <div className="input-group-prepend ">
                            <button type="button" className="namebtn"> Net Total</button>
                        </div>
                        <input type="text" disabled={true} value={this.state.formObj.nettotal} onChange={(e)=>{this.format("nettotal",e)}} className="form-control form-control-sm totalAmount"/>
                    </div>
                </div>
                <div className="d-flex row justify-content-end">
                    <div className="input-group  col-md-4">
                        <div className="input-group-prepend ">
                            <button type="button" className="namebtn">Change</button>
                        </div>
                        <input type="text" disabled={true} value={this.state.formObj.change} onChange={(e)=>{this.format("change",e)}} disabled={true}className="form-control form-control-sm totalAmount" />
                    </div>
                    <div className="input-group  col-md-4">
                        <div className="input-group-prepend ">
                            <button type="button" className="namebtn">Balance</button>
                        </div>
                        <input type="text" disabled={true} value={this.state.formObj.balance} onChange={(e)=>{this.format("balance",e)}} className="form-control form-control-sm totalAmount"  />
                    </div>
                </div>
                <div className="d-flex row justify-content-end"> 
                    <div className="p-2">
                        <button type="button" className={this.state.savebutton} onClick={()=>{this.saveObj()}}  disabled={this.state.disablesave}>Save</button>
                    </div>
                    <div className="p-2">
                        <button type="button" className="btn btn-primary btn-sm" onClick={()=>{this.getprint("print")}} disabled={this.state.printbtn} >Print</button>
                    </div>
                    <div className="p-2">
                        <button type="button" className="btn btn-success btn-sm" onClick={()=>{this.sendMail()}} disabled={this.state.printbtn} >Email</button>        
                    </div>
                    <div className="p-2">
                        <button type="button" className="btn btn-warning btn-sm"  onClick={()=>{this.backToSearch()}}>Cancel</button>
                    </div>
                </div>
                {this.state.inputbox?
                    <div className="d-flex row justify-content-end">
                        <div className="p-2">
                            <input type="text" value={this.state.formObj.email} onChange={(e)=>{this.format("email",e)}}className="form-control form-control-sm " />
                        </div>
                        <div className="p-2">
                            <button type="button" className="btn btn-success btn-sm" onClick={()=>{this.Sendpdf()}}>send</button>
                        </div>
                </div>:null}
            </div>:null}
            <Modal size="lg" show={this.state.showModel} onHide={()=>{this.closeModel()}}>
                    <Modal.Header className=" justify-content-end">
                    <div className="d-flex row">
                         <div className="p-2">
                                <Button variant="warning" onClick={()=>{this.savedata()}}>Add</Button>
                          </div>
                          <div className="p-2">
                                <Button variant="primary" onClick={()=>{this.closeModel()}}>Cancel</Button>
                         </div>
                         </div>
                    </Modal.Header>
                    <ModalBody>
                        <div className="d-flex row">
                            <div className="p-2 flex-fill">
                                <label htmlFor="idbilltype" className="label-custom" >Bill Type</label> 		
                                <input id="idbilltype"   value={this.state.billObj.billtype}  onBlur={()=>{ this.validate("billtype")}}  style={this.state.validations.billtype===""?{}:{"borderColor":"red"}} onChange={(e)=>{this.format("billtype",e)}} className="form-control form-control-sm"  type="text" className='form-control form-control-sm' required /> 
                                <span className="errormsg">{this.state.validations.billtype}</span>
                            </div>
                            <div className="p-2">
                                <label htmlFor="idcode" className="label-custom" >Price</label> 		
                                <input   value={this.state.billObj.fine} onChange={(e)=>{this.format("fine",e)}}  className="form-control form-control-sm"  onBlur={()=>{ this.validate("fine")}}  style={this.state.validations.fine===""?{}:{"borderColor":"red"}} type="number" className='form-control form-control-sm' required /> 
                                <span className="errormsg">{this.state.validations.fine}</span>
                            </div>
                            <div className="p-2">
                                <label htmlFor="idcode" className="label-custom" >Total Days</label> 		
                                <input   value={this.state.billObj.totaldays} onChange={(e)=>{this.format("totaldays",e)}}  className="form-control form-control-sm"  onBlur={()=>{ this.validate("totaldays")}}  style={this.state.validations.totaldays ===""?{}:{"borderColor":"red"}}  type="number" className='form-control form-control-sm'  required /> 
                                <span className="errormsg">{this.state.validations.totaldays}</span>
                               </div>

                        </div>
                    </ModalBody>
                    <ModalFooter>
                    </ModalFooter>
                </Modal>
        </div>
        )
    }
}
    const mapToProps = ({ loggedUserState }) => ({ user: loggedUserState })
    export default connect(mapToProps)(Bill_CatalogForm);
