/////////////////////////////////////////////////
// Warning : Propeerty of Deemsoft, It is illegal to make copy of this code
// Author : Gangaswamy Kempahonnaiah
//////////////////////////////////////////////////////

import React from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { base_url } from '../constants/Constants';
import flashMessage from '../utils/Flash';
import FlashMessage from 'react-flash-message';
import { login } from '../actions/LoginAction';
import { FaEye, FaEyeSlash, FaFilePdf} from 'react-icons/fa';
import Logo from '../assets/favicon1.png';
import '../styles/Styles.css';

class Login extends React.Component {
    constructor(props){
        super(props);
          this.state = {           
            email : '',
            password : '',              
            showSuccessMsg : false,      	
            message:'',
            msgType:'Failure',
            hidden:true,
            headerimage:base_url+'file/download/header.png',
          };     
    }

    componentDidMount(){		
    }   

    emailChange = (e) =>{   
        this.setState({ email:e.target.value});
    }
  
    passwordChange = (e) =>{   
        this.setState({ password:e.target.value});
    }

    keyPress= (e) =>{ 
        if(e.keyCode === 13){
           //console.log('value', e.target.value);
           this.login();
        }
     }
     resetAll= () =>{
        this.setState({
        showRegistration:false,showSuccessMsg : false,
        });
    }
     showSuccessMsg=()=>{
        this.resetAll();
          this.setState({showSuccessMsg:true});
        
      }
    
    
    login = () =>{
        if(this.state.email === '' || this.state.password === ''){
            this.setState({showSuccessMsg:false}, () => {this.setState({message:'Please fill the all the fields value to Login', showSuccessMsg:true})});      
      
        }else{
            this.setState({message:'', showSuccessMsg:false});
        var formObj = {email:this.state.email, password:this.state.password};
        axios.post(base_url+'main/login', formObj)                    
        //axios.post(base_url+'user/login', formObj)
          .then(res => {          
            if(res.data.status === 'success'){               
                login({logged:true,name:res.data.user.name, usertype:res.data.user.type, userid:res.data.user.id,email:res.data.user.email, token:res.data.user.token, accountid:res.data.user.accountid}).then(() => {
                    this.props.history.push('/app/menus');         
                }).catch(function (error) { 
                    console.log(error);  
                }); 
                
            }else{
                this.setState({message:'Username and password is not matching', showSuccessMsg:true});
            }                        
           
          }).catch(function (error) { 
            console.log(error);        
          });
        }
    }
    toggleShow = () => {
        this.setState({ hidden: !this.state.hidden });
      }
   
    render() { 

        if(this.props.Page.page && this.props.history ){
    
        }
		
        return (
            <div className="container">
                      <br/><br/>
                     <div className="brdcolor" >
                   
                    <div className="d-flex justify-content-center">
                    <div className="box"><span className="brandname"><img className="image2" src={this.state.headerimage} alt="headerbanner" /></span></div>
                      
                     </div>	
                     <br/> <br/>  
					<div className="d-flex justify-content-center">
                    
                    <img src={Logo} alt="Logo" />
                     
                     </div><br/>
                     <div className="d-flex justify-content-center">
                  {this.state.showSuccessMsg ?
                  <div>
                      <FlashMessage duration={5000} persistOnHover={true}>
                      {flashMessage(this.state.message, this.state.msgType)}
                      </FlashMessage>	
                  </div>
                  :null
                  }
              </div>  
                     <div className="d-flex justify-content-center">
                        <div className="form-group col-sm-4">                      
                            <input type="text" className="form-control " id="email" name="email" value={this.state.email} placeholder="Email"  required onChange={this.emailChange} onKeyDown={this.keyPress}/>
                        </div>                    
                     </div>
                     <div className="d-flex justify-content-center">
                    
                        <div className="form-group col-sm-4">  
                        <div className="input-icons">  
                        <i className="icon">{this.state.hidden ?<FaEyeSlash size="15px" onClick={()=>{this.toggleShow()}} />:<FaEye size="15px" onClick={()=>{this.toggleShow()}} />}</i>                  
                            <input  type={this.state.hidden ? 'password' : 'text'} className="form-control" id="password" name="password" value={this.state.password} placeholder="Password"  required onChange={this.passwordChange} onKeyDown={this.keyPress} />
                            </div>    
                           </div> 
                          
                     </div>

                     <div className="d-flex justify-content-center">
                        <div className="form-group col-sm-4">
                            <button type="submit" className="btn btn-primary btn-block " onClick={this.login}>Login</button><br/> 
                            				
                        </div>
                     </div>
                     <div className="d-flex justify-content-center">
                    <p className="password"><NavLink to="/password">Forgot Password ?</NavLink><br/></p>
                    </div>
                    <div className="d-flex justify-content-center">	
                    <p className="password"><NavLink to="/password">Password Reset </NavLink><br/></p>	
                     </div>
                     </div>
                
                     </div> 
         
             
                     
					                 				
        );	
    }
}
const mapToProps = ({ currentPageState }) => ({Page: currentPageState });
export default connect(mapToProps)(Login);