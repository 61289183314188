////////////////////////////////////////////////////////////////////////////////////////////////////
// Warning : Property of Deemsoft Global Solutions LLC, USA. It is illegal to make copy of this code
// Author : Gangaswamy Kempahonnaiah
////////////////////////////////////////////////////////////////////////////////////////////////////
import React from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { base_url } from '../constants/Constants';
import { Validators } from '../utils/Validators';
import { Formators } from '../utils/Formators';
import { Checkout } from '../models/Checkout';
import {DateOnly} from '../utils/DateTime';
import flashMessage from '../utils/Flash';
import FlashMessage from 'react-flash-message';
import '../styles/Styles.css';
import { Members } from '../models/Members';
import { Books_Catalog } from '../models/Books_Catalog';
import { Setting } from '../models/Setting';


class CheckoutForm extends React.Component {
constructor(props){
	super(props);
	this.state = {
		objList : [],
		settings:Setting,
		showModal : false,
		validations : {},
		cardid:"",
		searchtable : false,
		searchform : true,
		mainform:false,
		disvalue:true,
		btndisable:true,
		books_details:Books_Catalog,
		formObj : Checkout,
		formObj2 : Members,
		booktable:false,
		overdate:1,
		hideissue:true,
		limitmsg:false,
		endDate:new Date(),
		dateformat:'',
		showSuccessMsg:false,
		accnumber: ""
	}
}

componentDidMount(){
	let tempObj = {};
	for (let key in Checkout) { tempObj[key] = ""; }
	this.setState({validations:tempObj});
	let tempObjs = {};
	for (let key in Checkout) { tempObjs[key] = ""; }
	this.setState({formObj:tempObjs});
	this.getSettings();
	
}
getSettings = () => {
	axios.get(base_url+'settings/list', this.props.user.header ).then(res => {
		if ( res.data.length > 0 ){
			for(var i=0;i<res.data.length;i++){
				this.setState({ settings:res.data[i]});	
			}	
		} 
		if(this.state.settings.dateformats == ''){
			this.setState({dateformat:"dd/MM/yyyy"});
		}else if(this.state.settings.dateformats !==''){
			this.setState({dateformat:this.state.settings.dateformats});
		}  
	}).catch(function (error) { console.log(error); });
}

getObjList = (id) => {
	axios.get(base_url+'checkout/listbycardid/'+id, this.props.user.header ).then(res => {
			var temp=this.state.settings.registrationTypes;
			var obj =  temp.find( ({ registrationtype }) => registrationtype === this.state.formObj.registrationtype );
			if(obj.numberofbooks == res.data.length){
			this.setState({hideissue:false,limitmsg:true});
			}
			this.setState({ objList:res.data});
	}).catch(function (error) { console.log(error); });
}
changeEvent = (e) =>{
	console.log(e);
	this.setState({ endDate : e });
	var tempObj=this.state.formObj;
	tempObj.duedate=e ;
	this.setState({formObj:tempObj});
}

saveObj = () => {
	this.getObjList(this.state.formObj.membershipid);
	var now = new Date();
	var month = (now.getMonth() + 1);               
	var day = now.getDate();
	if(month < 10) month = "0" + month;
	if(day < 10) day = "0" + day;
	var today =  day+ '-' + month + '-' +now.getFullYear();
	let temp='';
	for (let key in Checkout){ 
		this.validate(key);
	}
	if(this.state.formObj.accnumber == "" || this.state.formObj.booktitle == ""){
		let temval=this.state.validations;
		temval.accnumber="Please search the Acc No.";
		this.setState({validations:temval});
	}
	for(let key in this.state.validations){
		temp += this.state.validations[key]
	}
	if(temp == ""){
		var dts=new Date(this.state.formObj.duedate);
		var dt=dts.toISOString();
		const temps = {
			_id: this.state.formObj._id,
			status: "Active",
			trackcode: this.state.formObj.trackcode,
			address: this.state.formObj.address,
			duedate: dt,
			indianprice: this.state.formObj.indianprice,
			firstname: this.state.formObj.firstname,
			registrationtype: this.state.formObj.registrationtype, 
			lastname: this.state.formObj.lastname, 
			cardid: this.state.formObj.cardid, 
			cellphone: this.state.formObj.cellphone, 
			rollno: this.state.formObj.rollno,
			accnumber: this.state.formObj.accnumber, 
			isbnnumber: this.state.formObj.isbnnumber, 
			booktitle: this.state.formObj.booktitle, 
			issuedate: this.state.formObj.issuedate, 
			receiveddate: this.state.formObj.receiveddate, 
			issuetype: this.state.formObj.issuetype,
			email: this.state.formObj.email,
			membershipid: this.state.formObj.membershipid
		}
		axios.post(base_url+'checkout/save', temps, this.props.user.header )
			.then(res => {
				//this.setState({ formObj:res.data.data});
				this.getObjList(this.state.formObj.membershipid);
				this.setState({btndisable:true});
				this.setState({showSuccessMsg:true});
				let tempObj=this.state.books_details;
				tempObj.bookstatus="issued";
				axios.post(base_url+'books_catalog/save', tempObj , this.props.user.header ).then(res =>{
					this.clearform();
				}).catch(function (error) { console.log(error); }); 
			}).catch(function (error) { console.log(error); });	 
	}
	this.setState({showSuccessMsg:false});
}

addObj = () => { 
	let tempObj = {};
	for (let key in Checkout ) { tempObj[key] = ""; }
	this.setState({ formObj:tempObj, showModal:true});
}

editObj = (i) => { 
	let tempObj = {};
	for (let key in this.state.objList[i] ) { tempObj[key] = this.state.objList[i][key]; }
	this.setState({ formObj:tempObj, showModal:true});
}

deleteObj = (i) => { 
	let tempObj = this.state.objList[i];
	tempObj.status = 'Inactive';
	axios.post(base_url+'checkout/save', tempObj , this.props.user.header ).then(res =>{
		if ( res.data.length > 0 ){
			this.setState({ formObj:res.data});
			this.getObjList();
		}
	}).catch(function (error) { console.log(error); });
}
clearform = () => {	
	var tempdata=this.state.formObj;
	tempdata.notes="";
	tempdata._id="";
	tempdata.issuedate="";
	tempdata.duedate="";
	tempdata.issuetype="";
	tempdata.notes="";
	tempdata.booktitle="";
	tempdata.isbnnumber="";
	tempdata.accnumber="";
	tempdata.trackcode="";
	this.setState({ formObj:tempdata,booktable:false,btndisable:true,disvalue:true}); 
	let tempObjs = {};
	for (let key in  Checkout) { tempObjs[key] = ""; }
	this.setState({validations:tempObjs});
}

searchType=(id,sname)=>{
	if(id !== '' && id !== undefined){
		let output = id.trim();
		let input=output.substring(0, 1).toUpperCase() + output.substring(1);
		this.searchObjs(input,"cardids");
	}else if(sname !== '' && sname !== undefined){
		let output = sname.trim();
		let input=output.substring(0, 1).toUpperCase() + output.substring(1);
		this.searchObjs(sname,"Names");
	}else{
		let tempObj = this.state.searchmessage;
		tempObj="Please Enter cardid or firstname to search";
		let temarr = [];
		this.setState({searchmessage:tempObj});
		this.setState({objList:temarr,searchtable:false});
	}
}
searchObjs = (id,by) => {
	if(id != "" && id != undefined){
		let temp={search : id ,type: by}
		axios.post(base_url+'members/search', temp, this.props.user.header )
		.then(res =>{
		if(res.data.length > 0){
		let tempObj = this.state.searchmessage;
			tempObj="";
			this.setState({searchmessage:tempObj});
			this.setState({objList1:res.data,searchtable:true});
		}else{
			let tempObj = this.state.searchmessage;
			tempObj="Member details you entered is not match with our records";
			this.setState({searchmessage:tempObj});
			let temarr = [];
			this.setState({objList1:temarr,searchtable:false}); 
			//for (let key in Members ) { tempObjs[key] = ""; }
			//this.setState({formObj:temp});
		}
	}).catch(function (error) { console.log(error); }); 
	this.renderObjlist();
}else{
	let tempObj = this.state.searchmessage;
	tempObj="Please Enter cardid or firstname to search";
	let temarr = [];
	this.setState({searchmessage:tempObj});
	this.setState({objList1:temarr,searchtable:false});
}
}

getDateFormat = (dt) =>{
	var now = new Date(dt);
	var month = (now.getMonth() + 1);               
	var day = now.getDate();
	if (month < 10) 
		month = "0" + month;
	if (day < 10) 
		day = "0" + day;

	if(this.state.settings.dateformats == "MM/dd/yyyy"){
		var today = month + '-' + day + '-' +now.getFullYear();
		return today;
	}else if(this.state.settings.dateformats == "yyyy/MM/dd"){
		var today =  now.getFullYear()+'-'+month + '-' + day;
		return today;
	}else if(this.state.settings.dateformats == "dd/MM/yyyy"){
		var today =  day + '-' + month + '-' +now.getFullYear();
		return today;
	}else{
		var today =  day + '-' + month + '-' +now.getFullYear();
		console.log("else");
		return today;
	}
	
	}

goToSearch = () =>{
	let tempObj = {};
	for (let key in Checkout ) { tempObj[key] = ""; }
	this.setState({ formObj:tempObj});
	this.setState({mainform:false,searchform:true,searchtable:false,limitmsg:false,});
}
enableDuedate = () =>{
	this.setState({disvalue:false});
}
getDetails = (i,id) => {
	let tempObjs = {};
	for (let key in Checkout) { tempObjs[key] = ""; }
	this.setState({validations:tempObjs});
	let tempObj = this.state.formObj;
	tempObj.cardid=this.state.objList1[i].cardid;
	tempObj.firstname=this.state.objList1[i].firstname;
	tempObj.lastname=this.state.objList1[i].lastname;
	tempObj.cellphone=this.state.objList1[i].cellphone;
	tempObj.email=this.state.objList1[i].email;
	tempObj.address=this.state.objList1[i].address1;
	tempObj.membershipid=this.state.objList1[i].id;
	tempObj.registrationtype=this.state.objList1[i].registrationtype;
	this.setState({formObj:tempObj});
	this.getObjList(this.state.objList1[i].id);
	this.setState({mainform:true,searchform:false,searchtable:false});
}
searchBook = (id) => {
	if(id.length){
		const tempId = id.trim()
		axios.get(base_url+'books_catalog/id1/'+tempId,  this.props.user.header)
		.then(res =>{
			if(res.data.length > 0){
			this.setState({books_details: res.data[0]});
			let tempObj = this.state.validations;
			let tempbookobj = this.state.formObj;
			var now = new Date();
			var month = (now.getMonth() + 1);               
			var day = now.getDate();
				if (month < 10) 
					month = "0" + month;
				if (day < 10) 
					day = "0" + day;
	
					var today = now.getFullYear()+'-'+ month + '-' + day;
					var totalday=this.state.settings.issueTypes[0].totaldays;
					var due= new Date(now.getFullYear(), now.getMonth(), now.getDate() + Number(totalday));
					var duemonth = (due.getMonth() + 1);               
					var duday = due.getDate();
					if (duday < 10) 
					duday = "0" + duday;
					if (duemonth < 10) 
					duemonth = "0" + duemonth;
					var duesday=   now.getFullYear()+ '-' + duemonth+'-'+duday;
				
				tempbookobj.accnumber = res.data[0].accnumber
				tempbookobj.issuedate=today;
				tempbookobj.duedate=duesday;
				tempbookobj.issuetype=this.state.settings.issueTypes[0].issuetype;
				tempbookobj.notes="";
				tempbookobj.trackcode=res.data[0].trackcode;
				tempbookobj.booktitle=res.data[0].booktitle;
				tempbookobj.isbnnumber=res.data[0].isbnnumber;
				tempbookobj.indianprice=res.data[0].indianprice;
				console.log(tempbookobj)
				
				tempObj.accnumber="";
				this.setState({validations:tempObj});
				this.setState({formObj: tempbookobj,booktable:true});
				
			}else{
				let tempObj = this.state.validations;
				let tempObjs = this.state.formObj;
				tempObj.accnumber="The book you are finding is not available Now";
				this.setState({btndisable:true});
				this.setState({validations:tempObj,formObj:tempObjs,booktable:false});
				
			}
		}).catch(function (error) { console.log(error); }); 
	}
}


validate = (val) => {
	let tempObj = this.state.validations;
	switch(val){
		case "accnumber": 
			const tempAccnumber = Validators.name1(this.state.accnumber) 
			if(tempAccnumber !== "" && tempAccnumber !== undefined){
				this.setState({btndisable:true});
			}
			break;
		case "duedate": tempObj.duedate = Validators.date(this.state.formObj.duedate); break;
		case "issuetype": tempObj.issuetype = Validators.select(this.state.formObj.issuetype); break;
	}
	this.setState({validations:tempObj});
} 

CheckIssueType = () =>{
	var temp=this.state.settings.issueTypes;
	var totalday=0;
	for(var i in temp){
		if(temp[i].issuetype === this.state.formObj.issuetype){
			totalday=temp[i].totaldays;
		}
	}
	var now=new Date();
	var due= new Date(now.getFullYear(), now.getMonth(), now.getDate()+Number(totalday));
	var duemonth = (due.getMonth() + 1);               
	var duday = due.getDate();
	if (duday < 10) 
	duday = "0" + duday;
	if (duemonth < 10) 
	duemonth = "0" + duemonth;
	var duesday=   now.getFullYear()+ '-' + duemonth +'-'+duday;
		var temp=this.state.formObj;
		temp.duedate=duesday;
}

format = (val, e) => { 
let tempObj = this.state.formObj;
switch(val){
	case "membershipid": tempObj.membershipid = Formators.name(e.target.value); break;
	case "cardid": tempObj.cardid = Formators.name(e.target.value); break;
	case "firstname": tempObj.firstname = Formators.firstname(e.target.value); break;
	case "lastname": tempObj.lastname = Formators.firstname(e.target.value); break;
	case "age": tempObj.age = Formators.name(e.target.value); break;
	case "accnumber":
		const tempAccnumber = Formators.name(e.target.value);
		if(tempAccnumber !== "" && tempAccnumber !== undefined){
			this.setState({btndisable: false});
		}
		this.setState({accnumber: e.target.value})
		break;
	case "barcode": tempObj.barcode = Formators.name(e.target.value); break;
	case "isbnnumber": tempObj.isbnnumber = Formators.name(e.target.value); break;
	case "booktitle": tempObj.booktitle = Formators.name(e.target.value); break;
	case "issuedate": tempObj.issuedate = Formators.name(e.target.value); break;
	case "duedate": tempObj.duedate = Formators.name(e.target.value); break;
	case "receiveddate": tempObj.receiveddate = Formators.name(e.target.value); break;
	case "overduedays": tempObj.overduedays = Formators.name(e.target.value); break;
	case "fine": tempObj.fine = Formators.name(e.target.value); break;
	case "notes": tempObj.notes = Formators.name(e.target.value); break;
	case "cellphone": tempObj.cellphone = Formators.name(e.target.value); break;
	case "indianprice": tempObj.indianprice = Formators.name(e.target.value); break;
	case "registrationtype": tempObj.registrationtype = Formators.name(e.target.value); break;
	case "issuedate": tempObj.issuedate = Formators.name(e.target.value); break;
	case "rollno": tempObj.rollno = Formators.name(e.target.value); break;
	case "issuetype": tempObj.issuetype = Formators.name(e.target.value); this.CheckIssueType(); break;
}
this.setState({formObj:tempObj});
} 

renderObjlist = () => {
if( this.state.objList != undefined && this.state.objList.length > 0){
	return this.state.objList.map((item, index) =>{
		var issueday = this.getDateFormat(item.issuedate);
		var duesday =  this.getDateFormat(item.duedate);

		return (
			<tr key={index} id={index}>
				<td>{item.cardid}</td>						
				<td>{item.accnumber}</td>
				<td>{item.booktitle}</td>
				<td>{issueday}</td>
				<td>{duesday}</td>
				</tr>
			)
		}, this);
}
}
renderMemberList = () =>{
if( this.state.objList1 != undefined && this.state.objList1.length > 0){
	return this.state.objList1.map((item, index) =>{
		return (
			<tr key={index} id={index} onClick={()=>{this.getDetails(index,item.membershipid)}} >
				<td>{item.cardid}</td>						
				<td>{item.firstname}</td>
				<td>{item.lastname}</td>
				<td>{item.cellphone}</td>
				<td>{item.email}</td>
				</tr>
			)
		}, this);
}
}

renderIssueTypes = () => {
	if( this.state.settings.issueTypes != undefined && this.state.settings.issueTypes.length > 0){
		return this.state.settings.issueTypes.map((item, index) =>{
			return (
				<option value={item.issuetype} key={index} id={index}>{item.issuetype}</option>
				)
		}, this);
	}
}

render() {
	return (
	<div className="shadow-sm p-3 mb-5 bg-white rounded">
<div><div className="d-flex row justify-content-center"><h3>Issue Book</h3></div></div><br/>
{this.state.showSuccessMsg ?
                  <div className="d-flex row">
					  <div className="p-2 flex-fill">
                      <FlashMessage duration={3000} persistOnHover={true}>
                      {flashMessage("Book issued Successfully", "Success")}
                      </FlashMessage>
					  </div>	
                  </div>
                  :null
                  }

		{this.state.searchform?
		<div className="d-flex row">
		
			<div className="p-2 flex-fill ">
					<label htmlFor="idcardid" className="label-custom" >Card Id/Barcode</label> 
					<input value={this.state.formObj.cardid} id="idcardid"  onChange={(e)=>{this.format("cardid",e)}} onKeyPress={(e) => e.key === 'Enter' ? this.searchType(this.state.formObj.cardid,this.state.formObj.firstname): null} className="form-control form-control-sm" type="text" required /> 
					<span className="errormsg">{this.state.searchmessage}</span>
			</div>
			<div className="p-2 flex-fill">
					<label htmlFor="idfirstname" className="label-custom" >Firstname</label> 
					<input value={this.state.formObj.firstname} id="idfirstname"  onKeyPress={(e) => e.key === 'Enter' ? this.searchType(this.state.formObj.cardid,this.state.formObj.firstname): null} onChange={(e)=>{this.format("firstname",e)}}   className="form-control form-control-sm" type="text" required /> 
			</div>
			<div className="p-2">
					<button type="button" className="btn btn-primary btn-sm" style={{marginTop:32}}  onClick={()=>{ this.searchType(this.state.formObj.cardid,this.state.formObj.firstname)}}>Search</button>
			</div>
			
			<div className="p-2 flex-fill "></div>
		</div>:null}

		{this.state.searchtable?
		<div className="d-flex row">
			<table className="table table-hover table-sm">
			<thead className="thead">
				<tr>
					<td>Cardid</td>
					<td>First Name</td>
					<td>Last Name</td>
					<td>Phone</td>
					<td>Email</td>
				</tr>
			</thead>
			<tbody>{this.renderMemberList()}</tbody>
			</table>
		</div>:null}

		{this.state.mainform?<div className="">
					<table className="table table-bordered tablepointer table-sm">
					<thead className="thead">
					<tr>
						<td>Cardid</td>
						<td>First Name</td>
						<td>Last Name</td>
						<td>Phone</td>
						<td>Email</td>
					</tr>
					</thead>
					<tbody>
					<tr>
						<td>{this.state.formObj.cardid}</td>						
						<td>{this.state.formObj.firstname}</td>
						<td>{this.state.formObj.lastname}</td>
						<td>{this.state.formObj.cellphone}</td>
						<td>{this.state.formObj.email}</td>
					</tr>
					</tbody>
				</table>
			<div>{this.state.limitmsg?
			<div>
			<div className="d-flex row justify-content-center"><h6 className="redlink">You reach your issue limits on the book.</h6></div>
			<div className="d-flex row justify-content-end">	
			<div className="p-2">
				<button type="button" className="btn btn-warning btn-sm" onClick={()=>{this.goToSearch()}}>Cancel</button>
			</div>	
			</div>
			</div>:null}
		</div>	
			{this.state.hideissue?<div>
			<div className="p-2 row">
				<div className="p-2 col-md-4">
					<label htmlFor="idaccnumber" className="label-custom" >ACC Number</label> 
					<input 
						value={this.state.accnumber} 
						id="idaccnumber" 
						onChange={(e)=>{this.format("accnumber", e)}}  
						onBlur={()=>{ this.validate("accnumber")}}  
						type="text" 
						className='form-control form-control-sm' 
						required 
					/> 
					<span className="errormsg">{this.state.validations.accnumber}</span>
				</div>
				<div className="p-2">
						<button 
							type="button" 
							style={{marginTop:36}} 
							className="btn btn-success btn-sm" 
							onClick={()=>{this.searchBook(this.state.accnumber)}} 
							disabled={this.state.btndisable}
						>
							Get Book Details
						</button>
				</div>
			</div>
			{this.state.booktable?<div>
			<div className="p-2 row">
				<table className="table table-bordered tablepointer table-sm">
						<thead className="thead">
						<tr>
							<td>ISBN NO.</td>
							<td>Book Title</td>
							<td>Book Trackcode</td>
						</tr>
						</thead>
						<tbody>
						<tr>
							<td>{this.state.formObj.isbnnumber}</td>						
							<td>{this.state.formObj.booktitle}</td>
							<td>{this.state.formObj.trackcode}</td>
						</tr>
						</tbody>
					</table>
			</div>
			<div className="d-flex row">
			<div className="p-2 col-md-4 ">
					<label htmlFor="idissuetype" className="label-custom" >Issue Type</label> 
					<select value={this.state.formObj.issuetype} id="idissuetype"  onChange={(e)=>{this.format("issuetype",e)}}  placeholder="Enter issuetype" onBlur={()=>{ this.validate("issuetype")}} className="form-control form-control-sm" style={this.state.validations.issuetype===""?{}:{"borderColor":"red"}} required >
						<option value=""></option>
						{this.renderIssueTypes()}
					
					</select>
					<span className="errormsg">{this.state.validations.issuetype}</span> 
				</div>
				<div className="p-2  col-md-4  ">
					<label htmlFor="idduedate" className="label-custom" >Due Date</label> 
	 <input value={this.state.formObj.duedate} id="idduedate"  onChange={(e)=>{this.format("duedate",e)}}  placeholder="Enter Duedate" onBlur={()=>{ this.validate("duedate")}} className="form-control form-control-sm" style={this.state.validations.duedate===""?{}:{"borderColor":"red"}} type="date" disabled={this.state.disvalue} required />
			{/* 	<br/><DateOnly 
                           name="dateofbirth"
                           selected={this.state.endDate}
                           value={this.state.formObj.duedate}  
							onChange={(e)=>{this.changeEvent(e)}}
							dateformat={this.state.dateformat}
							class_name={ this.state.validations.duedate==""?"form-control form-control-sm":"form-control form-control-sm red-border" }
							onBlur={this.validate}
						/><br/> */}
					<span className="errormsg">{this.state.validations.duedate}</span>
				</div>
				<div className="p-2">
				<button type="button" style={{marginTop:36}} className="btn btn-success btn-sm" onClick={()=>{this.enableDuedate()}}>Edit Due Date</button>
				</div>
			</div>
			</div>:null}
		<div className="d-flex row">
				<div className="p-2 flex-fill">
					<label htmlFor="idnotes" className="label-custom" >Notes</label> 
					<textarea value={this.state.formObj.notes} id="idnotes" rows="2" cols="20"onChange={(e)=>{this.format("notes",e)}}  onBlur={()=>{ this.validate("notes")}} className="form-control form-control-sm" style={this.state.validations.notes===""?{}:{"borderColor":"red"}} type="text" required /> 
					<span className="errormsg">{this.state.validations.notes}</span>
				</div>
		</div>
		<br/><br/>
		<div className="d-flex row justify-content-end">	
		<div className="p-2">
		<button type="button" className="btn btn-success btn-sm" onClick={()=>{this.clearform()}}>New</button>
		</div>
		<div className="p-2">
		<button type="button" className="btn btn-success btn-sm" onClick={()=>{this.saveObj()}}>Save</button>
		</div>
		<div className="p-2">
		<button type="button" className="btn btn-warning btn-sm" onClick={()=>{this.goToSearch()}}>Cancel</button>
		</div>	
		</div>
		</div>:null}
		
			
	<table className="table table-bordered tablepointer table-sm">
		<thead className="thead">
			<tr>
				
				<td>Card Id</td>					
				<td>Accnumber</td>
				<td>Book Title</td>
				<td>Issue Date</td>
				<td>Due Date</td>					
			</tr>
		</thead>
		<tbody>{ this.renderObjlist() }</tbody> 
		</table>
		</div>:null}
		</div>
	)
	}
}

const mapToProps = ({ loggedUserState }) => ({ user: loggedUserState })
export default connect(mapToProps)(CheckoutForm);
