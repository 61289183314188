
import React from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { FaPen, FaTimes } from 'react-icons/fa';
import { Button, Modal, ModalBody, ModalFooter } from 'react-bootstrap';
import { base_url } from '../constants/Constants';
import { Validators } from '../utils/Validators';
import {Setting} from '../models/Setting';
import { Formators } from '../utils/Formators';
import flashMessage from '../utils/Flash';
import FlashMessage from 'react-flash-message';
import '../styles/Styles.css';


class SettingsForm extends React.Component {
	constructor(props){
		super(props);
		this.state = {
            settings : Setting,
            showSuccessMsg : false,
            msgType : '',
            message:'',
            validations : {},
            index : -1,
            show:false,
            modelType:'',
            showModels : {billTypes:false, registrationTypes:false,labeltype:false,booktype:false,journalstype:false, billTypes:false,subjects:false, departments:false,issueType:false,batchtype:false },
            formObj:{registrationtype:'',defaultdate:'',numberofbooks:'',dateformat:'',label:'',billtype:'',code:'',journaltype:'',latefee:'',booktype:'',department:'',issuetype:'',totaldays:'',subject:'',batch:''},
            objList : [],
        }
    }


    componentDidMount(){
        var tempobj={};
        this.setState({formObj:tempobj});
        this.getSettings();
    }

    getSettings = () => {
        axios.get(base_url+'settings/list', this.props.user.header ).then(res => {
			if ( res.data.length >= 0 ){
					this.setState({ settings:res.data[res.data.length-1]});	
            }
		}).catch(function (error) { console.log(error); });
    }

    saveSettings = () => {
        console.log(this.state.settings);
        axios.post(base_url+'settings/save',this.state.settings, this.props.user.header ).then(res => {
			if ( res.data.length > 0 ){
				this.setState({ settings:res.data[res.data.length-1]});		    
			}
           
		}).catch(function (error) { console.log(error); }); 

    }
   
  
    editObj = (index, model) => {
        this.showModel(model);
        var tempForm = {};
        switch(model){			
			case "registrationTypes": tempForm = {registrationtype:this.state.settings.registrationTypes[index].registrationtype,latefee:this.state.settings.registrationTypes[index].latefee,numberofbooks:this.state.settings.registrationTypes[index].numberofbooks}; break;
            case "billTypes": tempForm = {billtype:this.state.settings.billTypes[index].billtype,code:this.state.settings.billTypes[index].code}; break;
            case "departmentTypes": tempForm = {department:this.state.settings.departmentTypes[index].department}; break;
            case "issueTypes": tempForm ={issuetype:this.state.settings.issueTypes[index].issuetype,totaldays:this.state.settings.issueTypes[index].totaldays}; break;
            case "subjectTypes": tempForm = {subject:this.state.settings.subjectTypes[index].subject}; break;
            case "batchTypes": tempForm = {batch:this.state.settings.batchTypes[index].batch}; break;
            case "bookTypes": tempForm ={booktype:this.state.settings.bookTypes[index].booktype}; break;
            case "journalTypes": tempForm = {journaltype:this.state.settings.journalTypes[index].journaltype}; break;
            case "labeltype": tempForm ={label:this.state.settings.labelTypes[index].label}; break;

        }
        this.setState({formObj:tempForm, index:index,showSuccessMsg:false});

    }
    confirmDelete=(i,m)=>{
        this.setState({show:true,modelType:m,index:i});
    }

    deleteObj = (index, model) => {
        var tempSettings = this.state.settings;
        switch(model){			
			case "registrationTypes": tempSettings.registrationTypes.splice(index, 1); this.setState({show:false});break;
            case "billTypes": tempSettings.billTypes.splice(index, 1);this.setState({show:false});break;
            case "departmentTypes": tempSettings.departmentTypes.splice(index, 1);this.setState({show:false});break;
            case "issueTypes": tempSettings.issueTypes.splice(index, 1);this.setState({show:false});break;
            case "subjectTypes": tempSettings.subjectTypes.splice(index, 1);this.setState({show:false});break;
            case "batchTypes": tempSettings.batchTypes.splice(index, 1);this.setState({show:false});break;
            case "bookTypes": tempSettings.bookTypes.splice(index, 1);this.setState({show:false}); break;
            case "journalTypes": tempSettings.journalTypes.splice(index, 1);this.setState({show:false});break;
            case "labeltype": tempSettings.labelTypes.splice(index, 1);this.setState({show:false}); break;
        }
        this.setState({settings:tempSettings});
        this.saveSettings();
    }
   

    saveObj = (model) => {
      
        var tempSettings = this.state.settings;
        switch(model){			
			case "registrationTypes": if( this.state.index >= 0 ){
                tempSettings.registrationTypes[this.state.index] = this.state.formObj;
            }else{
                if(this.state.formObj.registrationtype !== "" && this.state.formObj.registrationtype !== undefined && this.state.formObj.latefee !== "" && this.state.formObj.latefee !== undefined && this.state.formObj.numberofbooks !=="" && this.state.formObj.numberofbooks !==undefined){
                    tempSettings.registrationTypes.push({registrationtype:this.state.formObj.registrationtype, latefee:this.state.formObj.latefee,numberofbooks:this.state.formObj.numberofbooks});
                  
                    this.setState({showSuccessMsg:true});
                 }
            }; break;
            case "departmentTypes": if( this.state.index >= 0 ){
                tempSettings.departmentTypes[this.state.index] = this.state.formObj;
            }else{
                if(this.state.formObj.department !== "" && this.state.formObj.department !== undefined){
                    tempSettings.departmentTypes.push({department:this.state.formObj.department});
                    this.setState({showSuccessMsg:true});
                }
            }; break;
            case "issueTypes": if( this.state.index >= 0 ){
                tempSettings.issueTypes[this.state.index] = this.state.formObj;
            }else{
                if(this.state.formObj.issuetype !== "" && this.state.formObj.issuetype !== undefined && this.state.formObj.totaldays !== "" && this.state.formObj.totaldays!== undefined){
                    tempSettings.issueTypes.push({issuetype:this.state.formObj.issuetype,totaldays:this.state.formObj.totaldays});
                    this.setState({showSuccessMsg:true});
                }
            }; break;
            case "subjectTypes": if( this.state.index >= 0 ){
                tempSettings.subjectTypes[this.state.index] = this.state.formObj;
            }else{
                if(this.state.formObj.subject !== "" && this.state.formObj.subject !== undefined){
                    tempSettings.subjectTypes.push({subject:this.state.formObj.subject});
                    this.setState({showSuccessMsg:true});
                }
            }; break;
            case "batchTypes": if( this.state.index >= 0 ){
                tempSettings.batchTypes[this.state.index] = this.state.formObj;
            }else{
                if(this.state.formObj.batch !== "" && this.state.formObj.batch !== undefined){
                    tempSettings.batchTypes.push({batch:this.state.formObj.batch});
                    this.setState({showSuccessMsg:true});
                }
            }; break;
            case "bookTypes": if( this.state.index >= 0 ){
                tempSettings.bookTypes[this.state.index] = this.state.formObj;
            }else{
                if(this.state.formObj.booktype !== "" && this.state.formObj.booktype !== undefined){
                    tempSettings.bookTypes.push({booktype:this.state.formObj.booktype});
                    this.setState({showSuccessMsg:true});
                }
            }; break;
            case "dateformats": if( this.state.settings.dateformats != undefined && this.state.settings.dateformats.length > 0){ 
                console.log(this.state.settings.dateformats);
            }
           // tempSettings.dateformats.push({dateformat:this.state.formObj.dateformat});
             break;
            case "journalTypes": if( this.state.index >= 0 ){
                tempSettings.journalTypes[this.state.index] = this.state.formObj;
            }else{
                if(this.state.formObj.journaltype !== "" && this.state.formObj.journaltype !== undefined){
                    tempSettings.journalTypes.push({journaltype:this.state.formObj.journaltype});
                    this.setState({showSuccessMsg:true});
                }
            }; break;
            case "labelTypes": if( this.state.index >= 0 ){
                tempSettings.labelTypes[this.state.index] = this.state.formObj;
            }else{
                if(this.state.formObj.label !== "" && this.state.formObj.label !== undefined){
                    tempSettings.labelTypes.push({label:this.state.formObj.label});
                    this.setState({showSuccessMsg:true});
                }
            }; break;
            case "billTypes": if( this.state.index >= 0 ){
                tempSettings.billTypes[this.state.index] = this.state.formObj;
            }else{
                if(this.state.formObj.billtype !== "" && this.state.formObj.billtype !== undefined && this.state.formObj.code !== "" && this.state.formObj.code !== undefined){
                    tempSettings.billTypes.push({billtype:this.state.formObj.billtype,code:this.state.formObj.code});
                    this.setState({showSuccessMsg:true});
                }
            }; break;
            
         }
        this.setState({settings:tempSettings, index:-1});
        this.saveSettings();
        this.closeModel();
        var tempobj={};
        this.setState({formObj:tempobj});
    }
    renderdateformat=()=>{
        this.state.settings.dateformats.map((item,index)=>{
            this.deletedateformat(index);
        })
   
    }
    deletedateformat=(index)=>{
        var tempSettings = this.state.settings;
        tempSettings.dateformats.splice(index);
        this.setState({settings:tempSettings});
    }

   renderDepartmentTypes = () => {
		if( this.state.settings.departmentTypes != undefined && this.state.settings.departmentTypes.length > 0){
			return this.state.settings.departmentTypes.map((item, index) =>{
				return (
					<tr key={index} id={index}>
						<td>{item.department}
						
						<span><FaPen size="15px" color="blue" onClick={()=>{this.editObj(index,'departmentTypes')}}/>&nbsp;&nbsp;
						<FaTimes size="15px" color="red" onClick={() => {this.confirmDelete(index, 'departmentTypes')}} />&nbsp;&nbsp;</span></td>
					</tr>
					)
			}, this);
		}
	}
    

    renderRegistrationTypes = () => {
		if( this.state.settings.registrationTypes != undefined && this.state.settings.registrationTypes.length > 0){
			return this.state.settings.registrationTypes.map((item, index) =>{
				return (
					<tr key={index} id={index}>
						<td>{item.registrationtype}</td>
						<td>{item.latefee}</td>
                        <td>{item.numberofbooks}
						<span><FaPen size="15px" color="blue" onClick={()=>{this.editObj(index, 'registrationTypes')}}/>&nbsp;&nbsp;
						<FaTimes size="15px" color="red" onClick={() => {this.confirmDelete(index, 'registrationTypes')}} />&nbsp;&nbsp;</span></td>
					</tr>
					)
			}, this);
		}
	}
    renderIssueTypes = () => {
		if( this.state.settings.issueTypes != undefined && this.state.settings.issueTypes.length > 0){
			return this.state.settings.issueTypes.map((item, index) =>{
				return (
					<tr key={index} id={index}>
						<td>{item.issuetype}</td>
						<td className="totaldays">{item.totaldays}
						<span><FaPen size="15px" color="blue" onClick={()=>{this.editObj(index, 'issueTypes')}}/>&nbsp;&nbsp;
						<FaTimes size="15px" color="red" onClick={() => {this.confirmDelete(index, 'issueTypes')}} />&nbsp;&nbsp;</span></td>
					</tr>
					)
			}, this);
		}
	}
    renderSubjectTypes = () => {
		if( this.state.settings.subjectTypes != undefined && this.state.settings.subjectTypes.length > 0){
			return this.state.settings.subjectTypes.map((item, index) =>{
				return (
					<tr key={index} id={index}>
						<td>{item.subject}
						<span><FaPen size="15px" color="blue" onClick={()=>{this.editObj(index, 'subjectTypes')}}/>&nbsp;&nbsp;
						<FaTimes size="15px" color="red" onClick={() => {this.confirmDelete(index, 'subjectTypes')}} />&nbsp;&nbsp;</span></td>
					</tr>
					)
			}, this);
		}
	}
    renderBatchTypes = () => {
		if( this.state.settings.batchTypes != undefined && this.state.settings.batchTypes.length > 0){
			return this.state.settings.batchTypes.map((item, index) =>{
				return (
					<tr key={index} id={index}>
						<td>{item.batch}
						<span><FaPen size="15px" color="blue" onClick={()=>{this.editObj(index,'batchTypes')}}/>&nbsp;&nbsp;
						<FaTimes size="15px" color="red" onClick={() => {this.confirmDelete(index, 'batchTypes')}} />&nbsp;&nbsp;</span></td>
					</tr>
					)
			}, this);
		}
	}
    renderBookTypes = () => {
		if( this.state.settings.bookTypes != undefined  &&  this.state.settings.bookTypes.length > 0){
			return this.state.settings.bookTypes.map((item, index) =>{
				return (
					<tr key={index} id={index}>
						<td>{item.booktype}
						<span><FaPen size="15px" color="blue" onClick={()=>{this.editObj(index,'bookTypes')}}/>&nbsp;&nbsp;
						<FaTimes size="15px" color="red" onClick={() => {this.confirmDelete(index, 'bookTypes')}} />&nbsp;&nbsp;</span></td>
					</tr>
				)
			}, this);
		}
	}
    renderJournalTypes = () => {
		if( this.state.settings.journalTypes != undefined && this.state.settings.journalTypes.length > 0){
			return this.state.settings.journalTypes.map((item, index) =>{
				return (
					<tr key={index} id={index}>
						<td>{item.journaltype}
						<span><FaPen size="15px" color="blue" onClick={()=>{this.editObj(index,'journalTypes')}}/>&nbsp;&nbsp;
						<FaTimes size="15px" color="red" onClick={() => {this.confirmDelete(index, 'journalTypes')}} />&nbsp;&nbsp;</span></td>
					</tr>
					)
			}, this);
		}
	}
    renderLabelTypes = () => {
		if( this.state.settings.labelTypes != undefined && this.state.settings.labelTypes.length > 0){
			return this.state.settings.labelTypes.map((item, index) =>{
				return (
					<tr key={index} id={index}>
						<td>{item.label}
						<span><FaPen size="15px" color="blue" onClick={()=>{this.editObj(index,'labeltype')}}/>&nbsp;&nbsp;
						<FaTimes size="15px" color="red" onClick={() => { this.confirmDelete(index, 'labeltype')}} />&nbsp;&nbsp;</span></td>
					</tr>
					)
			}, this);
		}
	}
    renderBillTypes = () => {
		if( this.state.settings.billTypes != undefined && this.state.settings.billTypes.length > 0){
			return this.state.settings.billTypes.map((item, index) =>{
				return (
					<tr key={index} id={index}>
						<td>{item.billtype}</td>
                        <td>{item.code}
						<span><FaPen size="15px" color="blue" onClick={()=>{this.editObj(index,'billTypes')}}/>&nbsp;&nbsp;
				        <FaTimes size="15px" color="red" onClick={()=>{this.confirmDelete(index, 'billTypes')}} />&nbsp;&nbsp;</span></td>
					</tr>
					)
			}, this);
		}
	}


format = (val, e) => { 
    let tempObj = this.state.formObj;
    switch(val){
        case "registrationtype": tempObj.registrationtype = Formators.name(e.target.value); break;
        case "latefee": tempObj.latefee = Formators.name(e.target.value); break;
        case "department":tempObj.department= Formators.name(e.target.value); break;
        case "issuetype" : tempObj.issuetype= Formators.name(e.target.value); break;
        case "totaldays" : tempObj.totaldays= Formators.name(e.target.value); break;
        case "subject" : tempObj.subject= Formators.name(e.target.value); break;
        case "numberofbooks" : tempObj.numberofbooks= Formators.name(e.target.value); break;
        case "batch" : tempObj.batch= Formators.name(e.target.value); break;
        case "booktype" : tempObj.booktype= Formators.name(e.target.value); break;
        case "dateformat" : tempObj.dateformat= Formators.name(e.target.value); this.savedateformat(tempObj.dateformat);break;
        case "journaltype" : tempObj.journaltype= Formators.name(e.target.value); break;
        case "label" : tempObj.label= Formators.name(e.target.value); break;
        case "billtype" : tempObj.billtype= Formators.name(e.target.value); break;
        case "code" : tempObj.code= Formators.name(e.target.value); break;
    }
    this.setState({formObj:tempObj});    
    
}

savedateformat = (dt) =>{
   // console.log(dt);
    if(dt !== "" && dt !== undefined){
        var temp=this.state.settings;
        temp.dateformats=dt;
        this.setState({settings:temp});
        console.log(this.state.settings.dateformats);
       this.saveSettings();
    }

}


    showModel = (model) =>{
        var tempModels = this.state.showModels;
        this.setState({showSuccessMsg:false});
        tempModels.registrationTypes = false;
        tempModels.billTypes = false;
        tempModels.departments = false;
        tempModels.issueType = false;
        tempModels.subjects = false;
        tempModels.batchtype = false;
        tempModels.booktype = false;
        tempModels.journalstype= false;
        tempModels.labeltype= false;
        this.setState({formObj:{}});
        
        switch(model){			
			case "registrationTypes": tempModels.registrationTypes = true; break;
            case "billTypes": tempModels.billTypes= true; break;
            case "departmentTypes": tempModels.departments = true; break;
            case "issueTypes": tempModels.issueType  = true; break;
            case "subjectTypes": tempModels.subjects  = true; break;
            case "batchTypes": tempModels.batchtype  = true; break;
            case "bookTypes": tempModels.booktype  = true; break;
            case "journalTypes": tempModels.journalstype  = true; break;
            case "labeltype": tempModels.labeltype  = true; break;

        }
        this.setState({showModels:tempModels});
    }

    closeModel = () =>{
        var tempModels = this.state.showModels;
        tempModels.registrationTypes = false;
        tempModels.billTypes = false;
        tempModels.departments = false; 
        tempModels.issueType = false; 
        tempModels.subjects = false; 
        tempModels.batchtype = false; 
        tempModels.booktype = false; 
        tempModels.journalstype = false; 
        tempModels.labeltype = false; 
        this.setState({showModels:tempModels,index:-1});
    }


    render() {
		return (
            <div className="my-4">
                {this.state.showSuccessMsg ?
                <div className="">
                    <FlashMessage duration={3000} persistOnHover={true}>
                      {flashMessage('Data is saved successfully', 'settingdata')}
                    </FlashMessage>	
                </div>:null}
                <div className="d-flex row">
			        <div className="col-md-4 p-3">
                        <p className="settingheader">Registration Type</p>
                        <div className="scrollbar">
                        <table className="table table-bordered table-sm">
                            <thead className="thead">
                                <tr>
                                    <td>Type</td>
                                    <td>Late fee per day</td>
                                    <td>Number of books</td>
                                </tr>
                            </thead>
                            <tbody>
                            {this.renderRegistrationTypes()}
                            </tbody> 
                        </table>
                        </div>
                        <div className="float-right">
                            <button className="btn btn-primary btn-sm" style={{marginTop:2}} onClick={()=>{this.showModel('registrationTypes')}}>+&nbsp;&nbsp;&nbsp;Add</button>
                        </div>
                    </div>
                    <div className="col-md-4  p-3">
                       <p className="settingheader">Issue Type</p>
                       <div className="scrollbar">
                        <table className="table table-bordered table-sm">
                            <thead className="thead">
                                <tr>
                                    <td> Issue Type</td>
                                    <td >Number Of Days</td>	
                                </tr>
                            </thead>
                            <tbody>
                            {this.renderIssueTypes()} 
                            </tbody> 
                        </table>
                        </div>
                        <div className="float-right">
                            <button className="btn btn-primary btn-sm" style={{marginTop:2}} onClick={()=>{this.showModel('issueTypes')}}>+&nbsp;&nbsp;&nbsp;Add</button>
                        </div>
                    </div>
                    <div className="col-md-4 p-3">
                        <p className="settingheader">Department Details</p>
                        <div className="scrollbar">
                        <table className="table table-bordered table-sm">
                            <thead className="thead">
                                <tr>
                                    <td> Department</td>
                                </tr>
                            </thead>
                            <tbody>
                            {this.renderDepartmentTypes()}
                            </tbody> 
                        </table>
                        </div>
                        <div className="float-right">
                            <button className="btn btn-primary btn-sm" style={{marginTop:2}}onClick={()=>{this.showModel('departmentTypes')}}>+&nbsp;&nbsp;&nbsp;Add</button>
                        </div>
                    </div>
                </div>
                <div className="d-flex row ">
                    <div className="col-md-4 p-3">
                        <p className="settingheader">Subject Details</p>
                        <div className="scrollbar">
                        <table className="table table-bordered table-sm">
                            <thead className="thead">
                                <tr>
                                    <td>Subject Name</td>
                                </tr>
                            </thead>
                            <tbody>
                            {this.renderSubjectTypes()} 
                            </tbody> 
                        </table>
                        </div>
                        <div className="float-right">
                            <button className="btn btn-primary btn-sm " style={{marginTop:2}}onClick={()=>{this.showModel('subjectTypes')}}>+&nbsp;&nbsp;&nbsp;Add</button>
                        </div>
                    </div>
                    <div className="col-md-4 p-3">
                        <p className="settingheader">Batch Details</p>
                        <div className="scrollbar">
                        <table className="table table-bordered table-sm">
                            <thead className="thead">
                                <tr>
                                    <td> Batch Name</td>
                                </tr>
                            </thead>
                            <tbody>
                            {this.renderBatchTypes()} 
                            </tbody> 
                        </table>
                        </div>
                        <div className="float-right">
                            <button className="btn btn-primary btn-sm"style={{marginTop:2}} onClick={()=>{this.showModel('batchTypes')}}>+&nbsp;&nbsp;&nbsp;Add</button>
                        </div>
                    </div>
                    <div className="col-md-4 p-3" >
                        <p className="settingheader">Book Type Details</p>
                        <div className="scrollbar">
                        <table className="table table-bordered table-sm">
                            <thead className="thead">
                                <tr>
                                    <td> Book Type</td>	
                                </tr>
                            </thead>
                            <tbody>
                            {this.renderBookTypes()}
                            </tbody> 
                        </table>
                        </div>
                        <div className="float-right">
                            <button className="btn btn-primary btn-sm" style={{marginTop:2}}onClick={()=>{this.showModel('bookTypes')}}>+&nbsp;&nbsp;&nbsp;Add</button>
                        </div>
                    </div>
                </div>
                <div className="d-flex row ">
                    <div className="col-md-4 p-3">
                        <p className="settingheader">Journal Type Details</p>
                        <div className="scrollbar">
                        <table className="table table-bordered table-sm">
                            <thead className="thead">
                                <tr>
                                    <td>Journal Type</td>	
                                </tr>
                            </thead>
                            <tbody>
                            {this.renderJournalTypes()} 
                            </tbody> 
                        </table>
                        </div>
                        <div className="float-right">
                            <button className="btn btn-primary btn-sm"style={{marginTop:2}} onClick={()=>{this.showModel('journalTypes')}}>+&nbsp;&nbsp;&nbsp;Add</button>
                        </div>
                    </div>
                    <div className="col-md-4 p-3">
                        <p className="settingheader">Label Type</p>
                        <div className="scrollbar">
                        <table className="table table-bordered table-sm">
                            <thead className="thead">
                                <tr>
                                    <td>Barcode Label Type</td>	
                                </tr>
                            </thead>
                            <tbody>
                            {this.renderLabelTypes()} 
                            </tbody> 
                        </table>
                        </div>
                        <div className="float-right">
                            <button className="btn btn-primary btn-sm"style={{marginTop:2}}  onClick={()=>{this.showModel('labeltype')}}>+&nbsp;&nbsp;&nbsp;Add</button>
                        </div>
                    </div>
                    <div className="col-md-4 p-3">
                        <p className="settingheader ">Date Format</p>
                        <select  value={this.state.settings.dateformats} id="iddateformat"  onChange={(e)=>{this.format("dateformat",e)}} className="form-control form-control-sm">
                            <option value="">Select</option>
                            <option value="MM/dd/yyyy">MM/dd/yyyy</option>
                            <option value="dd/MM/yyyy">dd/MM/yyyy</option>
                            <option value="yyyy/MM/dd">yyyy/MM/dd</option>
                           
                        </select>
                    </div>
                </div>
                <div className="d-flex row">
                    <div className="col-md-4 p-3">
                        <p className="settingheader">Bill Type</p>
                        <div className="scrollbar">
                        <table className="table table-bordered table-sm">
                            <thead className="thead">
                                <tr>
                                    <td>Bill Type</td>
                                    <td>Code</td>	
                                </tr>
                            </thead>
                            <tbody>
                            {this.renderBillTypes()}
                            </tbody> 
                        </table>
                        </div>
                        <div className="float-right">
                            <button className="btn btn-primary btn-sm" style={{marginTop:2}} onClick={()=>{this.showModel('billTypes')}}>+&nbsp;&nbsp;&nbsp;Add</button>
                        </div>
                    </div>
                </div>
                    
                    <Modal size="lg"show={this.state.showModels.registrationTypes} onHide={()=>{this.closeModel()}}>
                        <Modal.Header closeButton>
                            <Modal.Title>Registration Type</Modal.Title>
                        </Modal.Header>
                        <ModalBody>
                            <div className="d-flex row">
                                <div className="col-md-4">
                                    <label htmlFor="idregistrationtype" className="label-custom" >Registration Type</label> 		
                                    <input value={this.state.formObj.registrationtype} id="idregistrationtype"  onChange={(e)=>{this.format("registrationtype",e)}}   className="form-control form-control-sm"  type="text" className='form-control form-control-sm' required /> 
                                    </div>
                                <div className="col-md-4">
                                    <label htmlFor="idlatefee" className="label-custom" >Late Fee</label> 		
                                    <input value={this.state.formObj.latefee} id="idlatefee"  onChange={(e)=>{this.format("latefee",e)}}   className="form-control form-control-sm"  type="text" className='form-control form-control-sm' required /> 
                                    </div>
                                <div className="col-md-4">
                                    <label htmlFor="idnumberofbooks" className="label-custom" >Number Of Books</label> 		
                                    <input value={this.state.formObj.numberofbooks} id="idnumberofbooks"  onChange={(e)=>{this.format("numberofbooks",e)}}   className="form-control form-control-sm"  type="text" className='form-control form-control-sm' required /> 
                                       </div>
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            <Button variant="warning" onClick={()=>{this.closeModel()}}>CLOSE</Button>
                            <Button variant="primary" onClick={()=>{this.saveObj('registrationTypes')}}>SAVE</Button>
                        </ModalFooter>
                    </Modal>
                    <Modal size="sm" show={this.state.showModels.departments} onHide={()=>{this.closeModel()}}>
                        <Modal.Header closeButton>
                            <Modal.Title>Department</Modal.Title>
                        </Modal.Header>
                        <ModalBody>
                            <div className="col-md-12">
                                <label htmlFor="iddepartment" className="label-custom" >Department Name</label> 				
                                <input value={this.state.formObj.department}   onChange={(e)=>{this.format("department",e)}}    className="form-control form-control-sm"  type="text" className='form-control form-control-sm' required /> 
                        
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            <Button variant="warning" onClick={()=>{this.closeModel()}}>CLOSE</Button>
                            <Button variant="primary" onClick={()=>{this.saveObj('departmentTypes')}}>SAVE</Button>
                        </ModalFooter>
                    </Modal>
                    <Modal size="sm"show={this.state.showModels.subjects} onHide={()=>{this.closeModel()}}>
                        <Modal.Header closeButton>
                            <Modal.Title>Subject</Modal.Title>
                        </Modal.Header>
                        <ModalBody>
                            <div className="col-md-12">
                                <label htmlFor="idsubject" className="label-custom" >Subject Name</label> 		
                                <input  value={this.state.formObj.subject}   onChange={(e)=>{this.format("subject",e)}}   className="form-control form-control-sm"  type="text" className='form-control form-control-sm' required /> 
                       
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            <Button variant="warning" onClick={()=>{this.closeModel()}}>CLOSE</Button>
                            <Button variant="primary" onClick={()=>{this.saveObj('subjectTypes')}}>SAVE</Button>
                        </ModalFooter>
                    </Modal>
                    <Modal size="sm"show={this.state.showModels.labeltype} onHide={()=>{this.closeModel()}}>
                        <Modal.Header closeButton>
                            <Modal.Title>Barcode Labels</Modal.Title>
                        </Modal.Header>
                        <ModalBody>
                            <div className="col-md-12">
                                <label htmlFor="idlabel" className="label-custom" >Label Name</label> 		
                                <input  value={this.state.formObj.label}  onChange={(e)=>{this.format("label",e)}}     className="form-control form-control-sm"  type="text" className='form-control form-control-sm' required /> 
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            <Button variant="warning" onClick={()=>{this.closeModel()}}>CLOSE</Button>
                            <Button variant="primary" onClick={()=>{this.saveObj('labelTypes')}}>SAVE</Button>
                        </ModalFooter>
                    </Modal>
                    <Modal size="sm"show={this.state.showModels.batchtype} onHide={()=>{this.closeModel()}}>
                        <Modal.Header closeButton>
                            <Modal.Title>Batch Types</Modal.Title>
                        </Modal.Header>
                        <ModalBody>
                            <div className="col-md-12">	
                                <label htmlFor="idbatch" className="label-custom" >Batch Name</label> 		
                                <input value={this.state.formObj.batch}   onChange={(e)=>{this.format("batch",e)}}   className="form-control form-control-sm"  type="text" className='form-control form-control-sm' required /> 
                    
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            <Button variant="warning" onClick={()=>{this.closeModel()}}>CLOSE</Button>
                            <Button variant="primary" onClick={()=>{this.saveObj('batchTypes')}}>SAVE</Button>
                        </ModalFooter>
                    </Modal>
                    <Modal size="sm"show={this.state.showModels.booktype} onHide={()=>{this.closeModel()}}>
                        <Modal.Header closeButton>
                            <Modal.Title>Book Types</Modal.Title>
                        </Modal.Header>
                        <ModalBody>
                            <div className="col-md-12">
                                <label htmlFor="idbooktype" className="label-booktype" >Book Type</label> 		
                                <input value={this.state.formObj.booktype}   onChange={(e)=>{this.format("booktype",e)}}    className="form-control form-control-sm"  type="text" className='form-control form-control-sm' required /> 
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            <Button variant="warning" onClick={()=>{this.closeModel()}}>CLOSE</Button>
                            <Button variant="primary" onClick={()=>{this.saveObj('bookTypes')}}>SAVE</Button>
                        </ModalFooter>
                    </Modal>
           
                    <Modal size="sm"show={this.state.showModels.journalstype} onHide={()=>{this.closeModel()}}>
                        <Modal.Header closeButton>
                            <Modal.Title>Journal Types</Modal.Title>
                        </Modal.Header>
                        <ModalBody>
                            <div className="col-md-12">
                                 <label htmlFor="idbooktype" className="label-batchtype" >Journal Type</label> 		
                                <input  value={this.state.formObj.journaltype}  onChange={(e)=>{this.format("journaltype",e)}}    className="form-control form-control-sm"  type="text" className='form-control form-control-sm' required /> 
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            <Button variant="warning" onClick={()=>{this.closeModel()}}>CLOSE</Button>
                            <Button variant="primary" onClick={()=>{this.saveObj('journalTypes')}}>SAVE</Button>
                    </ModalFooter>
                </Modal>
                <Modal size="lg"show={this.state.showModels.issueType} onHide={()=>{this.closeModel()}}>
                    <Modal.Header closeButton>
                        <Modal.Title>Issue Type</Modal.Title>
                    </Modal.Header>
                    <ModalBody>
                        <div className="d-flex row">
                            <div className="col-md-6">
                                <label htmlFor="idissuetype" className="label-custom" >Issue Type</label> 		
                                <input value={this.state.formObj.issuetype} onChange={(e)=>{this.format("issuetype",e)}}    className="form-control form-control-sm"  type="text" className='form-control form-control-sm' required /> 
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="idtotaldays" className="label-custom" >Number Of Days</label> 		
                                <input className="form-control form-control-sm" value={this.state.formObj.totaldays} onChange={(e)=>{this.format("totaldays",e)}}  type="text" className='form-control form-control-sm' required /> 
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button variant="warning" onClick={()=>{this.closeModel()}}>CLOSE</Button>
                        <Button variant="primary" onClick={()=>{this.saveObj('issueTypes')}}>SAVE</Button>
                    </ModalFooter>
                </Modal>
                <Modal size="lg"show={this.state.showModels.billTypes} onHide={()=>{this.closeModel()}}>
                    <Modal.Header closeButton>
                        <Modal.Title>Bill Type</Modal.Title>
                    </Modal.Header>
                    <ModalBody>
                        <div className="d-flex row">
                            <div className="p-2 flex-fill">
                                <label htmlFor="idbilltype" className="label-custom" >Bill Type</label> 		
                                <input id="idbilltype"   value={this.state.formObj.billtype} onChange={(e)=>{this.format("billtype",e)}} className="form-control form-control-sm"  type="text" className='form-control form-control-sm' required /> 
                            </div>
                            <div className="p-2 flex-fill">
                                <label htmlFor="idcode" className="label-custom" >Code</label> 		
                                <input   value={this.state.formObj.code} onChange={(e)=>{this.format("code",e)}}  className="form-control form-control-sm"  type="text" className='form-control form-control-sm' required /> 
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button variant="warning" onClick={()=>{this.closeModel()}}>CLOSE</Button>
                        <Button variant="primary" onClick={()=>{this.saveObj('billTypes')}}>SAVE</Button>
                    </ModalFooter>
                </Modal>
                <Modal size="md" show={this.state.show} centered onHide={()=>{this.setState({show:false})}}>
                    <ModalBody>
			            <div className="d-flex justify-content-right">
                            <div className="p-2">Are You Sure ?? Want to Delete the data !!.</div>
			            </div>
			            <div className="d-flex justify-content-end">
			                <div className="p-2"><button className="btn btn-warning" onClick={()=>{this.deleteObj(this.state.index, this.state.modelType)}}>YES</button></div>
			                <div className="p-2"><button className="btn btn-primary" onClick={()=>{this.setState({show:false})}}>NO</button></div>
			            </div>
			        </ModalBody>
                </Modal>
            </div>
        )
	}
    
}

const mapToProps = ({ loggedUserState }) => ({ user: loggedUserState })
export default connect(mapToProps)(SettingsForm);
   