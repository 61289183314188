export const barcode = {
    selectcatalog:'',
	selectcatalog1:'',
	selectlabel:'',
	barcodetype:'',
	notes:'',
	barcodefrom:'',
	barcodeto:'',
    id : '', 
	createdby : '', 
	updatedby : '', 
	date_created : '', 
	date_updated : '', 

}