///////////////////////////////////////////////////////////////////////////////////////////////
// Warning : Property of Deemsoft Global Solutions LLC, It is illegal to make copy of this code
// Author : Gangaswamy Kempahonnaiah
///////////////////////////////////////////////////////////////////////////////////////////////
export const Journals_Catalog = {
	pissn : '', 
	barcode : '', 
	eissn : '', 
	journaltitle : '', 
	journalpublisher : '', 
	journaltype : '', 
	vendor : '', 
	ponum : '', 
	podate : '', 
	price : '', 
	periodicity : '', 
	volumeandyear : '', 
	checknum : '', 
	checkdate : '', 
	notes : '',
	month:'',
	year:'',
	volumeno:'',
	issueno:'',
	journalstatus:'', 
	id : '', 
	_id:'',
	createdby : '', 
	updatedby : '', 
	date_created : '', 
	date_updated : '', 
}