export const Report = {
    selectcatalog:'',
    id : '', 
	check:'',
	startdate:'',
	enddate:'',
	createdby : '', 
	updatedby : '', 
	date_created : '', 
	date_updated : '', 

}