import React from 'react';
import axios from 'axios';
import { base_url } from '../constants/Constants';
import flashMessage from '../utils/Flash';
import FlashMessage from 'react-flash-message'
import queryString from 'query-string';
import Logo from '../assets/favicon1.png';

export default class ResetPassword extends React.Component {
  constructor(props){
    super(props);
      this.state = { 
        newpassword : '',
        confirmpwd : '',      
        showSuccessMsg : false,      	
        message:'',
        msgType:'',
      };     
    }
  
  newpasswordChange = (e) =>{   
    this.setState({ newpassword:e.target.value});
  }

  confirmpwdChange = (e) =>{   
    this.setState({ confirmpwd:e.target.value});
  }
  
  backToLogin = () => {
    this.props.history.push('/login');
  }

  resetPassword = () => {
    if(this.state.newpassword === this.state.confirmpwd){
      let url = this.props.location.search;
      let params = queryString.parse(url);                   
      var formObj = {resetcode:params.id, newpassword:this.state.newpassword};           
      axios.post(base_url+'main/submitpass', formObj)
          .then(res => { 
              this.setState({msgType:'Success', message:'Your account password updated successfully, You can login with new password.', showSuccessMsg:true});
              //alert("Your account password updated successfully")
              setTimeout(this.backToLogin, 3000);
      }).catch(function (error) { 
          console.log(error);          
      });
      setTimeout(this.backToLogin,5000);  
    }else{                    
        this.setState({msgType:'Failure', message:'Confirm password is not matching with password...', showSuccessMsg:true}); 
    }
  }
    
  render() {
    return (
      <div className="container">
        <br/><br/><br/><br/><br/><br/><br/>     
        <div className="row"> 
          <div className="col-md-4">
          </div>         
          <div className="col-md-4">
              <div>
                {this.state.showSuccessMsg ?
                  <div>
                    <FlashMessage duration={5000} persistOnHover={true}>
                      {flashMessage(this.state.message, this.state.msgType)}
                    </FlashMessage>	
                  </div>
                :null
                }
              </div> 
              <div className="d-flex justify-content-center">
                
                <span onClick={() =>this.backToLogin()}><img  src={Logo} alt="Logo" /></span>
                </div><br/>             
              <div className="row">
                  <div className="col-md-12 text-center loginsignintxt">                               
                      <span>Reset Password</span>                             
                  </div>                            
              </div>
              <br/>              
              <div className="row">
                <div className="col-md-12">                 
                  <div className="form-group">                      
                      <input type="password" className="form-control" id="newpassword" name="newpassword" value={this.state.newpassword} placeholder="New Password"  required onChange={this.newpasswordChange}/>
                      <div className="valid-feedback">Valid.</div>
                      <div className="invalid-feedback">Please fill out this field.</div>
                  </div>
                  <div className="form-group">                      
                      <input type="password" className="form-control" id="confirmpwd" name="confirmpwd" value={this.state.confirmpwd} placeholder="Confirm Password" required onChange={this.confirmpwdChange}/>
                      <div className="valid-feedback">Valid.</div>
                      <div className="invalid-feedback">Please fill out this field.</div>
                  </div>
                </div>              
              </div> 

              <div className="row">                
                <div className="col-md-12 text-right">                               
                  <button type="button" className="btn btn-primary btn-block" onClick={() =>this.resetPassword()}>Submit</button>                           
                </div>
              </div>
              <br/>                                
            </div>
            <div className="col-md-4">

            </div>
        </div>     
      </div>
    );
  }
}

