///////////////////////////////////////////////////////////////////////////////////////////////
// Warning : Property of Deemsoft Global Solutions LLC, It is illegal to make copy of this code
// Author : Gangaswamy Kempahonnaiah
///////////////////////////////////////////////////////////////////////////////////////////////
export const Journals_Editions = {
	journalid : '', 
	barcode : '', 
	pissn : '', 
	journaltitle : '', 
	journalpublisher : '', 
	journalmonth : '', 
	journalyear : '', 
	volumenum : '', 
	issuenum : '', 
	receiveddate : '', 
	notes : '', 
	id : '', 
	createdby : '', 
	updatedby : '', 
	date_created : '', 
	date_updated : '', 
}