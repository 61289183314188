import React, {Component} from 'react';
import { Bar } from 'react-chartjs-2';
import { connect } from 'react-redux';



class Barchart extends Component {
	constructor(props){
		super(props);
		this.state = {
			
        }
    }
componentDidMount() {
//	console.log(this.props.parentObject);
}
render(){

  const data = {
    labels: this.props.parentObject.label,
    datasets: [
      {
       label:'Checkin',
        backgroundColor: "#36A2EB",
        data:this.props.parentObject.checkin,
      }, {
        label:'Checkout',
        backgroundColor: "#FF6384",
        data:this.props.parentObject.checkouts,
      }
    ]
  
  };
  
    return (
      <div>
         <Bar
            ref="chart"
            data={data}
            width={100}
            height={60}
            
          />
      </div>
    );
  }

}
const mapToProps = ({ loggedUserState }) => ({ user: loggedUserState })
export default connect(mapToProps)(Barchart);
