const initialState = {
    page: ''
  }
  
  const PageStateReducer = (state = initialState, action) => {
    
    if (action.type === 'GET_CURRENT_PAGE') {
      return Object.assign({}, state, {
        page: action.page,
      })
    }
    
    if (action.type === 'SET_CURRENT_PAGE') {
      return Object.assign({}, state, {       
        page: action.page,
      })
    }
    
    return state;
  }
  
  export default PageStateReducer;