////////////////////////////////////////////////////////////////////////////////////////////////////
// Warning : Property of Deemsoft Global Solutions LLC, USA. It is illegal to make copy of this code
// Author : Gangaswamy Kempahonnaiah
////////////////////////////////////////////////////////////////////////////////////////////////////
import React from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { FaPen, FaTimes } from 'react-icons/fa';
import { Button, Modal, ModalBody, ModalFooter } from 'react-bootstrap';
import { base_url } from '../constants/Constants';
import { Validators } from '../utils/Validators';
import { Formators } from '../utils/Formators';
import { Permissions } from '../models/Permissions';
import '../styles/Styles.css';

class PermissionsForm extends React.Component {
constructor(props){
	super(props);
	this.state = {
		objList : [],
		showModal : false,
		validations : {},
		formObj : Permissions,
	}
	this.getObjList = this.getObjList.bind(this);
	this.saveObj = this.saveObj.bind(this);
	this.addObj = this.addObj.bind(this);
	this.editObj = this.editObj.bind(this);
	this.validate = this.validate.bind(this);
	this.format = this.format.bind(this);
	this.deleteObj = this.deleteObj.bind(this);
}

componentDidMount(){
	let tempObj = {};
	for (let key in Permissions) { tempObj[key] = ""; }
	this.setState({validations:tempObj});
	this.getObjList();
}

getObjList = () => {
	axios.get(base_url+'permissions/list', this.props.user.header ).then(res => {
		if ( res.data.length > 0 ){
			this.setState({ objList:res.data});
		}
	}).catch(function (error) { console.log(error); });
}
saveObj = () => {
	axios.post(base_url+'permissions/save', this.state.formObj, this.props.user.header ).then(res => {
		if ( res.data.length > 0 ){
			this.setState({ formObj:res.data});
			this.getObjList();
		}
	}).catch(function (error) { console.log(error); });
}

addObj = () => { 
	let tempObj = {};
	for (let key in Permissions ) { tempObj[key] = ""; }
	this.setState({ formObj:tempObj, showModal:true});
}

editObj = (i) => { 
	let tempObj = {};
	for (let key in this.state.objList[i] ) { tempObj[key] = this.state.objList[i][key]; }
	this.setState({ formObj:tempObj, showModal:true});
}

deleteObj = (i) => { 
	let tempObj = this.state.objList[i];
	tempObj.status = 'Inactive';
	axios.post(base_url+'permissions/save', tempObj , this.props.user.header ).then(res =>{
		if ( res.data.length > 0 ){
			this.setState({ formObj:res.data});
			this.getObjList();
		}
	}).catch(function (error) { console.log(error); });
}

validate = (val) => {
	let tempObj = this.state.validations;
	switch(val){
		case "groupid": tempObj.groupid = Validators.name(this.state.formObj.groupid); break;
		case "modulename": tempObj.modulename = Validators.name(this.state.formObj.modulename); break;
		case "read": tempObj.read = Validators.name(this.state.formObj.read); break;
		case "write": tempObj.write = Validators.name(this.state.formObj.write); break;
		case "print": tempObj.print = Validators.name(this.state.formObj.print); break;
		case "download": tempObj.download = Validators.name(this.state.formObj.download); break;
		case "share": tempObj.share = Validators.name(this.state.formObj.share); break;
		case "status": tempObj.status = Validators.name(this.state.formObj.status); break;
	}
	this.setState({validations:tempObj});
	} 

format = (val, e) => { 
	let tempObj = this.state.formObj;
	switch(val){
		case "groupid": tempObj.groupid = Formators.name(e.target.value); break;
		case "modulename": tempObj.modulename = Formators.name(e.target.value); break;
		case "read": tempObj.read = Formators.name(e.target.value); break;
		case "write": tempObj.write = Formators.name(e.target.value); break;
		case "print": tempObj.print = Formators.name(e.target.value); break;
		case "download": tempObj.download = Formators.name(e.target.value); break;
		case "share": tempObj.share = Formators.name(e.target.value); break;
		case "status": tempObj.status = Formators.name(e.target.value); break;
	}
	this.setState({formObj:tempObj});
	} 

renderObjlist = () => {
	if( this.state.objList != undefined && this.state.objList.length > 0){
		return this.state.objList.map((item, index) =>{
			return (
				<tr key={index} id={index}>
					<td>{item.groupid}</td>
					<td>{item.modulename}</td>
					<td>{item.read}</td>
					<td>{item.write}</td>
					<td>{item.print}</td>
					<td>{item.download}</td>
					<td>{item.share}</td>
					<td>{item.status}</td>
					<td><span><FaPen size="15px" color="blue" onClick={()=>{this.editObj(index)}}/>&nbsp;&nbsp;
					<FaTimes size="15px" color="red" onClick={() => {this.deleteObj(index)}} />&nbsp;&nbsp;</span></td>
				</tr>
				)
			}, this);
	}
}

render() {
	return (
	<div class="container mt-3">
		<label className="label-custom">PERMISSIONS LIST</label>
		<table className="table table-bordered table-sm">
		<thead className="thead">
			<tr>
				<td>Groupid</td>
				<td>Modulename</td>
				<td>Read</td>
				<td>Write</td>
				<td>Print</td>
				<td>Download</td>
				<td>Share</td>
				<td>Status</td>
			</tr>
		</thead>
		<tbody>{ this.renderObjlist() }</tbody> 
		</table>
		<div className="d-flex justify-content-end mb-3">
			<div className="p-2"><button type="button" className="btn btn-primary btn-block btn-sm" onClick={()=>{this.addObj()}}>+Add</button></div>
		</div>
		<Modal size="lg" show={this.state.showModal} onHide={()=>{this.setState({showModal:false})}} >
		<Modal.Header closeButton>
		<Modal.Title>PERMISSIONS</Modal.Title>
		</Modal.Header>
		<ModalBody>
			<div className="d-flex flex-wrap bg-light">
				<div className="p-2 border">
					<label htmlFor="idgroupid" className="label-custom" >Groupid</label> 
					<input value={this.state.formObj.groupid} id="idgroupid"  onChange={(e)=>{this.format("groupid",e)}}  placeholder="Enter Groupid" onBlur={()=>{ this.validate("groupid")}} className="form-control form-control-sm" style={this.state.validations.email===""?{}:{"borderColor":"red"}} type="text" className='form-control form-control-sm' required /> 
					<span className="errormsg">{this.state.validations.staffname}</span>
				</div>
				<div className="p-2 border">
					<label htmlFor="idmodulename" className="label-custom" >Modulename</label> 
					<input value={this.state.formObj.modulename} id="idmodulename"  onChange={(e)=>{this.format("modulename",e)}}  placeholder="Enter Modulename" onBlur={()=>{ this.validate("modulename")}} className="form-control form-control-sm" style={this.state.validations.email===""?{}:{"borderColor":"red"}} type="text" className='form-control form-control-sm' required /> 
					<span className="errormsg">{this.state.validations.staffname}</span>
				</div>
				<div className="p-2 border">
					<label htmlFor="idread" className="label-custom" >Read</label> 
					<input value={this.state.formObj.read} id="idread"  onChange={(e)=>{this.format("read",e)}}  placeholder="Enter Read" onBlur={()=>{ this.validate("read")}} className="form-control form-control-sm" style={this.state.validations.email===""?{}:{"borderColor":"red"}} type="text" className='form-control form-control-sm' required /> 
					<span className="errormsg">{this.state.validations.staffname}</span>
				</div>
				<div className="p-2 border">
					<label htmlFor="idwrite" className="label-custom" >Write</label> 
					<input value={this.state.formObj.write} id="idwrite"  onChange={(e)=>{this.format("write",e)}}  placeholder="Enter Write" onBlur={()=>{ this.validate("write")}} className="form-control form-control-sm" style={this.state.validations.email===""?{}:{"borderColor":"red"}} type="text" className='form-control form-control-sm' required /> 
					<span className="errormsg">{this.state.validations.staffname}</span>
				</div>
				<div className="p-2 border">
					<label htmlFor="idprint" className="label-custom" >Print</label> 
					<input value={this.state.formObj.print} id="idprint"  onChange={(e)=>{this.format("print",e)}}  placeholder="Enter Print" onBlur={()=>{ this.validate("print")}} className="form-control form-control-sm" style={this.state.validations.email===""?{}:{"borderColor":"red"}} type="text" className='form-control form-control-sm' required /> 
					<span className="errormsg">{this.state.validations.staffname}</span>
				</div>
				<div className="p-2 border">
					<label htmlFor="iddownload" className="label-custom" >Download</label> 
					<input value={this.state.formObj.download} id="iddownload"  onChange={(e)=>{this.format("download",e)}}  placeholder="Enter Download" onBlur={()=>{ this.validate("download")}} className="form-control form-control-sm" style={this.state.validations.email===""?{}:{"borderColor":"red"}} type="text" className='form-control form-control-sm' required /> 
					<span className="errormsg">{this.state.validations.staffname}</span>
				</div>
				<div className="p-2 border">
					<label htmlFor="idshare" className="label-custom" >Share</label> 
					<input value={this.state.formObj.share} id="idshare"  onChange={(e)=>{this.format("share",e)}}  placeholder="Enter Share" onBlur={()=>{ this.validate("share")}} className="form-control form-control-sm" style={this.state.validations.email===""?{}:{"borderColor":"red"}} type="text" className='form-control form-control-sm' required /> 
					<span className="errormsg">{this.state.validations.staffname}</span>
				</div>
				<div className="p-2 border">
					<label htmlFor="idstatus" className="label-custom" >Status</label> 
					<input value={this.state.formObj.status} id="idstatus"  onChange={(e)=>{this.format("status",e)}}  placeholder="Enter Status" onBlur={()=>{ this.validate("status")}} className="form-control form-control-sm" style={this.state.validations.email===""?{}:{"borderColor":"red"}} type="text" className='form-control form-control-sm' required /> 
					<span className="errormsg">{this.state.validations.staffname}</span>
				</div>
			</div>
		</ModalBody>
		<ModalFooter>
			<Button variant="warning" onClick={()=>{this.setState({showModal:false})}}>CLOSE</Button>
			<Button variant="primary" onClick={()=>{this.saveObj()}}>SAVE</Button>
		</ModalFooter>
		</Modal>
		</div>
	)
	}
}

const mapToProps = ({ loggedUserState }) => ({ user: loggedUserState })
export default connect(mapToProps)(PermissionsForm);
