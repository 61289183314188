/////////////////////////////////////////////////
// Warning : Propeerty of Deemsoft, It is illegal to make copy of this code
// Author : Gangaswamy Kempahonnaiah
//////////////////////////////////////////////////////
import React, {Component} from 'react';
import { connect } from 'react-redux';
import { base_url } from '../constants/Constants';
import axios from 'axios';
import { Button, Modal, ModalBody, ModalFooter } from 'react-bootstrap';
import Piechart from '../charts/Pie';
import Doughnutchart from '../charts/Doughnut';
import  PolarChart from '../charts/Polar';
import Barchart from '../charts/Bar';


class Dashboard extends Component {
	constructor(props){
		super(props);
		this.state = {
			objList:[],
			mainform:false,
			duedate:"",
			showpending:false,
		    resgitration:{labels:[],numlist:[]},
			catalog:{numlist:[]},
			bookstatus:{numlist:[]},
			iobookdata:{label:[],checkin:[],checkouts:[]}
		}
		this.gotoMenu=this.gotoMenu.bind(this);
	}  
	componentDidMount(){

var current=new Date();
var month=current.getMonth()+1;
var  months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
var checkin=[];	
var checkout=[];
months.splice(month, months.length);
axios.get(base_url+'checkin/getbymonth', this.props.user.header ).then(res => {
 var objdata=res.data;
 for(var i=1;i<=month;i++){
 for(var j in objdata){
	 if(i == objdata[j]._id){
		checkin.push(objdata[j].count);
	 }
 }
 if(i !== checkin.length){
  checkin.push(0);
 }
}
axios.get(base_url+'checkout/getbymonth', this.props.user.header ).then(res => {
 var checkoutdata=res.data;
 for(var i=1;i<=month;i++){
 for(var j in checkoutdata){
	 if(i == checkoutdata[j]._id){
		checkout.push(checkoutdata[j].count);
	 }
 }
 if(i !== checkout.length){
  checkout.push(0);
 }
}
var ioobj=this.state.iobookdata;
ioobj.label=months;
ioobj.checkin=checkin;
ioobj.checkouts=checkout;
this.setState({iobookdata:ioobj});
}).catch(function (error) { console.log(error); }); 
}).catch(function (error) { console.log(error); }); 


axios.get(base_url+'members/list', this.props.user.header ).then(res => {

var obj=res.data.reduce(function(a, e) {
let estKey = (e['registrationtype']); 
(a[estKey] ? a[estKey] : (a[estKey] = null || [])).push(e);
return a;
}, {});
//console.log(obj);
var temp=this.state.resgitration;
var arrtemp=Object.values(obj);
for(var i in arrtemp){
	temp.numlist.push(arrtemp[i].length);
}
this.setState({resgitration:temp});

for ( var property in obj ) {
this.state.resgitration.labels.push(property);
}
axios.get(`https://elibrarysoft.com/transactions/list`)
		.then(res => {
			var x=res.data;
			for(var i in x){
				if(x[i].accountid == this.props.user.accountid){
				if(x[i].status === "Pending"){
					if(this.props.user.usertype === "admin"){
                    this.setState({duedate:x[i].duedate,showpending:true});
					}
				}
				}
			}
}).catch(function (error) { console.log(error); });			
axios.get(base_url+'books_catalog/list', this.props.user.header ).then(res => {
this.state.catalog.numlist.push(res.data.length);
this.state.bookstatus.numlist.push(res.data.length);
axios.get(base_url+'checkout/list', this.props.user.header ).then(res => {
this.state.bookstatus.numlist.push(res.data.length);
let temp={type: "Damaged"}
axios.post(base_url+'books_catalog/search1', temp, this.props.user.header )
.then(res =>{
this.state.bookstatus.numlist.push(res.data.length);
axios.get(base_url+'cd_catalog/list', this.props.user.header ).then(res => {
this.state.catalog.numlist.push(res.data.length);
axios.get(base_url+'journals_catalog/list', this.props.user.header ).then(res => {
	this.state.catalog.numlist.push(res.data.length);
		this.setState({mainform:true});
		}).catch(function (error) { console.log(error); });
	}).catch(function (error) { console.log(error); });
}).catch(function (error) { console.log(error); });
}).catch(function (error) { console.log(error); });
}).catch(function (error) { console.log(error); });
}).catch(function (error) { console.log(error); });

			
	} 
	gotoMenu(){
		this.props.history.push('/app/menus');
	}
	
	
 	render() {

	  	return (

		<div>{this.state.mainform?
			<div className="container">
			<div className="d-flex justify-content-end">
				<div className="p-2"><button type="button" className="btn btn-success" onClick={this.gotoMenu}>Get Started</button></div>
			</div>
			<br/>
			<div className="row">
				<div className="col-md-5 ">
					<div className="card">
						<div className="card-header p-1 btnclass">Total Registration</div>
						<div className="card-body"><Doughnutchart parentObject={this.state.resgitration} reporttype="Weekly" header={this.props.user.header}/>				</div> 
					</div>
				</div>
				<div className="col-md-2 "></div>
				<div className="col-md-5">
					<div className="card">
						<div className="card-header p-1 btnclass">Catalog</div>
						<div className="card-body"><Piechart parentObject={this.state.catalog} reporttype="Weekly" header={this.props.user.header}/>				</div> 
			
										</div>
				</div>				
			</div>
			<br/><br/>
			<div className="row">
				<div className="col-md-5">
					<div className="card">
						<div className="card-header p-1 btnclass">Book Status</div>
						<div className="card-body"><PolarChart  parentObject={this.state.bookstatus} reporttype="Weekly" header={this.props.user.header}/>				</div> 
					</div>
				</div>
				<div className="col-md-2"></div>
				<div className="col-md-5">
					<div className="card">
						<div className="card-header p-1 btnclass">Books Issues</div>
						<div className="card-body"><Barchart parentObject={this.state.iobookdata} reporttype="Weekly" header={this.props.user.header}/>				</div> 

					</div>
				</div>				
			</div>
			</div>:null}
			<Modal size="md"  centered show={this.state.showpending} onHide={()=>{this.setState({showpending:false})}}>
			<ModalBody>
			{this.state.showpending?<div className="d-flex justify-content-center">
			<div className="pendingbill"><b>Your Due Date is {this.state.duedate}.Please Make The Payment And Enjoy The Services.</b>
			</div>
			</div>:null}
			<div className="d-flex justify-content-end">
			<div className="p-2"><button className="btn btn-primary" onClick={()=>{this.setState({showpending:false})}}>Close</button></div>
			</div>
			</ModalBody>
			</Modal>
		</div>
		);
  }
}
const mapToProps = ({ loggedUserState }) => ({ user: loggedUserState })
export default connect(mapToProps)(Dashboard);