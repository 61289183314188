const initialState = {
    cart: []
  }
 
  const CartStateReducer = (state = initialState, action) => {
   
    if (action.type === 'GET_CART') {
return Object.assign({}, state, {
        cart: action.cart,
})
    }
   
    if (action.type === 'SET_CART') {
return Object.assign({}, state, {      
        cart: action.cart,
})
    }

if (action.type === 'ADD_CART') {
return Object.assign({}, state, {      
cart:[...state.cart, action.cart]
})
    }


if (action.type === 'UPDATE_CART') {
return Object.assign({}, state, state.cart.map(item => { if(item.id === action.cart.id){ item.note = action.cart.note; } return item } ))
}


if (action.type === 'REMOVE_FROM_CART') {
return Object.assign({}, state, {
cart:state.cart.filter(item => item.id !== action.cart.id)
})
}
   
    return state;
  }
 
  export default CartStateReducer;