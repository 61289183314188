////////////////////////////////////////////////////////////////////////////////////////////////////
// Warning : Property of Deemsoft Global Solutions LLC, USA. It is illegal to make copy of this code
// Author : Gangaswamy Kempahonnaiah
////////////////////////////////////////////////////////////////////////////////////////////////////
import React from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { FaFilePdf, FaPen, FaTimes } from 'react-icons/fa';
import { Button, Modal, ModalBody, ModalFooter } from 'react-bootstrap';
import { base_url } from '../constants/Constants';
import { Validators } from '../utils/Validators';
import { Formators } from '../utils/Formators';
import { Audits } from '../models/Audits';
import {Setting} from '../models/Setting';
import pdfMake from 'pdfmake/build/pdfmake';
import vfsFonts from 'pdfmake/build/vfs_fonts';
import '../styles/Styles.css';
import { Auditreportlog } from '../models/Auditreportlog';


class AuditsForm extends React.Component {
constructor(props){
	super(props);
	this.state = {
		objList : [],
		bookList:[],
		cddvdList:[],
		journalList:[],
		settings : Setting,
		totalbooks:0,
		totalcd:0,
		totaljournal:0,
		totalscanbook:0,
		totalcount:0,
		totalissuedbook:0,
		totaldamagebook:0,
		totaldamagecd:0,
		totaldamagejournal:0,
		libraryBarcodesArray:[],
		auditScanArray:[],
		showModal : false,
		mainform:true,
		auditform:false,
		barcodemessage:'',
		completemsg:"In progress",
		errmsg:'',
		validations : {},
		formObj : Auditreportlog,
		auditformObj : Audits,
		imageheader:'data:image/png;base64,',
		showcomplete:false,
		show:false,
		index:-1,
	}
	
}

componentDidMount(){
	let tempObj = {};
	for (let key in Auditreportlog) { tempObj[key] = ""; }
	this.setState({validations:tempObj});
	let tempObjs = {};
	for (let key in Auditreportlog) { tempObjs[key] = ""; }
	this.setState({formObj:tempObjs});
	this.getObjList();
	this.getBarcodeList();
	var imageData=this.state.imageheader;
	axios.post(base_url+'file/b64download',{filename:'header.png'},this.props.user.header)
	.then(res=>{
		imageData += res.data.data;
		this.setState({imageheader:imageData});
	}).catch(function (error) { console.log(error); }); 
	this.getSettings();

}
getSettings = () => {
	axios.get(base_url+'settings/list', this.props.user.header ).then(res => {
		if ( res.data.length >= 0 ){
			this.setState({ settings:res.data[res.data.length-1]});	
	    }
	}).catch(function (error) { console.log(error); });
 }

downloadPDF=(option1,option2,option3,i)=>{
	let auditScanArray=this.state.objList[i];
	var totalcatalog=0;
	var firstArray=[];
	var secondArray=[];
	const auditdate=this.getDateFormat(auditScanArray.auditdate);
	const enddate=this.getDateFormat(auditScanArray.enddate);
	if(auditScanArray.catalogtype === "Books"){
		totalcatalog=this.state.totalbooks;
		firstArray=this.state.bookList;
		}else if(auditScanArray.catalogtype === "CD/DVD"){
		totalcatalog=this.state.totalcd;
		firstArray=this.state.cddvdList;
		}else if(auditScanArray.catalogtype === "Journals"){
		totalcatalog=this.state.totaljournal;
		firstArray=this.state.journalList;
		}else{
		
		}
	let auditScanObject=auditScanArray.auditbarcodes;
	for(var i=0;i<=auditScanObject.length-1;i++){
		secondArray.push(auditScanObject[i].barcode);
	}
	let totalUnmatch =auditScanObject.filter(auditScanObject => auditScanObject.status === "un match");
	let totalmatch =auditScanObject.filter(auditScanObject => auditScanObject.status === "match");
	var t1=JSON.stringify(totalUnmatch);
	var t2=JSON.parse(t1);
	var result = firstArray.filter(item=>secondArray.indexOf(item)==-1);
	var auditresult='';
	if(result.length == 0){
       auditresult="Pass";
	}else{
		auditresult="Not Passed";
	}

	if(t2.length <= 0){
		var unmatchlist=[[]];
	}else{
	var unmatchlist=[
		[
			{ text: 'Barcode', style: 'tableHeader' }, 				
			{ text: 'Status', style: 'tableHeader' },          
		]
	]; 
	var temp=t2;
	for(var i in temp) {                    
			
	unmatchlist.push(
	[
		{ text: ''+temp[i].barcode+'' , style:'header2'},						
		{ text: ''+temp[i].status+'' , style:'header2'},     
	]);
}
}
if(result.length <= 0){
	var unfoundlist=[[]];
}else{
var unfoundlist=[
	[
		{ text: 'Barcode', style: 'tableHeader' }, 				
		{ text: 'Status', style: 'tableHeader' },        
	]
]; 
var temps=result;
for(var i in temps) {                    
		
unfoundlist.push(
[
	{ text: ''+temps[i]+'' , style:'header2'},						
	{ text: 'Not found' , style:'header2'},
]);
}
}


var docDefinition = {
	pageOrientation: 'page',
	content: 
	[   
	{
		image: this.state.imageheader,
		width: 500,
		height:70,
		alignment:'center'
	},
	{ text: 'Book Audit Report', style:'header' },
	{text:'\n\n'},
		{  table:{
			headerRows: 1,
			widths: [ '*', 150 ],
			body:[[{	
					table:{ 
					widths: [ 100,150],
					body: [
							[{ text: 'Auditor Name :', alignment:'left', border:[false, false, false, false] }, 
							{ text: auditScanArray.auditorname, alignment:'left', border:[false, false, false, false] },],
							[{ text: 'Designation :', alignment:'left', border:[false, false, false, false] }, 
							{ text: auditScanArray.designation, alignment:'left', border:[false, false, false, false] },],
							[{ text: 'Phone Number :', alignment:'left', border:[false, false, false, false] }, 
							{ text: auditScanArray.phone, alignment:'left', border:[false, false, false, false] },],
							[{ text: 'Librarian Name :', alignment:'left', border:[false, false, false, false] }, 
							{ text: auditScanArray.librarianname, alignment:'left', border:[false, false, false, false] },]
						]},
					border:[false, false, false, false]
					},		
		{
		table: { 
			headerRows: 1,
			widths: [ 60, '*' ],
			body: [ 
					[{ text: 'Audit No.  :'+'', alignment:'right', border:[false, false, false, false] }, 
					{ text: auditScanArray.auditnumber, alignment:'right', border:[false, false, false, false]} 
					],
					[{ text: 'Start Date : ', alignment:'right', border:[false, false, false, false] }, 
					{ text: auditdate, alignment:'right', border:[false, false, false, false]} 
					],
					[{ text: 'End Date  :', alignment:'right', border:[false, false, false, false] }, 
					{ text: enddate, alignment:'right', border:[false, false, false, false]} 
					]
				]
			},
			border:[false, false, false, false]
			},	
		]]
	}
	},
		{text:'\n\n\n'},
	{  table:{
		headerRows: 1,
		widths: [ '*', 100 ],
		body:[[{	
				table:{ 
				widths: [ 400],
				body: [
							[{ text: 'Total Number of Books As Per Library Record : ',style:'label' , alignment:'left', border:[false, false, false, false] } ],
							[{ text: 'Total Number of Audited Books : ', alignment:'left',style:'label' , border:[false, false, false, false] } ],
							[{ text: 'Total Missing Books : ', alignment:'left',style:'label' , border:[false, false, false, false] } ],
							[{ text: 'Total Additional Books : ', alignment:'left',style:'label' , border:[false, false, false, false] } ],
							[{ text: 'Total Damaged Books : ', alignment:'left',style:'label' , border:[false, false, false, false] } ]
						]},
				border:[false, false, false, false]
				},		
	{
	table: { 
		headerRows: 1,
		widths: [ 30,'*' ],
		body: [ 
				[{ text: '', alignment:'right', border:[false, false, false, false]},{ text: totalcatalog, alignment:'right', border:[false, false, false, false]} ],
				[{ text: '', alignment:'right', border:[false, false, false, false]},{ text: auditScanArray.totalscanned, alignment:'right', border:[false, false, false, false]} ],
				[{ text: '', alignment:'right', border:[false, false, false, false]},{ text: result.length , alignment:'right', border:[false, false, false, false]} ],
				[{ text: '', alignment:'right', border:[false, false, false, false]},{ text: totalUnmatch.length, alignment:'right', border:[false, false, false, false]} ],
				[{ text: '', alignment:'right', border:[false, false, false, false]},{ text: auditScanArray.totaldamage, alignment:'right', border:[false, false, false, false]} ]
				]
		},
		border:[false, false, false, false]
		},	
	]]
}
},
{text:'\n\n\n'},
{  table:{
headerRows: 1,
widths: [ '*'],
body:[[{	
		table:{ 
		widths: [ 100,200],
		body: [
				[{ text: 'Auditor Status :', alignment:'left', border:[false, false, false, false] }, 
				{ text: auditScanArray.status+'\n\n', alignment:'left', border:[false, false, false, false] },],
				[{ text: 'Audit Result   :', alignment:'left', border:[false, false, false, false] }, 
				{ text: auditresult+'\n\n', alignment:'left', border:[false, false, false, false] },],
			]},
		border:[false, false, false, false]
		},		
]]
}
}, 
{text:'\n\n\n\n'},
{  table:{
headerRows: 1,
widths: [ '*', 100 ],
body:[[{	
	table:{ 
	widths: [ 400],
	body: [
				[{ text: 'Librarian Signature',style:'label' , alignment:'left', border:[false, false, false, false] } ],
			]},
	border:[false, false, false, false]
	},		
{
table: { 
headerRows: 1,
widths: [ 100],
body: [ 
	[{ text: 'Auditor Signature', alignment:'right', border:[false, false, false, false]} ],
	
	]
},
border:[false, false, false, false]
},	
]]
}
},
]	,
	styles:{
	header:{fontSize:13, bold:true, margin:[0, 10, 0, 10], alignment:'center'},
	header1:{fontSize:20, bold:true, margin:[0, 10, 0, 10],alignment:'center',color:'#1160a6'},
	header2:{fontSize:12},
	header4:{fontSize:10},
	header3:{fontSize:10, alignment:'right'},
	tableHeader:{fillColor:'#C0C0C0',bold:true,fontSize:14},
	label:{fontSize:11},
	}

	}
	const {vfs} = vfsFonts.pdfMake;
	pdfMake.vfs = vfs;
	if(option1=='open'){
	pdfMake.createPdf(docDefinition).open(); 
	}
	else if(option2=='download'){
	pdfMake.createPdf(docDefinition).download(); 
	}
else if(option3=='print'){
pdfMake.createPdf(docDefinition).print(); 

}
}

getObjList = () => {
		axios.get(base_url+'auditreportlog/list',this.props.user.header ).then(res => {
			this.setState({ objList:res.data});
		}).catch(function (error) { console.log(error); });	
	}

	getBarcodeList = () => {

	axios.get(base_url+'books_catalog/list',  this.props.user.header ).then(res => {
		if ( res.data.length > 0 ){
			var temp=res.data;
			var count=0;
			for(var i=0;i<=temp.length-1;i++){
				count++;
				this.state.bookList.push(temp[i].accnumber);
			}
			this.setState({totalbooks:res.data.length});
			axios.get(base_url+'checkout/activelist', this.props.user.header ).then(res => {
				this.setState({totalissuedbook:res.data.length});
				let tempdata={type: "Damaged"}
				axios.post(base_url+'books_catalog/search1', tempdata, this.props.user.header )
				.then(res =>{
				this.setState({totaldamagebook:res.data.length});
				}).catch(function (error) { console.log(error); });
			}).catch(function (error) { console.log(error); });	
		}
	}).catch(function (error) { console.log(error); });

	axios.get(base_url+'cd_catalog/list',  this.props.user.header ).then(res => {
		if ( res.data.length > 0 ){
			var temp=res.data;
			var count=0;
			for(var i=0;i<=temp.length-1;i++){
				count++;
				this.state.cddvdList.push(temp[i].cdnumber);
			}
			this.setState({totalcd:count});
		}

		let tempdata={type: "Damaged"}
		axios.post(base_url+'cd_catalog/search2', tempdata, this.props.user.header )
			.then(res =>{
				this.setState({totaldamagecd:res.data.length});
		}).catch(function (error) { console.log(error); });
	}).catch(function (error) { console.log(error); });

	axios.get(base_url+'journals_catalog/list',  this.props.user.header ).then(res => {
		if ( res.data.length > 0 ){
			var temp=res.data;
			var count=0;
			for(var i=0;i<=temp.length-1;i++){
				count++;
				this.state.journalList.push(temp[i].barcode);
			}
			this.setState({totaljournal:count});
		}
		let tempdata={type: "Damaged"}
			axios.post(base_url+'journals_catalog/search1', tempdata, this.props.user.header )
			.then(res =>{
				this.setState({totaldamagejournal:res.data.length});
			}).catch(function (error) { console.log(error); });
	}).catch(function (error) { console.log(error); });

}

editObj = (i) => { 
  var temp=this.state.objList[i];
  this.setState({showModal:true,totalcount:temp.totalscanned});
}

deleteObj = (i) => { 
	let tempObj = this.state.objList[i];
	tempObj.status = 'Inactive';
	axios.post(base_url+'auditreportlog/save', tempObj , this.props.user.header ).then(res =>{
			this.getObjList();
			this.setState({mainform:true,auditform:false,show:false});
	}).catch(function (error) { console.log(error); });
}

checkAuditNumber = () =>{
var tempval=this.state.validations;

if(this.state.formObj.auditnumber === ""){
	tempval.auditnumber = "Please Enter Audit Number";
}else{
if(this.state.formObj.auditnumber !== "" && this.state.formObj.id === ""){
		axios.post(base_url+'auditreportlog/verifyauditnumber', {auditnumber:this.state.formObj.auditnumber})        
		.then(res => {
		if(res.data.msg === 'success'){   
			tempval.auditnumber='The auditnumber is already exist.';   
			this.setState({validations:tempval});
		}else{
		tempval.auditnumber=''; 
		this.setState({validations:tempval});  
		}                
		}).catch(function (error) { 
		console.log(error);        
	}); 
}
}
this.setState({validations:tempval});  
}
checkCompleteStatus = () =>{
	console.log("inside check");
	var temp=this.state.formObj;
var total=0;
total=this.state.formObj.totalcatalogcount;
var Issued=this.state.formObj.totalissued;
var damage=this.state.formObj.totaldamage;
var totalmatch=this.state.formObj.totalmatched;
var match=Number(totalmatch)+Number(damage)+Number(Issued);
if(match >= total){
	console.log("inside check true");
	temp.status="Completed";
	this.setState({showcomplete:true});
}else{
	console.log("inside check false");
	temp.status="In Progress";
	this.setState({showcomplete:false});	
}
this.setState({formObj:temp});
}

validate = (val) => {
let tempObj = this.state.validations;
switch(val){
	case "auditnumber": this.checkAuditNumber(); break;
	case "catalogtype": tempObj.catalogtype = Validators.select(this.state.formObj.catalogtype); break;
	case "phone": tempObj.phone = Validators.phone(this.state.formObj.phone); break;
	case "auditorname": tempObj.auditorname = Validators.name(this.state.formObj.auditorname); break;
	case "designation": tempObj.designation = Validators.name(this.state.formObj.designation ); break;
	case "librarianname": tempObj.librarianname = Validators.name(this.state.formObj.librarianname); break;
}
this.setState({validations:tempObj});
} 

format = (val, e) => { 
let tempObj = this.state.formObj;
switch(val){
	case "auditnumber": tempObj.auditnumber = Formators.name(e.target.value); break;
	case "barcode": tempObj.barcode = Formators.name(e.target.value); break;
	case "status": tempObj.status = Formators.name(e.target.value); break;
	case "catalogtype": tempObj.catalogtype = Formators.name(e.target.value); break;
	case "auditorname": tempObj.auditorname = Formators.firstname(e.target.value); break;
	case "designation": tempObj.designation = Formators.firstname(e.target.value); break;
	case "phone": tempObj.phone = Formators.name(e.target.value); break;
	case "librarianname": tempObj.librarianname = Formators.firstname(e.target.value); break;
}
this.setState({formObj:tempObj});
}

newAudit = () =>{
//this.savereportLog();
this.setState({mainform:true,auditform:false,completemsg:"Completed"});
}
goTomainform = () =>{
	this.setState({mainform:true,auditform:false,barcodemessage:''});
}

addObj = () => { 
let tempObj = {};
for (let key in Audits ) { tempObj[key] = ""; }
this.setState({formObj:tempObj, showModal:true,showcomplete:false,errmsg:''});
}

getDateFormat = (dt) =>{
	var now = new Date(dt);
	var month = (now.getMonth() + 1);               
	var day = now.getDate();
	if (month < 10) 
		month = "0" + month;
	if (day < 10) 
		day = "0" + day;

	if(this.state.settings.dateformats == "MM/dd/yyyy"){
		var today = month + '-' + day + '-' +now.getFullYear();
		return today;
	}else if(this.state.settings.dateformats == "yyyy/MM/dd"){
		var today =  now.getFullYear()+'-'+month + '-' + day;
		return today;
	}else if(this.state.settings.dateformats == "dd/MM/yyyy"){
		var today =  day + '-' + month + '-' +now.getFullYear();
		return today;
	}else{
		var today =  day + '-' + month + '-' +now.getFullYear();
		return today;
	}
	
	}

closeModal=()=>{
let tempObj = {};
for (let key in Auditreportlog) { tempObj[key] = ""; }
this.setState({validations:tempObj});
this.setState({showModal:false});
}
getCatalogtype = ()=>{
	var temp=[];
	var audittemp=this.state.formObj;
if(this.state.formObj.catalogtype === "Books"){
	temp=this.state.bookList;
		audittemp.totalcatalogcount=this.state.totalbooks;
		audittemp.totalissued=this.state.totalissuedbook;
		audittemp.totaldamage=this.state.totaldamagebook; 
	}else if(this.state.formObj.catalogtype === "CD/DVD"){
	temp=this.state.cddvdList;
	audittemp.totalcatalogcount=this.state.totalcd;
	audittemp.totalissued=0;
	audittemp.totaldamage=this.state.totaldamagecd;
	}else if(this.state.formObj.catalogtype === "Journals"){
	temp=this.state.journalList;
	audittemp.totalcatalogcount=this.state.totaljournal;
	audittemp.totalissued=0;
	audittemp.totaldamage=this.state.totaldamagejournal;
	}else{

	}
	this.setState({formObj:audittemp});
	return temp;
}

scanCatalog_data = () =>{
//console.log(this.state.formObj.auditbarcodes);
let tempo = '';
for (let key in Auditreportlog) { this.validate(key);}
for (let key in this.state.validations) {tempo += this.state.validations[key];}
if(tempo === ""){
var temp= this.getCatalogtype();
console.log(temp);
var auditBorcode = this.state.formObj.auditbarcodes;
var count=this.state.totalscanbook;
var tcount=this.state.formObj.totalscanned;
this.state.libraryBarcodesArray=temp;
var barcode = this.state.formObj.barcode;
if(barcode !== "" && barcode !== undefined){
if(!auditBorcode.some(auditBorcode => auditBorcode.barcode === barcode)){
if( this.state.libraryBarcodesArray.includes(barcode)){
	this.state.formObj.auditbarcodes.push({barcode:this.state.formObj.barcode, status:"match"});
	count++;
	tcount++;
}else{
	this.state.formObj.auditbarcodes.push({barcode:this.state.formObj.barcode, status:"un match"});
	tcount++;
}
this.setState({barcodemessage:''});
this.setState({totalscanbook:count});
this.setState({totalcount:tcount});
this.savereportLog();
}else{
	var msg=this.state.barcodemessage;
	msg="Barcode Number Is Already Scanned";
	this.setState({barcodemessage:msg});
}

}else{
	var msg=this.state.barcodemessage;
	msg="Please scann Valid Barcode Number";
	this.setState({barcodemessage:msg});
}
}
}
savereportLog(){
var temp=this.state.formObj;
if(this.state.formObj.id === ""){
	var obj=this.getCatalogtype();
	temp.totalscanned=0;
	temp.totalmatched=0;
	temp.auditdate=new Date();
}else{
	temp.totalscanned=Number(this.state.totalcount)+1;
	temp.totalmatched=Number(this.state.totalscanbook)+1
	temp.enddate=new Date();
}
this.checkCompleteStatus();
console.log(this.state.showcomplete);
let tempo = '';
for (let key in Auditreportlog) { this.validate(key);}
for (let key in this.state.validations) {tempo += this.state.validations[key];}
if(tempo === ""){
	console.log(temp);
	axios.post(base_url+'auditreportlog/save', temp,this.props.user.header ).then(res => {
	this.setState({formObj:res.data.data,showModal:false});
	this.getObjList();
	this.renderObjlist();
	}).catch(function (error) { console.log(error); }); 

}
}
openScanningBookPage = (i) =>{
var temp=this.state.objList[i];
this.setState({formObj:temp,auditform:true,mainform:false,totalcount:temp.totalscanned,barcodemessage:""});
//this.checkCompleteStatus();
if(temp.status === "In Progress"){
	this.setState({showcomplete:false});
}else{
	this.setState({showcomplete:true});
}

}
confirmDelete =(i)=>{
this.setState({index:i,show:true});
}
searchAuditNumber=()=>{
	var temp={type:"AuditNo" ,search:this.state.formObj.auditnumber}
axios.post(base_url+'auditreportlog/search', temp,this.props.user.header ).then(res => {
	if(res.data.length > 0){
	this.setState({objList:res.data,errmsg:''});
	this.renderObjlist();
	}else{
		this.setState({errmsg:"The Audit Number is Not In the Record."});
	}
	}).catch(function (error) { console.log(error); }); 
}

renderObjlist = () => {
if( this.state.objList != undefined && this.state.objList.length > 0){
	return this.state.objList.map((item, index) =>{

				var today =  this.getDateFormat(item.date_created);
				var pdfshow=false;
				var editshow=false;
				if(item.status == "Completed"){
					pdfshow=true;
				}else{
					editshow=true;
				}
		return (
			<tr key={index} id={index}>
				<td>{item.auditnumber}</td>
				<td>{today}</td>
				<td>{item.status}</td>
				<td>{pdfshow?<FaFilePdf size="20px" color="red" onClick={()=>this.downloadPDF('open','download','print',index)}/>:null}
				<span>{editshow?<FaPen size="15px" color="blue"  onClick={()=>{this.openScanningBookPage(index)}}/>:null}&nbsp;&nbsp;
				<FaTimes size="15px" color="red" onClick={() => {this.confirmDelete(index)}} /></span>
				</td>
			</tr>
			)
		}, this);
}
}

render() {
	const auditdate=this.getDateFormat(this.state.formObj.auditdate);
return (
<div className="shadow-sm p-3 mb-5 bg-white rounded">
<div><div className="d-flex row justify-content-center"><h3>Stock Verification</h3></div></div><br/>
	{this.state.mainform?<div>
		<div className="d-flex row">
		<div className="p-2">
			<label htmlFor="idfirstname" className="label-custom" >Audit Number </label> 
			<input  value={this.state.formObj.auditnumber} id="idauditnumber"  onChange={(e)=>{this.format("auditnumber",e)}}  onKeyPress={(e) => e.key === 'Enter' ? this.searchAuditNumber(): null} type="text" className='form-control form-control-sm' required /> 
			<span className="errormsg">{this.state.errmsg}</span>
		</div>
		<div className="p-2">
		<button type="button" className="btn btn-primary btn-sm" style={{marginTop:32}}  onClick={()=>{ this.searchAuditNumber()}}>Search</button>
		</div>
		<div className="p-2">
		<button className="btn btn-success btn-sm" style={{marginTop:32}} onClick={()=>{this.addObj()}}>New</button>
	</div>
		</div>
	<div className="d-flex row">
	<table className="table table-hover table-sm">
	<thead className="thead">
		<tr>
			<td>Audit Number</td>
			<td>Audit Date</td>
			<td>Status</td>
			<td>Reports</td>
		</tr>
	</thead>
	<tbody>{this.renderObjlist()}</tbody>
	</table>
	</div>
	</div>:null}
	{this.state.auditform?<div>
		<div className="d-flex row justify-content-between">
			<div className="p-2">
			<label className="label-custom">Auditor Name : </label><span>{this.state.formObj.auditorname}</span>
			</div>
			<div className="p-2">
				<label className="label-custom">Audit Number : </label><span>{this.state.formObj.auditnumber}</span>
			</div>
			<div className="p-2">
				<label className="label-custom">Audit Date : </label><span>{auditdate}</span>
			</div>
			<div className="p-2">
				<label className="label-custom">Catalog Type : </label><span>{this.state.formObj.catalogtype}</span>
			</div>
		</div>
		<div className="d-flex row justify-content-center">
			<div className="p-2">
			<label htmlFor="idbarcode" className="label-custom" >Barcode/ACC No.</label> 
			<input value={this.state.formObj.barcode} id="idbarcode"  onKeyPress={(e) => e.key === 'Enter' ? this.scanCatalog_data(): null} onChange={(e)=>{this.format("barcode",e)}}  onBlur={()=>{ this.validate("barcode")}} className="form-control" style={this.state.validations.barcode===""?{}:{"borderColor":"red"}} type="number" required /> 
			<span className="errormsg">{this.state.barcodemessage}</span>
			</div>
			<div className="p-2">
			<button type="button" style={{marginTop:36}} className="btn btn-success btn-sm" onClick={()=>{this.scanCatalog_data()}}>Scan</button>
			</div>	
		</div>
		<div className="d-flex row">
			<div className="p-2 flex-fill">
			<table className="table table-bordered  table-sm">
				<thead className="thead">
					<tr>
						<td>Total {this.state.formObj.catalogtype}</td>
						<td>Total Issued</td>
						<td>Total Damaged</td>
						<td>Total Scanned</td>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>{this.state.formObj.totalcatalogcount}</td>
						<td>{this.state.formObj.totalissued}</td>
						<td>{this.state.formObj.totaldamage}</td>
						<td>{this.state.formObj.totalscanned}</td>
					</tr>
				</tbody>
			</table>
			</div>
		</div>
		<div className="d-flex row justify-content-end">
			<div className="p-2 ">
			<button type="button"  className="btn btn-primary" onClick={()=>{this.goTomainform()}}>Back</button>
			</div>
			<div className="p-2 ">
			{this.state.showcomplete?<button type="button"  className="btn btn-success" onClick={()=>{this.newAudit()}}>Complete</button>:null}
			</div>
		</div>
	</div>
		
	:null}
	<Modal size="lg" show={this.state.showModal} onHide={()=>{this.closeModal()}} >
	<Modal.Header closeButton>
	<Modal.Title>AUDITS</Modal.Title>
	</Modal.Header>
	<ModalBody>
		<div className="d-flex raw">
			<div className="p-2 flex-fill">
				<label htmlFor="idauditnumber" className="label-custom" >Audit Number</label> 
				<input value={this.state.formObj.auditnumber} id="idauditnumber"  onChange={(e)=>{this.format("auditnumber",e)}}  onBlur={()=>{ this.validate("auditnumber")}} className="form-control form-control-sm" style={this.state.validations.auditnumber===""?{}:{"borderColor":"red"}} type="text" className='form-control form-control-sm' required /> 
				<span className="errormsg">{this.state.validations.auditnumber}</span>
			</div>
			<div className="p-2 flex-fill">
				<label htmlFor="idauditorname" className="label-custom" >Auditor Name</label> 
				<input value={this.state.formObj.auditorname} id="idauditorname"  onChange={(e)=>{this.format("auditorname",e)}}  onBlur={()=>{ this.validate("auditorname")}} className="form-control form-control-sm" style={this.state.validations.auditorname===""?{}:{"borderColor":"red"}} type="text" className='form-control form-control-sm' required /> 
				<span className="errormsg">{this.state.validations.auditorname}</span>
			</div>
			<div className="p-2 flex-fill">
				<label htmlFor="iddesignation" className="label-custom" >Designation</label> 
				<input value={this.state.formObj.designation} id="iddesignation"  onChange={(e)=>{this.format("designation",e)}}  onBlur={()=>{ this.validate("designation")}} className="form-control form-control-sm" style={this.state.validations.designation===""?{}:{"borderColor":"red"}} type="text" className='form-control form-control-sm' required /> 
				<span className="errormsg">{this.state.validations.designation}</span>
			</div>
			</div>
			<div className="d-flex raw">
			<div className="p-2 flex-fill">
				<label htmlFor="idphone" className="label-custom" >Phone Number</label> 
				<input value={this.state.formObj.phone} id="idphone"  onChange={(e)=>{this.format("phone",e)}}  onBlur={()=>{ this.validate("phone")}} className="form-control form-control-sm" style={this.state.validations.phone===""?{}:{"borderColor":"red"}} type="text" className='form-control form-control-sm' required /> 
				<span className="errormsg">{this.state.validations.phone}</span>
			</div>
			<div className="p-2 flex-fill">
				<label htmlFor="idlibrarianname" className="label-custom" >Librarian Name</label> 
				<input value={this.state.formObj.librarianname} id="idlibrarianname"  onChange={(e)=>{this.format("librarianname",e)}}  onBlur={()=>{ this.validate("librarianname")}} className="form-control form-control-sm" style={this.state.validations.librarianname===""?{}:{"borderColor":"red"}} type="text" className='form-control form-control-sm' required /> 
				<span className="errormsg">{this.state.validations.librarianname}</span>
			</div>
			<div className="p-2 flex-fill">
				<label htmlFor="idcatalogtype" className="label-custom" >Catalog Type</label> 
				<select value={this.state.formObj.catalogtype} id="idcatalogtype"  onChange={(e)=>{this.format("catalogtype",e)}}  onBlur={()=>{ this.validate("catalogtype")}} className="form-control form-control-sm" style={this.state.validations.catalogtype===""?{}:{"borderColor":"red"}} type="text" className='form-control form-control-sm'>
				<option value=""></option>
				<option>Books</option>
				<option>CD/DVD</option>
				<option>Journals</option>
				</select>
				<span className="errormsg">{this.state.validations.catalogtype}</span>
			</div>
		</div>
	</ModalBody>
	<ModalFooter>
		<Button variant="warning" onClick={()=>{this.savereportLog()}}>SAVE</Button>
	</ModalFooter>
	</Modal>
	<Modal size="md"  centered show={this.state.show} onHide={()=>{this.setState({show:false})}}>
	<ModalBody>
	<div className="d-flex justify-content-right">
		<div className="p-2">Are You Sure ?? Want to Delete the Audit Report !!.</div>
	</div>
	<div className="d-flex justify-content-end">
	<div className="p-2"><button className="btn btn-warning" onClick={()=>{this.deleteObj(this.state.index)}}>YES</button></div>
	<div className="p-2"><button className="btn btn-primary" onClick={()=>{this.setState({show:false})}}>NO</button></div>
	</div>
	</ModalBody>
	</Modal>
	</div>
)
}
}

const mapToProps = ({ loggedUserState }) => ({ user: loggedUserState })
export default connect(mapToProps)(AuditsForm);
