////////////////////////////////////////////////////////////////////////////////////////////////////
// Warning : Property of Deemsoft Global Solutions LLC, USA. It is illegal to make copy of this code
// Author : Gangaswamy Kempahonnaiah
////////////////////////////////////////////////////////////////////////////////////////////////////
import React from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { Button, Modal} from 'react-bootstrap';
import { base_url } from '../constants/Constants';
import { Validators } from '../utils/Validators';
import { Formators } from '../utils/Formators';
import { Users } from '../models/Users';
import '../styles/Styles.css';

class UsersForm extends React.Component {
constructor(props){
	super(props);
	this.state = {
		objList : [],
		showModal : false,
		validations : {},
		saveuser : false,
		formObj : Users,
	}
}

componentDidMount(){
	console.log(this.props.user);
	let tempObj = {};
	for (let key in Users) { tempObj[key] = ""; }
	this.setState({validations:tempObj});
	this.getObjList();

	axios.post(base_url+'users/userid/',{ id : this.props.user.userid}, this.props.user.header )
	.then(res =>{
		console.log(res.data[0]);
		if ( res.data.length > 0 ){
			this.setState({formObj:res.data[0]});
			console.log(this.state.formObj);
		}
	}).catch(function (error) { console.log(error); });
}

getObjList = () => {
	axios.get(base_url+'users/list', this.props.user.header ).then(res => {
		if ( res.data.length > 0 ){
			this.setState({ objList:res.data});
		}
	}).catch(function (error) { console.log(error); });
}
saveObj = () => {
	let temp='';
	for (let key in Users) { this.validate(key);}
	for (let key in this.state.validations) {temp += this.state.validations[key];}
	if(temp == ""){
		console.log(this.state.validations);
	axios.post(base_url+'users/save', this.state.formObj, this.props.user.header ).then(res => {
			this.getObjList();
			this.updateSuccess();
	}).catch(function (error) { console.log(error); });
}
}

addObj = () => { 
	let tempObj = {};
	for (let key in Users ) { tempObj[key] = ""; }
	this.setState({ formObj:tempObj, showModal:true});
}

editObj = (i) => { 
	let tempObj = {};
	for (let key in this.state.objList[i] ) { tempObj[key] = this.state.objList[i][key]; }
	this.setState({ formObj:tempObj, showModal:true});
}

deleteObj = (i) => { 
	let tempObj = this.state.objList[i];
	tempObj.status = 'Inactive';
	axios.post(base_url+'users/save', tempObj , this.props.user.header ).then(res =>{
		if ( res.data.length > 0 ){
			this.setState({ formObj:res.data});
			this.getObjList();
		}
	}).catch(function (error) { console.log(error); });
}

updateSuccess = () =>{
	this.setState({saveuser:!this.state.saveuser}); 
}
	
validate = (val) => {
	let tempObj = this.state.validations;
	switch(val){
		case "firstname": tempObj.firstname = Validators.name(this.state.formObj.firstname); break;
		case "lastname": tempObj.lastname = Validators.name1(this.state.formObj.lastname); break;
		case "phone": tempObj.phone = Validators.phone(this.state.formObj.phone); break;
		case "email": tempObj.email = Validators.email(this.state.formObj.email); break;
		case "institutionname": tempObj.institutionname = Validators.name(this.state.formObj.institutionname);
		case "address1":  tempObj.address1= Validators.name(this.state.formObj.address1);  break; 
		case "city":  tempObj.city = Validators.name(this.state.formObj.city);  break; 
		case "state":  tempObj.state = Validators.state(this.state.formObj.state);  break; 
		case "zip":  tempObj.zip = Validators.zipcode(this.state.formObj.zip);  break; 
	}
	this.setState({validations:tempObj});
	} 

format = (val, e) => { 
	let tempObj = this.state.formObj;
	switch(val){
		case "firstname": tempObj.firstname = Formators.firstname(e.target.value); break;
		case "lastname": tempObj.lastname = Formators.firstname(e.target.value); break;
		case "title": tempObj.title = Formators.name(e.target.value); break;
		case "phone": tempObj.phone = Formators.name(e.target.value); break;
		case "email": tempObj.email = Formators.name(e.target.value); break;
		case "password": tempObj.password = Formators.name(e.target.value); break;
		case "institutionname": tempObj.institutionname = Formators.firstname(e.target.value); break;
		case "address1": tempObj.address1 = Formators.name(e.target.value); break;
		case "address2": tempObj.address2 = Formators.name(e.target.value); break;
		case "city": tempObj.city = Formators.name(e.target.value); break;
		case "state": tempObj.state = Formators.firstname(e.target.value); break;
		case "zip": tempObj.zip = Formators.name(e.target.value); break;

	}
	this.setState({formObj:tempObj});
	} 


render() {
	return (
		<div className="container">	
	<div class="shadow-sm p-3 mb-5 bg-white rounded">
		<div className="d-flex row">
				<div className="p-2 flex-fill">
					<label htmlFor="idfirstname" className="label-custom" >Firstname</label> 
					<input value={this.state.formObj.firstname} id="idfirstname"  onChange={(e)=>{this.format("firstname",e)}}   onBlur={()=>{ this.validate("firstname")}} className="form-control form-control-sm" style={this.state.validations.firstname===""?{}:{"borderColor":"red"}} type="text" className='form-control form-control-sm' required /> 
					<span className="errormsg">{this.state.validations.firstname}</span>
				</div>
				<div className="p-2 flex-fill">
					<label htmlFor="idlastname" className="label-custom" >Lastname</label> 
					<input value={this.state.formObj.lastname} id="idlastname"  onChange={(e)=>{this.format("lastname",e)}}  onBlur={()=>{ this.validate("lastname")}} className="form-control form-control-sm" style={this.state.validations.lastname===""?{}:{"borderColor":"red"}} type="text" className='form-control form-control-sm' required /> 
					<span className="errormsg">{this.state.validations.lastname}</span>
				</div>
				<div className="p-2 flex-fill">
					<label htmlFor="idphone" className="label-custom" >Phone</label> 
					<input value={this.state.formObj.phone} id="idphone" onChange={(e)=>{this.format("phone",e)}}  onBlur={()=>{ this.validate("phone")}} className="form-control form-control-sm" style={this.state.validations.phone===""?{}:{"borderColor":"red"}} type="text" className='form-control form-control-sm' required /> 
					<span className="errormsg">{this.state.validations.phone}</span>
				</div>
				<div className="p-2 flex-fill">
					<label htmlFor="idemail" className="label-custom" >Email</label> 
					<input value={this.state.formObj.email} id="idemail"  onChange={(e)=>{this.format("email",e)}}  onBlur={()=>{ this.validate("email")}} className="form-control form-control-sm" style={this.state.validations.email===""?{}:{"borderColor":"red"}} type="text" className='form-control form-control-sm' required readOnly/> 
					<span className="errormsg">{this.state.validations.email}</span>
				</div>
		</div>
		<div className="d-flex row">
				<div className="p-2 flex-fill">
					<label htmlFor="idemail" className="label-custom" >Institutionname</label> 
					<input value={this.state.formObj.institutionname} id="idemail"  onChange={(e)=>{this.format("institutionname",e)}}  onBlur={()=>{ this.validate("institutionname")}} className="form-control form-control-sm" style={this.state.validations.institutionname===""?{}:{"borderColor":"red"}} type="text" className='form-control form-control-sm' required /> 
					<span className="errormsg">{this.state.validations.institutionname}</span>
				</div>
				<div className="p-2 flex-fill">
					<label htmlFor="idaddress1" className="label-custom" >Address1</label> 
					<input value={this.state.formObj.address1} id="idaddress1"  onChange={(e)=>{this.format("address1",e)}}  onBlur={()=>{ this.validate("address1")}} className="form-control form-control-sm" style={this.state.validations.address1===""?{}:{"borderColor":"red"}} type="text" className='form-control form-control-sm' required /> 
					<span className="errormsg">{this.state.validations.address1}</span>
				</div>
				<div className="p-2 flex-fill">
					<label htmlFor="idaddress2" className="label-custom" >Address2</label> 
					<input value={this.state.formObj.address2} id="idaddress2"  onChange={(e)=>{this.format("address2",e)}}  onBlur={()=>{ this.validate("address2")}} className="form-control form-control-sm" style={this.state.validations.address2===""?{}:{"borderColor":"red"}} type="text" className='form-control form-control-sm' required /> 
					<span className="errormsg">{this.state.validations.address2}</span>
				</div>
			</div>
			<div className="d-flex row">		
				<div className="p-2 flex-fill">
					<label htmlFor="idcity" className="label-custom" >City</label> 
					<input value={this.state.formObj.city} id="idcity"  onChange={(e)=>{this.format("city",e)}}   onBlur={()=>{ this.validate("city")}} className="form-control form-control-sm" style={this.state.validations.city===""?{}:{"borderColor":"red"}} type="text" className='form-control form-control-sm' required /> 
					<span className="errormsg">{this.state.validations.city}</span>
				</div>
				<div className="p-2 flex-fill">
					<label htmlFor="idstate" className="label-custom" >State</label> 
					<input value={this.state.formObj.state} id="idstate"  onChange={(e)=>{this.format("state",e)}}  onBlur={()=>{ this.validate("state")}} className="form-control form-control-sm" style={this.state.validations.state===""?{}:{"borderColor":"red"}} type="text" className='form-control form-control-sm' required /> 
					<span className="errormsg">{this.state.validations.state}</span>
				</div>
				<div className="p-2 flex-fill">
					<label htmlFor="idzip" className="label-custom" >Zipcode</label> 
					<input value={this.state.formObj.zip} id="idzip"  onChange={(e)=>{this.format("zip",e)}}   onBlur={()=>{ this.validate("zip")}} className="form-control form-control-sm" style={this.state.validations.zip===""?{}:{"borderColor":"red"}} type="text" className='form-control form-control-sm' required /> 
					<span className="errormsg">{this.state.validations.zip}</span>
				</div>

		</div>
		<div className="d-flex justify-content-end row">
		<button type="button" className="btn btn-primary btn-sm" onClick={()=>{this.saveObj()}}>SAVE</button>
		</div>

		<Modal show={this.state.saveuser} centered onHide={this.updateSuccess}  size="md"
				aria-labelledby="contained-modal-title-vcenter"
				centered>
			<Modal.Body>
			<br/><br/>
			<div className="d-flex justify-content-center row">
				<h5>  <strong className="textblue">Thank You !!! {this.state.formObj.firstname}</strong> </h5> 
				<br/><br/> 
			</div>
			<div className="d-flex justify-content-center row">
				<h6>Your Profile Details Updated successfully.</h6>
			</div> <br/> <br/>
			<div className="d-flex justify-content-center row">
			<Button variant="primary" onClick={this.updateSuccess}>OK</Button>
			</div>
			</Modal.Body>
			</Modal>
		</div>
		</div>
	)
	}
}

const mapToProps = ({ loggedUserState }) => ({ user: loggedUserState })
export default connect(mapToProps)(UsersForm);
