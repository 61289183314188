///////////////////////////////////////////////////////////////////////////////////////////////
// Warning : Property of Deemsoft Global Solutions LLC, It is illegal to make copy of this code
// Author : Gangaswamy Kempahonnaiah
///////////////////////////////////////////////////////////////////////////////////////////////


import React from 'react';
import { connect } from 'react-redux';
import { base_url } from '../constants/Constants';
import {Link, NavLink } from 'react-router-dom';
import {FaUserCircle } from 'react-icons/fa';


class Header extends React.Component {
	constructor(props){
		super(props);
		this.state = {
      checkadmin:false,
      headerimage:base_url+'file/download/header.png',
      logoimage:base_url+'file/download/favicon1.png',
    }
  }
 componentDidMount(){
   if(this.props.user.usertype === "admin"){
     this.setState({checkadmin:true});
   }

  }
render(){
return (
 <div className="container-fluid"> 
 <div className="d-flex row">
   <div className="p-2 justify-content-start">
   <Link className="navbar-brand" to="/app/"><span className="brandname"><img className="image1" src={this.state.logoimage}  alt="favicon1" /></span> </Link>
   </div>
   <div className="p-2  flex-grow-1"><div className="box"><span className="brandname"><img className="image2" src={this.state.headerimage} alt="headerbanner" /></span></div>
   </div>
   <div className="p-2  justify-content-end">
   <span className="nav-link dropdown-toggle" id="navbarDropdownMenuLink" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false" >
            <FaUserCircle size="35px"/>
            </span>
            <div className="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownMenuLink">
            {this.state.checkadmin?<NavLink to="/app/admin" className="dropdown-item">ADMIN</NavLink>:null}            
              <NavLink to="/app/users" className="dropdown-item">PROFILE</NavLink>
              <NavLink to="/search" className="dropdown-item">LOGOUT</NavLink>
            </div>
   </div>
 </div>
    <hr/>
    </div>

	)
}
}
const mapToProps = ({ loggedUserState }) => ({ user: loggedUserState })
export default connect(mapToProps)(Header);