///////////////////////////////////////////////////////////////////////////////////////////////
// Warning : Property of Deemsoft Global Solutions LLC, It is illegal to make copy of this code
// Author : Gangaswamy Kempahonnaiah
///////////////////////////////////////////////////////////////////////////////////////////////
export const Audits = {
	auditnumber : '', 
	barcode : '', 
	status : '', 
	catalogtype : '', 
	id : '', 
	createdby : '', 
	updatedby : '', 
	date_created : '', 
	date_updated : '', 
}