////////////////////////////////////////////////////////////////////////////////////////////////////
// Warning : Property of Deemsoft Global Solutions LLC, USA. It is illegal to make copy of this code
// Author : Gangaswamy Kempahonnaiah
////////////////////////////////////////////////////////////////////////////////////////////////////
import React from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { base_url } from '../constants/Constants';
import { Validators } from '../utils/Validators';
import { Formators } from '../utils/Formators';
import { Users_Group } from '../models/Users_Group';
import { Sms} from '../models/Sms';
import {Setting} from '../models/Setting';
import flashMessage from '../utils/Flash';
import FlashMessage from 'react-flash-message';
import '../styles/Styles.css';


class SmsForm extends React.Component {
constructor(props){
	super(props);
	this.state = {
		objList : [],
		showModal : false,
		settings : Setting,
		validations : {},
		sendlist: [],
		searchmessage:'',
		showtable:false,
		mainform:false,
		searchform:true,
		formObj : Sms,
		checkdisable:true,
		check:false,
		disvalue:true,
		senddisable:true,
		senddisableemail:true,
		showSuccessMsg :false,
		showbtn:true,
	}

}

componentDidMount(){
let tempObj = {};
for (let key in Sms) { tempObj[key] = ""; }
this.setState({validations:tempObj});

let tempObjs = {};
for (let key in Sms) { tempObjs[key] = ""; }
this.setState({formObj:tempObjs});

this.getSettings();
}
getSettings = () => {
axios.get(base_url+'settings/list', this.props.user.header ).then(res => {
	if ( res.data.length > 0 ){
		for(var i=0;i<res.data.length;i++){
			this.setState({ settings:res.data[i]});	
		}	
	}     
}).catch(function (error) { console.log(error); });
}

getObjList = () => {
axios.get(base_url+'users_group/list', this.props.user.header ).then(res => {
	if ( res.data.length > 0 ){
		this.setState({ objList:res.data});
	}
}).catch(function (error) { console.log(error); });
}


addObj = () => { 
let tempObj = {};
for (let key in Users_Group ) { tempObj[key] = ""; }
this.setState({ formObj:tempObj, showModal:true});
}

editObj = (i) => { 
let tempObj = {};
for (let key in this.state.objList[i] ) { tempObj[key] = this.state.objList[i][key]; }
this.setState({ formObj:tempObj, showModal:true});
}

deleteObj = (i) => { 
let tempObj = this.state.objList[i];
tempObj.status = 'Inactive';
axios.post(base_url+'users_group/save', tempObj , this.props.user.header ).then(res =>{
	if ( res.data.length > 0 ){
		this.setState({ formObj:res.data});
		this.getObjList();
	}
}).catch(function (error) { console.log(error); });
}

validate = (val) => {
let tempObj = this.state.validations;
switch(val){
	case "cardid": tempObj.cardid = Validators.name(this.state.formObj.catch); break;
	case "firstname": tempObj.firstname = Validators.name(this.state.formObj.firstname); break;
	case "message": tempObj.message = Validators.name(this.state.formObj.message); break;
	case "membertype":tempObj.membertype = Validators.select(this.state.formObj.membertype); break;
	case "template": tempObj.template = Validators.select(this.state.formObj.template);break;
	case "department": tempObj.department = Validators.select(this.state.formObj.department); break;
}
this.setState({validations:tempObj});
} 

format = (val, e) => { 
let tempObj = this.state.formObj;
switch(val){
	case "cardid": tempObj.cardid = Formators.name(e.target.value); break;
	case "firstname": tempObj.firstname = Formators.name(e.target.value); break;
	case "message": tempObj.message = Formators.name(e.target.value); break;
	case "template": tempObj.template = Formators.name(e.target.value); tempObj.message = Formators.name(e.target.value); this.checkButton();break;
	case "department" : tempObj.department = Formators.name(e.target.value);break;
	case "membertype" : tempObj.membertype = Formators.name(e.target.value);break;
	case "select" : tempObj.select = Formators.name(e.target.value);break;
}
this.checkButton();
this.setState({formObj:tempObj});

} 
checkButton = ()=>{
if(this.state.formObj.membertype !== "" && this.state.formObj.membertype !== undefined && this.state.formObj.department !== "" && this.state.formObj.department !== undefined){
	this.setState({disvalue:false});
	}else{
		this.setState({disvalue:true,showtable:false});
	}
if(this.state.formObj.message !== "" && this.state.formObj.message !== undefined){
	this.setState({senddisable:false,senddisableemail:false});
}else{
	this.setState({senddisable:true,senddisableemail:true});
}	
}

searchType=()=>{
	if(this.state.formObj.membertype !== "" && this.state.formObj.membertype !== undefined && this.state.formObj.department !== "" && this.state.formObj.department !== undefined){
	this.searchObj(this.state.formObj.membertype,"RegistrationType",this.state.formObj.department);
	}else{
		this.setState({showtable:false});
	}

}  
searchObj = (id,by,dpt) => {
if(id != "" && id != undefined){
	let temp={search : id ,type: by,department: dpt}
	axios.post(base_url+'members/search', temp, this.props.user.header )
	.then(res =>{
	if(res.data.length > 0){
	let tempObj = this.state.searchmessage;
		tempObj="";
		this.setState({showtable:true,check:false,sendlist:[]});
		this.setState({searchmessage:tempObj});
	this.setState({objList:res.data,searchtable:true,showSuccessMsg:false});
	this.checkDisableNext();
	}else{
		let tempObj = this.state.searchmessage;
		tempObj=this.state.formObj.membertype+" details is not match with our records with "+this.state.formObj.department+" Department.";
		this.setState({searchmessage:tempObj});
		this.setState({showtable:false,objList:[],sendlist:[],checkdisable:true,disvalue:true});
	}
}).catch(function (error) { console.log(error); }); 

this.renderObjlist();
}else{
let tempObj = this.state.searchmessage;
tempObj="Please Enter Registration Type and Department to search";
let temarr = [];
this.setState({searchmessage:tempObj});
this.setState({objList:temarr,searchtable:false,showtable:false});
}
}

gototemplateForm = () =>{
this.setState({mainform:true,searchform:false})
}

sendEmail = () =>{
	var temp=this.state.sendlist;
	for(var i in temp){
		console.log(temp[i].email,this.state.formObj.message);
		  var emailobj={email:temp[i].email,msgBody: this.state.formObj.message}
		 axios.post(base_url+'sms/sendemail', emailobj).then(res => {
			    console.log(res.data)
			}).catch(function (error) { console.log(error); });  
	   }
this.setState({senddisableemail:true,showbtn:false});
this.setState({showSuccessMsg: true}, ()=>{
	setTimeout(() => {
	this.setState({showSuccessMsg: false })
	}, 3000);
});

}
sendSMS = () =>{
this.setState({showSuccessMsg:false});
var numberlist="";
var temp=this.state.sendlist;
for(var i in temp){
	if(temp[i].select === true){
		if(numberlist == ""){
			numberlist = temp[i].phone;
		}else{
			numberlist += ","+temp[i].phone;
		}
	}
}
 console.log(numberlist,this.state.formObj.message);
 var sms = {number: numberlist, msg: this.state.formObj.message};
  axios.post(base_url+'sms/sendsms', sms).then(res => {
	 console.log(res);
   }).catch(function (error) { console.log(error); });  
this.setState({senddisable:true,showbtn:false});
this.setState({showSuccessMsg: true}, ()=>{
	setTimeout(() => {
	this.setState({showSuccessMsg: false })
	}, 3000);
});
}

backToSearch = () =>{
	this.setState({formObj:{},searchform:true,mainform:false,showtable:false,disvalue:true,showSuccessMsg:false,checkdisable:true,objList:[],senddisable:true,senddisableemail:true,showbtn:true});
}

checkDisableNext = () =>{
	var temp=this.state.formObj;
	temp.template="";
	temp.message="";
	this.setState({formObj:temp,senddisable:true,senddisableemail:true});
	if(this.state.sendlist.length !== 0){
		this.setState({checkdisable:false});
	}else{
		this.setState({checkdisable:true});
	}	
}



checkRowData = (id) =>{
	var arr=this.state.objList[id];
	if(arr.select === "" || arr.select === undefined ||arr.select === false){
		arr.select = true;
	}else{
		arr.select = false;
	}
	var number=arr.cellphone;
	number=number.replace(/\D/g,"");
	var email=arr.email;
	var obj={phone: number ,email: email,select: arr.select ,id: arr.id}		
	var objlist=this.state.sendlist;
	if(obj.select === false){
		for(var i in objlist){
			if(objlist[i].id === obj.id){
			objlist.splice(i,1);
			}
		}
	}else{
		this.state.sendlist.push(obj);
	}
	this.checkDisableNext();

}

setTemplate = (tmplt) =>{
var temp=this.state.formObj;
temp.message=tmplt;
}

selectAll = (sl) =>{
var arr=this.state.objList;
this.setState({sendlist:[]});
var sendarr=[];
if(sl === true){
for(var i in arr){
	arr[i].select=true;
	var number=arr[i].cellphone;
	number=number.replace(/\D/g,"");
	var email=arr[i].email;
	var obj={phone: number ,select: arr[i].select,email: email,id: arr[i].id}
	sendarr.push(obj);
	this.setState({objList:arr,checkdisable:false,sendlist:sendarr});
}
}else{
	var sendarr=this.state.sendlist;
	for(var i=sendarr.length;i>=-1;i--){
		sendarr.splice(i);
	}
	for(var i in arr){
		arr[i].select=false;
	}
	this.setState({objList:arr,checkdisable:true});
}
this.setState({check:sl});
}


renderObjlist = () => {
	if( this.state.objList != undefined && this.state.objList.length > 0){
		return this.state.objList.map((item, index) =>{
			return (
				<tr key={index} id={index}>
					<td><input type="checkbox"  checked={item.select} id="myCheck"  onChange={()=>{this.checkRowData(index)}} /></td>
					<td>{item.cardid}</td>
					<td>{item.firstname}</td>
					<td>{item.cellphone}</td>
					<td>{item.email}</td>
					</tr>
				)
			}, this);
	}
}

renderSMSTemplate = () => {
	if( this.state.settings.smstemplate != undefined && this.state.settings.smstemplate.length > 0){
		return this.state.settings.smstemplate.map((item, index) =>{
			if(item.smsapproval == "Approved"){
			return (
				<option value={item.templatecontent} key={index} id={index}>{item.template}</option>
	
				)
			}
		}, this);
	
	}
}
renderDepartmentTypes = () => {
	if( this.state.settings.departmentTypes != undefined && this.state.settings.departmentTypes.length > 0){
		return this.state.settings.departmentTypes.map((item, index) =>{
			return (
				<option value={item.department} key={index} id={index}>{item.department}</option>	
				)
		}, this);
	}
}

renderRegistrationTypes = () => {
	if( this.state.settings.registrationTypes != undefined && this.state.settings.registrationTypes.length > 0){
		return this.state.settings.registrationTypes.map((item, index) =>{
			return (
				<option vale={item.registrationtype} key={index} id={index}>{item.registrationtype}</option>
				)
		}, this);
	}
}

render() {

return (
<div className="shadow-sm p-3 mb-5 bg-white rounded">
<div><div className="d-flex row justify-content-center"><h3>SMS/Notification</h3></div></div><br/>
	{this.state.showSuccessMsg ?
	<div className="d-flex row ">
		<div className="p-2 flex-fill">
		<FlashMessage duration={3000} persistOnHover={true}>
		{flashMessage("Notifications Sent Successfully", "Success")}
		</FlashMessage>
		</div>	
	</div>
	:null
	}
	{this.state.searchform?<div><div className="d-flex row justify-content-start">
	<div className="p-2 col-md-4">
		<label htmlFor="idmembertype" className="label-custom" >Member Type</label> 
		<select value={this.state.formObj.membertype} id="idmembertype"  onChange={(e)=>{this.format("membertype",e)}}   onBlur={()=>{ this.validate("membertype")}} className="form-control form-control-sm" style={this.state.validations.membertype===""?{}:{"borderColor":"red"}} type="text" className='form-control form-control-sm' required > 
		<option value=""></option>
		{this.renderRegistrationTypes()}
		</select>
		<span className="errormsg">{this.state.validations.membertype}</span>
	</div>
	<div className="p-2 col-md-4">
	<label htmlFor="iddepartment" className="label-custom" >Department Type</label> 
		<select value={this.state.formObj.department} id="iddepartment"  onChange={(e)=>{this.format("department",e)}}   onBlur={()=>{ this.validate("department")}} className="form-control form-control-sm" style={this.state.validations.department===""?{}:{"borderColor":"red"}} type="text" className='form-control form-control-sm' required > 
			<option value=""></option>
			{this.renderDepartmentTypes()}	</select>
		<span className="errormsg">{this.state.validations.department}</span>
</div>
	<div className="p-2">
		<button type="button" className="btn btn-primary btn-sm" style={{marginTop:32}} disabled={this.state.disvalue}   onClick={()=>{ this.searchType()}}>Search</button>
	</div>
</div>
<span className="errormsg">{this.state.searchmessage}</span>
{this.state.showtable?
<div>
		<div className="table-wrapper-scroll-y my-custom-scrollbar">
	<table className="table table-bordered  table-sm">
	<thead className="thead">
		<tr>
			<td className="col-sm-2"><input type="checkbox"  id="myCheck" checked={this.state.check}  onChange={()=>{this.selectAll(!this.state.check)}} />&nbsp;&nbsp;Select All</td>
			<td className="col-sm-2">Cardid</td>
			<td>First Name</td>
			<td>Phone</td>
			<td>Email</td>
		</tr>
	</thead>
	<tbody>
		{this.renderObjlist()}</tbody> 
	</table>
	</div>
	</div>
	:null}
	<div className="p-2  row justify-content-end">
		<button type="button" className="btn btn-primary btn-sm" style={{marginTop:32}} disabled={this.state.checkdisable}   onClick={()=>{ this.gototemplateForm()}}>Next</button>
	</div></div>:null}
{this.state.mainform?<div><div className="d-flex row justify-content-end">
<div className="p-2 col-md-4">
<label htmlFor="idtemplate" className="label-custom" >Template</label> 
		<select value={this.state.formObj.template} id="idtemplate"  onChange={(e)=>{this.format("template",e)}}   onBlur={()=>{ this.validate("template")}} className="form-control form-control-sm" style={this.state.validations.template===""?{}:{"borderColor":"red"}} type="text" className='form-control form-control-sm' required > 
			<option value="">Select Template</option>
			{this.renderSMSTemplate()}	</select>
		<span className="errormsg">{this.state.validations.template}</span>
</div>
</div>
<div className="d-flex row">
	<div className="p-2 flex-fill">
		<label htmlFor="idmessage" className="label-custom" >Message</label> 
		<textarea value={this.state.formObj.message} id="idmessage" rows="4" cols="40"  className="form-control form-control-sm" readOnly required /> 
	</div>
</div>
<div className="d-flex row justify-content-end">
{this.state.showbtn?<div className="p-2">
<button type="button" className="btn btn-primary btn-sm" onClick={()=>{this.setState({mainform:false,searchform:true})}} >Back</button>
</div>:<div className="p-2">
<button type="button" className="btn btn-primary btn-sm" onClick={()=>{this.backToSearch()}} >Close</button>
</div>}
<div className="p-2">
<button type="button" className="btn btn-success btn-sm" onClick={()=>{this.sendSMS()}} disabled={this.state.senddisable} >Send SMS</button>
</div>
<div className="p-2">
<button type="button" className="btn btn-success btn-sm" onClick={()=>{this.sendEmail()}} disabled={this.state.senddisableemail} >Send Email</button>
</div>
</div>
</div>:null}
</div>
)
}
}

const mapToProps = ({ loggedUserState }) => ({ user: loggedUserState })
export default connect(mapToProps)(SmsForm);
