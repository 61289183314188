import React from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { Validators } from '../utils/Validators';
import { Formators } from '../utils/Formators';
import { base_url } from '../constants/Constants';
import { Report } from '../models/report';
import BookTabulator from './BookTabulator';
import CdTabulator from './CdTabulator';
import JournalTabulator from './JournalTabulator';
import MemberTabulator from './MemberTabulator';
import IssuebookTabulator from './IssueBookTabulator';
import ReceiveTabulator from './ReceiveTabulator';
import InactiveTabulator from'./InactivememberTabulator';
import FastmovingTabulator from'./FastMovingTabulator';
import SlowmovingTabulator from'./SlowMovingTabulator';
import CheckoutTabulator from'./Checkout_HistoryTabulator';
import CheckoutmemberTabulator from'./Checkout_MemberTabulator';
import OverdueTabulator from './Overdue_Tabulator';
import  {Getpdf} from '../utils/ReportPdf';
import 'react-tabulator/lib/styles.css';
import "react-tabulator/lib/css/bootstrap/tabulator_bootstrap.min.css";
import '../styles/Styles.css';
import { Redirect } from 'react-router-dom';

class ReportForm extends React.Component {
constructor(props) {
    super(props);
    this.state = {
        validations: {},
        showModels:{booktable:false,todaycheckintable:false, overduetable:false,cdlisttable:false,journaltable:false,historyMembertable:false,membertable: false,checkouttable: false,checkintable: false,memberchecked:false,inactiveMemtable: false,fastMovingtable: false,slowMovingtable: false,historyTitletable:false,duetable:false},
        formObj: Report,
        check1:false,
        showDate:false,
        Bookdata:[],
        Bookdatalist:[],
        cddata:[],
        showAdvanced:false,
        journaldata:[],
        memberdata:[],
        inactivememberdata:[],
        checkoutdata:[],
        checkindata:[],
        cashdata:[],
        memberchecked:false,
        memberchecked1:false,
        data:[],
        reporttype:true,
        select1:false,
        select:false,
    }
}
componentDidMount() {
    let tempObj = {};
    for (let key in Report) { tempObj[key] = ""; }
    this.setState({ validations: tempObj });
    let tempObjs = {};
    for(let key in Report){ tempObjs[key]="";}
    this.setState({formObj:tempObjs});
   
}

getReport = (id) => {

    var tempobj=this.state.showModels;
    tempobj.booktable=false;
    tempobj.overduetable=false;
    tempobj.cdlisttable=false;
    tempobj.journaltable=false;
    tempobj.historyMembertable=false;
    tempobj.membertable=false;
    tempobj.checkouttable=false;
    tempobj.checkintable=false;
    tempobj.memberchecked=false;
    tempobj.inactiveMemtable=false;
    tempobj.fastMovingtable= false;
    tempobj.slowMovingtable=false;
    tempobj.historyTitletable=false;
    tempobj.todaycheckintable=false;
    tempobj.duetable=false;
    this.setState({showModels:tempobj});
    if(id !== "" && id !== undefined){
    switch(id){
      
        case "Statistics On Books":
            if(this.state.showAdvanced){ 
                if(this.state.formObj.enddate !== "" && this.state.formObj.startdate !== "" && this.state.formObj.enddate !== undefined && this.state.formObj.startdate !== undefined){
                if(this.state.formObj.enddate < this.state.formObj.startdate)
                {
                    var temp=this.state.validations;
                    temp.enddate="Please provide the date grater then start date.";
                    this.setState({validations:temp});
                    tempobj.booktable=false;
                    this.setState({data:[]});
                    this.setState({showModels:tempobj});
                }else{
            if(this.state.memberchecked){   
                var enddt=new Date(this.state.formObj.enddate);
                enddt.setHours(23,59,0,0);
                var obj={filter:"all",startdate:this.state.formObj.startdate,enddate:enddt};
                axios.post(base_url+'books_catalog/betweendatelist',obj, this.props.user.header ).then(res => { 
                    tempobj.booktable=true;
                    if ( res.data.length > 0 ){
                        this.setState({data:res.data});
                    }else{
                        this.setState({data:[]});
                    }
                    this.setState({showModels:tempobj});
                }).catch(function (error) { console.log(error); });
              
    }else{
                var enddt=new Date(this.state.formObj.enddate);
                enddt.setHours(23,59,0,0);
                var obj={filter:"allactive",startdate:this.state.formObj.startdate,enddate:enddt};
                axios.post(base_url+'books_catalog/betweendatelist',obj, this.props.user.header ).then(res => { 
                    tempobj.booktable=true;
                    if ( res.data.length > 0 ){
                        this.setState({data:res.data});
                    }else{
                        this.setState({data:[]});
                    }
                    this.setState({showModels:tempobj});
                }).catch(function (error) { console.log(error); });  
           // this.state.data=Getpdf.Getallbookdata(this.state.formObj.startdate,this.state.formObj.enddate,this.state.Bookdata);tempobj.booktable=true;
         
        }
    }
    }else{
        var temp=this.state.validations;
        temp.enddate="Please enter End date";
        temp.startdate="Please enter Start date";
        this.setState({validations:temp});
        tempobj.booktable=false;
        this.setState({data:[]});
        this.setState({showModels:tempobj});

    }
        }else{
            axios.get(base_url+'books_catalog/list', this.props.user.header ).then(res => { 
                tempobj.booktable=true;
                    if ( res.data.length > 0 ){
                        this.setState({data:res.data});
                    }else{
                        this.setState({data:[]});
                    }
                    this.setState({showModels:tempobj});
            }).catch(function (error) { console.log(error); });
            
           // this.setState({showModels:tempobj});
        }
        break;
        case "Statistics On CD/DVD" :
            if(this.state.showAdvanced){
                if(this.state.formObj.enddate !== "" && this.state.formObj.startdate !== "" && this.state.formObj.enddate !== undefined && this.state.formObj.startdate !== undefined){
                    if(this.state.formObj.enddate < this.state.formObj.startdate)
                    {
                        var temp=this.state.validations;
                        temp.enddate="Please provide the date grater then start date";
                        this.setState({validations:temp});
                        tempobj.cdlisttable=false;
                        this.setState({data:[]});
                        this.setState({showModels:tempobj});
                    }else{
                if(this.state.memberchecked){
                    var enddt=new Date(this.state.formObj.enddate);
                    enddt.setHours(23,59,0,0);
                    axios.post(base_url+'cd_catalog/betweendatelist',{filter:"all",startdate:this.state.formObj.startdate,enddate:enddt} ,this.props.user.header ).then(res => {
                        if ( res.data.length > 0 ){
                            tempobj.cdlisttable=true;
                            this.setState({ data:res.data});
                        }else{
                            this.setState({ data:[]});
                        }
                        this.setState({showModels:tempobj});
                    }).catch(function (error) { console.log(error); });
                        }
                else{
                    var enddt=new Date(this.state.formObj.enddate);
                         enddt.setHours(23,59,0,0);
                     axios.post(base_url+'cd_catalog/betweendatelist',{filter:"onlyactive",startdate:this.state.formObj.startdate,enddate:enddt} ,this.props.user.header ).then(res => {
                        tempobj.cdlisttable=true;
                        if ( res.data.length > 0 ){
                            this.setState({ data:res.data});
                        }else{
                            this.setState({ data:[]}); 
                        }
                        this.setState({showModels:tempobj});  
                    }).catch(function (error) { console.log(error); });
                   // this.state.data=Getpdf.GetActivebookdata(this.state.formObj.startdate,this.state.formObj.enddate,this.state.cddata);tempobj.cdlisttable=true;
             
                }
            }
        }else{
            var temp=this.state.validations;
            temp.enddate="Please enter End date";
            temp.startdate="Please enter Start date";
            this.setState({validations:temp});
            tempobj.booktable=false;
            this.setState({data:[]});
            this.setState({showModels:tempobj});
    
        }
    }
            else{
                axios.get(base_url+'cd_catalog/list', this.props.user.header ).then(res => {
                    tempobj.cdlisttable=true;
                    if ( res.data.length > 0 ){
                        this.setState({ data:res.data});
                        
                    }else{
                        this.setState({ data:res.data});
                    }
                    this.setState({showModels:tempobj});
                }).catch(function (error) { console.log(error); });
            }
        break;
        case "Statistics On Journals" :
            if(this.state.showAdvanced){
                if(this.state.formObj.enddate !== "" && this.state.formObj.startdate !== "" && this.state.formObj.enddate !== undefined && this.state.formObj.startdate !== undefined){
                    if(this.state.formObj.enddate < this.state.formObj.startdate)
                    {
                        var temp=this.state.validations;
                        temp.enddate="Please provide the date grater then start date";
                        this.setState({validations:temp});
                        tempobj.journaltable=false;
                        this.setState({data:[]});
                        this.setState({showModels:tempobj});
                    }else{
                if(this.state.memberchecked){
                    var enddt=new Date(this.state.formObj.enddate);
                    enddt.setHours(23,59,0,0);
                    axios.post(base_url+'journals_catalog/betweendatelist',{filter:"all",startdate:this.state.formObj.startdate,enddate:enddt} ,this.props.user.header ).then(res => {
                        if ( res.data.length > 0 ){
                            tempobj.journaltable=true;
                            this.setState({ data:res.data});
                        }else{
                            this.setState({ data:[]});
                        }
                        this.setState({showModels:tempobj});
                    }).catch(function (error) { console.log(error); });
                        }
                else{
                    var enddt=new Date(this.state.formObj.enddate);
                         enddt.setHours(23,59,0,0);
                     axios.post(base_url+'journals_catalog/betweendatelist',{filter:"onlyactive",startdate:this.state.formObj.startdate,enddate:enddt} ,this.props.user.header ).then(res => {
                        tempobj.journaltable=true;
                        if ( res.data.length > 0 ){
                            this.setState({ data:res.data});
                        }else{
                            this.setState({ data:[]}); 
                        }
                        this.setState({showModels:tempobj});  
                    }).catch(function (error) { console.log(error); });
              
                }
            }
        }else{
            var temp=this.state.validations;
            temp.enddate="Please enter End date";
            temp.startdate="Please enter Start date";
            this.setState({validations:temp});
            tempobj.booktable=false;
            this.setState({data:[]});
            this.setState({showModels:tempobj});
    
        }
        }
            else{
                axios.get(base_url+'journals_catalog/list', this.props.user.header ).then(res => {
                    tempobj.journaltable=true;
                    if ( res.data.length > 0 ){
                        this.setState({ data:res.data});
                        
                    }else{
                        this.setState({ data:[]});
                    }
                    this.setState({showModels:tempobj});
                }).catch(function (error) { console.log(error); });
            }
        break;
        case "Statistics On Members":
            if(this.state.showAdvanced){
                if(this.state.formObj.enddate !== "" && this.state.formObj.startdate !== "" && this.state.formObj.enddate !== undefined && this.state.formObj.startdate !== undefined){
                    if(this.state.formObj.enddate < this.state.formObj.startdate)
                    {
                        var temp=this.state.validations;
                        temp.enddate="Please provide the date grater then start date";
                        this.setState({validations:temp});
                        tempobj.membertable=false;
                        this.setState({data:[]});
                        this.setState({showModels:tempobj});
                    }else{
                if(this.state.memberchecked){
                    var enddt=new Date(this.state.formObj.enddate);
                    enddt.setHours(23,59,0,0);
                    axios.post(base_url+'members/betweendatelist',{filter:"all",startdate:this.state.formObj.startdate,enddate:enddt} ,this.props.user.header ).then(res => {
                        if ( res.data.length > 0 ){
                            tempobj.membertable=true;
                            this.setState({ data:res.data});
                        }else{
                            this.setState({ data:[]});
                        }
                        this.setState({showModels:tempobj});
                    }).catch(function (error) { console.log(error); });
                        }
                else{
                    var enddt=new Date(this.state.formObj.enddate);
                         enddt.setHours(23,59,0,0);
                     axios.post(base_url+'members/betweendatelist',{filter:"onlyactive",startdate:this.state.formObj.startdate,enddate:enddt} ,this.props.user.header ).then(res => {
                        tempobj.membertable=true;
                        if ( res.data.length > 0 ){
                            this.setState({ data:res.data});
                        }else{
                            this.setState({ data:[]}); 
                        }
                        this.setState({showModels:tempobj});  
                    }).catch(function (error) { console.log(error); });
              
                }
            }
        }else{
            var temp=this.state.validations;
            temp.enddate="Please enter End date";
            temp.startdate="Please enter Start date";
            this.setState({validations:temp});
            tempobj.memberable=false;
            this.setState({data:[]});
            this.setState({showModels:tempobj});
    
        }
        }
            else{
                axios.get(base_url+'members/list', this.props.user.header ).then(res => {
                    tempobj.membertable=true;
                    if ( res.data.length > 0 ){
                        this.setState({ data:res.data});
                        
                    }else{
                        this.setState({ data:[]});
                    }
                    this.setState({showModels:tempobj});
                }).catch(function (error) { console.log(error); });
            }
           
            break;
        case "Check-out Statistics" : 
        if(this.state.showAdvanced){
            if(this.state.formObj.enddate !== "" && this.state.formObj.startdate !== "" && this.state.formObj.enddate !== undefined && this.state.formObj.startdate !== undefined){
                if(this.state.formObj.enddate < this.state.formObj.startdate)
                {
                    var temp=this.state.validations;
                    temp.enddate="Please provide the date grater then start date";
                    this.setState({validations:temp});
                    tempobj.checkouttable=false;
                    this.setState({data:[]});
                    this.setState({showModels:tempobj});
                }else{
            if(this.state.memberchecked){
                var enddt=new Date(this.state.formObj.enddate);
                enddt.setHours(23,59,0,0);
                axios.post(base_url+'checkout/betweendatelist',{filter:"all",startdate:this.state.formObj.startdate,enddate:enddt} ,this.props.user.header ).then(res => {
                    if ( res.data.length > 0 ){
                        tempobj.checkouttable=true;
                        this.setState({ data:res.data});
                    }else{
                        this.setState({ data:[]});
                    }
                    this.setState({showModels:tempobj});
                }).catch(function (error) { console.log(error); });
                    }
            else{
                var enddt=new Date(this.state.formObj.enddate);
                     enddt.setHours(23,59,0,0);
                 axios.post(base_url+'checkout/betweendatelist',{filter:"onlyactive",startdate:this.state.formObj.startdate,enddate:enddt} ,this.props.user.header ).then(res => {
                    tempobj.checkouttable=true;
                    if ( res.data.length > 0 ){
                        this.setState({ data:res.data});
                    }else{
                        this.setState({ data:[]}); 
                    }
                    this.setState({showModels:tempobj});  
                }).catch(function (error) { console.log(error); });
          
            }
        }
    }else{
        var temp=this.state.validations;
        temp.enddate="Please enter End date";
        temp.startdate="Please enter Start date";
        this.setState({validations:temp});
        tempobj.checkouttable=false;
        this.setState({data:[]});
        this.setState({showModels:tempobj});

    }
        }
        else{
            axios.get(base_url+'checkout/list', this.props.user.header ).then(res => {
                tempobj.checkouttable=true;
                if ( res.data.length > 0 ){
                    this.setState({ data:res.data});
                    
                }else{
                    this.setState({ data:[]});
                }
                this.setState({showModels:tempobj});
            }).catch(function (error) { console.log(error); });
        }
       
        break;   
        case "Check-In Statistics" :
            if(this.state.showAdvanced){
                if(this.state.formObj.enddate !== "" && this.state.formObj.startdate !== "" && this.state.formObj.enddate !== undefined && this.state.formObj.startdate !== undefined){
                    if(this.state.formObj.enddate < this.state.formObj.startdate)
                    {
                        var temp=this.state.validations;
                        temp.enddate="Please provide the date grater then start date";
                        this.setState({validations:temp});
                        tempobj.checkintable=false;
                        this.setState({data:[]});
                        this.setState({showModels:tempobj});
                    }else{
                if(this.state.memberchecked){
                    var enddt=new Date(this.state.formObj.enddate);
                    enddt.setHours(23,59,0,0);
                    console.log("hi");
                    axios.post(base_url+'checkin/betweendatelist',{filter:"all",startdate:this.state.formObj.startdate,enddate:enddt} ,this.props.user.header ).then(res => {
                        if ( res.data.length > 0 ){
                            tempobj.checkintable=true;
                            this.setState({ data:res.data});
                        }else{
                            this.setState({ data:[]});
                        }
                        this.setState({showModels:tempobj});
                    }).catch(function (error) { console.log(error); });
                        }
                else{
                    var enddt=new Date(this.state.formObj.enddate);
                         enddt.setHours(23,59,0,0);
                     axios.post(base_url+'checkin/betweendatelist',{filter:"onlyactive",startdate:this.state.formObj.startdate,enddate:enddt} ,this.props.user.header ).then(res => {
                        tempobj.checkintable=true;
                        if ( res.data.length > 0 ){
                            this.setState({ data:res.data});
                        }else{
                            this.setState({ data:[]}); 
                        }
                        this.setState({showModels:tempobj});  
                    }).catch(function (error) { console.log(error); });
              
                }
            }
        }else{
            var temp=this.state.validations;
            temp.enddate="Please enter End date";
            temp.startdate="Please enter Start date";
            this.setState({validations:temp});
            tempobj.checkintable=false;
            this.setState({data:[]});
            this.setState({showModels:tempobj});
    
        }
    }
            else{
                axios.get(base_url+'checkin/list', this.props.user.header ).then(res => {
                    tempobj.checkintable=true;
                    if ( res.data.length > 0 ){
                        this.setState({ data:res.data});
                        
                    }else{
                        this.setState({ data:[]});
                    }
                    this.setState({showModels:tempobj});
                }).catch(function (error) { console.log(error); });
            }
           
            break;
        case "Fast Moving Titles":
            if(this.state.showAdvanced){
                if(this.state.formObj.enddate !== "" && this.state.formObj.startdate !== "" && this.state.formObj.enddate !== undefined && this.state.formObj.startdate !== undefined){
                    if(this.state.formObj.enddate < this.state.formObj.startdate)
                    {
                        var temp=this.state.validations;
                        temp.enddate="Please provide the date grater then start date";
                        this.setState({validations:temp});
                        tempobj.fastMovingtable=false;
                        this.setState({data:[]});
                        this.setState({showModels:tempobj});
                    }else{
                  axios.get(base_url+'checkout/list', this.props.user.header ).then(res => {
                    if ( res.data.length > 0 ){
                        this.setState({ checkoutdata:res.data});
                        
                    }else{
                        this.setState({ data:[]});
                    }
                    this.state.data=Getpdf.FastMovingTitles(this.state.formObj.startdate,this.state.formObj.enddate,this.state.checkoutdata);tempobj.fastMovingtable=true; 
                    this.setState({showModels:tempobj});
                }).catch(function (error) { console.log(error); });
              
            }
        
        }else{
            var temp=this.state.validations;
            temp.enddate="Please enter End date";
            temp.startdate="Please enter Start date";
            this.setState({validations:temp});
            tempobj.fastMovingtable=false;
            this.setState({data:[]});
            this.setState({showModels:tempobj});
    
        }
        }
            else{
                axios.get(base_url+'checkout/list', this.props.user.header ).then(res => {
                   // tempobj.fastMovingtable=true;
                    if ( res.data.length > 0 ){
                        this.setState({ checkoutdata:res.data});
                        
                    }else{
                        this.setState({ data:[]});
                    }
                      
                this.state.data=Getpdf.FastMovingTitle(this.state.formObj.startdate,this.state.formObj.enddate,this.state.checkoutdata);tempobj.fastMovingtable=true; 
                this.setState({showModels:tempobj});
                }).catch(function (error) { console.log(error); });
            }
            break;
        case "Slow Moving Titles" :
            if(this.state.showAdvanced){
                if(this.state.formObj.enddate !== "" && this.state.formObj.startdate !== "" && this.state.formObj.enddate !== undefined && this.state.formObj.startdate !== undefined){
                    if(this.state.formObj.enddate < this.state.formObj.startdate)
                    {
                        var temp=this.state.validations;
                        temp.enddate="Please provide the date grater then start date";
                        this.setState({validations:temp});
                        tempobj.slowMovingtable=false;
                        this.setState({data:[]});
                        this.setState({showModels:tempobj});
                    }else{
                axios.get(base_url+'checkout/list', this.props.user.header ).then(res => {
                    if ( res.data.length > 0 ){
                         this.setState({ checkoutdata:res.data});
                         
                     }else{
                         this.setState({ data:[]});
                     }
                 this.state.data=Getpdf.SlowMovingTitles(this.state.formObj.startdate,this.state.formObj.enddate,this.state.checkoutdata);tempobj.slowMovingtable=true;   
                 this.setState({showModels:tempobj});
                 }).catch(function (error) { console.log(error); });
                }
            }else{
                var temp=this.state.validations;
                temp.enddate="Please enter End date";
                temp.startdate="Please enter Start date";
                this.setState({validations:temp});
                tempobj.slowMovingtable=false;
                this.setState({data:[]});
                this.setState({showModels:tempobj});
        
            }
            }
            else{
                axios.get(base_url+'checkout/list', this.props.user.header ).then(res => {
                     if ( res.data.length > 0 ){
                         this.setState({ checkoutdata:res.data});
                         
                     }else{
                         this.setState({ data:[]});
                     }
                     this.state.data=Getpdf.SlowMovingTitle(this.state.formObj.startdate,this.state.formObj.enddate,this.state.checkoutdata);tempobj.slowMovingtable=true;
                     this.setState({showModels:tempobj});
                 }).catch(function (error) { console.log(error); });
           }
        break;
        case "Today Check-Out List":
            if(this.state.showAdvanced){
                if(this.state.formObj.enddate !== "" && this.state.formObj.startdate !== "" && this.state.formObj.enddate !== undefined && this.state.formObj.startdate !== undefined){
                    if(this.state.formObj.enddate !== this.state.formObj.startdate)
                    {
                        var temp=this.state.validations;
                        temp.enddate="Please provide the same date for start date and end date";
                        this.setState({validations:temp});
                        tempobj.checkouttable=false;
                        this.setState({data:[]});
                        this.setState({showModels:tempobj});
                    }else{
            var startOfToday = new Date(this.state.formObj.startdate);
            startOfToday.setHours(0,0,0,0);
            var endOfToday = new Date(this.state.formObj.startdate);
            endOfToday.setHours(23,59,0,0);
            console.log(endOfToday);
            var obj={filter:"todaylist",startdate:startOfToday,enddate:endOfToday };
            axios.post(base_url+'checkout/betweendatelist',obj, this.props.user.header ).then(res => { 
        
                tempobj.checkouttable=true;
                if ( res.data.length > 0 ){
            
                this.setState({data:res.data});
                }else{
                    this.setState({data:[]}); 
                }
                this.setState({showModels:tempobj});
            }).catch(function (error) { console.log(error); }); 
        }
    }else{
        var temp=this.state.validations;
        temp.enddate="Please enter End date";
        temp.startdate="Please enter Start date";
        this.setState({validations:temp});
        tempobj.slowMovingtable=false;
        this.setState({data:[]});
        this.setState({showModels:tempobj});
    }
    }else{
        var startOfToday = new Date();
        startOfToday.setHours(0,0,0,0);
        var endOfToday = new Date();
        endOfToday.setHours(23,59,0,0);
        console.log(endOfToday);
        var obj={filter:"todaylist",startdate:startOfToday,enddate:endOfToday };
        axios.post(base_url+'checkout/betweendatelist',obj, this.props.user.header ).then(res => { 
    
            tempobj.checkouttable=true;
            if ( res.data.length > 0 ){
        
            this.setState({data:res.data});
            }else{
                this.setState({data:[]}); 
            }
            this.setState({showModels:tempobj});
        }).catch(function (error) { console.log(error); }); 

    }
           
            
        break;
        case "Today Check-in List":
            if(this.state.showAdvanced){
                if(this.state.formObj.enddate !== "" && this.state.formObj.startdate !== "" && this.state.formObj.enddate !== undefined && this.state.formObj.startdate !== undefined){
                    if(this.state.formObj.enddate !== this.state.formObj.startdate)
                    {
                        var temp=this.state.validations;
                        temp.enddate="Please provide the same date for start date and end date";
                        this.setState({validations:temp});
                        tempobj.checkintable=false;
                        this.setState({data:[]});
                        this.setState({showModels:tempobj});
                    }else{
            var startOfToday = new Date(this.state.formObj.startdate);
            startOfToday.setHours(0,0,0,0);
            var endOfToday = new Date(this.state.formObj.startdate);
            endOfToday.setHours(23,59,0,0);
            console.log(endOfToday);
            var obj={filter:"todaylist",startdate:startOfToday,enddate:endOfToday };
            axios.post(base_url+'checkin/betweendatelist',obj, this.props.user.header ).then(res => { 
        
                tempobj.checkintable=true;
                if ( res.data.length > 0 ){
            
                this.setState({data:res.data});
                }else{
                    this.setState({data:[]}); 
                }
                this.setState({showModels:tempobj});
            }).catch(function (error) { console.log(error); }); 
        }
    }else{
        var temp=this.state.validations;
        temp.enddate="Please enter End date";
        temp.startdate="Please enter Start date";
        this.setState({validations:temp});
        tempobj.checkintable=false;
        this.setState({data:[]});
        this.setState({showModels:tempobj});
    }
    }else{
        var startOfToday = new Date();
        startOfToday.setHours(0,0,0,0);
        var endOfToday = new Date();
        endOfToday.setHours(23,59,0,0);
        console.log(endOfToday);
        var obj={filter:"todaylist",startdate:startOfToday,enddate:endOfToday };
        axios.post(base_url+'checkin/betweendatelist',obj, this.props.user.header ).then(res => { 
    
            tempobj.checkintable=true;
            if ( res.data.length > 0 ){
        
            this.setState({data:res.data});
            }else{
                this.setState({data:[]}); 
            }
            this.setState({showModels:tempobj});
        }).catch(function (error) { console.log(error); }); 

    }
            
        break;
        
        case "Today Check-Out History (Member)" :
            if(this.state.showAdvanced){  
                this.state.data=Getpdf.CheckoutMembers(this.state.formObj.startdate,this.state.formObj.enddate,this.state.checkoutdata); tempobj.historyMembertable=true;
            }
            else{
            this.state.data=Getpdf.CheckoutMember(this.state.formObj.startdate,this.state.formObj.enddate,this.state.checkoutdata); tempobj.historyMembertable=true;
            
            }
        break;
        
        case "Cash Report" :
            if(this.state.showAdvanced){
                if(this.state.formObj.enddate !== "" && this.state.formObj.startdate !== "" && this.state.formObj.enddate !== undefined && this.state.formObj.startdate !== undefined){
                    if(this.state.formObj.enddate < this.state.formObj.startdate)
                    {
                        var temp=this.state.validations;
                        temp.enddate="Please provide the date grater then start date";
                        this.setState({validations:temp});
                        tempobj.overduetable=false;
                        this.setState({data:[]});
                        this.setState({showModels:tempobj});
                    }else{
                if(this.state.memberchecked){
                    var startdt=new Date(this.state.formObj.startdate);
                    startdt.setHours(0,0,0,0);
                    var enddt=new Date(this.state.formObj.enddate);
                         enddt.setHours(23,59,0,0);
                    axios.post(base_url+'bills/betweendatelist',{filter:"all",startdate:startdt,enddate:enddt} ,this.props.user.header ).then(res => {
                        if ( res.data.length > 0 ){
                            tempobj.overduetable=true;
                            this.setState({ data:res.data});
                        }else{
                            this.setState({ data:[]});
                        }
                        this.setState({showModels:tempobj});
                    }).catch(function (error) { console.log(error); });
                        }
                else{
                    var startdt=new Date(this.state.formObj.startdate);
                    startdt.setHours(0,0,0,0);
                    var enddt=new Date(this.state.formObj.enddate);
                         enddt.setHours(23,59,0,0);
                     axios.post(base_url+'bills/betweendatelist',{filter:"onlyactive",startdate:startdt,enddate:enddt} ,this.props.user.header ).then(res => {
                        tempobj.overduetable=true;
                        if ( res.data.length > 0 ){
                            this.setState({ data:res.data});
                        }else{
                            this.setState({ data:[]}); 
                        }
                        this.setState({showModels:tempobj});  
                    }).catch(function (error) { console.log(error); });
                   // this.state.data=Getpdf.GetActivebookdata(this.state.formObj.startdate,this.state.formObj.enddate,this.state.cddata);tempobj.overduetable=true;
             
                }
            }
        }else{
            var temp=this.state.validations;
            temp.enddate="Please enter End date";
            temp.startdate="Please enter Start date";
            this.setState({validations:temp});
            tempobj.overduetable=false;
            this.setState({data:[]});
            this.setState({showModels:tempobj});
    
        }
    }
            else{
                axios.get(base_url+'bills/paidlist', this.props.user.header ).then(res => {
                    tempobj.overduetable=true;
                    if ( res.data.length > 0 ){
                        this.setState({ data:res.data});
                        
                    }else{
                        this.setState({ data:res.data});
                    }
                    this.setState({showModels:tempobj});
                }).catch(function (error) { console.log(error); });
            }
        break;

        case "Book Due Staistics" : 
        if(this.state.showAdvanced){
            if(this.state.formObj.enddate !== "" && this.state.formObj.startdate !== "" && this.state.formObj.enddate !== undefined && this.state.formObj.startdate !== undefined){
                if(this.state.formObj.enddate < this.state.formObj.startdate)
                {
                    var temp=this.state.validations;
                    temp.enddate="Please provide the date grater then start date";
                    this.setState({validations:temp});
                    tempobj.duetable=false;
                    this.setState({data:[]});
                    this.setState({showModels:tempobj});
                }else{
                    var startdt=new Date(this.state.formObj.startdate);
                    startdt.setHours(0,0,0,0);
                    var enddt=new Date(this.state.formObj.enddate);
                         enddt.setHours(23,59,0,0);
                axios.post(base_url+'checkout/betweendatelist',{filter:"due",startdate:startdt,enddate:enddt} ,this.props.user.header ).then(res => {
                    if ( res.data.length > 0 ){
                        tempobj.duetable=true;
                        this.setState({ data:res.data});
                    }else{
                        this.setState({ data:[]});
                    }
                    this.setState({showModels:tempobj});
                }).catch(function (error) { console.log(error); });
        }
    }else{
        var temp=this.state.validations;
        temp.enddate="Please enter End date";
        temp.startdate="Please enter Start date";
        this.setState({validations:temp});
        tempobj.duetable=false;
        this.setState({data:[]});
        this.setState({showModels:tempobj});

    }
        }
        else{
            axios.get(base_url+'checkout/duelist', this.props.user.header ).then(res => {
                tempobj.duetable=true;
                if ( res.data.length > 0 ){
                    this.setState({ data:res.data});
                    
                }else{
                    this.setState({ data:[]});
                }
                this.setState({showModels:tempobj});
            }).catch(function (error) { console.log(error); });
        }
       
        break;       
    }

    this.setState({showModels:tempobj});
    
}else{
    var temp=this.state.validations;
    temp.selectcatalog="Please select the report type.";
    this.setState({validations:temp});
    this.setState({data:[]});
    this.setState({showModels:tempobj});

}  
}

validate = (val) => {
    let tempObj = this.state.validations;
    switch (val) {
    case "selectcatalog": tempObj.selectcatalog = Validators.select(this.state.formObj.selectcatalog); break;
    case "startdate": tempObj.startdate = Validators.date1(this.state.formObj.startdate); break;
    case "enddate": tempObj.enddate = Validators.date1(this.state.formObj.enddate); break;
    
    }
    this.setState({ validations: tempObj });

}
selectAll=(e)=>{
    if(e.target.checked == true){
    this.setState({memberchecked:true,});
    }
    else{
    this.setState({memberchecked:false});
    }
    
}
selectCatalog=(id)=>{
    this.setState({select:false});

}
selectAdvaced=(e)=>{
    if(e.target.checked == true){
        this.setState({showAdvanced:true});
    }
    else if(e.target.checked == false){
        var temp=this.state.validations;
        temp.startdate="";
        temp.enddate="";
        var temps=this.state.formObj;
        temps.startdate="";
        temps.enddate="";
        this.setState({validations:temp,formObj:temps});
        this.setState({showAdvanced:false});

    }
}
format = (val, e) => {
    let tempObj = this.state.formObj;
    switch (val) {
    case "selectcatalog": tempObj.selectcatalog = Formators.name(e.target.value); break;
    case "startdate": tempObj.startdate = Formators.name(e.target.value); break;
    case "enddate": tempObj.enddate = Formators.name(e.target.value); break;
    }
    this.setState({ formObj: tempObj });
}
render() {
    return (
    <div className="container-fluid">
        <div className="shadow-sm p-3 mb-5 bg-white rounded">
        <div><div className="d-flex row justify-content-center"><h3>Reports</h3></div></div><br/>
            <div className="d-flex row ">
                
                <div className="p-2 col-md-4">
                    <label htmlFor="idselectcatalog" className="label-custom" >Report Type<span className="star">*</span></label>
                    <select value={this.state.formObj.selectcatalog} id="idselectcatalog" onClick={()=>{this.selectCatalog(this.state.formObj.selectcatalog)}}onChange={(e) => { this.format("selectcatalog", e) }} onBlur={() => { this.validate("selectcatalog") }} style={this.state.validations.selectcatalog === "" ? {} : { "borderColor": "red" }} className="form-control form-control-sm">
                        <option value=""></option>
                        <option value="Statistics On Books">Statistics On Books</option>
                        <option value="Statistics On CD/DVD">Statistics On CD/DVD</option>
                        <option value="Statistics On Journals">Statistics On Journals</option>
                        <option value="Statistics On Members">Statistics On Members</option>
                        <option value="Check-out Statistics">Issue Book Statistics</option>
                        <option value="Check-In Statistics">Recive Book Statistics</option>
                        <option value="Fast Moving Titles">Fast Moving Titles</option>
                        <option value="Slow Moving Titles">Slow Moving Titles</option>
                        <option value="Today Check-Out List">Today Issue Book  List</option>
                        <option value="Today Check-in List">Today Recive Book  List</option>
                        <option value="Book Due Staistics">Book Dues Statistics</option>
                       {/*  <option value="Today Check-Out History (Member)">Today Recive Book  History (Member)</option> */}
                        <option value="Cash Report">Cash Report</option>
                    </select>
                    <span className="errormsg">{this.state.validations.selectcatalog}</span>
                </div>
                <div className="p-2">
                    <label htmlFor="idselectcatalog" className="label-custom" >Advanced check</label><br />
                    <input type="checkbox" className="custom-checkboxes form-control form-control-sm"  onClick={(e)=>{this.selectAdvaced(e)}} id="myCheck" defaultChecked={this.state.select1} />
                </div>
        
                {this.state.showAdvanced?
                
                <div className="p-2 ">
                    <label htmlFor="idpodate" className="label-custom" >Start Date</label> 
                    <input value={this.state.formObj.startdate} id="idstartdate"  onChange={(e)=>{this.format("startdate",e)}}   onBlur={()=>{ this.validate("startdate")}} className="form-control form-control-sm" type="date"  style={this.state.validations.startdate===""?{}:{"borderColor":"red"}} required /> 
                    <span className="errormsg">{this.state.validations.startdate}</span>   
                    </div>:null}
                    {this.state.showAdvanced?
                
                <div className="p-2">
                    <label htmlFor="idenddate" className="label-custom" >End Date</label> 
                    <input value={this.state.formObj.enddate} id="idenddate"  onChange={(e)=>{this.format("enddate",e)}}   onBlur={()=>{ this.validate("enddate")}} className="form-control form-control-sm" type="date"  style={this.state.validations.enddate===""?{}:{"borderColor":"red"}} required /> 
                    <span className="errormsg">{this.state.validations.enddate}</span>
                </div> :null}
                {this.state.showAdvanced?
                <div className="p-2">
                    <label htmlFor="idselectcatalog" className="label-custom" >Show All</label>
                    <input type="checkbox" className="custom-checkboxes form-control form-control-sm"  onClick={(e)=>{this.selectAll(e)}} id="myCheck" defaultChecked={this.state.select}  value={this.state.formObj.check} />
                </div>:null}
                <div className="p-2 ">
                    <button type="button" className="btn btn-success btn-sm" style={{ marginTop: 32 }} onClick={() => { this.getReport(this.state.formObj.selectcatalog) }} >Get Report</button>
                </div>
                </div>
            
        </div>
        {this.state.showModels.booktable ?
        <BookTabulator parentObject={this.state.data} header={this.props.user.header} />: null}
        {this.state.showModels.cdlisttable?
        <CdTabulator parentObject={this.state.data} header={this.props.user.header} />:null}
        {this.state.showModels.journaltable ?
        <JournalTabulator parentObject={this.state.data} header={this.props.user.header} />: null}
        {this.state.showModels.membertable ?
        <MemberTabulator parentObject={this.state.data} header={this.props.user.header}  />: null}
        {this.state.showModels.checkouttable ?
        <IssuebookTabulator  parentObject={this.state.data} header={this.props.user.header}/>: null}
        {this.state.showModels.checkintable?
        <ReceiveTabulator parentObject={this.state.data} header={this.props.user.header} />: null}
        {this.state.showModels.inactiveMemtable?
        < InactiveTabulator parentObject={this.state.data} header={this.props.user.header} />:null}
        {this.state.showModels.todaycheckintable?
        <IssuebookTabulator  parentObject={this.state.data} header={this.props.user.header}/>: null}
        {this.state.showModels.fastMovingtable?
        <FastmovingTabulator parentObject={this.state.data} header={this.props.user.header} />:null}
        {this.state.showModels.slowMovingtable?
        <SlowmovingTabulator  parentObject={this.state.data} header={this.props.user.header}/>:null}
        {this.state.showModels.historyTitletable?
        <CheckoutTabulator parentObject={this.state.data} header={this.props.user.header} />:null}
        {this.state.showModels.historyMembertable?
        <CheckoutmemberTabulator  parentObject={this.state.data} header={this.props.user.header}/>:null}
        {this.state.showModels.overduetable?
        <OverdueTabulator parentObject={this.state.data} header={this.props.user.header} />:null}
        {this.state.showModels.duetable?
        <CheckoutTabulator parentObject={this.state.data} header={this.props.user.header} />:null}
        
    </div>
)
}
}
const mapToProps = ({ loggedUserState }) => ({ user: loggedUserState })
export default connect(mapToProps)(ReportForm);

