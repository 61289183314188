export const Auditreportlog = {
    auditnumber : '',
    auditbarcodes : [],
    catalogtype : '',
    auditorname:'',
    designation:'',
    phone:'',
    librarianname:'',
    barcode : '',
    status : '',
    auditdate : '',
    enddate:'',
	id : '',
    totalcatalogcount:'',
    totaldamage:'',
    totalissued:'',
    totalscanned:'',
    totalmatched:'',
    _id:'',
	createdby : '', 
	updatedby : '', 
	date_created : '', 
	date_updated : '', 
}