import React from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { FaPen, FaTimes,FaCheck } from 'react-icons/fa';
import { Button, Modal, ModalBody, ModalFooter } from 'react-bootstrap';
import { base_url } from '../constants/Constants';
import { Validators } from '../utils/Validators';
import { Formators } from '../utils/Formators';
import '../styles/Styles.css';
import { Users } from '../models/Users';


class UserDetailsForm extends React.Component {
constructor(props){
	super(props);
	this.state = {
		formObj : Users,
		validations : {},
		objList : [],
		showpass : true,
		show:false,
		index:-1,
		deleteuser:false,
		usermessage:'',
	}
}
componentDidMount(){
	let tempObj = {};
	for (let key in Users) { tempObj[key] = ""; }
	this.setState({validations:tempObj});
	this.getObjList();
}

getObjList = () => {
	axios.get(base_url+'users/list', this.props.user.header ).then(res => {
		if ( res.data.length > 0 ){
			this.setState({ objList:res.data});
			this.renderObjlist();
		}else{
			this.setState({ objList:[]});
			this.renderObjlist();	
		}
	}).catch(function (error) { console.log(error); });
}

addObj = () => { 
	this.setState({showpass:true});
	let tempObj = {};
	let tempObjs = {};
	for (let key in Users ) { tempObj[key] = ""; }
	this.setState({ formObj:tempObjs});
	this.setState({ validations:tempObj, showModal:!this.state.showModal});
}
editObj = (i) => { 
	this.setState({showpass:false});
	let tempObj = {};
	for (let key in this.state.objList[i] ) { tempObj[key] = this.state.objList[i][key]; }
	this.setState({ formObj:tempObj, showModal:true});
}
updateSuccess = () =>{
	this.setState({saveuser:!this.state.saveuser}); 
}
saveObj = () =>{
	let temp='';
	for (let key in Users) { this.validate(key);}
	if(this.state.showpass === false){
			let valtempobj=this.state.validations;
			valtempobj.password="";
			valtempobj.confirmpwd="";
			valtempobj.email="";
			this.setState({validations: valtempobj});
			for (let key in this.state.validations) {temp += this.state.validations[key];}
					if(temp == ""){
				axios.post(base_url+'users/save', this.state.formObj, this.props.user.header ).then(res => {			
					this.getObjList();
					this.setState({showModal:false});	
				}).catch(function (error) { console.log(error); });
				}
		}else{
			for (let key in this.state.validations) {temp += this.state.validations[key];}
			if(temp == ""){
				axios.post(base_url+'main/signup',this.state.formObj)
					.then(res => { 		
					this.getObjList();
					this.setState({showModal:false});
					this.updateSuccess();
			}).catch(function (error) { console.log(error); });
		}

		}
}
showDeleteconfirm =(i)=>{
	this.setState({show:true,index:i});
}
deleteObj = (i) => { 
	let tempObj = this.state.objList[i];
	tempObj.status = 'Disabled';
	axios.post(base_url+'users/save', tempObj , this.props.user.header ).then(res =>{
		this.getObjList();
		this.setState({show:false,deleteuser:true,usermessage:'User Disabled Successfully'});
		if ( res.data.length > 0 ){
			this.setState({ formObj:res.data});
			this.renderObjlist();
		}
	}).catch(function (error) { console.log(error); });
}
enableUser = (i) => { 
	let tempObj = this.state.objList[i];
	tempObj.status = 'Enabled';
	axios.post(base_url+'users/save', tempObj , this.props.user.header ).then(res =>{
		this.getObjList();
		this.setState({show:false,deleteuser:true,usermessage:'User Enabled Successfully'});
		if ( res.data.length > 0 ){
			this.setState({ formObj:res.data});
			this.renderObjlist();
		}
	}).catch(function (error) { console.log(error); });
}

verifyEmailID = (email) =>{
	if(email !== ""){
		var emailObj = {email:this.state.formObj.email};
		var tempObj=this.state.validations;
		axios.post(base_url+'main/emailverify',  emailObj)        
		.then(res => {
			if(res.data.msg === 'success'){   
				tempObj.email='The emailid you have entered is already associated with another account'; 
				this.setState({validations:tempObj});
			}               
		}).catch(function (error) { 
		console.log(error);        
		}); 
	}      
	}

matchPassword = (confirmpwd) =>{
	if(confirmpwd=== ""){
		if(this.state.formObj.password === ''){
			confirmpwd="Please fill the password field first.";			
		}else{
			if(this.state.formObj.password !== this.state.formObj.confirmpwd){
				confirmpwd="Password and confirm password is not matching";				
			}
		}
	} 
	return confirmpwd;
}


validate = (val) =>{
	let tempval = this.state.validations;
	switch(val){
		case "firstname":  tempval.firstname =Validators.name(this.state.formObj.firstname);  break;
		case "lastname":  tempval.lastname = Validators.name1(this.state.formObj.lastname);  break;
		case "institutionname": tempval.institutionname = Validators.name(this.state.formObj.institutionname ); break;
		case "phone":  tempval.phone = Validators.phone(this.state.formObj.phone);  break; 
		case "email": tempval.email = Validators.email(this.state.formObj.email); this.verifyEmailID(this.state.formObj.email); break; 
		case "password":  tempval.password = Validators.password(this.state.formObj.password);	break; 
		case "confirmpwd": tempval.confirmpwd = Validators.password(this.state.formObj.confirmpwd); tempval.confirmpwd = this.matchPassword(tempval.confirmpwd); break;
		case "address1":  tempval.address1 = Validators.name(this.state.formObj.address1);  break;  
		case "city":  tempval.city = Validators.name(this.state.formObj.city);  break; 
		case "state":  tempval.state = Validators.state(this.state.formObj.state);  break; 
		case "zip": tempval.zip = Validators.zipcode(this.state.formObj.zip); break;
		//default : tempval[val] =Validators.name(this.state.formObj[val]);
		
	}
	this.setState({validations:tempval});
}

format = (val, e) => { 
	let tempObj = this.state.formObj;
	switch(val){
		case "firstname": tempObj.firstname = Formators.firstname(e.target.value); break;
		case "lastname": tempObj.lastname = Formators.firstname(e.target.value); break;
		case "phone": tempObj.phone = Formators.name(e.target.value); break;
		case "email": tempObj.email = Formators.name(e.target.value); break;
		case "password": tempObj.password = Formators.name(e.target.value); break;
		case "confirmpwd": tempObj.confirmpwd = Formators.name(e.target.value); break;
		case "institutionname": tempObj.institutionname = Formators.firstname(e.target.value); break;
		case "address1": tempObj.address1 = Formators.name(e.target.value); break;
		case "address2": tempObj.address2 = Formators.name(e.target.value); break;
		case "city": tempObj.city = Formators.name(e.target.value); break;
		case "state": tempObj.state = Formators.firstname(e.target.value); break;
		case "zip": tempObj.zip = Formators.name(e.target.value); break;
	}
	this.setState({formObj:tempObj});
} 


renderObjlist = () => {
if( this.state.objList != undefined &&  this.state.objList != "" && this.state.objList.length > 0){
	return this.state.objList.map((item, index) =>{
		let dt1=new Date(item.date_created);
		var day = ("0" + dt1.getDate()).slice(-2);
		var month = ("0" + (dt1.getMonth() + 1)).slice(-2);
		var year = dt1.getFullYear();
		var h = dt1.getHours();
		var m =("0" + dt1.getMinutes()).slice(-2);
		var _time2 = (h > 12) ? (h-12 + ':' + m +' PM') : (h + ':' + m +' AM');
		let time= day+'-'+month+'-'+year;
		var type=true;
		var edittype=false;
		if(item.type == "admin"){
            type=false;
		}
		if(item.status == "Disabled" ){
            edittype=true;
			type=false;
		}
		return (
			<tr key={index} id={index}>
				<td>{item.firstname}</td>
				<td>{item.lastname}</td>
				<td>{item.phone}</td>
				<td>{item.email}</td>
				<td>{item.institutionname}</td>
				<td>{time}</td>
				<td>{item.status}<span><FaPen size="15px" color="blue" onClick={()=>{this.editObj(index)}}/>&nbsp;&nbsp;
				{type?<FaTimes size="15px" color="red" onClick={() => {this.showDeleteconfirm(index)}} />:null}&nbsp;&nbsp;
				{edittype?<FaCheck size="15px" color="green" onClick={() => {this.enableUser(index)}} />:null}&nbsp;&nbsp;</span></td>
			</tr>
			)
		}, this);
	}
}



render() {
	return (
	<div className="shadow-sm p-3 mb-5 bg-white rounded">
	<table className="table table-bordered table-sm">
		<thead className="thead">
			<tr>
				<td>Firstname</td>
				<td>Lastname</td>
				<td>Phone</td>
				<td>Email</td>
				<td>Institution Name</td>
				<td>Created Date</td>
				<td>Status</td>
			</tr>
		</thead>
		<tbody>{ this.renderObjlist() }</tbody> 
	</table>
	<div className="d-flex row justify-content-end">
		<div className="p-2">
			<button type="button" className="btn btn-primary btn-sm" onClick={()=>{this.addObj()}} >Add New User</button>
		</div>
	</div>
		<Modal size="lg" show={this.state.showModal} onHide={()=>{this.setState({showModal:false})}} >
		<Modal.Header closeButton>
		<Modal.Title>USER DETAILS</Modal.Title>
		</Modal.Header>
		<ModalBody>
		<div className="d-flex row">
		<div className="p-2">
		<label htmlFor="idfirstname" className="label-custom">First Name</label> 
		<input value={this.state.formObj.firstname} id="idfirstname"  onChange={(e)=>{this.format("firstname",e)}}   onBlur={()=>{ this.validate("firstname")}} className="form-control form-control-sm" style={this.state.validations.firstname===""?{}:{"borderColor":"red"}} type="text" className='form-control form-control-sm' required /> 
		<span className="errormsg">{this.state.validations.firstname}</span>
		</div>
		<div className="p-2">
		<label htmlFor="idlastname" className="label-custom">Last Name</label> 
		<input value={this.state.formObj.lastname} id="idlastname"  onChange={(e)=>{this.format("lastname",e)}}  onBlur={()=>{ this.validate("lastname")}} className="form-control form-control-sm" style={this.state.validations.lastname===""?{}:{"borderColor":"red"}} type="text" className='form-control form-control-sm' required /> 
		<span className="errormsg">{this.state.validations.lastname}</span>
		</div>
		<div className="p-2 flex-fill">
		<label htmlFor="idphone" className="label-custom">Phone</label> 
		<input value={this.state.formObj.phone} id="idphone"  onChange={(e)=>{this.format("phone",e)}}   onBlur={()=>{ this.validate("phone")}}  className="form-control form-control-sm" style={this.state.validations.phone===""?{}:{"borderColor":"red"}} type="text" className='form-control form-control-sm' required /> 
		<span className="errormsg">{this.state.validations.phone}</span>
		</div>
		</div>
		<div className="d-flex row">
		<div className="p-2 flex-fill">  
		<label htmlFor="idinsname" className="label-custom">Institution Name</label>                      
		<input type="text"  id="idinsname" name="institutionname" value={this.state.formObj.institutionname}onChange={(e)=>{this.format("institutionname",e)}} onBlur={()=>{ this.validate("institutionname")}} className="form-control form-control-sm " style={this.state.validations.institutionname===""?{}:{"borderColor":"red"}} required   />
		<span className="errormsg">{this.state.validations.institutionname}</span>    
		</div>
		{this.state.showpass?
		<div className="p-2 flex-fill">
		<label htmlFor="idemail" className="label-custom">Email</label> 
		<input value={this.state.formObj.email} id="idemail"  onChange={(e)=>{this.format("email",e)}}   onBlur={()=>{ this.validate("email")}} className="form-control form-control-sm" style={this.state.validations.email===""?{}:{"borderColor":"red"}} type="text" className='form-control form-control-sm' required /> 
		<span className="errormsg">{this.state.validations.email}</span>
		</div>
		:null}
		</div>
		<div className="d-flex row">
		<div className="p-2 flex-fill">
		<label htmlFor="idaddress1" className="label-custom" >Address1</label> 
		<input value={this.state.formObj.address1} id="idaddress1"  onChange={(e)=>{this.format("address1",e)}}  onBlur={()=>{ this.validate("address1")}} className="form-control form-control-sm" style={this.state.validations.address1===""?{}:{"borderColor":"red"}} type="text" className='form-control form-control-sm' required /> 
		<span className="errormsg">{this.state.validations.address1}</span>
		</div>
		<div className="p-2 flex-fill">
		<label htmlFor="idaddress2" className="label-custom" >Address2</label> 
		<input value={this.state.formObj.address2} id="idaddress2"  onChange={(e)=>{this.format("address2",e)}}   onBlur={()=>{ this.validate("address2")}} className="form-control form-control-sm" style={this.state.validations.address2===""?{}:{"borderColor":"red"}} type="text" className='form-control form-control-sm' required /> 
		<span className="errormsg">{this.state.validations.address2}</span>
		</div>
		</div>
		<div className="d-flex row">
		<div className="p-2 flex-fill">
		<label htmlFor="idcity" className="label-custom" >City</label> 
		<input value={this.state.formObj.city} id="idcity"  onChange={(e)=>{this.format("city",e)}}   onBlur={()=>{ this.validate("city")}} className="form-control form-control-sm" style={this.state.validations.city===""?{}:{"borderColor":"red"}} type="text" className='form-control form-control-sm' required /> 
		<span className="errormsg">{this.state.validations.city}</span>
		</div>
		<div className="p-2 flex-fill">
		<label htmlFor="idstate" className="label-custom" >State</label> 
		<input value={this.state.formObj.state} id="idstate"  onChange={(e)=>{this.format("state",e)}}  onBlur={()=>{ this.validate("state")}} className="form-control form-control-sm" style={this.state.validations.state===""?{}:{"borderColor":"red"}} type="text" className='form-control form-control-sm' required /> 
		<span className="errormsg">{this.state.validations.state}</span>
		</div>
		<div className="p-2 flex-fill">
		<label htmlFor="idzip" className="label-custom" >Zipcode</label> 
		<input value={this.state.formObj.zip} id="idzip"  onChange={(e)=>{this.format("zip",e)}}  onBlur={()=>{ this.validate("zip")}} className="form-control form-control-sm" style={this.state.validations.zip===""?{}:{"borderColor":"red"}} type="text" className='form-control form-control-sm' required /> 
		<span className="errormsg">{this.state.validations.zip}</span>
		</div>
		</div>
		{this.state.showpass?<div className="d-flex row">
		<div className="p-2 flex-fill">
		<label htmlFor="idpassword" className="label-custom">Password</label> 
		<input value={this.state.formObj.password} id="idpassword"  onChange={(e)=>{this.format("password",e)}} onBlur={()=>{ this.validate("password")}} className="form-control form-control-sm" style={this.state.validations.password===""?{}:{"borderColor":"red"}} type="password" className='form-control form-control-sm' required /> 
		<span className="errormsg">{this.state.validations.password}</span>
		</div>
		<div className="p-2 flex-fill"> 
		<label htmlFor="confirmpwd" className="label-custom">Confirm Password</label>                      
		<input type="password"  id="confirmpwd" name="confirmpwd" value={this.state.formObj.confirmpwd} onChange={(e)=>{this.format("confirmpwd",e)}} onBlur={()=>{ this.validate("confirmpwd")}} className="form-control form-control-sm" style={this.state.validations.confirmpwd===""?{}:{"borderColor":"red"}} required   />
		<span className="errormsg">{this.state.validations.confirmpwd}</span>
		</div>
		</div>:null}
		</ModalBody>
		<ModalFooter>
		<Button variant="warning" onClick={()=>{this.addObj()}}>CLOSE</Button>
		<Button variant="primary" onClick={()=>{this.saveObj()}}>SAVE</Button>
		</ModalFooter>
		</Modal>
		<Modal show={this.state.saveuser} onHide={this.updateSuccess}  size="lg"
		aria-labelledby="contained-modal-title-vcenter"
		centered>
		<Modal.Body>
		<br/><br/>
		<div className="d-flex justify-content-center row">
		<h5>  <strong className="textblue">Thank You !!! {this.state.formObj.firstname}</strong> </h5> 
		<br/><br/> 
		</div>
		<div className="d-flex justify-content-center row">
		<h6>Registration Successfull and Follow your email for Activation.</h6>
		</div> <br/> <br/>
		<div className="d-flex justify-content-center row">
		<Button variant="primary" onClick={this.updateSuccess}>OK</Button>
		</div>
		</Modal.Body>
		</Modal>
		<Modal size="md"   centered show={this.state.show} onHide={()=>{this.setState({show:false})}}>
		<ModalBody>
		<div className="d-flex justify-content-right">
			<div className="p-2">Are you Sure Want to Disabled this User??</div>
		</div>
	
		<div className="d-flex justify-content-end">
		<div className="p-2"><button className="btn btn-warning  btn-sm" onClick={()=>{this.deleteObj(this.state.index)}}>YES</button></div>
		<div className="p-2"><button className="btn btn-primary  btn-sm" onClick={()=>{this.setState({show:false})}}>NO</button></div>
			</div>
		</ModalBody>
		</Modal>
		<Modal show={this.state.deleteuser} onHide={()=>{this.setState({deleteuser:false})}}  size="md"
		aria-labelledby="contained-modal-title-vcenter"
		centered>
		<Modal.Body>
		<br/><br/>
		<div className="d-flex justify-content-center row">
		<h5>  <strong className="textblue">Thank You !!! {this.state.formObj.firstname}</strong> </h5> 
		<br/>
		</div>
		<div className="d-flex justify-content-center row">
		<h6>{this.state.usermessage}</h6>
		</div> <br/>
		<div className="d-flex justify-content-center row">
		<Button variant="primary" onClick={()=>{this.setState({deleteuser:false})}}>OK</Button>
		</div>
		</Modal.Body>
		</Modal>
	</div>
	
)
}

}
const mapToProps = ({ loggedUserState }) => ({ user: loggedUserState })
export default connect(mapToProps)(UserDetailsForm);

