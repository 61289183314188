	////////////////////////////////////////////////////////////////////////////////////////////////////
	// Warning : Property of Deemsoft Global Solutions LLC, USA. It is illegal to make copy of this code
	// Author : Gangaswamy Kempahonnaiah
	////////////////////////////////////////////////////////////////////////////////////////////////////
	import React from 'react';
	import axios from 'axios';
	import { connect } from 'react-redux';
	import { base_url } from '../constants/Constants';
	import { Validators } from '../utils/Validators';
	import { Formators } from '../utils/Formators';
	import { Checkin } from '../models/Checkin';
	import {Setting} from '../models/Setting';
	import {Bills_Catalog } from '../models/Bill_Catalog'
	import '../styles/Styles.css';
	import flashMessage from '../utils/Flash';
    import FlashMessage from 'react-flash-message';
	import { Books_Catalog } from '../models/Books_Catalog';

	class CheckinForm extends React.Component {
	constructor(props){
	super(props);
	this.state = {
		objList : [],
		objList4:[],
		settings : Setting,
		showModal : false,
		registrationlist:[],
		searchform : true,
		mainform:false,
		books_details : Books_Catalog,
		bill_details:Bills_Catalog ,
		billlist:{},
		validations : {},
		checkoutid:'',
		tempObj:[],
		billObj:{billtype:'',fine:'',totaldays:'',subtotal:''},
		formObj : Checkin,
		formObj1:{},
		btndisable:false,
		dueObj:{},
		showSuccessMsg:false,
	}

	}

	componentDidMount(){
	let tempObj = {};
	for (let key in Checkin) { tempObj[key] = ""; }
	this.setState({validations:tempObj});
	let tempObjs = {};
	for (let key in Checkin) { tempObjs[key] = ""; }
	this.setState({formObj:tempObjs});
	this.getSettings();

	}
	getSettings = () => {
	axios.get(base_url+'settings/list', this.props.user.header ).then(res => {
		if ( res.data.length > 0 ){
			for(var i=0;i<res.data.length;i++){
				this.setState({ settings:res.data[i]});	
			}	
			this.setState({registrationlist:this.state.settings.registrationTypes});
		}
	}).catch(function (error) { console.log(error); });
	}

	getObjList = (id) => {
	axios.get(base_url+'checkin/listbycardid/'+id, this.props.user.header ).then(res => {
			this.setState({ objList:res.data});
	}).catch(function (error) { console.log(error); });
	}
	saveObj = () => {
	let tems="";
	for (let key in Checkin) { this.validate(key);}
	for (let key in this.state.validations) {tems += this.state.validations[key];}
	if(tems == ''){
	let tempObj=this.state.books_details;
	if(this.state.formObj.bookstatus == "Book lost"){
	tempObj.bookstatus="Not Available";
	}
	else if(this.state.formObj.bookstatus == "Book broken"){
		tempObj.bookstatus="Damaged";
	}
	else{
		tempObj.bookstatus="Available"
	}
	axios.post(base_url+'books_catalog/save', tempObj , this.props.user.header ).then(res =>{
		this.saveBillObj();
		var now = new Date();
		var month = (now.getMonth() + 1);               
		var day = now.getDate();
		if (month < 10) 
			month = "0" + month;
		if (day < 10) 
			day = "0" + day;
		var today =  month + '-' + day + '-' +now.getFullYear();
		var temp=this.state.formObj;
		temp.receiveddate=today;
		temp.status="Active";
		axios.post(base_url+'checkin/save', temp, this.props.user.header ).then(res => {
			this.setState({ formObj:res.data.data,btndisable:true});
			this.getObjList(this.state.formObj.membershipid);
			temp.status="Inactive";
			temp._id=this.state.checkoutid;
			console.log(temp);
			this.setState({showSuccessMsg:true});
			axios.post(base_url+'checkout/save', temp , this.props.user.header ).then(res =>{
				
			}).catch(function (error) { console.log(error); });
			
		}).catch(function (error) { console.log(error); }); 
	}).catch(function (error) { console.log(error); }); 
	}
	this.setState({showSuccessMsg:false});
	}
	saveBillObj=()=>{
	var sum=0;
	var now = new Date();
	var month = (now.getMonth() + 1);               
	var day = now.getDate();
	if (month < 10) 
		month = "0" + month;
	if (day < 10) 
		day = "0" + day;
	var today =  month + '-' + day + '-' +now.getFullYear();
		for(var i=0;i<this.state.tempObj.length;i++){
	sum=Number(sum) + Number(this.state.tempObj[i].subtotal);
		}
		if(sum > 0){
			
		var billobj=this.state.formObj;
		billobj.total=Number(sum).toFixed(2);
		billobj.discount="0.00";
		billobj.paid='0.00';
		billobj.change= "-"  + "0.00";
		billobj.balance=Number(sum).toFixed(2);
		billobj.nettotal=Number(sum).toFixed(2);
		billobj.billTypes=this.state.tempObj;
		billobj.billstatus="pending";
		billobj.date=today;
		billobj.membershipid=this.state.formObj.membershipid;
		billobj.cashbookno="";
		axios.post(base_url+'bills/save', billobj, this.props.user.header ).then(res => {
		}).catch(function (error) { console.log(error); });
		
		}
	}


	addObj = () => { 
	let tempObj = {};
	for (let key in Checkin ) { tempObj[key] = ""; }
	this.setState({ formObj:tempObj, showModal:true});
	}

	editObj = (i) => { 
	let tempObj = {};
	for (let key in this.state.objList[i] ) { tempObj[key] = this.state.objList[i][key]; }
	this.setState({ formObj:tempObj, showModal:true});
	}

	deleteObj = (i) => { 
	let tempObj = this.state.objList[i];
	tempObj.status = 'Inactive';
	axios.post(base_url+'checkin/save', tempObj , this.props.user.header ).then(res =>{
		if ( res.data.length > 0 ){
			this.setState({ formObj:res.data});
			this.getObjList();
		}
	}).catch(function (error) { console.log(error); });
	}
	clearform = () => {
	let tempObj = {};
	for (let key in Checkin ) { tempObj[key] = ""; }
	this.setState({ formObj:tempObj});
	}


	getDateFormat = (dt) =>{
	var now = new Date(dt);
	var month = (now.getMonth() + 1);               
	var day = now.getDate();
	if (month < 10) 
		month = "0" + month;
	if (day < 10) 
		day = "0" + day;

	if(this.state.settings.dateformats == "MM/dd/yyyy"){
		var today = month + '-' + day + '-' +now.getFullYear();
		return today;
	}else if(this.state.settings.dateformats == "yyyy/MM/dd"){
		var today =  now.getFullYear()+'-'+month + '-' + day;
		return today;
	}else if(this.state.settings.dateformats == "dd/MM/yyyy"){
		var today =  day + '-' + month + '-' +now.getFullYear();
		return today;
	}else{
		var today =  day + '-' + month + '-' +now.getFullYear();
		return today;
	}
	
	}



	GetOverduedays=(temp)=>{
		//console.log(temp);
	var fine='';
	for(var i=0;i<this.state.registrationlist.length;i++){
		
		if(this.state.registrationlist[i].registrationtype == temp.registrationtype){
				fine = this.state.registrationlist[i].latefee;
		}
	}
	var duedate=temp.duedate;
	var arr=this.state.billObj;
	var date1=new Date(duedate);
	var date2=new Date();
	var gettime=date2.getTime() - date1.getTime();
	var totaldays =parseInt((gettime / (1000 * 60 * 60 * 24))); 
	if(totaldays > 0){
		arr.fine = fine;
		arr.membershipid=temp.membershipid;
		arr.totaldays=totaldays;
		arr.billtype="Over Due Fee";
		arr.subtotal=Number(arr.fine) * Number(arr.totaldays);
		this.setState({dueObj:arr});
		this.state.tempObj.push(arr);
	}
	var tempdata={};
	this.setState({billObj:tempdata});	
	}

	Getbooklostfee = (e) =>{
		console.log(e.target.value);
	var tempObj=this.state.formObj;
	console.log(tempObj);
	var arr=this.state.billObj;
	var findbill=this.state.tempObj;

	if(tempObj.bookstatus == "Book broken"){
		arr.fine=tempObj.indianprice;
		arr.totaldays=0;
		arr.membershipid=tempObj.membershipid;
		arr.subtotal=Number(arr.fine);
		arr.billtype="Book Broken Fee";
			this.setState({tempObj:[]}, ()=>{
			
				if(Object.keys(this.state.dueObj).length > 0 || Object.keys(this.state.dueObj).length < 0){
			this.state.tempObj.push(this.state.dueObj);
				}
			this.state.tempObj.push(arr);
			var tempdata={};
			this.setState({billObj:tempdata});
			var tempdata=this.state.formObj;

			var sum=0;
			for(var i=0;i<this.state.tempObj.length;i++){
				sum=Number(sum) + Number(this.state.tempObj[i].subtotal);
			}
			tempdata.totalfine=Number(sum).toFixed(2);
			this.setState({formObj:tempdata});
			});
		
		
	}
	else if(tempObj.bookstatus == "Book lost" ){
		arr.fine=Number(tempObj.indianprice).toFixed(2);
		arr.totaldays=0;
		arr.membershipid=tempObj.membershipid;
		arr.subtotal=Number(arr.fine);
		arr.billtype="Book Lost Fee";
		this.setState({tempObj:[]},()=>{
			if(Object.keys(this.state.dueObj).length > 0 || Object.keys(this.state.dueObj).length < 0){
			this.state.tempObj.push(this.state.dueObj);
			}
			this.state.tempObj.push(arr);
			var tempdata={};
			this.setState({billObj:tempdata});
			var tempdata=this.state.formObj;

			var sum=0;
			for(var i=0;i<this.state.tempObj.length;i++){
				sum=Number(sum) + Number(this.state.tempObj[i].subtotal);
			}
			tempdata.totalfine=Number(sum).toFixed(2);
			this.setState({formObj:tempdata});
		});
		
	}else if(tempObj.bookstatus == ""){	
		var temp=this.state.tempObj;

		var tempdata=this.state.formObj;
	    if(this.state.tempObj.length == 1){
			tempdata.totalfine=Number(0).toFixed(2);
			temp.pop();
		}else if(this.state.tempObj.length > 1){
			tempdata.totalfine=Number(this.state.tempObj[0].subtotal).toFixed(2);
			temp.pop();
		}else{
			tempdata.totalfine=Number(0).toFixed(2);
		}   
		this.setState({formObj:tempdata});
		this.setState({tempObj:temp});
	}

	}
	searchIssueBook = (id) => {
		if(id !== "" && id !== undefined){	
			axios.get(`${base_url}books_catalog/id/${id}`,  this.props.user.header )
			.then(res => {
				console.log(res.data[0])
				this.setState({books_details: res.data[0]});
			}).catch(err => console.log(err)) 

			axios.get(`${base_url}checkout/id/${id}`,  this.props.user.header )
			.then(res =>{
				console.log(res.data);
				if(res.data.length){
					var temps=res.data[0];
					var id_ofcheckout=temps._id;
					this.setState({checkoutid:id_ofcheckout});
					var temp=res.data[0];
					this.GetOverduedays(temp);
					temp.firstname=res.data[0].firstname;
					temp.lastname=res.data[0].lastname;
					temp.cardid=res.data[0].cardid;
					temp.cellphone=res.data[0].cellphone;
					temp.bookprice=res.data[0].indianprice;
					temp.membershipid=res.data[0].membershipid;
					temp.trackcode=res.data[0].trackcode;
					temp.receiveddate=new Date();
					if(this.state.tempObj.length > 0){
						temp.price=Number(this.state.tempObj[0].subtotal).toFixed(2);
						temp.totalfine=Number(this.state.tempObj[0].subtotal).toFixed(2);
					}else{
						temp.price=Number(0.00).toFixed(2);
						temp.totalfine=Number(0.00).toFixed(2);	
					}
					temp._id="";
					this.getObjList(temp.membershipid); 
					this.setState({formObj:temp,mainform:true,searchform:false,btndisable:false});
				
				}else{
					let tempObj = this.state.validations;
					let tempObjs = {};
					tempObj.accnumber="Acc Number you entered is not issued yet.";
					for (let key in Checkin ){
						tempObjs[key] = ""; 
					}
					this.setState({validations: tempObj});
				}
			}).catch(err => console.log(err))
		} 
	}
	gotoSearch = () =>{
		this.clearform();
		this.setState({mainform:false,searchform:true});
		}
		validate = (val) => {
		let tempObj = this.state.validations;
		switch(val){
			case "accnumber": tempObj.accnumber = Validators.name(this.state.formObj.accnumber); break;
			
		}
		this.setState({validations:tempObj});
	} 

	format = (val, e) => { 
		let tempObj = this.state.formObj;
		switch(val){
			case "membershipid": tempObj.membershipid = Formators.name(e.target.value); break;
			case "cardid": tempObj.cardid = Formators.name(e.target.value); break;
			case "accnumber": tempObj.accnumber = Formators.name(e.target.value); break;
			case "barcode": tempObj.barcode = Formators.name(e.target.value); break;
			case "isbnnumber": tempObj.isbnnumber = Formators.name(e.target.value); break;
			case "booktitle": tempObj.booktitle = Formators.name(e.target.value); break;
			case "receiveddate": tempObj.receiveddate = Formators.name(e.target.value); break;
			case "notes": tempObj.notes = Formators.name(e.target.value); break;
			case "firstname": tempObj.firstname = Formators.name(e.target.value); break;
			case "lastname": tempObj.lastname = Formators.name(e.target.value); break;
			case "cellphone":tempObj.cellphone= Formators.name(e.target.value);break;
			case "address" :tempObj.address=Formators.name(e.target.value);break;
			case "bookstatus" :
				tempObj.bookstatus=Formators.name(e.target.value);
				this.Getbooklostfee(e);
				break;
			case "price" :tempObj.price=Formators.name(e.target.value);break;
			case "rollno": tempObj.rollno = Formators.name(e.target.value); break;
		}
		this.setState({formObj:tempObj});
	} 

	renderObjlist = () => {
	if( this.state.objList != undefined && this.state.objList.length > 0){
		return this.state.objList.map((item, index) =>{	
		var today = this.getDateFormat(item.receiveddate);
			return (
				<tr key={index} id={index}>
					<td>{item.accnumber}</td>
					<td>{item.booktitle}</td>
					<td>{today}</td>
					</tr>
				)	
		}, this);
	}
	}

	render() {
	return (
	<div className="shadow-sm p-3 mb-5 bg-white rounded">
		<div><div className="d-flex row justify-content-center"><h3>Receive Book</h3></div></div><br/>
		{this.state.showSuccessMsg ?
                  <div className="d-flex row">
					  <div className="p-2 flex-fill">
                      <FlashMessage duration={3000} persistOnHover={true}>
                      {flashMessage("Book Returned Successfully", "Success")}
                      </FlashMessage>
					  </div>	
                  </div>
                  :null
                  }
		{this.state.searchform?
			<div className="d-flex row">
		
				<div className="p-2 flex-fill">
					<label htmlFor="idaccnumber" className="label-custom" >ACC Number/Barcode</label> 
					<input value={this.state.formObj.accnumber} id="idaccnumber"  onChange={(e)=>{this.format("accnumber",e)}} onKeyPress={(e) => e.key === 'Enter' ? this.searchIssueBook(this.state.formObj.accnumber): null} onBlur={()=>{ this.validate("accnumber")}} className="form-control form-control-sm" style={this.state.validations.accnumber===""?{}:{"borderColor":"red"}} type="text" required /> 
					<span className="errormsg">{this.state.validations.accnumber}</span>
				</div>
				<div className="p-2">
					<button type="button" style={{marginTop:36}} className="btn btn-success btn-sm"  onClick={()=>{this.searchIssueBook(this.state.formObj.accnumber)}}>Get Book Details</button>
				</div>
				
				<div className="p-2 flex-fill"></div>
			</div>
			:null}

		{this.state.mainform?
		<div>
			<div className="p-2 row">
			<table className="table table-bordered tablepointer table-sm">
				<thead className="thead">
				<tr>
					<td>ISBN NO.</td>
					<td>Book Title</td>
					<td>Track Number</td>
					<td>Accnumber</td>
				</tr>
				</thead>
				<tbody>
				<tr>
					<td>{this.state.formObj.isbnnumber}</td>						
					<td>{this.state.formObj.booktitle}</td>
					<td>{this.state.formObj.trackcode}</td>
					<td>{this.state.formObj.accnumber}</td>
				</tr>
				</tbody>
			</table>
			</div>
				
			<div className="d-flex row">
				<div className="p-2 col-md-3">
						<label htmlFor="idcardid" className="label-custom" >Card Id</label> 
						<input value={this.state.formObj.cardid} id="idcardid"  className="form-control form-control-sm" type="text"  required readOnly/> 
						<span className="errormsg">{this.state.validations.cardid}</span>
				</div>
				<div className="p-2 col-md-3 ">
						<label htmlFor="idfirstname" className="label-custom" >First Name</label> 
						<input value={this.state.formObj.firstname} id="idfirstname"  type="text"  className="form-control form-control-sm" required readOnly /> 
						<span className="errormsg">{this.state.validations.firstname}</span>
				</div>
				<div className="p-2 col-md-3 ">
						<label htmlFor="idlastname" className="label-custom" >Last Name</label> 
						<input value={this.state.formObj.lastname} id="idlastname"  type="text" className='form-control form-control-sm' required readOnly/> 
						<span className="errormsg">{this.state.validations.lastname}</span>
				</div>
				<div className="p-2 col-md-3">
						<label htmlFor="idcellphoneno" className="label-custom" >Cellphone</label> 
						<input value={this.state.formObj.cellphone} id="idcellphone" type="text" className='form-control form-control-sm' required readOnly /> 
						<span className="errormsg">{this.state.validations.cellphone}</span>
				</div>
				</div>
				<div className="d-flex row">
					<div className="p-2 col-md-3">
						<label htmlFor="idbookstatus" className="label-custom" >Return Status</label> 
						<select value={this.state.formObj.bookstatus} id="idbookstatus" onChange={(e)=>{this.format("bookstatus",e)}}   onBlur={()=>{ this.validate("bookstatus")}} className="form-control form-control-sm" disabled={this.state.btndisable} style={this.state.validations.bookstatus===""?{}:{"borderColor":"red"}} required >
						<option value="">Normal</option>
						<option value="Book broken">Book broken</option>
						<option value="Book lost">Book lost</option>
						</select>
						<span className="errormsg">{this.state.validations.bookstatus}</span>
			
					</div>
					<div className="p-2  col-md-3">
					<label htmlFor="idoverdue" className="label-custom" >OverDues</label> 
					<input type="text" value={this.state.formObj.price} readOnly id="idoverdue"  onChange={(e)=>{this.format("price",e)}}  className='form-control form-control-sm' required /> 
					</div>
					<div className="p-2  col-md-3">
					<label htmlFor="idbookprice" className="label-custom" >Book Price</label> 
					<input type="text" value={this.state.formObj.bookprice} readOnly id="idbookprice" onChange={(e)=>{this.format("bookprice",e)}} className='form-control form-control-sm' required /> 
					</div>
					<div className="p-2  col-md-3">
					<label htmlFor="idtotalfine" className="label-custom" >Total Fine</label> 
					<input type="text" value={this.state.formObj.totalfine} readOnly id="idtotalfine" onChange={(e)=>{this.format("totalfine",e)}} className='form-control form-control-sm' required /> 
				</div>
				</div>
				
				<div className="d-flex row">
				<div className="p-2 flex-fill">
					<label htmlFor="idnotes" className="label-custom" >Notes</label> 
					<textarea value={this.state.formObj.notes} id="idnotes" rows="2" cols="20"  onChange={(e)=>{this.format("notes",e)}}  onBlur={()=>{ this.validate("notes")}} className="form-control form-control-sm" style={this.state.validations.notes===""?{}:{"borderColor":"red"}} type="text" required /> 
					<span className="errormsg">{this.state.validations.notes}</span>
				</div>
			</div>

			<br/><br/>

			<div className="d-flex row justify-content-end">
				<div className="p-2">
					<button type="button" disabled={this.state.btndisable} className="btn btn-success btn-sm" onClick={()=>{this.saveObj()}}>Save</button>
				</div>
				<div className="p-2">
					<button type="button" className="btn btn-warning btn-sm" onClick={()=>{this.gotoSearch()}}>Cancel</button>
				</div>	
			</div>

			<table className="table table-bordered tablepointer table-sm">
				<thead className="thead">
					<tr>					
						<td>Accnumber</td>
						<td>Book Title</td>
						<td>Received Date</td>				
					</tr>
				</thead>
				<tbody>{ this.renderObjlist() }</tbody> 
			</table>
		</div>
		:null}
				
	</div>

	)
	}
	}

	const mapToProps = ({ loggedUserState }) => ({ user: loggedUserState })
	export default connect(mapToProps)(CheckinForm);
