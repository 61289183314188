////////////////////////////////////////////////////////////////////////////////////////////////////
// Warning : Property of Deemsoft Global Solutions LLC, USA. It is illegal to make copy of this code
// Author : Gangaswamy Kempahonnaiah
////////////////////////////////////////////////////////////////////////////////////////////////////
import React from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { Modal, ModalBody,ProgressBar} from 'react-bootstrap';
import { base_url } from '../constants/Constants';
import { Vendors } from '../models/Vendors';
import flashMessage from '../utils/Flash';
import FlashMessage from 'react-flash-message';
import '../styles/Styles.css';

class UploaCatalogSetting extends React.Component {
constructor(props){
	super(props);
	this.state = {
		objList : [],
		showModal : false,
		validations : {},
		formObj : Vendors,
		bookcatalogxlsx:'',
		cdcatalogxlsx:'',
		journalcatalogxlsx:'',
		memberdetailsgxlsx:'',
		show:false,
		showbar:false,
		btnhide:true,
		btnhide2:true,
		btnhide3:true,
		btnhide4:true,
		BarcodeList:[],
		cddvdbarcodeList:[],
		memberbarcodeList:[],
		journalbarcodeList:[],
		bookfinalList:[],
		bookrejectList:[],
		cdfinalList:[],
		cdrejectList:[],
		journalfinalList:[],
		journalrejectList:[],
		memberrejectList:[],
		memberfinalList:[],
		recordlength:-1,
		recordcount:0,
		uploadPercentage:0,
		showSuccessMsg:false,
		confirmshow:false,
		rejectlist:0,
		finallist:0,
		errormsg:true,
		type:"",
		maxbookid:"",
		maxjournalid:"",
		maxcdid:"",
		maxmemberid:"",
		showtablebook:false,
		showtablecd:false,
		showtablejournal:false,
		showtablemember:false,
		showsucess:false,
		invaliddate:false,
		barNumBook:[],
		barNumJournal:[],
		barNumCD:[],
		barNumMember:[],
		showfail:false,

		}

}

componentDidMount(){
	this.getObjList();
	axios.get(base_url+'books_catalog/getbymaxid',  this.props.user.header ).then(res => {
	console.log(res.data.data);
	this.setState({maxbookid:res.data.data});
	}).catch(function (error) { console.log(error); });

	axios.get(base_url+'cd_catalog/getbymaxid',  this.props.user.header ).then(res => {
		this.setState({maxcdid:res.data.data});
	}).catch(function (error) { console.log(error); });

	axios.get(base_url+'journals_catalog/getbymaxid',  this.props.user.header ).then(res => {
		this.setState({maxjournalid:res.data.data});
	}).catch(function (error) { console.log(error); });

	axios.get(base_url+'members/getbymaxid',  this.props.user.header ).then(res => {
		this.setState({maxmemberid:res.data.data});
	}).catch(function (error) { console.log(error); });


}

getObjList = () => {
	axios.get(base_url+'books_catalog/list',  this.props.user.header ).then(res => {
		if ( res.data.length > 0 ){
			this.setState({ objList:res.data});
			var temp=this.state.objList;
			var bookdata=[];
			for(var i =0 ;i<=temp.length-1;i++)	{
				bookdata.push(temp[i].accnumber);

			}
			this.setState({BarcodeList:bookdata})
		}		
	}).catch(function (error) { console.log(error); });

axios.get(base_url+'cd_catalog/list',  this.props.user.header ).then(res => {
	if ( res.data.length > 0 ){
		var temp=res.data;
		var cddata=[];
		for(var i=0;i<=temp.length-1;i++){
			cddata.push(temp[i].cdnumber);
		}
		this.setState({cddvdbarcodeList:cddata});
	}
	//console.log(this.state.cddvdbarcodeList);
	}).catch(function (error) { console.log(error); });

	axios.get(base_url+'journals_catalog/list',  this.props.user.header ).then(res => {
		if ( res.data.length > 0 ){
			var temp=res.data;
			var jourdata=[];
			for(var i=0;i<=temp.length-1;i++){
				jourdata.push(temp[i].barcode);
			}
			this.setState({journalbarcodeList:jourdata});
		}
		//console.log(this.state.journalbarcodeList);
	}).catch(function (error) { console.log(error); });

	axios.get(base_url+'members/list',  this.props.user.header ).then(res => {
		if ( res.data.length > 0 ){
			var temp=res.data;
			var memdata=[];
			for(var i=0;i<=temp.length-1;i++){
				memdata.push(temp[i].cardid);
			}
			this.setState({memberbarcodeList:memdata});
		}
		//console.log(this.state.memberbarcodeList);
	}).catch(function (error) { console.log(error); });
}
save=(type)=>{
	switch(type){
		case "Books":  axios.post(base_url+'books_catalog/saveall', this.state.bookfinalList, this.props.user.header ).then(res => {
			console.log(res);	
		}).catch(function (error) { console.log(error); });
		this.setState({confirmshow:false,bookcatalogxlsx:"",btnhide:true});
			break;
		case "Cddvd":  
		axios.post(base_url+'cd_catalog/saveall', this.state.cdfinalList, this.props.user.header ).then(res => {	
			console.log(res);
		}).catch(function (error) { console.log(error); });
		this.setState({confirmshow:false,cdatalogxlsx:"",btnhide2:true});
		break;
		case "Journal":
			axios.post(base_url+'journals_catalog/saveall', this.state.journalfinalList, this.props.user.header ).then(res => {
				console.log(res);
			}).catch(function (error) { console.log(error); }); 
			this.setState({confirmshow:false,journalcatalogxlsx:"",btnhide3:true});
		break;
		case "Member":
			axios.post(base_url+'members/saveall', this.state.memberfinalList, this.props.user.header ).then(res => {
				console.log(res);
			}).catch(function (error) { console.log(error); }); 
			this.setState({confirmshow:false,memberdetailsgxlsx:"",btnhide4:true});
		break;
		}
		this.setState({showsucess:true});
	
}
clearAll=()=>{
	this.setState({
		bookfinalList:[],
		bookrejectList:[],
		cdfinalList:[],
		cdrejectList:[],
		journalfinalList:[],
		journalrejectList:[],
		memberrejectList:[],
		memberfinalList:[],
		barNumBook:[],
		barNumCD:[],
		barNumJournal:[],
		barNumMember:[],
	});
}
checkDateformat = (ds) =>{
	try {
		var dt= ds;
		var checkdt=dt.replace(/\D/g,"");
		if(checkdt.length == 8){
		var inputdate = dt.split('-');
		console.log( inputdate );
		if (inputdate.length == 3) {
		var inputformat= inputdate[1]+'-'+inputdate[0]+'-'+inputdate[2];
		var date= new Date(inputformat);

		if( date == "Invalid Date"){
		   return {isvalid:false};
		}else{
           var dts=date.toISOString();
			return {isvalid:true, outdate:dts};
		}
	    }else{
			return {isvalid:false};	
		}
		}else{
			return {isvalid:false};
		  //Please provide the correct Date format with dd-mm-yyyy format
		}
		} catch (error) {
			console.log(error);
		}
}
checkBarcode = (list,type) =>{
	var temp=list;
	this.clearAll();
	this.setState({showbar:true,showSuccessMsg:false,confirmshow:false,rejectlist:0,finallist:0,errormsg:true});
	switch(type){
		case "Books": 
		var maxid=this.state.maxbookid;
		console.log(maxid);
		for(var i=0;i<=list.length-1;i++){
		    if(list[i].accnumber !== "" && list[i].booktitle !== "" && list[i].accnumber !== undefined && list[i].booktitle !== undefined  && list[i].isbnnumber !== "" && list[i].isbnnumber !== undefined &&
			list[i].bookauthor !== undefined  && list[i].bookauthor !== "" && list[i].indianprice !== "" &&  list[i].indianprice !== undefined &&  list[i].booktype !== "" &&  list[i].booktype !== undefined  && list[i].department !== "" &&  list[i].bookstatus !== "" &&  list[i].subject !== "" &&  list[i].status !== "" && list[i].trackcode !== ""
			){
				var accnum=list[i].accnumber;
			    var acc1=accnum.toString();
				if(this.state.BarcodeList.includes(acc1) || this.state.barNumBook.includes(acc1)){
					this.state.bookrejectList.push(list[i]);
				}else{
						maxid++;
						list[i].id=maxid;
						list[i].barcode = list[i].accnumber;
						list[i].date_created = new Date().toISOString();
						list[i].date_updated = new Date().toISOString(); 
						this.state.barNumBook.push(acc1);
						this.state.bookfinalList.push(list[i]);
			   }
			}else{
				this.state.bookrejectList.push(list[i]);	
			}		
		}
		this.setState({ uploadPercentage: 100 }, ()=>{
			setTimeout(() => {
			this.setState({ uploadPercentage: 0,showbar:false })
			}, 2000);

		   setTimeout(() => {
			if(this.state.bookfinalList.length > 0){
			this.setState({confirmshow:true,rejectlist:this.state.bookrejectList.length,finallist:this.state.bookfinalList.length});
			if(this.state.bookrejectList.length > 0){
				this.setState({showtablebook:true,showtablecd:false,showtablejournal:false,showtablemember:false});
			}
			}else{
				this.setState({confirmshow:true,errormsg:false});	
			}
			}, 3000);
		 
			}); 
				
		break;
		case "Cddvd":  
		var cdmaxid=this.state.maxcdid;
		for(var i=0;i<=list.length-1;i++){

			if(list[i].cdnumber !== "" && list[i].cdtitle !== "" && list[i].cdnumber !== undefined && list[i].cdtitle !== undefined  && list[i].author !== ""  && list[i].cdstatus !== ""  && list[i].status !== "" && list[i].cdentrydate !== ""){
				var accnum=list[i].cdnumber;
				var acc1=accnum.toString();
					if(this.state.cddvdbarcodeList.includes(acc1) || this.state.barNumCD.includes(acc1)){
						this.state.cdrejectList.push(list[i]);
					}else{	
						var cdentryDate=this.checkDateformat(list[i].cdentrydate);
						if(cdentryDate.isvalid){
						cdmaxid++;
			           	list[i].id=cdmaxid;
						list[i].cdentrydate=cdentryDate.outdate;
					    list[i].barcode =list[i].cdnumber;
						list[i].date_created = new Date().toISOString();
		                list[i].date_updated = new Date().toISOString(); 
					  this.state.barNumCD.push(acc1);
				      this.state.cdfinalList.push(list[i]);
					}else{
						this.state.cdrejectList.push(list[i]);	
					}
					}
				}else{
					this.state.cdrejectList.push(list[i]);	
				}
			}
			this.setState({ uploadPercentage: 100 }, ()=>{
				setTimeout(() => {
				this.setState({ uploadPercentage: 0,showbar:false })
				}, 2000);
				setTimeout(() => {
					if(this.state.cdfinalList.length > 0){
					this.setState({confirmshow:true,rejectlist:this.state.cdrejectList.length,finallist:this.state.cdfinalList.length});
					if(this.state.cdrejectList.length > 0){
						this.setState({showtablebook:false,showtablecd:true,showtablejournal:false,showtablemember:false});
					}
				}else{
					this.setState({confirmshow:true,errormsg:false});	
				}
			}, 3000);
			}); 
			break;
		case "Journal": 
		var jourmaxid=this.state.maxjournalid;
		for(var i=0;i<=list.length-1;i++){

			if(list[i].barcode !== "" && list[i].journaltitle !== "" && list[i].pissn !== "" && list[i].journaltitle !== undefined  && list[i].pissn !== undefined  && list[i].journalpublisher !== ""  && list[i].journalstatus !== ""  && list[i].status !== ""   && list[i].journaltype !== ""  ){
				var accnum=list[i].barcode;
				var acc1=accnum.toString();
			
					if(this.state.journalbarcodeList.includes(acc1) || this.state.barNumJournal.includes(acc1) ){
						this.state.journalrejectList.push(list[i]);	
					}else{
					
						jourmaxid++;
						list[i].id=jourmaxid;
						list[i].date_created = new Date().toISOString();
						list[i].date_updated = new Date().toISOString(); 
						this.state.barNumJournal.push(acc1);
						this.state.journalfinalList.push(list[i]);
				    }
				}else{
					this.state.journalrejectList.push(list[i]);	
				}	
			}
			this.setState({ uploadPercentage: 100 }, ()=>{
				setTimeout(() => {
				this.setState({ uploadPercentage: 0,showbar:false })
				}, 2000);
				setTimeout(() => {
					if(this.state.journalfinalList.length > 0){
					this.setState({confirmshow:true,rejectlist:this.state.journalrejectList.length,finallist:this.state.journalfinalList.length});
					if(this.state.journalrejectList.length > 0){
						this.setState({showtablebook:false,showtablecd:false,showtablejournal:true,showtablemember:false});
					}	
				}else{
					this.setState({confirmshow:true,errormsg:false});	
				}  }, 3000);
			}); 
			console.log(this.state.journalrejectList);
			break;

			case "Member": 
			var membermaxid=this.state.maxmemberid;
			for(var i=0;i<=list.length-1;i++){

					if(list[i].cardid !== "" && list[i].registrationtype !== "" && list[i].email !== "" && list[i].email !== undefined  && list[i].cardid !== undefined  && list[i].department !== ""  && list[i].batch !== ""  && list[i].status !== ""  && list[i].dateofbirth !== "" ){
						var accnum=list[i].cardid ;
						var acc1=accnum.toString();
					
							if(this.state.memberbarcodeList.includes(acc1) || this.state.barNumMember.includes(acc1) ){
								this.state.memberrejectList.push(list[i]);	
							}else{
								var dofB=this.checkDateformat(list[i].dateofbirth);
						        if(dofB.isvalid){		
								membermaxid++;
								list[i].id=membermaxid;
								list[i].dateofbirth=dofB.outdate;
								list[i].barcode = list[i].cardid ;
								list[i].date_created = new Date().toISOString();
								list[i].date_updated = new Date().toISOString(); 
								this.state.barNumMember.push(acc1);
								this.state.memberfinalList.push(list[i]);
						     }else{
								this.state.memberrejectList.push(list[i]);	
						     }
					}
				
				}else{
					this.state.memberrejectList.push(list[i]);	
				}
				}
				this.setState({ uploadPercentage: 100 }, ()=>{
					setTimeout(() => {
					this.setState({ uploadPercentage: 0,showbar:false })
					}, 2000);
					setTimeout(() => {
					if(this.state.memberfinalList.length > 0){
						this.setState({confirmshow:true,rejectlist:this.state.memberrejectList.length,finallist:this.state.memberfinalList.length});
						if(this.state.memberrejectList.length >0){
							this.setState({showtablebook:false,showtablecd:false,showtablejournal:false,showtablemember:true});
						}	
					}else{
						this.setState({confirmshow:true,errormsg:false});	
					}  }, 3000);
				}); 
				break;
		}		
	
}
uploadXlsx = (xldata,type) => {
	this.clearAll();
	this.setState({ uploadPercentage: 1,showbar:true });
	const data = new FormData() 
	data.append('file', xldata);
	let fileObj = xldata;
	data.append('myfile', fileObj);
	const options = {
		onUploadProgress: (progressEvent) => {
		  const {loaded, total} = progressEvent;
		  let percent = Math.floor( (loaded * 100) / total )
		  //console.log( `${loaded}kb of ${total}kb | ${percent}%` );
  
		  if( percent < 100 ){
			this.setState({ uploadPercentage: percent })
		  }
		}
	  }
	  console.log(data);
	axios.post(base_url+'file/uploadxlsx',data,options, this.props.user.header).then(res =>{ 
		console.log(res.data.result);
		if(res.data.result == "success"){
			var temp=res.data.data[0].data;
			console.log(res.data.result);
			this.setState({  uploadPercentage: 100 }, ()=>{
				setTimeout(() => {
				  this.setState({ uploadPercentage: 0,showbar:false })
				}, 1000);
			  });
			switch(type){
			case "Books": this.checkBarcode(temp,type); this.setState({type:"Books"});  break;
			case "Cddvd":  this.checkBarcode(temp,type); this.setState({type:"Cddvd"}); break;
			case "Journal":this.checkBarcode(temp,type); this.setState({type:"Journal"});break;
			case "Member":this.checkBarcode(temp,type); this.setState({type:"Member"});break;
			}
		}else{
			this.setState({showfail:true,showbar:false});
		}	 
	}).catch(function (error) {
		 console.log(error); 
		}); 
}

onChangecatalog=(type,e)=>{
	var image=e.target.files[0];
	if(image !== "" &&  image !== undefined){
	if (!image.name.match(/\.(xlsx|.XLSX)$/)) {
		this.setState({show:true,btnhide:true,btnhide2:false,btnhide3:false,btnhide4:false});
	}else{
		switch(type){
			case "Books":  this.setState({bookcatalogxlsx: e.target.files[0],btnhide:false}); break;
			case "Cddvd":   this.setState({cdcatalogxlsx: e.target.files[0],btnhide2:false}); break;
			case "Journal": this.setState({journalcatalogxlsx: e.target.files[0],btnhide3:false}); break;
			case "Member": this.setState({memberdetailsgxlsx: e.target.files[0],btnhide4:false});break;
		}
	}
	}else{
	this.setState({btnhide:true,btnhide2:true,btnhide3:true,btnhide4:true,bookcatalogxlsx: "",cdcatalogxlsx:" ",journalcatalogxlsx: "",memberdetailsgxlsx:""});  
	}
}
renderbookrejectlist = () => {
	if( this.state.bookrejectList != undefined && this.state.bookrejectList.length > 0){
		return this.state.bookrejectList.map((item, index) =>{
			return (
				<tr key={index} id={index}>
					<td>{item.accnumber}</td>
					<td>{item.booktitle}</td>
					<td>{item.isbnnumber}</td>
					<td>{item.bookstatus}</td>
				</tr>
				
				)
		}, this);
	}
}

renderjournalrejectlist = () => {
	if( this.state.journalrejectList != undefined && this.state.journalrejectList.length > 0){
		return this.state.journalrejectList.map((item, index) =>{
			return (
				<tr key={index} id={index}>
					<td>{item.barcode}</td>
					<td>{item.journaltitle}</td>
					<td>{item.journalpublisher}</td>
					<td>{item.journaltype}</td>
				</tr>
				
				)
		}, this);
	}
}
rendercddvdrejectlist = () => {
	if( this.state.cdrejectList != undefined && this.state.cdrejectList.length > 0){
		return this.state.cdrejectList.map((item, index) =>{
			return (
				<tr key={index} id={index}>
					<td>{item.cdnumber}</td>
					<td>{item.cdtitle}</td>
					<td>{item.authorname}</td>
				</tr>
				
				)
		}, this);
	}
}

rendermemberrejectlist = () => {
	if( this.state.memberrejectList != undefined && this.state.memberrejectList.length > 0){
		return this.state.memberrejectList.map((item, index) =>{
			return (
				<tr key={index} id={index}>
					<td>{item.cardid}</td>
					<td>{item.firstname}</td>
					<td>{item.lastname}</td>
					<td>{item.email}</td>
					<td>{item.cellphone}</td>
				</tr>
				
				)
		}, this);
	}
}


render() {
var booksformat=base_url+'file/downloadxlsx/bookcatalog_Upload_sample.xlsx';
var cdformat=base_url+'file/downloadxlsx/cdcatalog_Upload_sample.xlsx';
var journalformat=base_url+'file/downloadxlsx/journalcatalog_Upload_sample.xlsx';
var membersformat=base_url+'file/downloadxlsx/member_Upload_sample.xlsx';

return (
<div className="container mt-3">
	<div className="shadow-sm p-3 mb-5 bg-white rounded">
	<div className="d-flex row">
	<div className="p-2 flex-fill">
	{this.state.showSuccessMsg ?
			<div className="p-2">
				<FlashMessage duration={3000} persistOnHover={true}>
				{flashMessage('File Uploaded Successfully', 'Success')}
				</FlashMessage>	
			</div>
			:null
			}
			</div>
			</div>
	<div className="d-flex row">
		<div className="p-2 col-md-3">
			<p className="label-custom">Upload BooksCatalog:</p>
		</div>
		<div className="p-2 col-md-3">
			<input type="file" className="form-control-file" name="file" accept=".xlsx"  onChange={(e)=>{this.onChangecatalog("Books",e)}}/>
		</div>
		<div className=" p-2 col-md-2">
			<button type="button" className="btn btn-primary btn-sm" disabled={this.state.btnhide} onClick={()=>{this.uploadXlsx(this.state.bookcatalogxlsx,"Books")}}>Upload</button>	
		</div>
		<div className="p-2 col-md-3">
		<a href={booksformat}><button type="button" className="btn btn-warning btn-sm">Sample File</button></a>	
		</div>
		</div>

		<div className="d-flex row">
				<div className="p-2 col-md-3">
					<p className="label-custom">Upload CDDVDCatalog:</p>
				</div>
				<div className="p-2 col-md-3">
					<input type="file" className="form-control-file" name="file" accept=".xlsx"  onChange={(e)=>{this.onChangecatalog("Cddvd",e)}}/>
				</div>
				<div className="p-2 col-md-2">
					<button type="button" className="btn btn-primary btn-sm" disabled={this.state.btnhide2} onClick={()=>{this.uploadXlsx(this.state.cdcatalogxlsx,"Cddvd")}}>Upload</button>	
				</div>
				<div className="p-2 col-md-3">
				<a href={cdformat}><button type="button" className="btn btn-warning btn-sm">Sample File</button></a>	
				</div>
		</div>

		<div className="d-flex row">
				<div className="p-2 col-md-3">
					<p className="label-custom">Upload JournalCatalog:</p>
				</div>
				<div className="p-2 col-md-3">
					<input type="file" className="form-control-file" name="file" accept=".xlsx"  onChange={(e)=>{this.onChangecatalog("Journal",e)}}/>
				</div>
				<div className="p-2 col-md-2">
					<button type="button" className="btn btn-primary btn-sm" disabled={this.state.btnhide3} onClick={()=>{this.uploadXlsx(this.state.journalcatalogxlsx,"Journal")}}>Upload</button>	
				</div>
				<div className="p-2 col-md-3">
				<a href={journalformat}><button type="button" className="btn btn-warning btn-sm">Sample File</button></a>	
				</div>
		</div>

		<div className="d-flex row">
				<div className="p-2 col-md-3">
					<p className="label-custom">Upload MembersDetails:</p>
				</div>
				<div className="p-2 col-md-3">
					<input type="file" className="form-control-file" name="file" accept=".xlsx"  onChange={(e)=>{this.onChangecatalog("Member",e)}}/>
				</div>
				<div className="p-2 col-md-2">
					<button type="button" className="btn btn-primary btn-sm" disabled={this.state.btnhide4} onClick={()=>{this.uploadXlsx(this.state.memberdetailsgxlsx,"Member")}}>Upload</button>	
				</div>
				<div className="p-2 col-md-3">
				<a href={membersformat}><button type="button" className="btn btn-warning btn-sm">Sample File</button></a>	
				</div>
		</div>
			
	</div>
	

	<Modal size="sm" centered show={this.state.show} onHide={()=>{this.setState({show:false})}}>
	<ModalBody>
	<div className="d-flex justify-content-right">
	<div className="p-2">Upload Only .xlsx/.XLSX Files
	</div>
	</div>
	<div className="d-flex justify-content-end">
	<div className="p-2"><button className="btn btn-warning" onClick={()=>{this.setState({show:false})}}>CLOSE</button></div>	
	</div>
	</ModalBody>
	</Modal>
	<Modal size="md" centered show={this.state.showbar}>
	<ModalBody>
	<div className="d-flex  row">
	<div className="p-2 flex-fill">
	<div>
	<span>Upload in Progress</span>
	{ this.state.uploadPercentage > 0 && <ProgressBar  animated  now={this.state.uploadPercentage} active="true" label={`${this.state.uploadPercentage}%`} /> }
	</div>
	</div>
	</div>
	</ModalBody>
	</Modal>

	<Modal size="lg" centered show={this.state.confirmshow} onHide={()=>{this.setState({confirmshow:false})}}>
	<ModalBody>
	{this.state.errormsg?<div>	
	<div className="d-flex justify-content-right">
	<div className="p-2">
	<span>File Processed Successfully!!!<br/>
	Total Number of Good  Record is : <b>{this.state.finallist} </b><br/> 
	Total Number of Bad Record is : <b>{this.state.rejectlist} </b><br/>
	</span>
	</div>
	</div>
	{this.state.showtablebook?
	<div className="p-2">
	<span>Rejected Data List</span>
	<div className="scrollbar">	
	<table className="table">
	<tbody>{this.renderbookrejectlist()}</tbody>
	</table></div></div>
	:null}
	{this.state.showtablecd?
	<div className="p-2">
		<span>Rejected Data List</span>
		<div className="scrollbar">	
		<table className="table">
		<tbody>{this.rendercddvdrejectlist()}</tbody>
		</table>
		</div></div>
		:null}
		{this.state.showtablejournal?
		<div className="p-2">
		<span>Rejected Data List</span>
	    <div className="scrollbar">	
	    <table className="table">
		<tbody>{this.renderjournalrejectlist()}</tbody>
		</table></div></div>:null}
		{this.state.showtablemember?
		<div className="p-2">
		<span>Rejected Data List</span>
		<div className="scrollbar">	
		<table className="table">
		<tbody>{this.rendermemberrejectlist()}</tbody></table>
	  </div>
	</div>:null}

	<div className="d-flex justify-content-end">
	<div className="p-2"><button className="btn btn-warning" onClick={()=>{this.setState({confirmshow:false})}}>Cancel</button></div>	
	<div className="p-2"><button className="btn btn-success" onClick={()=>{this.save(this.state.type)}}>Continue</button></div>	
	</div>
	</div>
	:<div>
	<div className="d-flex justify-content-right">
	<div className="p-2">
	<span>File Processed Successfully!!!<br/><br/>Something Went Wrong.<br/>Please check the correct Date format(dd-mm-yyyy) for all Date feilds and provide The header & value as given in sample and upload the file.</span>
	</div>
	</div>
	<div className="d-flex justify-content-end">
	<div className="p-2"><button className="btn btn-warning" onClick={()=>{this.setState({confirmshow:false})}}>Cancel</button></div>	
	</div>
	</div>
	}
	</ModalBody>
	</Modal>
	
	<Modal size="md" centered show={this.state.invaliddate}>
	<ModalBody>
	<div className="d-flex justify-content-right">
	<div className="p-2 flex-fill">
	<span>Please Provide the date in dd-mm-yyyy format separeted by '-'. </span>
	</div>
	</div>
	<div className="d-flex justify-content-end">
	<div className="p-2"><button className="btn btn-warning" onClick={()=>{this.setState({invaliddate:false})}}>OK</button></div>	
	</div>
	</ModalBody>
	</Modal>
	<Modal size="md" centered show={this.state.showsucess}>
	<ModalBody>
	<div className="d-flex justify-content-right">
	<div className="p-2 flex-fill">
	<span>File Inserted Successfully.</span>
	</div>
	</div>
	<div className="d-flex justify-content-end">
	<div className="p-2"><button className="btn btn-warning" onClick={()=>{this.setState({showsucess:false})}}>OK</button></div>	
	</div>
	</ModalBody>
	</Modal>
	<Modal size="md" centered show={this.state.showfail}>
	<ModalBody>
	<div className="d-flex justify-content-right">
	<div className="p-2 flex-fill">
	<span>Please upload the correct data inside the xlsx sheet.</span>
	</div>
	</div>
	<div className="d-flex justify-content-end">
	<div className="p-2"><button className="btn btn-warning" onClick={()=>{this.setState({showfail:false})}}>OK</button></div>	
	</div>
	</ModalBody>
	</Modal>


	</div>
	)
	}
}

const mapToProps = ({ loggedUserState }) => ({ user: loggedUserState })
export default connect(mapToProps)( UploaCatalogSetting);
