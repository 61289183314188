///////////////////////////////////////////////////////////////////////////////////////////////
// Warning : Property of Deemsoft Global Solutions LLC, It is illegal to make copy of this code
// Author : Gangaswamy Kempahonnaiah
///////////////////////////////////////////////////////////////////////////////////////////////
export const Checkin = {
	membershipid : '', 
	cardid : '', 
	accnumber : '', 
	barcode : '', 
	isbnnumber : '', 
	booktitle : '', 
	receiveddate : '', 
	notes : '', 
	id : '',
	trackcode :'',
	status : '',
	firstname:'',
	bookstatus:'',
	lastname:'',
	price:'',
	totalfine:'',
	bookprice:'',
	rollno:'', 
	status:'',
	createdby : '', 
	updatedby : '', 
	date_created : '', 
	date_updated : '', 
}