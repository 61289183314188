///////////////////////////////////////////////////////////////////////////////////////////////
// Warning : Property of Deemsoft Global Solutions LLC, It is illegal to make copy of this code
// Author : Gangaswamy Kempahonnaiah
///////////////////////////////////////////////////////////////////////////////////////////////
export const Vendors = {
	title : '', 
	service : '', 
	firstname : '', 
	lastname : '', 
	phone : '', 
	email : '', 
	company : '', 
	designation : '', 
	address1 : '', 
	address2 : '', 
	city : '', 
	state : '', 
	country : '', 
	zipcode : '', 
	url : '', 
	notes : '', 
	status : '', 
	eventsId : '', 
	id : '', 
	createdby : '', 
	updatedby : '', 
	date_created : '', 
	date_updated : '', 
}