////////////////////////////////////////////////////////////////////////////////////////////////////
// Warning : Property of Deemsoft Global Solutions LLC, USA. It is illegal to make copy of this code
// Author : Gangaswamy Kempahonnaiah
////////////////////////////////////////////////////////////////////////////////////////////////////
import React from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { FaPen, FaTimes } from 'react-icons/fa';
import { Button, Modal, ModalBody, ModalFooter } from 'react-bootstrap';
import { base_url } from '../constants/Constants';
import { Validators } from '../utils/Validators';
import { Formators } from '../utils/Formators';
import { Vendors } from '../models/Vendors';
import '../styles/Styles.css';

class VendorsForm extends React.Component {
	constructor(props){
		super(props);
		this.state = {
			objList : [],
			showModal : false,
			validations : {},
			formObj : Vendors,
		}
		this.getObjList = this.getObjList.bind(this);
		this.saveObj = this.saveObj.bind(this);
		this.addObj = this.addObj.bind(this);
		this.editObj = this.editObj.bind(this);
		this.validate = this.validate.bind(this);
		this.format = this.format.bind(this);
		this.deleteObj = this.deleteObj.bind(this);
	}

	componentDidMount(){
		let tempObj = {};
		for (let key in Vendors) { tempObj[key] = ""; }
		this.setState({validations:tempObj});
		this.getObjList();
	}

	getObjList = () => {
		axios.get(base_url+'vendors/list', this.props.user.header ).then(res => {
			if ( res.data.length > 0 ){
				this.setState({ objList:res.data});
			}
		}).catch(function (error) { console.log(error); });
	}
	saveObj = () => {
		axios.post(base_url+'vendors/save', this.state.formObj, this.props.user.header ).then(res => {
			if ( res.data.length > 0 ){
				this.setState({ formObj:res.data});
				this.getObjList();
			}
		}).catch(function (error) { console.log(error); });
	}

	addObj = () => { 
		let tempObj = {};
		for (let key in Vendors ) { tempObj[key] = ""; }
		this.setState({ formObj:tempObj, showModal:true});
	}

	editObj = (i) => { 
		let tempObj = {};
		for (let key in this.state.objList[i] ) { tempObj[key] = this.state.objList[i][key]; }
		this.setState({ formObj:tempObj, showModal:true});
	}

	deleteObj = (i) => { 
		let tempObj = this.state.objList[i];
		tempObj.status = 'Inactive';
		axios.post(base_url+'vendors/save', tempObj , this.props.user.header ).then(res =>{
			if ( res.data.length > 0 ){
				this.setState({ formObj:res.data});
				this.getObjList();
			}
		}).catch(function (error) { console.log(error); });
	}

	validate = (val) => {
		let tempObj = this.state.validations;
		switch(val){
			case "title": tempObj.title = Validators.name(this.state.formObj.title); break;
			case "service": tempObj.service = Validators.name(this.state.formObj.service); break;
			case "firstname": tempObj.firstname = Validators.name(this.state.formObj.firstname); break;
			case "lastname": tempObj.lastname = Validators.name(this.state.formObj.lastname); break;
			case "phone": tempObj.phone = Validators.name(this.state.formObj.phone); break;
			case "email": tempObj.email = Validators.name(this.state.formObj.email); break;
			case "company": tempObj.company = Validators.name(this.state.formObj.company); break;
			case "designation": tempObj.designation = Validators.name(this.state.formObj.designation); break;
			case "address1": tempObj.address1 = Validators.name(this.state.formObj.address1); break;
			case "address2": tempObj.address2 = Validators.name(this.state.formObj.address2); break;
			case "city": tempObj.city = Validators.name(this.state.formObj.city); break;
			case "state": tempObj.state = Validators.name(this.state.formObj.state); break;
			case "country": tempObj.country = Validators.name(this.state.formObj.country); break;
			case "zipcode": tempObj.zipcode = Validators.name(this.state.formObj.zipcode); break;
			case "url": tempObj.url = Validators.name(this.state.formObj.url); break;
			case "notes": tempObj.notes = Validators.name(this.state.formObj.notes); break;
			case "status": tempObj.status = Validators.name(this.state.formObj.status); break;
			case "events_id": tempObj.events_id = Validators.name(this.state.formObj.events_id); break;
		}
		this.setState({validations:tempObj});
	 } 

	format = (val, e) => { 
		let tempObj = this.state.formObj;
		switch(val){
			case "title": tempObj.title = Formators.name(e.target.value); break;
			case "service": tempObj.service = Formators.name(e.target.value); break;
			case "firstname": tempObj.firstname = Formators.name(e.target.value); break;
			case "lastname": tempObj.lastname = Formators.name(e.target.value); break;
			case "phone": tempObj.phone = Formators.name(e.target.value); break;
			case "email": tempObj.email = Formators.name(e.target.value); break;
			case "company": tempObj.company = Formators.name(e.target.value); break;
			case "designation": tempObj.designation = Formators.name(e.target.value); break;
			case "address1": tempObj.address1 = Formators.name(e.target.value); break;
			case "address2": tempObj.address2 = Formators.name(e.target.value); break;
			case "city": tempObj.city = Formators.name(e.target.value); break;
			case "state": tempObj.state = Formators.name(e.target.value); break;
			case "country": tempObj.country = Formators.name(e.target.value); break;
			case "zipcode": tempObj.zipcode = Formators.name(e.target.value); break;
			case "url": tempObj.url = Formators.name(e.target.value); break;
			case "notes": tempObj.notes = Formators.name(e.target.value); break;
			case "status": tempObj.status = Formators.name(e.target.value); break;
			case "events_id": tempObj.events_id = Formators.name(e.target.value); break;
		}
		this.setState({formObj:tempObj});
	 } 

	renderObjlist = () => {
		if( this.state.objList != undefined && this.state.objList.length > 0){
			return this.state.objList.map((item, index) =>{
				return (
					<tr key={index} id={index}>
						<td>{item.title}</td>
						<td>{item.service}</td>
						<td>{item.firstname}</td>						
						<td>{item.phone}</td>
						<td>{item.email}</td>
						<td>{item.company}</td>
						<td>{item.designation}</td>						
						<td>{item.url}<span><FaPen size="15px" color="blue" onClick={()=>{this.editObj(index)}}/>&nbsp;&nbsp;
						<FaTimes size="15px" color="red" onClick={() => {this.deleteObj(index)}} />&nbsp;&nbsp;</span></td>
					</tr>
					)
			 }, this);
		}
	}

	render() {
		return (
		<div class="container mt-3">
			<label className="label-custom">VENDORS LIST</label>
			<table className="table table-bordered table-sm">
			<thead className="thead">
				<tr>
					<td>Title</td>
					<td>Service</td>
					<td>Firstname</td>					
					<td>Phone</td>
					<td>Email</td>
					<td>Company</td>
					<td>Designation</td>					
					<td>Url</td>					
				</tr>
			</thead>
			<tbody>{ this.renderObjlist() }</tbody> 
			</table>
			<div className="d-flex justify-content-end mb-3">
				<div className="p-2"><button type="button" className="btn btn-primary btn-block btn-sm" onClick={()=>{this.addObj()}}>+Add</button></div>
			</div>
			<Modal size="lg" show={this.state.showModal} onHide={()=>{this.setState({showModal:false})}} >
			<Modal.Header closeButton>
			<Modal.Title>VENDORS</Modal.Title>
			</Modal.Header>
			<ModalBody>
				<div className="d-flex flex-wrap bg-light">
					<div className="p-2 border">
						<label htmlFor="idtitle" className="label-custom" >Title</label> 
						<input value={this.state.formObj.title} id="idtitle"  onChange={(e)=>{this.format("title",e)}}  placeholder="Enter Title" onBlur={()=>{ this.validate("title")}} className="form-control form-control-sm" style={this.state.validations.email===""?{}:{"borderColor":"red"}} type="text" className='form-control form-control-sm' required /> 
						<span className="errormsg">{this.state.validations.staffname}</span>
					</div>
					<div className="p-2 border">
						<label htmlFor="idservice" className="label-custom" >Service</label> 
						<input value={this.state.formObj.service} id="idservice"  onChange={(e)=>{this.format("service",e)}}  placeholder="Enter Service" onBlur={()=>{ this.validate("service")}} className="form-control form-control-sm" style={this.state.validations.email===""?{}:{"borderColor":"red"}} type="text" className='form-control form-control-sm' required /> 
						<span className="errormsg">{this.state.validations.staffname}</span>
					</div>
					<div className="p-2 border">
						<label htmlFor="idfirstname" className="label-custom" >Firstname</label> 
						<input value={this.state.formObj.firstname} id="idfirstname"  onChange={(e)=>{this.format("firstname",e)}}  placeholder="Enter Firstname" onBlur={()=>{ this.validate("firstname")}} className="form-control form-control-sm" style={this.state.validations.email===""?{}:{"borderColor":"red"}} type="text" className='form-control form-control-sm' required /> 
						<span className="errormsg">{this.state.validations.staffname}</span>
					</div>
					<div className="p-2 border">
						<label htmlFor="idlastname" className="label-custom" >Lastname</label> 
						<input value={this.state.formObj.lastname} id="idlastname"  onChange={(e)=>{this.format("lastname",e)}}  placeholder="Enter Lastname" onBlur={()=>{ this.validate("lastname")}} className="form-control form-control-sm" style={this.state.validations.email===""?{}:{"borderColor":"red"}} type="text" className='form-control form-control-sm' required /> 
						<span className="errormsg">{this.state.validations.staffname}</span>
					</div>
					<div className="p-2 border">
						<label htmlFor="idphone" className="label-custom" >Phone</label> 
						<input value={this.state.formObj.phone} id="idphone"  onChange={(e)=>{this.format("phone",e)}}  placeholder="Enter Phone" onBlur={()=>{ this.validate("phone")}} className="form-control form-control-sm" style={this.state.validations.email===""?{}:{"borderColor":"red"}} type="text" className='form-control form-control-sm' required /> 
						<span className="errormsg">{this.state.validations.staffname}</span>
					</div>
					<div className="p-2 border">
						<label htmlFor="idemail" className="label-custom" >Email</label> 
						<input value={this.state.formObj.email} id="idemail"  onChange={(e)=>{this.format("email",e)}}  placeholder="Enter Email" onBlur={()=>{ this.validate("email")}} className="form-control form-control-sm" style={this.state.validations.email===""?{}:{"borderColor":"red"}} type="text" className='form-control form-control-sm' required /> 
						<span className="errormsg">{this.state.validations.staffname}</span>
					</div>
					<div className="p-2 border">
						<label htmlFor="idcompany" className="label-custom" >Company</label> 
						<input value={this.state.formObj.company} id="idcompany"  onChange={(e)=>{this.format("company",e)}}  placeholder="Enter Company" onBlur={()=>{ this.validate("company")}} className="form-control form-control-sm" style={this.state.validations.email===""?{}:{"borderColor":"red"}} type="text" className='form-control form-control-sm' required /> 
						<span className="errormsg">{this.state.validations.staffname}</span>
					</div>
					<div className="p-2 border">
						<label htmlFor="iddesignation" className="label-custom" >Designation</label> 
						<input value={this.state.formObj.designation} id="iddesignation"  onChange={(e)=>{this.format("designation",e)}}  placeholder="Enter Designation" onBlur={()=>{ this.validate("designation")}} className="form-control form-control-sm" style={this.state.validations.email===""?{}:{"borderColor":"red"}} type="text" className='form-control form-control-sm' required /> 
						<span className="errormsg">{this.state.validations.staffname}</span>
					</div>
					<div className="p-2 border">
						<label htmlFor="idaddress1" className="label-custom" >Address1</label> 
						<input value={this.state.formObj.address1} id="idaddress1"  onChange={(e)=>{this.format("address1",e)}}  placeholder="Enter Address1" onBlur={()=>{ this.validate("address1")}} className="form-control form-control-sm" style={this.state.validations.email===""?{}:{"borderColor":"red"}} type="text" className='form-control form-control-sm' required /> 
						<span className="errormsg">{this.state.validations.staffname}</span>
					</div>
					<div className="p-2 border">
						<label htmlFor="idaddress2" className="label-custom" >Address2</label> 
						<input value={this.state.formObj.address2} id="idaddress2"  onChange={(e)=>{this.format("address2",e)}}  placeholder="Enter Address2" onBlur={()=>{ this.validate("address2")}} className="form-control form-control-sm" style={this.state.validations.email===""?{}:{"borderColor":"red"}} type="text" className='form-control form-control-sm' required /> 
						<span className="errormsg">{this.state.validations.staffname}</span>
					</div>
					<div className="p-2 border">
						<label htmlFor="idcity" className="label-custom" >City</label> 
						<input value={this.state.formObj.city} id="idcity"  onChange={(e)=>{this.format("city",e)}}  placeholder="Enter City" onBlur={()=>{ this.validate("city")}} className="form-control form-control-sm" style={this.state.validations.email===""?{}:{"borderColor":"red"}} type="text" className='form-control form-control-sm' required /> 
						<span className="errormsg">{this.state.validations.staffname}</span>
					</div>
					<div className="p-2 border">
						<label htmlFor="idstate" className="label-custom" >State</label> 
						<input value={this.state.formObj.state} id="idstate"  onChange={(e)=>{this.format("state",e)}}  placeholder="Enter State" onBlur={()=>{ this.validate("state")}} className="form-control form-control-sm" style={this.state.validations.email===""?{}:{"borderColor":"red"}} type="text" className='form-control form-control-sm' required /> 
						<span className="errormsg">{this.state.validations.staffname}</span>
					</div>
					<div className="p-2 border">
						<label htmlFor="idcountry" className="label-custom" >Country</label> 
						<input value={this.state.formObj.country} id="idcountry"  onChange={(e)=>{this.format("country",e)}}  placeholder="Enter Country" onBlur={()=>{ this.validate("country")}} className="form-control form-control-sm" style={this.state.validations.email===""?{}:{"borderColor":"red"}} type="text" className='form-control form-control-sm' required /> 
						<span className="errormsg">{this.state.validations.staffname}</span>
					</div>
					<div className="p-2 border">
						<label htmlFor="idzipcode" className="label-custom" >Zipcode</label> 
						<input value={this.state.formObj.zipcode} id="idzipcode"  onChange={(e)=>{this.format("zipcode",e)}}  placeholder="Enter Zipcode" onBlur={()=>{ this.validate("zipcode")}} className="form-control form-control-sm" style={this.state.validations.email===""?{}:{"borderColor":"red"}} type="text" className='form-control form-control-sm' required /> 
						<span className="errormsg">{this.state.validations.staffname}</span>
					</div>
					<div className="p-2 border">
						<label htmlFor="idurl" className="label-custom" >Url</label> 
						<input value={this.state.formObj.url} id="idurl"  onChange={(e)=>{this.format("url",e)}}  placeholder="Enter Url" onBlur={()=>{ this.validate("url")}} className="form-control form-control-sm" style={this.state.validations.email===""?{}:{"borderColor":"red"}} type="text" className='form-control form-control-sm' required /> 
						<span className="errormsg">{this.state.validations.staffname}</span>
					</div>
					<div className="p-2 border">
						<label htmlFor="idnotes" className="label-custom" >Notes</label> 
						<input value={this.state.formObj.notes} id="idnotes"  onChange={(e)=>{this.format("notes",e)}}  placeholder="Enter Notes" onBlur={()=>{ this.validate("notes")}} className="form-control form-control-sm" style={this.state.validations.email===""?{}:{"borderColor":"red"}} type="text" className='form-control form-control-sm' required /> 
						<span className="errormsg">{this.state.validations.staffname}</span>
					</div>
					<div className="p-2 border">
						<label htmlFor="idstatus" className="label-custom" >Status</label> 
						<input value={this.state.formObj.status} id="idstatus"  onChange={(e)=>{this.format("status",e)}}  placeholder="Enter Status" onBlur={()=>{ this.validate("status")}} className="form-control form-control-sm" style={this.state.validations.email===""?{}:{"borderColor":"red"}} type="text" className='form-control form-control-sm' required /> 
						<span className="errormsg">{this.state.validations.staffname}</span>
					</div>
					<div className="p-2 border">
						<label htmlFor="idevents_id" className="label-custom" >Events_Id</label> 
						<input value={this.state.formObj.events_id} id="idevents_id"  onChange={(e)=>{this.format("events_id",e)}}  placeholder="Enter Events_Id" onBlur={()=>{ this.validate("events_id")}} className="form-control form-control-sm" style={this.state.validations.email===""?{}:{"borderColor":"red"}} type="text" className='form-control form-control-sm' required /> 
						<span className="errormsg">{this.state.validations.staffname}</span>
					</div>
				</div>
			</ModalBody>
			<ModalFooter>
				<Button variant="warning" onClick={()=>{this.setState({showModal:false})}}>CLOSE</Button>
				<Button variant="primary" onClick={()=>{this.saveObj()}}>SAVE</Button>
			</ModalFooter>
			</Modal>
			</div>
		)
		}
	}

const mapToProps = ({ loggedUserState }) => ({ user: loggedUserState })
export default connect(mapToProps)(VendorsForm);
