///////////////////////////////////////////////////////////////////////////////////////////////
// Warning : Property of Deemsoft Global Solutions LLC, It is illegal to make copy of this code
// Author : Gangaswamy Kempahonnaiah
///////////////////////////////////////////////////////////////////////////////////////////////
export const Setting = {
	registrationTypes :[],
	departmentTypes :[],
	issueTypes :[],
	subjectTypes : [],
	batchTypes : [],
	bookTypes : [],
	dateformats : '',
	journalTypes : [],
	labelTypes :[],
	billTypes: [],
    smstemplate:[],
	defaultdate:'',
	emailtemplate:[],
	autoemailservice:"",
	autosmsservice:"",
	id : '',
	createdby : '', 
	updatedby : '', 
	date_created : '', 
	date_updated : '',
    
}